import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width: 75px;
    height: 75px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
   
    border-radius: 50%;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
  }
  & svg{
    border-bottom-left-radius: 43%;
    border-bottom-right-radius: 50%;
    margin-top: 3px;
  }
`


export  const  ButtonAvatarMen =  ({func,avatarId})  => {
    return(
      <Button  onClick= {e => func(avatarId)}>   
         <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="65px" height="65px" viewBox="0 0 198 198.43" enableBackground="new 0 0 198 198.43" space="preserve">
            <g>
                <g>
                    <rect x="83.566" y="148.48" fill="#FDCC9B" width="29.209" height="32.716"/>
                    <path fill="#FCBC85" d="M83.568,152.334c0,0,12.288,7.824,29.21,6.011v-9.867h-29.21V152.334z"/>
                    <ellipse fill="#FCBC85" cx="41.445" cy="97.751" rx="14.252" ry="16.26"/>
                    <ellipse fill="#FCBC85" cx="154.897" cy="97.751" rx="14.254" ry="16.26"/>
                    <path fill="#FDCC9B" d="M154.841,66.691c0-23.953-17.526-41.479-56.67-41.479c-39.14,0-56.669,17.526-56.669,41.479
                        c0,23.953-4.088,87.047,56.669,87.047C158.93,153.739,154.841,90.644,154.841,66.691z"/>
                    <g>
                        <g>
                            <ellipse fill="#3B2519" cx="70.57" cy="93.856" rx="6.134" ry="6.719"/>
                            <circle fill="#FFFFFF" cx="67.897" cy="90.889" r="1.834"/>
                        </g>
                        <path fill="#51362A" d="M60.757,77.207c2.9,1.451,7.558-4.929,18.219,0.622c1.944,1.011,3.105-8.654-8.324-8.654
                            C60.757,69.174,59.004,76.331,60.757,77.207z"/>
                        <g>
                            <ellipse fill="#3B2519" cx="126.531" cy="93.856" rx="6.134" ry="6.719"/>
                            <circle fill="#FFFFFF" cx="123.856" cy="90.889" r="1.834"/>
                        </g>
                        <path fill="#51362A" d="M135.586,77.207c-2.9,1.451-7.557-4.929-18.22,0.622c-1.944,1.011-3.104-8.654,8.325-8.654
                            C135.586,69.174,137.339,76.331,135.586,77.207z"/>
                    </g>
                    <path fill="#FCBC85" d="M98.171,117.489c-6.06,0-9.712-4.528-9.712-2.338c0,2.191,1.754,6.354,9.712,6.354
                        c7.96,0,9.713-4.163,9.713-6.354S104.233,117.489,98.171,117.489z"/>
                    <path fill="#FCBC85" d="M98.171,137.631c-2.096,0-3.357-1.565-3.357-0.809c0,0.758,0.605,2.198,3.357,2.198
                        c2.755,0,3.36-1.439,3.36-2.198C101.531,136.066,100.269,137.631,98.171,137.631z"/>
                    <path fill="#F7945E" d="M98.171,132.049c-9.606,0-15.394-3.911-15.394-2.921s2.778,4.895,15.394,4.895
                        c12.62,0,15.396-3.904,15.396-4.895C113.565,128.137,107.779,132.049,98.171,132.049z"/>
                </g>
                <path fill="#F7941E" d="M98.171,161.624v36.806H27.192C27.192,183.533,64.58,161.624,98.171,161.624z"/>
                <path fill="#F7941E" d="M98.171,161.624v36.806h70.98C169.151,183.533,131.764,161.624,98.171,161.624z"/>
                <path fill="#F36C21" d="M98.171,161.624c-7.143,0-14.459,0.999-21.552,2.703c0.049,0.063,7.867,10.15,21.552,10.15
                    c12.675,0,21.102-8.639,22.323-9.967C113.163,162.691,105.575,161.624,98.171,161.624z"/>
                <path fill="#FDCC9B" d="M83.566,162.898c0,0,4.272,5.299,14.605,5.299c10.332,0,14.604-5.299,14.604-5.299
                    S97.623,157.382,83.566,162.898z"/>
                <path fill="#51362A" d="M144.762,20.101c0,0-21.033-26.728-64.744-16.65c-43.71,10.076-46.986,36.368-45.67,61.943
                    c1.313,25.58,4.819,40.584,8.964,38.73c4.145-1.856,1.988-18.739,2.427-22.635c0.438-3.897,4.381-21.518,30.966-18.889
                    c26.585,2.629,48.987-7.011,48.987-7.011s9.017,11.399,14.952,13.583c11.859,4.365,7.97,33.458,12.615,33.458
                    C157.909,102.632,180.69,35.436,144.762,20.101z"/>
            </g>
            </svg>
        
      </Button>   
    )
}