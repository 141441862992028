import React from 'react'
import styled from 'styled-components'
import { styleSelected , unSelected} from '../styles'

const ButtonForm = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
border-radius:10px;
&:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    bottom: auto;
    left: 6px;
    right: auto;
    top: -13px;
    border: 6px solid;
    border-color: transparent transparent #8a95ad #8a95ad;
}
`


export  const  CallOutsFormRadiusTop = ({func, itemSelected}) => {
    return(
      <ButtonForm  onClick={e => { 
        window.chatbot.updateUserGlobeStyles({
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            peakPosition: "top-right"
          })
    
          window.chatbot.updateBotGlobeStyles({
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            peakPosition: "top-left"
          })
          func('CallOutsFormRadiusTop')} }
          style={itemSelected === 'CallOutsFormRadiusTop' ? styleSelected : unSelected}> 
      </ButtonForm> 
    )
}