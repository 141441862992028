import React from 'react'
import { withRouter } from "react-router";
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.SENTRY_DSN
});


export const handleUnexpectedError = error => {
  Sentry.withScope(scope => {
    scope.setExtras(error);
    //const eventId = Sentry.captureException(error);
    Sentry.captureException(error);
  });
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    //if (process.env.NODE_ENV !== 'development')  { 
      Sentry.setUser({"id": localStorage.getItem("userID")});
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId })
      });
    //}
    this.props.history.push('/error')
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>
        <h1>Something went wrong.</h1>
      </div>
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary)