import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  width: 40%;
  margin-right: 30px;
  display: inline-block;
  min-width: 200px;
  vertical-align: top;
  text-align: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 3px;
  background:transparent;
  padding:20px;
  height:186px;
  margin-top:10px;
  cursor:pointer;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
    border:1px solid #ef7e29;
  }
`


export  const  ButtonUpload = ({func}) => {
    return(
      <Button onClick= {e => func()}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="140px" height="140px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" space="preserve">
          <path fill="#324A5E" d="M512,256c0,141.388-114.612,256-256,256S0,397.387,0,256C0,114.613,114.613,0,256,0C397.387,0,512,114.613,512,256z"/>
          <g>
	          <path fill="#FFFFFF" d="M422.919,342.914h-53.781v-53.781h-30v53.781h-53.781v30h53.781v53.781h30v-53.781h53.781V342.914z"/>
          </g>
          <g>
            <g>
              <path fill="#FFFFFF" d="M319.275,268.683c33.828-20.59,55.891-57.361,55.891-100.017C375.168,103.951,322.217,51,257.5,51
                c-64.715,0-117.667,52.951-117.667,117.667c0,42.655,22.061,79.427,55.891,100.017C136.891,290.745,92.766,342.225,81,404h29.416
                c14.709-67.656,75.013-117.337,147.084-117.668C296.187,286.154,319.275,268.683,319.275,268.683z M169.25,168.667
                c0-48.538,39.713-88.25,88.25-88.25c48.535,0,88.25,39.712,88.25,88.25c0,48.536-39.715,88.251-88.25,88.251
                C208.963,256.918,169.25,217.204,169.25,168.667z"/>
            </g>
          </g>
        </svg>
      </Button>   
    )
}