import styled from 'styled-components'
import FormControlMaterial from '@material-ui/core/FormControl'
import InputLabelMaterial from '@material-ui/core/InputLabel'
import InputMaterial from '@material-ui/core/Input'
import MenuItemMaterial from '@material-ui/core/MenuItem'
import SelectMaterial from '@material-ui/core/Select'

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 20px;
    padding: 10px 0 20px;
    text-align: left;
`

export const template = styled.div`

`

export const template2 = styled(InputMaterial)`
    && {
        margin-top: 10px;
    }

`

export const FormControl = styled(FormControlMaterial)`

`

export const InputLabel = styled(InputLabelMaterial)`
    && {
        font-size: 18px;
        margin-bottom: 10px;
    }
`

export const Input = styled(InputMaterial)`
    && {
        margin-top: 15px;
        width: 90%;
        margin-bottom: 25px;
    }

`
export const Column = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    
`

export const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`

export const Select = styled(SelectMaterial)`
    && {
        width: 90%;
        margin-bottom: 25px;
    }

`

export const MenuItem = styled(MenuItemMaterial)`
    && {
        margin-top: 10px;
    }

`