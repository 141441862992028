import React from "react";
import classNames from "classnames";
import "./AnimatedInput.scss";

export default class AnimatedInput extends React.Component {
  state = {
    value: "",
    error: ""
  };

  onChangeHandler = newValue => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <div className={"animated-input"}>
        <div>
          <input
            className={classNames({
              dirty: this.state.value !== ""
            })}
            name={this.props.name}
            type={this.props.type}
            onChange={e => this.onChangeHandler(e.target.value)}
          />
          <label htmlFor={this.props.name}>{this.props.label}</label>
        </div>
        {this.props.error ? (
          <div className="error">{this.props.errorMessage}</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
