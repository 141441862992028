import React from "react";
import "./Navigation.scss";
import { withRouter } from "react-router";
import LogoWhiteBackground from "../../assets/img/BlahBoxLogo50ConMargen.png";
import NavItemHelp from "../../assets/img/nav-item-help.svg";
import NavItemProfile from "../../assets/img/nav-item-profile.svg";
import lang from '../../i18n/lang';
import i18n from  '../../i18n/i18n'
import {connect} from 'react-redux';

const tRoot = i18n()

class Navigation extends React.Component {


  render() {
  
    return (
      <section className="navigation">
        <div className="first-level-nav">
          <nav>
            <div className="logo">
              <img
                src={LogoWhiteBackground}
                alt="logo"
                onClick={() => {
                  this.props.history.push("/dashboard/projects");
                }}
              />
            </div>
           
            <div className="bottom-nav">
              <ul>
                <li >
                <a href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/home': 'https://supporten.blahbox.net/portal/home'} target="_blank" rel="noopener noreferrer">
                  <img
                    src={NavItemHelp}
                    alt="help"
                   target="_blank"
                  />
                  </a>
                  <label title={tRoot('help')}>{tRoot('help')}</label>
                </li>
                <li>
                  <img
                    src={NavItemProfile}
                    alt="profile"
                    onClick={() =>
                      this.props.history.push("/dashboard/profile")
                    }
                  />
                  <label title={tRoot('myprofile')}>{tRoot('myprofile')}</label>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    chatId: state.baseData.currentChatConfig ? state.baseData.currentChatConfig._id : ''
  }
}

const mapDispatchToProps = dispatch => { 
  return {

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Navigation))
