export const selectedCallOut={
    opacity: 0.9
}

export const unSelectedCallOut={
    opacity: 0.3
}

export const selectedFormStyle = {
    boxShadow: "5px 5px 10px rgba(0,0,0,0.2)"
}

export const unSelectedFormStyle = {
    boxShadow: "none"
}