import React from "react";
import { Switch, Route } from "react-router-dom";
import "./ChatSettings.scss";
import ChatSettingsNavigation from "../../../components/ChatSettingsNavigation/ChatSettingsNavigation";
import ChatLanding from "./ChatLanding"
import ChatConfig from "./ChatConfig/ChatConfig";
import ChatConfigElegant from "./ChatConfigElegant"
import Npl from "./Npl/Npl";
import Integration from "./Integration/Integration";
import Team from "./Team/Team";
import SuscriptionGeneral from '../../Suscription/SuscriptionGeneral/index'
import Usage from '../../Suscription/Usage/index'
import StripeSubscription from '../../Suscription/StripeSubscription'
import Thanks from '../../Suscription/Thanks/index'
import { connect } from 'react-redux'
import CheckoutSuccess from "../../Suscription/StripeSubscription/checkoutSuccess"
import Joyride from "../../../components/Joyride"
import i18n from '../../../i18n/i18n'

const tRoot = i18n()

export const UPDATE_FLOATING_ICON_POSITION = "UPDATE_FLOATING_ICON_POSITION"
export const UPDATE_FLOATING_ICON_BACKGROUND = "UPDATE_FLOATING_ICON_BACKGROUND"
export const UPDATE_FLOATING_ICON_TOOLTIP = "UPDATE_FLOATING_ICON_TOOLTIP"
export const UPDATE_FLOATING_ICON_ICON = "UPDATE_FLOATING_ICON_ICON"
export const UPDATE_FLOATING_ICON_FORM = "UPDATE_FLOATING_ICON_FORM"

export const UPDATE_HEADER_BACKGROUND = "UPDATE_HEADER_BACKGROUND"
export const UPDATE_HEADER_BORDERS = "UPDATE_HEADER_BORDERS"
export const UPDATE_HEADER_NAME = "UPDATE_HEADER_NAME"
export const UPDATE_HEADER_IMAGE = "UPDATE_HEADER_IMAGE"

export const UPDATE_DIALOGBOX_BACKGROUND = "UPDATE_DIALOGBOX_BACKGROUND"
export const UPDATE_DIALOGBOX_CALLOUTS = "UPDATE_DIALOGBOX_CALLOUTS"
export const UPDATE_SENDERSECTION_MESSAGE = "UPDATE_SENDERSECTION_MESSAGE"
export const UPDATE_SENDERSECTION_BACKGROUND = "UPDATE_SENDERSECTION_BACKGROUND"
export const UPDATE_SENDERSECTION_BUTTON = "UPDATE_SENDERSECTION_BUTTON"

export const UPDATE_BOX_SIZE = "UPDATE_BOX_SIZE"
export const EL_UPDATE_BOX_BACKGROUND = "EL_UPDATE_BOX_BACKGROUND"
export const EL_UPDATE_HEADER_IMAGE = "EL_UPDATE_HEADER_IMAGE"


class ChatSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: []
    }

  }

  setSetps = () => {

    var currentChatConfig = JSON.parse(localStorage.getItem("appState")).baseData.currentChatConfig

    if (!currentChatConfig.NLP) {
      var stepsList = [{
        target: '#NLPMenuItem',
        title: tRoot('credentials'),
        content: tRoot('tourContentNLP'),
        placementBeacon: 'right',
        placement: 'right',
        disableBeacon: true
      }
    ]


      var steps = this.state.steps
      steps.push(...stepsList)

      this.setState({ steps: steps })
    }
  }


  UNSAFE_componentWillMount() {
    this.setSetps()
  }


  render() {
    const baseUrl = this.props.match.url;
    const { steps } = this.state;
    return (
      <div className="chat-settings">
        {steps.length > 0 ?
        <Joyride
          steps={steps}
        />
        : ''}
        <ChatSettingsNavigation chatID={this.props.chat._id} style={{ display: "none" }} />
        <Switch>
          <Route
            path={baseUrl + "/chatlanding"}
            exact
            component={ChatLanding}
          />
          <Route
            path={baseUrl + "/configurations"}
            exact
            component={this.props.chat.widgetType === 'classic' ? ChatConfig : ChatConfigElegant}
          />
          <Route path={baseUrl + "/npl"} exact component={Npl} />
          <Route
            path={baseUrl + "/integration"}
            exact
            component={Integration}
          />
          <Route path={baseUrl + "/team"} exact component={Team} />
          
          <Route path={baseUrl + "/subscription"} exact component={StripeSubscription} />
          <Route path={baseUrl + "/generalSubscription"} exact component={SuscriptionGeneral} />
          <Route path={baseUrl + "/usage"} exact component={Usage} />
          <Route path={baseUrl + "/checkoutsuccess"} exact component={CheckoutSuccess} />
          <Route path={baseUrl + "/thankyou"} exact component={Thanks} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    chat: state.baseData.currentChatConfig
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSettings)
