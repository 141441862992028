import React from "react"
import classNames from "classnames"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { selectSetting, setCurrentChat } from "../../rootActions"
import NavItemConfigurations from "../../assets/img/nav-item-configurations.svg"
import "./ElegantSecondNav.scss"
import i18n from '../../i18n/i18n'
import { getChat } from "../../containers/Dashboard/ChatSettings/actionCreators.js"


const t = i18n("chatSettings")
const tRoot = i18n()

class ElegantSecondNav extends React.Component {
  state = {
    //openSettings: false,
    openSuscription: false
  }

  loadChatSettings = () => {
    this.props.selectSetting("boxSize")

    getChat(this.props.chatId).then(result => {
      this.props.setCurrentChat(result.data.data);
    })
  }


  render() {

    return (
      <nav
        className={classNames("chat-styles-settings", {
          open: this.props.openNav
        })}
      >
        <div className="second-level-nav">
          <div className="close" onClick={this.props.toggleSettings} />
          <header>
            <img src={NavItemConfigurations} alt="settings icon" />
            <h3>{tRoot('settings')}</h3>
          </header>
          <div className="chat-section-container">
            <h4>{tRoot('box')}</h4>
            <ul className="chat-config-links-container">
              <li onClick={() => { this.props.selectSetting("boxSize") }}>
                {this.props.selectedItem === "boxSize" ? " >  " + tRoot('size') : tRoot('size')}
              </li>
              <li onClick={() => { this.props.selectSetting("boxBackground") }}>
                {this.props.selectedItem === "boxBackground" ? " >  " + tRoot('background') : tRoot('background')}
              </li>
              <li onClick={() => this.props.selectSetting("boxPosition")}>
                {this.props.selectedItem === "boxPosition" ? " >  " + t('positionTittle') : t('positionTittle')}
              </li>
            </ul>
          </div>
          <div className="chat-section-container">
            <h4>{tRoot('header')}</h4>
            <ul className="chat-config-links-container">
              <li onClick={() => this.props.selectSetting("headerLogo")}>
                {this.props.selectedItem === "headerLogo" ? " >  " + tRoot('image') : tRoot('image')}
              </li>
              <li onClick={() => this.props.selectSetting("headerMenu")}>
                {this.props.selectedItem === "headerMenu" ? " >  " + tRoot('menu') : tRoot('menu')}
              </li>
            </ul>
          </div>
          <div className="chat-section-container">
            <h4>{tRoot('dialogbox')}</h4>
            <ul className="chat-config-links-container">
              <li onClick={() => this.props.selectSetting("dialogBoxCallOuts")}>
                {this.props.selectedItem === "dialogBoxCallOuts" ? " >  " + t('callOutTittle') : t('callOutTittle')}
              </li>
            </ul>
          </div>
          <div className="chat-section-container">
            <h4>{tRoot('senderSection')}</h4>
            <ul className="chat-config-links-container">
              <li onClick={() => this.props.selectSetting("placeHolderMessage")}>
                {this.props.selectedItem === "placeHolderMessage" ? " >  " + tRoot('message') : tRoot('message')}
              </li>
              <li onClick={() => this.props.selectSetting("senderSectionButton")}>
                {this.props.selectedItem === "senderSectionButton" ? " >  " + tRoot('button') : tRoot('button')}
              </li>
            </ul>
          </div>
          <div className="chat-section-container">
            <h4>{t('floatingIcon')}</h4>
            <ul className="chat-config-links-container">
              <li onClick={() => { this.props.selectSetting("floatingIconIcon") }}>
                {this.props.selectedItem === "floatingIconIcon" ? " >  " + tRoot('image') : tRoot('image')}
              </li>
              <li onClick={() => this.props.selectSetting("floatingIconForm")}>
                {this.props.selectedItem === "floatingIconForm" ? " >  " + t('shape') : t('shape')}
              </li>
              <li onClick={() => this.props.selectSetting("floatingIconTooltip")}>
                {this.props.selectedItem === "floatingIconTooltip" ? " >  " + tRoot('tooltip') : tRoot('tooltip')}
              </li>
              <li onClick={() => this.props.selectSetting("floatingIconPosition")}>
                {this.props.selectedItem === "floatingIconPosition" ? " >  " + t('badge') : t('badge')}
              </li>

            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedItem: state.baseData.settingSelected,
    chatId: state.baseData.currentChatConfig._id
  }
}


export default connect(
  mapStateToProps,
  { selectSetting, setCurrentChat }
)(withRouter(ElegantSecondNav));
