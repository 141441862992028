import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import styled from 'styled-components'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ButtonSendIconWhite } from './ImageButtonIcons/sendIconWhite';
import { ButtonSendArrowWhite } from './ImageButtonIcons/sendArrowWhite';
import { ButtonSendIconArrow } from './ImageButtonIcons/sendIconArrow';
import { ButtonSendPlain } from './ImageButtonIcons/sendPlain';
import { SketchPicker } from 'react-color';
import i18n from '../../../../../../i18n/i18n'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Feedback from '../../../../../../components/feedback'

const tRoot = i18n()
const t = i18n('chatSettings')

const ContentButtons = styled.div`
    display: grid;
    grid-template-columns:100px 100px 100px 100px;
    text-align:left;
    margin-bottom:25px;
`
const ContentForm = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr;
    text-align:left;
    margin-bottom:25px;
    grid-gap:30px;
`
const Content = styled.div`
    display: grid;
    text-align:left;
    margin-bottom:25px;
`
const Label = styled.label`
    text-align:left !important;
    font-size:18px;
    margin-bottom:15px;
`

const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`

export const styles = theme => ({
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',

        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },
    notchedOutline: {}
});


export const view = ({
    classes
}, {
    image,
    mainColor,
    background,
    colorHover,
    backgroundHover
},
    handleMainColor,
    handleBackgroundColor,
    handleHoverColor,
    handleBackgroundHover,
    selectImage,
    handleClosers
) => {

    return (
        <Content>
            <HeaderConfig title={tRoot('button')} />
            <Label>{tRoot('image')}</Label>
            <ContentButtons>
                <ButtonSendIconWhite handleSelect={selectImage} currentImage={image}></ButtonSendIconWhite>
                <ButtonSendArrowWhite handleSelect={selectImage} currentImage={image}></ButtonSendArrowWhite>
                <ButtonSendIconArrow handleSelect={selectImage} currentImage={image}></ButtonSendIconArrow>
                <ButtonSendPlain handleSelect={selectImage} currentImage={image}></ButtonSendPlain>
            </ContentButtons>
            <Label>{t('editColor')}</Label>
            <ContentForm>

                <ClickAwayListener onClickAway={handleClosers.closeColor}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                            {tRoot('color')}
                        </InputLabel>
                        <Input
                            id="color"
                            value={mainColor.color ? mainColor.color : ''}
                            classes={{ underline: classes.cssUnderline, }} />
                        <Color className="color" style={{ background: mainColor.color }} onClick={handleMainColor.handleShow} />
                        {mainColor.showChooseColor ? <SketchPicker
                            onChangeComplete={handleMainColor.pickColor}
                            color={mainColor.color} /> : ""}
                    </FormControl>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={handleClosers.closeBackground}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                            {tRoot('background')}
                        </InputLabel>
                        <Input
                            id="background"
                            value={background.color ? background.color : ''}
                            classes={{ underline: classes.cssUnderline, }} />
                        <Color className="background" style={{ background: background.color }} onClick={handleBackgroundColor.handleShow} />
                        {background.showChooseColor ? <SketchPicker
                            onChangeComplete={handleBackgroundColor.pickColor}
                            color={background.color} /> : ""}
                    </FormControl>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={handleClosers.closeColorHover}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                            {tRoot('colorhover')}
                        </InputLabel>
                        <Input
                            id="colorHover"
                            value={colorHover.color ? colorHover.color : ''}
                            classes={{ underline: classes.cssUnderline, }} />
                        <Color className="cHover" style={{ background: colorHover.color }} onClick={handleHoverColor.handleShow} />
                        {colorHover.showChooseColor ? <SketchPicker
                            onChangeComplete={handleHoverColor.pickColor}
                            color={colorHover.color} /> : ""}
                    </FormControl>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={handleClosers.closeBackgroundHover}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                            {tRoot('backgroundhover')}
                        </InputLabel>
                        <Input
                            id="backgroundHover"
                            value={backgroundHover.color ? backgroundHover.color : ''}
                            classes={{ underline: classes.cssUnderline, }} />
                        <Color className="bdHover" style={{ background: backgroundHover.color }} onClick={handleBackgroundHover.handleShow} />
                        {backgroundHover.showChooseColor ? <SketchPicker
                            onChangeComplete={handleBackgroundHover.pickColor}
                            color={backgroundHover.color} /> : ""}
                    </FormControl>
                </ClickAwayListener>
            </ContentForm>
            <Feedback seccion="Sender-Button" previewMode/>
        </Content>
    )
}