import React from 'react'
import styled from 'styled-components'
import { styleSelected , unSelected} from '../styles'

const ButtonForm = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
&:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    bottom: auto;
    left: 25px;
    right: auto;
    top: -13px;
    border: 6px solid;
    border-color: transparent transparent #8a95ad #8a95ad;
}
`


export  const  CallOutsFormSquareTopCenter = ({func, itemSelected}) => {
    return(
      <ButtonForm  onClick={e => { 
        window.chatbot.updateUserGlobeStyles({
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            peakPosition: "top-center",
        })

        window.chatbot.updateBotGlobeStyles({
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            peakPosition: "top-center",

        })
          func('CallOutsFormSquareTopCenter')}
          }
          style={itemSelected === 'CallOutsFormSquareTopCenter' ? styleSelected : unSelected} >  
      </ButtonForm> 
    )
}