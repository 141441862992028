import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width: 75px;
    height: 75px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    background:#324a5e;
    border-radius: 50%;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
  }
  & svg{
    border-bottom-left-radius: 43%;
    border-bottom-right-radius: 50%;
    margin-top: 3px;
  }
`


export  const  ButtonAvatarHumanWhite =  ({func,avatarId}) => {
    return(
      <Button onClick = {e => func(avatarId)}> 
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="65px" height="65px" viewBox=" 0  0 490 390" enableBackground="new 0  0 490 390" space="preserve">
            <g>
                <g>
                    <path fill="#FFFFFF" d="M363.283,330.837l-68.94-17.239l-3.978-15.926c47.563-8.008,72.035-22.261,73.115-22.89
                        c2.556-1.495,4.14-4.212,4.356-7.163c0.197-2.952-1.117-5.848-3.438-7.702c-0.323-0.252-32.481-26.813-32.481-118.842
                        c0-77.615-18.157-116.971-53.986-116.971h-5.309c-12.92-12.488-22.619-17.995-48.678-17.995
                        c-33.813,0-107.973,33.994-107.973,134.966c0,92.029-32.157,118.59-32.391,118.77c-2.394,1.8-3.743,4.66-3.6,7.649
                        c0.162,3.005,1.781,5.705,4.354,7.252c1.08,0.646,25.32,14.971,73.17,22.961l-3.978,15.89l-68.939,17.24
                        C39.491,342.12,8,382.466,8,429.002c0,4.967,4.031,8.998,8.998,8.998h413.895c4.967,0,8.998-4.066,8.998-9.033
                        C439.89,382.466,408.399,342.12,363.283,330.837z"/>
                </g>
            </g>
        </svg>
      </Button>   
    )
}