import React from 'react'
import styled from 'styled-components'
import {styleSelected, unSelected} from '../styles'

const ButtonRadius = styled.button`
    width: 75px;
    height: 75px;
    border-radius: 3px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    & svg{
        margin-top:10px;
    }
`


export  const  ButtonSendIconWhite = ({handleSelect, currentImage}) => {
    return(
      <ButtonRadius  onClick ={e => handleSelect('https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send4.svg')} style={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send4.svg' ? styleSelected : unSelected}>  
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="75%" height="75%" viewBox="0 0 565 565" enableBackground="new 0 0 565 565"  space="preserve">
        <path fill={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send4.svg' ? '#fff' : '#3F566A'} d="M505.239,86.505c-6.675-9.099-17.197-14.264-28.146-14.263c-2.832,0-5.692,0.346-8.522,1.058L26.391,184.607
            C12.706,188.05,2.444,199.393,0.376,213.35c-2.063,13.958,4.477,27.786,16.575,35.044l93.504,56.103v123.067
            c0,4.657,2.653,8.908,6.838,10.955c4.185,2.045,9.17,1.528,12.845-1.332l87.7-68.259l72.044,43.226
            c5.621,3.373,11.813,4.98,17.929,4.98c11.729,0,23.174-5.912,29.764-16.621l169.246-275.066
            C514.264,113.352,513.638,97.955,505.239,86.505z M305.72,373.522l-109.629-65.779l111.221-95.841
            c1.558-1.342,1.923-3.609,0.865-5.372c-1.057-1.762-3.228-2.509-5.145-1.768l-169.411,65.5L51.338,220.89l412.128-103.742
            L305.72,373.522z"/>
        </svg>
    </ButtonRadius>   
    )
}