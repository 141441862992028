import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';

import { accessList } from '../hooksActionCreators'
import Modal from './modal'
import useModal from './useModal'
import UserTableMaterial from './userTableMaterial'
import Loader from '../../../../components/LinearLoader/linearLoader'
import i18n from '../../../../i18n/i18n'
import lang from '../../../../i18n/lang';
import "./Team.scss";
import ReactGA from 'react-ga';
import Feedback from '../../../../components/feedback'
import TiaraHeader from '../../../../components/TiaraHeader'

const tRoot = i18n()

const Team = () => {
  var appState = JSON.parse(localStorage.getItem('appState'))
  var chatID = appState.baseData.currentChatConfig._id
  var chatName = appState.baseData.currentChatConfig.name

  const usersData = []
  const [users, setUsers] = useState(usersData);
  const [loader, setLoader] = useState(false)
  const { open, toggle } = useModal();

  useEffect(() => {
    ReactGA.pageview("/chat/team", ['backTrackerV2', 'backTrackerV3'])
    openLoader()
    fetchData()
  }, [])

  const fetchData = async () => {
    let result = await accessList(chatID)
    closeLoader()
    setUsers(result)
  }

  const openLoader = () => setLoader(true)
  const closeLoader = () => setLoader(false)

  
  return (
    <div className="bodyCentral" style={{'width': '100%'}}>
    <TiaraHeader />
    <section className="dashboard-container">
      <div className="project-name">{chatName}</div>
      <h2>{tRoot('team')}</h2>
      <div>
        <div className="button-box">
          <Button variant="outlined" color="primary" onClick={toggle}>
            + {tRoot('inviteMember')}
          </Button>
        </div>
        <div className="header">
        {tRoot('teamDescLine1')}<br></br>
        {tRoot('teamDescLine2')} <a href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/colaboraci%C3%B3n' : 'https://supporten.blahbox.net/portal/kb/articles/member-invitation'} target="_blank" rel="noopener noreferrer" style={{ color: '#7E9BA4' }}>{tRoot('moreInformation')}</a>
        </div>
      </div>
      <div className="white-box">
        <div>

          <div>
            <Modal
              open={open}
              hide={toggle}
              chatID={chatID}
              fetchData={fetchData}
            ></Modal>
          </div>
        </div>
        <div>
          {loader ? <Loader /> : ''}
          <UserTableMaterial users={users} chatID={chatID} fetchData={fetchData}></UserTableMaterial>
        </div>
      </div>
      <Feedback seccion="Team"/>
    </section>
    </div>
    
  )
}

export default Team
