import React, { useState, useEffect } from "react"
import ReactGA from 'react-ga'
import sortArray from 'sort-array'
import _ from 'lodash'
import {
    Icon,
    Badge,
    Card,
    Button,
    IconButton,
    Drawer
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { useHistory } from 'react-router-dom'

import { getNotificationList, deleteNotificationDB, setNotificationRead } from '../../../src/containers/Dashboard/ChatSettings/hooksActionCreators'
import { getTimeDifference, isValidUrl } from '../../helpers'
import i18n from '../../i18n/i18n'
import "./notificationCards.scss"

const tRoot = i18n()

const NotificationCards = () => {
    const history = useHistory()
    var appState = JSON.parse(localStorage.getItem('appState'))
    var chatID = appState.baseData.currentChatConfig._id
    var userID = localStorage.getItem("userID")
    var notifKey = "notif_" + chatID + "_" + userID
    const [panelOpen, setPanelOpen] = useState(false);
    const [list, setList] = useState([])
    const [notifCount, setNotifCount] = useState(0)

    useEffect(() => {

        fetchNotifications()

    }, [])

    const updateNotificationsState = (notifications) => {

        var notificationsCount = 0
        notifications.forEach(e => notificationsCount = e.status === 'unread' ? notificationsCount + 1 : notificationsCount)
        setNotifCount(notificationsCount)
        sortArray(notifications, {
            by: 'created',
            order: 'desc'
        })
        setList(notifications)
        sessionStorage.setItem(notifKey, JSON.stringify(notifications))
    }

    const handleDrawerToggle = async () => {
        if (!panelOpen) {
            ReactGA.event({
                category: 'Notifications',
                action: 'Open'
            }, ['backTrackerV2', 'backTrackerV3'])
        }

        if (panelOpen) {
            var unread = _.includes(sessionStorage.getItem(notifKey), 'unread')
            if (unread) {
                let notifications = await setNotificationRead(chatID)
                updateNotificationsState(notifications)
            }
        }

        setPanelOpen(!panelOpen);
    }

    const fetchNotifications = async () => {
        var notifications = []
        notifications = sessionStorage.getItem(notifKey)

        if (!notifications) {
            var nList = await getNotificationList(chatID)

            if (nList) {
                updateNotificationsState(nList)
            }
        } else {
            let n = []
            n = JSON.parse(notifications)
            updateNotificationsState(n)
        }
    }

    const deleteNotification = (notificationID) => {

        ReactGA.event({
            category: 'Notifications',
            action: 'Delete'
        }, ['backTrackerV2', 'backTrackerV3'])

        deleteNotificationDB(chatID, notificationID) //delete from DB
        var newList = list.filter(item => item.id !== notificationID)
        updateNotificationsState(newList)
    }

    const deleteAllNotification = () => {
        ReactGA.event({
            category: 'Notifications',
            action: 'Delete All'
        }, ['backTrackerV2', 'backTrackerV3'])
        //TODO delete all notif from the DB
        deleteNotificationDB(chatID, 'all') //delete all notifications from DB
        setList([])
        setNotifCount(0)
        sessionStorage.setItem(notifKey, JSON.stringify([]))
    }

    const handleCardButtonClick = path => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging Upgrade'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push(path)
    }

    const buttonUpgrade = {
        //margin: theme.spacing(1),
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    }


    const NotificationCard = (notification) => {

        return (
            <Card className="mx-4 mb-6" elevation={3}>
                <div className="card__topbar flex items-center justify-between p-2 bg-light-gray">
                    <div className="flex items-center">
                        <div className="card__topbar__button">
                            <Icon
                                className="card__topbar__icon"
                                fontSize="small"
                                color={notification.notificationType === 'alert' ? 'red' : '#2196F3'}
                                style={{ color: notification.notificationType === 'alert' ? 'red' : '#2196F3' }}
                            >
                                {notification.notificationType === 'alert' ? 'notifications' : 'email_icon'}
                            </Icon>
                        </div>
                        <span className="ml-4 font-medium text-muted">
                            {notification.heading}
                        </span>
                        {notification.status === 'unread' ?
                            <Icon fontSize="medium" style={{ color: 'red' }}>
                                fiber_news
                        </Icon>
                            : ''}
                    </div>
                    <small className="card__topbar__time text-muted">
                        {getTimeDifference(new Date(notification.created))} {tRoot('ago')}
                    </small>
                </div>
                <div className="px-4 pt-2 pb-4">
                    <p className="m-0">{notification.title}</p>
                    <small className="text-muted smaller">
                        {notification.content}
                    </small>
                    {notification.button ?
                        <div className="buttonCard">
                            <Button
                                style={buttonUpgrade}
                                size="small"
                                variant="contained"
                                onClick={() => handleCardButtonClick(notification.button.path)}
                            >
                                {tRoot(notification.button.label)}
                            </Button>
                        </div>
                        : ''}
                </div>
            </Card>
        )
    }

    return (
        <div>
            <IconButton
                onClick={handleDrawerToggle}
                style={{ color: '#93919F' }}
            >
                <Badge color='primary' badgeContent={notifCount}>
                    <Icon style={{ color: '#93919F' }}> notifications  </Icon>
                </Badge>
            </IconButton>

            <Drawer
                width={"100px"}
                variant="temporary"
                anchor={"right"}
                open={panelOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true
                }}
            >
                <div className="notification">
                    <div className="notification__topbar flex items-center p-4 mb-4">
                        <Icon color="primary" style={{ color: '#2196F3' }}>notifications</Icon>
                        <h5 className="ml-2 my-0 font-medium">{tRoot('notifications')}</h5>
                    </div>

                    {list.map(notification => (
                        <div
                            key={notification.id}
                            className="notification__card position-relative"
                        >
                            <IconButton
                                size="small"
                                className="delete-button bg-light-gray mr-6"
                                onClick={() => deleteNotification(notification.id)}
                            >
                                <Icon className="text-muted" fontSize="small">
                                    clear
                            </Icon>
                            </IconButton>
                            {notification.path !== 'none' ?
                                <Link
                                    to={isValidUrl(notification.path) ? { pathname: notification.path } : `${notification.path}`}
                                    onClick={handleDrawerToggle}
                                    className="link"
                                    rel={isValidUrl(notification.path) ? 'noopener noreferrer' : ''}
                                    target={isValidUrl(notification.path) ? '_blank' : '_self'}
                                >
                                    {NotificationCard(notification)}
                                </Link >
                                :
                                NotificationCard(notification)
                            }

                        </div>
                    ))}


                    <div className="text-center">
                        <Button onClick={deleteAllNotification}>{tRoot('clearNotifications')}</Button>
                    </div>
                </div>
            </Drawer>
        </div >
    )

}

export default NotificationCards