import {
    UPDATE_FLOATING_ICON_POSITION,
    UPDATE_HEADER_BACKGROUND,
    UPDATE_HEADER_BORDERS,
    UPDATE_HEADER_NAME,
    UPDATE_HEADER_IMAGE,
    UPDATE_FLOATING_ICON_BACKGROUND,
    UPDATE_FLOATING_ICON_FORM,
    UPDATE_DIALOGBOX_BACKGROUND,
    UPDATE_DIALOGBOX_CALLOUTS,
    UPDATE_SENDERSECTION_BACKGROUND,
    UPDATE_SENDERSECTION_MESSAGE,
    UPDATE_SENDERSECTION_BUTTON,
    UPDATE_FLOATING_ICON_TOOLTIP,
    UPDATE_FLOATING_ICON_ICON,
    UPDATE_BOX_SIZE,
    EL_UPDATE_BOX_BACKGROUND,
    EL_UPDATE_HEADER_IMAGE
} from './ChatSettings'

const chatSettingsInicialState = {
    transactionResult: '',

    DialogBoxCallOutsResult: {
        type: '',
        operation: '',
        value: ''
    },
    FloatingIconTooltipResult: {
        type: '',
        operation: '',
        value: ''
    },
    FloatingIconIconResult: {
        type: '',
        operation: '',
        value: ''
    },
    FloatingIconFormResult: {
        type: '',
        operation: '',
        value: ''
    },
    FloatingIconPositionResult: {
        type: '',
        operation: '',
        value: ''
    },
    HeaderBordersResult: {
        type: '',
        operation: '',
        value: ''
    },
    HeaderNameResult: {
        type: '',
        operation: '',
        value: ''
    },
    HeaderImageResult: {
        type: '',
        operation: '',
        value: ''
    },
    SenderSectionMessageResult: {
        type: '',
        operation: '',
        value: ''
    },
    SenderSectionButtonResult: {
        type: '',
        operation: '',
        value: ''
    },
    SenderSectionBackgroundResult: {
        type: '',
        operation: '',
        value: ''
    },
    BoxSectionSizeResult: {
        type: '',
        operation: '',
        value: ''
    },
    ElBoxSectionBackgroundResult: {
        type: '',
        operation: '',
        value: ''
    },
    ElHeaderSectionImageResult: {
        type: '',
        operation: '',
        value: ''
    }

}

export const chatConfigReducer = (state = chatSettingsInicialState, action) => {
    switch (action.type) {
        case UPDATE_FLOATING_ICON_POSITION:
            return { ...state, FloatingIconPositionResult: action.data }
        case UPDATE_FLOATING_ICON_TOOLTIP:
            return { ...state, FloatingIconTooltipResult: action.data }
        case UPDATE_FLOATING_ICON_ICON:
            return { ...state, FloatingIconIconResult: action.data }
        case UPDATE_HEADER_BACKGROUND:
            return { ...state, HeaderBackgroundResult: action.data }
        case UPDATE_HEADER_BORDERS:
            return { ...state, HeaderBordersResult: action.data }
        case UPDATE_HEADER_NAME:
            return { ...state, HeaderNameResult: action.data }
        case UPDATE_HEADER_IMAGE:
            return { ...state, HeaderImageResult: action.data }
        case UPDATE_FLOATING_ICON_BACKGROUND:
            return { ...state, FloatingIconBackgroundResult: action.data }
        case UPDATE_FLOATING_ICON_FORM:
            return { ...state, FloatingIconFormResult: action.data }
        case UPDATE_DIALOGBOX_CALLOUTS:
            return { ...state, DialogBoxCallOutsResult: action.data }
        case UPDATE_DIALOGBOX_BACKGROUND:
            return { ...state, DialogBoxBackgroundResult: action.data }
        case UPDATE_SENDERSECTION_BACKGROUND:
            return { ...state, SenderSectionBackgroundResult: action.data }
        case UPDATE_SENDERSECTION_MESSAGE:
            return { ...state, SenderSectionMessageResult: action.data }
        case UPDATE_SENDERSECTION_BUTTON:
            return { ...state, SenderSectionButtonResult: action.data }
        case UPDATE_BOX_SIZE:
            return { ...state, BoxSectionSizeResult: action.data }
        case EL_UPDATE_BOX_BACKGROUND:
            return { ...state, ElBoxSectionBackgroundResult: action.data }
        case EL_UPDATE_HEADER_IMAGE:
            return { ...state, ElHeaderSectionImageResult: action.data }
        default:
            return state
    }
}