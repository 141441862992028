import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Minus from '@material-ui/icons/RemoveCircle';
import Plus from '@material-ui/icons/AddCircle';

import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'



const tRoot = i18n()

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
`

export const styles = theme => ({
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',

        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },
    notchedOutline: {},
    sliderWide: {
        width: '200px',
    }
});

export const view = (classes, boxHeight, boxWidth, handleHeightChange, handleWidthChange, handleChangeCommitted) => {

    return (
        <div>
            <HeaderConfig title={tRoot('box')} />
            <Content>
                <div>
                    <Typography id="continuous-slider" gutterBottom>
                        {tRoot('width')}

                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Minus />
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={boxWidth}
                                onChange={handleWidthChange}
                                aria-labelledby="continuous-slider"
                                min={230}
                                max={900}
                                step={10}
                                onChangeCommitted={handleChangeCommitted}
                            />
                        </Grid>
                        <Grid item>
                            <Plus />
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Typography id="continuous-slider" gutterBottom>
                        {tRoot('height')}

                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Minus />
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={boxHeight}
                                onChange={handleHeightChange}
                                aria-labelledby="continuous-slider"
                                min={300}
                                max={620}
                                step={10}
                                onChangeCommitted={handleChangeCommitted}
                            />
                        </Grid>
                        <Grid item>
                            <Plus />
                        </Grid>
                    </Grid>
                </div>

                <Feedback seccion="Header-Background" previewMode />
            </Content>
        </div>
    )
}


