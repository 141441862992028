import React from "react"
import classNames from "classnames"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { selectSetting, setCurrentChat } from "../../rootActions"
import NavItemConfigurations from "../../assets/img/nav-item-configurations.svg"
import "./ClassicSecondNav.scss"
import i18n from '../../i18n/i18n'
import { getChat } from "../../containers/Dashboard/ChatSettings/actionCreators.js"


const t = i18n("chatSettings")
const tRoot = i18n()

class ClassicSecondNav extends React.Component {
  state = {
    //openSettings: false,
    openSuscription: false
  };

 loadChatSettings = () => {
    getChat(this.props.chatId).then(result => {
      this.props.setCurrentChat(result.data.data);
    })
  }


  render() {
    
    return (
        <nav
          className={classNames("chat-styles-settings", {
            open: this.props.openNav
          })}
        >
          <div className="second-level-nav">
            <div className="close" onClick={this.props.toggleSettings} />
            <header>
              <img src={NavItemConfigurations} alt="settings icon" />
              <h3>{tRoot('settings')}</h3>
            </header>
            <div className="chat-section-container">
              <h4>{tRoot('header')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => this.props.selectSetting("headerImage")}>
                  {this.props.selectedItem === "headerImage" ? " >  " + tRoot('image') : tRoot('image')}

                </li>
                <li onClick={() => this.props.selectSetting("headerName")}>
                  {this.props.selectedItem === "headerName" ? " >  " + tRoot('name') : tRoot('name')}

                </li>
                <li onClick={() => this.props.selectSetting("headerBorders")}>
                  {this.props.selectedItem === "headerBorders" ? " >  " + tRoot('border') : tRoot('border')}

                </li>
                <li
                  onClick={() => this.props.selectSetting("headerBackground")}
                >

                  {this.props.selectedItem === "headerBackground" ? " >  " + tRoot('background') : tRoot('background')}

                </li>
              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{tRoot('dialogbox')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => this.props.selectSetting("dialogBoxCallOuts")}>

                  {this.props.selectedItem === "dialogBoxCallOuts" ? " >  " + t('callOutTittle') : t('callOutTittle')}
                </li>
                <li onClick={() => this.props.selectSetting("dialogBoxBackground")}>
                  {this.props.selectedItem === "dialogBoxBackground" ? " >  " + tRoot('background') : tRoot('background')}
                </li>
              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{tRoot('senderSection')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => this.props.selectSetting("senderSectionMessage")}>

                  {this.props.selectedItem === "senderSectionMessage" ? " >  " + tRoot('message') : tRoot('message')}
                </li>
                <li onClick={() => this.props.selectSetting("senderSectionButton")}>
                  {this.props.selectedItem === "senderSectionButton" ? " >  " + tRoot('button') : tRoot('button')}
                </li>
                <li onClick={() => this.props.selectSetting("senderSectionBackground")}>
                  {this.props.selectedItem === "senderSectionBackground" ? " >  " + tRoot('background') : tRoot('background')}
                </li>
              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{t('floatingIcon')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => { this.props.selectSetting("floatingIconIcon") }}>
                  {this.props.selectedItem === "floatingIconIcon" ? " >  " + tRoot('image') : tRoot('image')}
                </li>
                <li onClick={() => this.props.selectSetting("floatingIconForm")}>
                  {this.props.selectedItem === "floatingIconForm" ? " >  " + t('formTittle') : t('formTittle')}
                </li>
                <li onClick={() => this.props.selectSetting("floatingIconPosition")}>
                  {this.props.selectedItem === "floatingIconPosition" ? " >  " + t('positionTittle') : t('positionTittle')}
                </li>
                <li onClick={() => this.props.selectSetting("floatingIconTooltip")}>
                  {this.props.selectedItem === "floatingIconTooltip" ? " >  " + tRoot('tooltip') : tRoot('tooltip')}
                </li>

              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{tRoot('box')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => { this.props.selectSetting("boxSize") }}>
                  {this.props.selectedItem === "boxSize" ? " >  " + tRoot('size') : tRoot('size')}
                </li>
              </ul>
            </div>
          </div>
        </nav>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedItem: state.baseData.settingSelected,
    chatId: state.baseData.currentChatConfig._id
  }
}


export default connect(
  mapStateToProps,
  { selectSetting, setCurrentChat }
)(withRouter(ClassicSecondNav));
