import styled from 'styled-components'
import SliderS from '@material-ui/core/Slider'
import ButtonS from '@material-ui/core/Button'

export const Container = styled.div`
    width: 100%;
`

export const CropContiner = styled.div`
    position: relative;
    width: 100%;
    height: 300px;
    background: #333;
    margin-bottom: 15px;
`

export const ZoomSlider = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    
    `

export const ZoomLabel = styled.div`
    font-size: 1rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: -0.80em;
    text-align-last: justify;

`

export const Slider = styled(SliderS)`
    && {
    padding: 22px 0px;
    margin-left: 16;
    }
`

export const ButtonsContent = styled.div`
    
`

export const ButtonUpload = styled(ButtonS)`
    && {
        margin-left: 1.5em;
        width: 100px;
        color: #fff;
        background-color: #2196F3;
        &:hover {
            background-color: #9044AD;
        }
    }
`
export const ButtonCancel = styled(ButtonS)`
    && {
        margin-right: 1.5em;
        width: 100px;
        color: #fff;
        background-color: #c2c2c2;
        &:hover {
            background-color: #7c7c7c;
        }
    }
`