import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { openLoader, closeLoader } from "../../../../../rootActions";
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import i18n from '../../../../../i18n/i18n'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { publish } from '../../actionCreators.js'
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import compose from 'recompose/compose';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ReactGA from 'react-ga'
import { checkAccess } from '../../../../../helpers/chatUserAccess'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const tRoot = i18n()
const t = i18n('chatSettings')

const HeaderContent = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap:20px;
`
const Project = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: #b9bec1;
    text-align:left;
    float: left;
    height:30px;
    width:100%

`

const PublishButton = styled.button`
border: 1px solid rgba(0,0,0,0.3);
padding: 10px 25px;
border-radius: 20px;
width: auto;
display: inline-block;
margin: 20px 0;
transition: all 0.2s ease 0s;
text-align:center;

&:hover{
  color:#fff;
	background-color: #F99346;
	border:1px solid #F99346;
}

`
const Title = styled.div`
width: 93%;
float: left;
display: inline-block;
font-size: 32px;
padding: 10px 0 20px;
font-weight: 300;
text-align: center;
color: #324a5e;

`
const TitlePanel = styled.div`
font-size:14px;
color: #324a5e;
text-align: left;
    cursor: pointer;
`


const Close = styled.button`
display: inline-block;
background: transparent;
border:0px;
svg{
  width:20px;
  height:20px;
}

`

const Code = styled.div`
background-color: #fff;
border: 1px solid #ccc;
color: #999;
padding: 25px;
width:100%;
overflow-x: scroll;
`
const Note = styled.div`
margin: 10px 1px 25px;
text-align: left;
font-size:14px;
width:100%;
`

const styles = theme => ({
  button: {
    //    margin: theme.spacing.unit,
    margin: theme.spacing(1),
    borderRadius: '20px',
    color: "white",
    width: "125px",
    border: '1px solid #ef7e29',
    marginTop: '20px  ',
    cursor: 'pointer',
    textTransform: 'capitalize',
    boxShadow: 'none',
    padding: '7px 5px !important',
    height: '40px',
  },
  input: {
    display: 'none',
  },
  dialog: {
    width: '600px'
  },
  root: {
    flexGrow: 1,

  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    'box-shadow': 'none'
  },
  gralgrid: {
    'flex-wrap': 'initial'
  },
  buttonUpgrade: {
    margin: theme.spacing(1),
    color: '#fff',
    backgroundColor: '#2196F3',
    "&:hover": {
      backgroundColor: "#9044AD"
    },
    borderRadius: '20px',
    width: "125px",
    border: '1px solid #2196F3',
    marginTop: '20px  ',
    cursor: 'pointer',
    textTransform: 'capitalize',
    boxShadow: 'none',
    padding: '7px 5px !important',
    height: '40px',
  }
});


class HeaderConfig extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  state = {
    open: false,
    value: '',
    error: true,
    completed: 0,
    scriptContent: '',
    published: false,
    msgsUse: 0,
    messagesReserved: 0,
    perc: 0,
    progressColor: ''
  };



  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    this.setMsgsUse()
  }


  handleValue = (e) => {
    this.setState({
      value: e.target.value,
      error: e.target.value !== this.props.chatName ? true : false
    })
  }



  handleClickOpen = () => {
    this.setState({ open: true, published: false });
  };

  handleUpgrade = () => {
    ReactGA.event({
      category: 'Chat',
      action: 'Config Upgrade'
    }, ['backTrackerV2', 'backTrackerV3'])

    this.props.history.push("/dashboard/chat-settings/subscription")

  }


  handleClose = () => {
    this.setState({ open: false, published: false, completed: 0 });
  };

  setMsgsUse = () => {
    var msgsUse = sessionStorage.getItem("msgsUse")
    var messagesReserved = JSON.parse(localStorage.getItem("plan")).messagesReserved
    this.setState({ msgsUse: msgsUse, messagesReserved: messagesReserved });

    var p = Number.parseFloat(((msgsUse / messagesReserved) * 100)).toFixed(0)
    this.setState({ perc: p })

    var color

    switch (true) {
      case (p <= 50):
        color = 'white'
        break;
      case (p > 50 && p <= 75):
        color = 'yellow'
        break;
      case (p > 75):
        color = 'red'
        break
      default:
        color = 'white'
        break;
    }
    //console.log('color :', color);
    this.setState({ progressColor: color })

  }


  progress = () => {
    const { completed } = this.state;

    if (completed >= 100) {
      clearInterval(this.timer);
      var chatboxURL = process.env.CHATBOX_URL;

      const script =
        `<script>
            (function(b,c){var e=document.createElement('link');e.rel='stylesheet',e.type='text/css',e.href='` + chatboxURL + `/static/css/main.css',document.getElementsByTagName('head')[0].appendChild(e);
            var f=document.createElement('script');f.onload=function(){var g;if(c)g='previewInit';else{var h=document.createElement('div');g='cbinit',h.id='cbinit',document.body.append(h)}
            console.log(document.querySelector('#'+g)),chatbox.initChat(document.querySelector('#'+g),b,c)},f.src='` + chatboxURL + `/static/js/chat-lib.js',document.getElementsByTagName('head')[0].appendChild(f)})    
            ('` + this.props.chatId + `', 0);
          </script>`;

      this.setState({ scriptContent: script, published: true });
    } else {
      //  const diff = Math.random() * 10;
      this.setState({ completed: this.state.completed + 1 });
    }
  };

  confirm = () => {
    this.props.openLoader()
    this.timer = setInterval(this.progress, 50);
    publish(this.props.chatId).then(result => {
      ReactGA.event({
        category: 'Chat',
        action: 'Publish'
      }, ['backTrackerV2', 'backTrackerV3'])

      this.setState({
        value: '',
        error: true,
      })
    }).catch(err => {
      this.props.closeLoader()
      console.log(err)
    })

  }


  publishDialogComponent = (state, handleClose, confirm) => {
    return (<Dialog
      open={state.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"

    >
      <DialogTitle id="form-dialog-title" style={{ width: "600px" }}><Title>
        {t('publish') + ' ' + this.props.chatName}!</Title>
        <Close onClick={handleClose} color="primary">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="311.809px" height="198.43px" viewBox="0 0 311.809 198.43" enableBackground="new 0 0 311.809 198.43"
            space="preserve">
            <g>
              <path fill="#898989" d="M145.824,99.117l-78.528,78.529c-2.181,2.179-2.181,5.714,0,7.897c1.089,1.093,2.52,1.634,3.948,1.634
          c1.431,0,2.857-0.541,3.947-1.634l79.119-79.12l79.12,79.12c1.094,1.093,2.521,1.634,3.946,1.634c1.43,0,2.86-0.541,3.947-1.634
          c2.183-2.184,2.183-5.719,0-7.897L162.8,99.117l78.578-78.586c2.182-2.182,2.182-5.716,0-7.898c-2.183-2.178-5.718-2.178-7.894,0
          l-79.172,79.175L75.135,12.636c-2.182-2.178-5.713-2.178-7.895,0c-2.181,2.182-2.181,5.716,0,7.898L145.824,99.117z"/>
            </g>
          </svg>
        </Close>
      </DialogTitle>
      <DialogContent>

        <DialogContentText style={{ textAlign: "center" }} >
          {t('publishConfirm')}
        </DialogContentText>

        <LinearProgress variant="determinate" value={state.completed} style={{ height: "30px", marginTop: "25px", border: "1px solid #ccc", background: "transparent" }}
        />

      </DialogContent>
      <DialogActions style={{ paddingBottom: "20px", textAlign: "center", margin: "auto" }}>

        {
          !this.state.published ? <PublishButton onClick={confirm} color={'primary'}> {t('publish')}</PublishButton>
            :
            <PublishButton onClick={handleClose} color={'primary'}>{tRoot('close')}</PublishButton>
        }

      </DialogActions>


      {!this.state.scriptContent ? '' : <ExpansionPanel style={{ boxShadow: "none", padding: "0px", borderTop: "0px", backgroundColor: "#fff", paddingBottom: "15px" }}  >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TitlePanel >{tRoot('instalationCode')}</TitlePanel >
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: "block" }}>

          <Code>
            {this.state.scriptContent}
          </Code>
          <Note>{tRoot('copyAndPaste')}</Note>
        </ExpansionPanelDetails>
      </ExpansionPanel>}




    </Dialog>)
  }


  render() {
    const { classes, chatName } = this.props;
    return (
      <HeaderContent>
        <div>
          <Project>{chatName}</Project>
          <h2>{this.props.title}</h2>
        </div>

        <div className={classes.root}>
          <Grid container spacing={1} className={classes.gralgrid}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Button
                  disabled={!checkAccess("chat_publish")}
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={classes.button}
                  onClick={this.handleClickOpen}
                >{t('publish')}</Button>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Button
                  disabled={!checkAccess("chat_publish")}
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={classes.buttonUpgrade}
                  onClick={this.handleUpgrade}
                >
                  <Box position="relative" display="inline-flex" style={{ 'padding-right': ' 5px' }}>
                    <CircularProgress variant="static" value={this.state.perc} size="1.8rem" thickness="4.2" style={{ 'color': this.state.progressColor }} />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="caption" component="div" color="white" style={{ 'font-size': '0.60rem', 'padding-right': '3px' }}>{this.state.perc}%</Typography>
                    </Box>
                  </Box>
                  Upgrade
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>






        {this.state.open ? this.publishDialogComponent(this.state, this.handleClose, this.confirm) : ''}

      </HeaderContent>
    )
  }
}

const mapStateToProps = state => {
  return {
    chatName: state.baseData.currentChatConfig.name,
    chatId: state.baseData.currentChatConfig._id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader, closeLoader
  }
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles,{name: 'HeaderConfig'})(HeaderConfig));
//export default connect(mapStateToProps, mapDispatchToProps)(HeaderConfig);
//export default connect(mapStateToProps, {openLoader,closeLoader})(withStyles(styles)(HeaderConfig));
export default compose(
  withStyles(styles, { name: 'HeaderConfig' }),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(HeaderConfig))

