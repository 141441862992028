import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateBoxSize } from "../../../actionCreators.js"
import compose from 'recompose/compose'

class BoxSize extends React.Component {
	state = {
		boxHeight: '',
		boxWidth: ''
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => {
		this.setState({ ...this.props.data })
	}

	handleWidthChange = (e, newWidth) => {
		let width = newWidth + 'px'
		window.chatbot.updateChatFrameWidth(width)

		this.setState({ boxWidth: newWidth })
	}

	handleChangeCommitted = () => {
		this.props.save(this.state, this.props.chatId)
	}

	render = () => view(
		this.state.boxWidth,
		this.handleWidthChange,
		this.handleChangeCommitted
	)
}


const mapStateToProps = state => {
	return {
		data: state.chatSettings.BoxSectionSizeResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (data, chatId) => updateBoxSize(data, chatId).then(result => dispatch(result))
	}
}

export default compose(
	withStyles(styles, { name: 'BoxSize' }),
	connect(mapStateToProps, mapDispatchToProps)
)(BoxSize)
