import styled from 'styled-components'
import RadioMaterial from '@material-ui/core/Radio'
import RadioGroupMaterial from '@material-ui/core/RadioGroup'
import FormControlLabelMaterial from '@material-ui/core/FormControlLabel'


/* export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
    margin-bottom:25px;
` */

export const Content = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: column;
    margin-bottom: 25px;
    justify-content: center;
    margin-top: 20px;
`

export const ContentForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;

`
export const Label = styled.label`
     text-align:left !important;
     display:grid;
     font-size:18px;
`

export const Radio = styled(RadioMaterial)`
`

export const RadioGroup = styled(RadioGroupMaterial)`
`

export const FormControlLabel = styled(FormControlLabelMaterial)`
`

export const RadiosContent = styled.div`
    align-self: center;
`

export const IconsContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 25px;
`

export const ShapeContent = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`



