import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'

import i18n from '../../i18n/i18n'


import { getCroppedImg } from './canvasUtils'
import {
    Container,
    CropContiner,
    ZoomSlider,
    Slider,
    ButtonUpload,
    ButtonCancel,
    ButtonsContent,
    ZoomLabel
} from './styled'

const tRoot = i18n()

const CropImage = ({ handlerStep, handleImageChange, image, handlerCancel, setCroppedImage, imgWidth, imgHeight  }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [file, setFile] = useState('')
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
        //console.log(croppedArea, croppedAreaPixels)
    }, [])

    const handleCroppImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                file,
                croppedAreaPixels
            )
            setCroppedImage(croppedImage)
            handleImageChange(croppedImage)
            handlerStep(1)

        } catch (e) {
            console.error(e)
        }

    }, [file, croppedAreaPixels, setCroppedImage])




    useEffect(() => {
        const convertFileToBase64 = () => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.readAsDataURL(image)
            reader.onload = () => setFile(reader.result)
        }
        convertFileToBase64()
    }, [image])


    return (
        <Container>
            <CropContiner>
                <Cropper
                    image={file}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    cropSize={{ width: parseInt(imgWidth), height: parseInt(imgHeight) }}
                    objectFit="contain"
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </CropContiner>
            <ZoomSlider>
                <ZoomLabel>Zoom</ZoomLabel>
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.05}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}

                />
            </ZoomSlider>
            <ButtonsContent>
                <ButtonCancel
                    onClick={handlerCancel}
                    variant="contained"
                    color="primary"
                >
                    {tRoot('cancel')}
                </ButtonCancel>
                <ButtonUpload
                    onClick={handleCroppImage}
                    variant="contained"
                    color="primary"
                >
                    {tRoot('done')}
                </ButtonUpload>
            </ButtonsContent>
        </Container>
    )
}

export default CropImage