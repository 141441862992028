
import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ShadowNone } from './FormShadow/shadowNone';
import { ShadowBottomBlack } from './FormShadow/shadowBottomBlack';
import { ShadowBottomGray } from './FormShadow/shadowBottomGray';
import { ShadowRightBlack } from './FormShadow/shadowRightBlack';
import { ShadowRightGray } from './FormShadow/shadowRightGray';
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'

const t = i18n('chatSettings')
const tRoot = i18n()

const ContentButtons = styled.div`
    display: grid;
    grid-template-columns: 90px 90px 90px 90px 90px 90px;
 
    padding:10px 0 20px;
    margin-bottom:25px;
`
const ContentForm = styled.div`
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap:20px;
    padding:10px 0 20px;
    margin-bottom:25px;
`
const Content = styled.div`
    display: grid;
    text-align:left;
`
const Label = styled.label`
    font-size:18px;
    margin-bottom:10px;
    text-align:left;
`

const Rectangle = styled.div`
    display: inline-block;
    width: 65px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;

    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`
const RectangleLeft = styled.div`
    display: inline-block;
    width: 65px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius:15px 0 0 15px;

    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`
const CircleleLeft = styled.div`
    display: inline-block;
    width: 65px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius:50px 0 0 50px;

    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`
const RectangleRadius = styled.div`
    display: inline-block;
    width: 65px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius:15px;
    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`

const Circle = styled.div`
    display: inline-block;
    width: 65px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius:50px;
    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`
const ContentShadow = styled.div`
    display:grid;
    grid-template-columns:90px 90px 90px 90px 90px 90px;
    grid-row-gap:15px;
    text-align:left;
    margin-bottom:25px;
`


export const styles = theme => ({
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',

        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },
    notchedOutline: {}
});


export const view = (
    { classes },
    { form, shadow, visible, margin, enable },
    {
        handleForm,
        handleVisible,
        handleMargin,
        handleShadow
    }) => (

        <div>
            <HeaderConfig title={t('formTittle')} />
            <Content>
                <Label className="noInput">{t('border')}</Label>
                <ContentButtons >
                    <Rectangle onClick={e => handleForm('RECTANGLE')} style={form === 'RECTANGLE' ? { "boxShadow": "5px 5px 10px rgba(0,0,0,0.2)" } : {}} />
                    <RectangleRadius onClick={e => handleForm('RADIUS')} style={form === 'RADIUS' ? { "boxShadow": "5px 5px 10px rgba(0,0,0,0.2)" } : {}} />
                    <Circle onClick={e => handleForm('CIRCLE')} style={form === 'CIRCLE' ? { "boxShadow": "5px 5px 10px rgba(0,0,0,0.2)" } : {}} />
                    <RectangleLeft onClick={e => handleForm('RECTANGLELEFT')} style={form === 'RECTANGLELEFT' ? { "boxShadow": "5px 5px 10px rgba(0,0,0,0.2)" } : {}} />
                    <CircleleLeft onClick={e => handleForm('CIRCLELEFT')} style={form === 'CIRCLELEFT' ? { "boxShadow": "5px 5px 10px rgba(0,0,0,0.2)" } : {}} />
                </ContentButtons>
                <ContentForm>

                    <FormControl >
                        <InputLabel htmlFor='margin' > {t('floating')}</InputLabel>
                        <Select
                            className={classes.cssUnderline}
                            value={margin}
                            onChange={handleMargin}
                            inputProps={{
                                name: 'margin',
                                id: 'margin',
                            }}
                        >
                            <MenuItem value="YES">{tRoot('yes')}</MenuItem>
                            <MenuItem value="NO">{tRoot('no')}</MenuItem>

                        </Select>
                    </FormControl>

                    {margin === 'YES' ? '' :
                    <FormControl >
                        <InputLabel htmlFor='visible'> {t('allwaysVisible')}</InputLabel>
                        <Select
                            value={visible}
                            onChange={handleVisible}
                            className={classes.cssUnderline}
                            inputProps={{
                                name: 'visible',
                                id: 'visible',
                            }}
                        >
                            <MenuItem value="YES">{tRoot('yes')}</MenuItem>
                            <MenuItem value="NO">{tRoot('no')}</MenuItem>

                        </Select>
                    </FormControl>
                    }

                </ContentForm>

                <Label >{t('shadow')}</Label>
                <ContentShadow>
                    <ShadowNone handleShadow={handleShadow} selected={shadow} />
                    <ShadowBottomBlack handleShadow={handleShadow} selected={shadow} />
                    <ShadowBottomGray handleShadow={handleShadow} selected={shadow} />
                    <ShadowRightBlack handleShadow={handleShadow} selected={shadow} />
                    <ShadowRightGray handleShadow={handleShadow} selected={shadow} />
                </ContentShadow>
                <Feedback seccion="Floating-Form" previewMode/>
            </Content>
        </div>
    )
