import axios from 'axios';


const BACKEND_API_URL = process.env.API_URL
const BACKEND_API_KEY = process.env.API_KEY
const getToken = () => (localStorage.getItem("token"))
const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-core-client-id': BACKEND_API_KEY,
    'Authorization': getToken(),
    'Access-Control-Allow-Origin': '*'
}

export const accessList = async (chatId) => {
    return axios.get(
        BACKEND_API_URL + "/chat/accessList/" + chatId,
        { headers }
    ).then(result => {
        let usersData = []
        result.data.accessList.forEach(element => {
            let e = {}
            e.idUA = element._id
            e.userID = element.userID
            e.role = element.admin ? 'admin' : element.access
            e.admin = element.admin
            e.email = element.user_email || element.userdata.email
            e.name = element.userdata ? element.userdata.nickname : ''
            e.pic = element.userdata ? element.userdata.pic : ''
            e.actualUser = element.userdata ? element.userdata.actualUser : false
            e.status = element.status

            usersData.push(e)
        })
        return usersData
    })

}

export const invite = async (newUser, chatID) => {
    const content = {
        email: newUser.email,
        role: newUser.role
    }

    return axios.post(
        BACKEND_API_URL + "/chat/invite/" + chatID,
        content,
        { headers }
    )
        .then(result => {
            let r = {}
            r.status = result.status
            r.msg = result.msg
            return r
        })
        .catch(function (error) {
            if (error.response.status === 409) {
                let r = {}
                r.status = error.response.status
                r.msg = error.response.data
                return r
            }
            console.log(error)
        });
}

export const deleteUserAccess = async (chatID, uaID) => {
    const data = {
        uaID: uaID
    }

    return axios.delete(
        BACKEND_API_URL + "/chat/userAccessDelete/" + chatID,
        {
            headers,
            data
        }
    )
        .then(result => result)
        .catch(error => console.log(error))
}

export const userAccessUpdate = (chatID, uaID, newRole) => {
    const content = {
        uaID: uaID,
        newRole: newRole
    }

    return axios.post(
        BACKEND_API_URL + "/chat/userAccessUpdate/" + chatID,
        content,
        headers
    )
        .then(result => result)
        .catch(error => console.log(error))
}

export const sendFeedback = (chatID, feedback) => {
    const content = feedback

    return axios.put(
        BACKEND_API_URL + "/chat/sendFeedback/" + chatID,
        content,
        { headers }
    )
        .then(result => result)
        .catch(error => console.log(error))
}

export const usage = async (chatId) => {
    return axios.get(
        BACKEND_API_URL + "/messages/usage/" + chatId,
        { headers }
    ).then(result => {
        return result.data.messages
    })
        .catch(error => console.log(error))

}

export const msgsByMonths = async (chatId, dateFilters) => {

    return axios.post(
        BACKEND_API_URL + "/messages/messagesByMonth/" + chatId,
        dateFilters,
        { headers }
    ).then(result => {
        return result.data.messages
    })
        .catch(error => console.log(error))

}

export const fetchCheckoutSession = async (chatID, priceID) => {

    return axios.post(
        BACKEND_API_URL + "/subs/stripeCreateCheckoutSession/" + chatID,
        { priceId: priceID },
        { headers }
    ).then(result => {
        return result.data
    })
        .catch(error => console.log(error))

}

export const upgradeSubscription = async (chatID, sessionID) => {

    return axios.post(
        BACKEND_API_URL + "/subs/stripeUpgrade/" + chatID,
        { stripeSessionID: sessionID },
        { headers }
    ).then(result => {
        return result.data
    })
        .catch(error => console.log(error))

}


export const stripeCustomerPortalSession = async (chatID, stripeCustomerID) => {

    return axios.post(
        BACKEND_API_URL + "/subs/stripeCustomerPortalSession/" + chatID,
        { stripeCustomerID: stripeCustomerID },
        { headers }
    ).then(result => {
        return result.data
    })
        .catch(error => console.log(error))

}

export const getNotificationList = async (chatID) => {
    return axios.get(
        BACKEND_API_URL + "/notifications/list/" + chatID,
        //"http://localhost:3777/api/notifications/list/" + chatID,
        { headers }
    ).then(result => {
        return result.data
    })
        .catch(error => console.log(error))
}

export const deleteNotificationDB = async (chatID, notificationID) => {

    return axios.delete(
        BACKEND_API_URL + "/notifications/delete/" + chatID,
        //"http://localhost:3777/api/notifications/delete/" + chatID,
        { data: { notificationID: notificationID } },
        { headers }
    ).then(result => {
        return result.data
    })
        .catch(error => console.log(error))
}

export const setNotificationRead = async (chatID) => {
    return axios.post(
        BACKEND_API_URL + "/notifications/read/" + chatID,
        //"http://localhost:3777/api/notifications/read/" + chatID,
        { headers }
    ).then(result => {
        return result.data
    })
        .catch(error => console.log(error))
}

export const getChat = async (chatID) => {

    return axios.get(
        BACKEND_API_URL + "/chat/getChat/" + chatID,
        { headers }
    )
        .then(result => result.data.data)
        .catch(error => console.log(error))
}

export const getChatRQ = async (chatID) => {

    const { data } = await axios.get(
        BACKEND_API_URL + "/chat/getChat/" + chatID,
        { headers }
    )

    return data.data

}

export const chatUpdate = (chatID, change) => {

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatID,
        { ...change },
        { headers }
    )
        .then(result => result)
        .catch(error => console.log(error))

}

export const listUpdate = (chatID, change) => {

    return axios.post(
        BACKEND_API_URL + "/chat/listUpdate/" + chatID,
        { ...change },
        { headers }
    )
        .then(result => result)
        .catch(error => console.log(error))

}

export const upLoadAvatar = (imageBase64, chatId) => {
    let data = {
        image: {
            imgBase64: imageBase64,
            fileType: "png"
        }
    }

    return axios.post(
        BACKEND_API_URL + "/chat/avatarUpload/" + chatId,
        data,
        { headers }
    )
        .then(result => result)
        .catch(error => console.log(error))
}

export const imageUpload = (imageBase64, type, chatId) => {
    let data = {
        image: {
            imgBase64: imageBase64,
            fileType: "png",
            type: type
        }
    }

    return axios.post(
        BACKEND_API_URL + "/chat/imageUpload/" + chatId,
        data,
        { headers }
    )
        .then(result => result)
        .catch(error => console.log(error))
}