import i18n from '../i18n/i18n'
export * from './user-error-handler';
export * from './user-success-handler';
export * from './history';
export * from './chatUserAccess';


const tRoot = i18n()

export const getTimeDifference = (date) => {

    var today = new Date();
    var dif = date.getTime() - today.getTime();
    var Seconds_from_T1_to_T2 = dif / 1000;
    var difference = Math.abs(Seconds_from_T1_to_T2);

    if (difference < 60) return `${Math.floor(difference)} ${tRoot('seconds')} `;
    else if (difference < 3600) return `${Math.floor(difference / 60)} ${tRoot('minutes')}`;
    else if (difference < 86400) return `${Math.floor(difference / 3660)} ${tRoot('hours')}`;
    else if (difference < 86400 * 30)
        return `${Math.floor(difference / 86400)} ${tRoot('days')}`;
    else if (difference < 86400 * 30 * 12)
        return `${Math.floor(difference / 86400 / 30)} ${tRoot('months')}`;
    else return `${(difference / 86400 / 30 / 12).toFixed(1)} ${tRoot('years')}`;
}

export const isValidUrl = (string) => {
    try {
        new URL(string);
    } catch (_) {
        return false;
    }
    return true;
}