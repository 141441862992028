import React from 'react'
import styled from 'styled-components'



const Content = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    width:70%;
    margin:auto;
    color:#545454;
    font-size:14px;
    margin-bottom:10px;
    margin-top:15%;
    text-align:center;
    img{
        margin:auto;
    }
    h2{
        font-size: 50px;
        
    }
`
export const view = () => (
    <Content>
        <div>
            <h2>Oops!!!!</h2>
            <p>Lo sentimos, ah ocurrido un error inesperado.</p>
<p>Nuestros servicios no se encuentran disponibles en este momento. <br/>Por favor vuelva a intentarlo nuevamente mas tarde .</p>
        </div>
        <img src="https://res.cloudinary.com/dz2gxorkj/image/upload/v1554899181/error.png" alt="error"/>
    </Content>
)