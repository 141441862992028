import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Minus from '@material-ui/icons/RemoveCircle'
import Plus from '@material-ui/icons/AddCircle'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { SketchPicker } from 'react-color'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'



const tRoot = i18n()

const Content = styled.div`
    display: flex;
    padding: 10px 0 20px;
    flex-direction: column;
    align-content: flex-start;
    text-align-last: justify;
`

const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`

const ColorContent = styled.div`
    display: block;
    margin-top: 20px;
`

const OpacityControl = styled.div`
    margin-top: 35px;
`

export const styles = theme => ({
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',

        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },
    notchedOutline: {},
    sliderWide: {
        width: '200px',
    }
});

export const view = (
    classes,
    boxColor,
    boxOpacity,
    boxBlur,
    handleChooseColor,
    handleOpacityChange,
    handleBlurChange,
    handleChangeCommitted,
    showColorPicker,
    handleShowColorPicker,
    handleCloseColorPicker) => {

    return (
        <div>
            <HeaderConfig title={tRoot('box')} />
            <Content>
                <ClickAwayListener onClickAway={handleCloseColorPicker}>
                    <ColorContent>
                        <FormControl className={classes.margin} style={{display: 'flex'}}>
                            <InputLabel
                                classes={{
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                }} >
                                {tRoot('color')}
                            </InputLabel>
                            <Input
                                id="boxColor"
                                value={boxColor}
                                classes={{
                                    underline: classes.cssUnderline,
                                }} />
                            <Color style={{ background: boxColor }} onClick={handleShowColorPicker}></Color>
                            {showColorPicker ? <SketchPicker
                                onChangeComplete={handleChooseColor}
                                color={boxColor} /> : ""}
                        </FormControl>
                    </ColorContent>
                </ClickAwayListener>
                <OpacityControl>
                    <Typography id="continuous-slider" gutterBottom>
                        {tRoot('opacity')}

                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Minus />
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={boxOpacity}
                                onChange={handleOpacityChange}
                                aria-labelledby="continuous-slider"
                                min={0}
                                max={1}
                                step={0.01}
                                onChangeCommitted={handleChangeCommitted}
                            />
                        </Grid>
                        <Grid item>
                            <Plus />
                        </Grid>
                    </Grid>
                </OpacityControl>
                {/*                 <div>
                    <Typography id="continuous-slider" gutterBottom>
                        {tRoot('blur')}

                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Minus />
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={boxBlur}
                                onChange={handleBlurChange}
                                aria-labelledby="continuous-slider"
                                min={0}
                                max={1}
                                step={0.01}
                                onChangeCommitted={handleChangeCommitted}
                            />
                        </Grid>
                        <Grid item>
                            <Plus />
                        </Grid>
                    </Grid>
                </div> */}

                <Feedback seccion="Box-Background" previewMode />
            </Content>
        </div>
    )
}


