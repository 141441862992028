import React from "react";
import { connect } from "react-redux";
import "./Npl.scss";
import Cortana from "./Cortana/Cortana";
import WatsonAssistant from "./WatsonAssistant/WatsonAssistant";
import DialogFlow from "./DialogFlow/DialogFlow";
import DialogFlowCX from "./DialogflowCX/DialogFlowCX";
import AmazonLex from "./AmazonLex/AmazonLex";
import { openLoader, closeLoader } from "../../../../rootActions";
import i18n from "../../../../i18n/i18n"
import ReactGA from 'react-ga'
import Feedback from '../../../../components/feedback'
import TiaraHeader from '../../../../components/TiaraHeader'

const tRoot = i18n()

class Npl extends React.Component {
  state = {
    engineSelected: "",
    watson: {
      workspace_id: '',
      key: '',
      password: '',
    }
  };

  changeMotor = newMotor => {
    this.setState({ engineSelected: newMotor });
  };

  UNSAFE_componentWillMount() {
    ReactGA.pageview("dashboard/chat-settings/npl", ['backTrackerV2', 'backTrackerV3'])

    var provider = this.props.nlp ? this.props.nlp.provider : 'watsonV2'
    this.setState({ engineSelected: provider })
  }

  render() {

    return (
      <div className="bodyCentral" style={{ 'width': '100%' }}>
        <TiaraHeader />
        <section className="dashboard-container">
          <h2 className="text-center">{tRoot('nlpPageTittle')}</h2>
          <div className="white-box">
            {this.state.engineSelected === "watson" || this.state.engineSelected === "watsonV2" ? (
              <WatsonAssistant
                changeMotor={this.changeMotor}
                chatId={this.props.chatId}
              />
            ) : (
                ""
              )}
            {this.state.engineSelected === "microsoft" ? (
              <Cortana
                changeMotor={this.changeMotor}
                chatId={this.props.chatId}
              />
            ) : (
                ""
              )}
            {this.state.engineSelected === "google" ? (
              <DialogFlow
                changeMotor={this.changeMotor}
                chatId={this.props.chatId}
              />
            ) : (
                ""
              )}
            {this.state.engineSelected === "dialogFlowCx" ? (
              <DialogFlowCX
                changeMotor={this.changeMotor}
                chatId={this.props.chatId}
              />
            ) : (
                ""
              )}
            {this.state.engineSelected === "aws" ? (
              <AmazonLex
                changeMotor={this.changeMotor}
                chatId={this.props.chatId}
              />
            ) : (
                ""
              )}
          </div>
          <Feedback seccion="NLP Update" />
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    chatId: state.baseData.currentChatId,
    nlp: state.baseData.currentChatConfig.NLP
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openLoader,
    closeLoader
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Npl);


