import React from "react";
import { Card, Button, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom'

import "./StatsCards.scss"
import i18n from '../../../../i18n/i18n'

const tRoot = i18n()

const styles = theme => ({
    icon: {
        fontSize: "44px",
        opacity: 0.6,
        color: theme.palette.primary.main
    },
    MuiCard: {
        borderRadius: "8px",
        overflow: 'hidden',
        'box-shadow': '0px 2px 1px -1px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.042), 0px 1px 3px 0px rgba(0, 0, 0, 0.036)',
        color: 'rgba(52, 49, 76, 1)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'background-color': '#fff',
        padding: '24px !important',
        'margin-bottom': '1.5rem!important'
    },
    MuiCardInside: {
        'box-shadow': 'none !important',
        background: 'rgba(116,103,239, 0.15) !important',
        padding: '24px !important',
        'text-align': 'center',
        position: 'relative',
        overflow: 'hidden',
        'border-radius': '8px',
        color: 'rgba(52, 49, 76, 1)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'background-color':' #fff'
    },
    buttonUpgrade: {
        margin: theme.spacing(1),
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    }


});


const UpgradeCard = ({ classes }) => {

    const history = useHistory()
    
    const handleUpgrade = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging Upgrade'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push('/dashboard/chat-settings/subscription')   
    }

  return (
    <Card className={classes.MuiCard}>
      <Card elevation={0} className={classes.MuiCardInside}>
        {/* <img src="/assets/images/illustrations/upgrade.svg" alt="upgrade" /> */}
        <Icon style={{ fontSize: 85, color: '#F3B07D' }}>navigation</Icon>
        <p className="text-muted m-0 py-6">
          
          {tRoot('upgradeLandingMsg')}
        </p>
        <Button
          className={classes.buttonUpgrade}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleUpgrade}
        >
          {tRoot('upgrade')}
        </Button>
      </Card>
    </Card>
  );
};

export default withStyles(styles, { withTheme: true })(UpgradeCard);