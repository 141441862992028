import {
    UPDATE_HEADER_BACKGROUND,
    UPDATE_HEADER_BORDERS,
    UPDATE_HEADER_IMAGE,
    UPDATE_FLOATING_ICON_POSITION,
    UPDATE_FLOATING_ICON_BACKGROUND,
    UPDATE_FLOATING_ICON_FORM,
    UPDATE_DIALOGBOX_BACKGROUND,
    UPDATE_DIALOGBOX_CALLOUTS,
    UPDATE_SENDERSECTION_BACKGROUND,
    UPDATE_FLOATING_ICON_TOOLTIP,
    UPDATE_FLOATING_ICON_ICON,
    UPDATE_HEADER_NAME,
    UPDATE_SENDERSECTION_MESSAGE,
    UPDATE_SENDERSECTION_BUTTON,
    UPDATE_BOX_SIZE,
    EL_UPDATE_BOX_BACKGROUND,
    EL_UPDATE_HEADER_IMAGE
} from "./ChatSettings";

import axios from 'axios';

const BACKEND_API_URL = process.env.API_URL
const BACKEND_API_KEY = process.env.API_KEY
const getToken = () => (localStorage.getItem("token"))

const headersData = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-core-client-id': BACKEND_API_KEY,
    'Authorization': getToken()
}



export const getChat = chatId => {
    return axios.get(
        BACKEND_API_URL + "/chat/getChat/" + chatId,
        { headers: headersData }
    )
}

export const getSubs = subs => {
    return axios.post(
        BACKEND_API_URL + "/subs/getsubs/" + subs,
        { headers: headersData }
    ).then(result => {
        return result.data.subscription
    })
}

export const getAccessChat = chatId => {
    return axios.get(
        BACKEND_API_URL + "/chat/chatAccess/" + chatId,
        { headers: headersData }
    )
}

export const deleteChat = chat => {
    return axios.delete(
        BACKEND_API_URL + "/chat/" + chat._id,
        { headers: headersData }
    )
}

export const changeName = (chat, newName) => {
    const content = {
        name: newName
    }
    return axios.put(BACKEND_API_URL + "/chat/update/" + chat._id,
        content,
        { headers: headersData })

}

export const updateFloatingIconPosition = (value, chatId) => {
    /*TODO:  Alineacion Horizontal
        
    */


    let content = {
        styles: {
            chatIcon: {
                alignIcon: value.horizontal
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_FLOATING_ICON_POSITION, data: {
                type: "success",
                operation: UPDATE_FLOATING_ICON_POSITION,
                value: value
            }
        }
    })

}

export const publish = chatId => {
    return axios.post(
        BACKEND_API_URL + "/chat/publish/" + chatId,
        {},
        { headers: headersData }
    )
}

export const updateFloatingIconIcon = (chatId, data) => {

    let avatar = null
    Object.keys(data).forEach(e => {
        if (data[e].selected)
            avatar = data[e]
    })



    let content = {
        styles: {
            chatIcon: {
                backgroundColor: data.background.color || 'white',
                iconColor1: avatar.color1.color,
                iconColor2: avatar.color2.color,
                iconColor3: avatar.color3.color
            }
        },
        config: {
            chatIcon: {
                iconImage: {
                    secure_url: avatar.url
                }
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_FLOATING_ICON_ICON, data: {
                type: "success",
                operation: UPDATE_FLOATING_ICON_ICON,
                value: data
            }
        }
    })


}

export const updateFloatingIconForm = (chatId, data) => {
    var shadow
    switch (data.shadow) {
        case 'ShadowBottomBlack':
            shadow = '0px 7px 10px rgba(0,0,0,0.6)'
            break
        case 'ShadowBottomGray':
            shadow = '0px 7px 7px rgba(0,0,0,0.3)'
            break
        case 'None':
            shadow = '0px 0px 0px rgba(0,0,0,0)'
            break
        case 'ShadowRightBlack':
            shadow = '7px 7px 10px rgba(0,0,0,0.6)'
            break
        case 'ShadowRightGray':
            shadow = '7px 7px 7px rgba(0,0,0,0.3)'
            break
        default:
            break
    }


    let content = {
        styles: {
            chatIcon: {
                ...data.formParameters,
                boxShadow: shadow,
                marginLeft: data.marginLeft,
                marginRight: data.marginRight,
                alwaysVisible: data.visible === 'YES' ? 'alwaysVisible' : 'notVisible'
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_FLOATING_ICON_FORM, data: {
                type: "success",
                operation: UPDATE_FLOATING_ICON_FORM,
                value: data
            }
        }
    })

}

export const updateHeaderBoders = (border, activeShadow, chatId) => {
    let data = {}
    switch (border) {
        case "RECTANGLE":
            data = {
                styles: {
                    chatTop: {
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px"
                    }
                }
            }
            break

        case "RECTANGLERADIUS":
            data = {
                styles: {
                    chatTop: {
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px"
                    }
                }
            }
            break

        case "CIRCLE":
            data = {
                styles: {
                    chatTop: {
                        borderTopLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px"
                    }
                }
            }
            break
        default:
            break
    }

    if (!data.styles) {
        data = {
            styles: {
                chatTop: {

                }
            }
        }
    }
    if (activeShadow) {
        data.styles.chatTop.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)'
    } else {
        data.styles.chatTop.boxShadow = 'none'
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        data,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_HEADER_BORDERS, data: {
                type: "success",
                operation: UPDATE_HEADER_BORDERS,
                value: {
                    border,
                    activeShadow
                }
            }
        }
    })
}

export const updateHeaderBackground = (color, chatId) => {
    let data = {
        styles: {
            chatTop: {
                backgroundColor: color,
                buttonHoverColor: color
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        data,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_HEADER_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_HEADER_BACKGROUND,
                value: color
            }
        }
    })



}

export const updateBoxSize = (data, chatId) => {
    let content = {
        styles: {
            chatFrame: {
                height: data.boxHeight + 'px',
                width: data.boxWidth + 'px'
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_BOX_SIZE, data: {
                type: "success",
                operation: UPDATE_BOX_SIZE,
                value: data
            }
        }
    })



}

export const updateHeaderName = (data, chatId) => {
    let content = {
        styles: {
            chatTop: {
                fontFamily: data.font ? data.font : 'Arial',
                textAlign: data.textAlignment ? data.textAlignment : 'left',
                color: data.color ? data.color : 'white',
                fontSize: data.fontSize ? data.fontSize : '10px',
                buttonColor: data.color ? data.color : 'white',
                buttonBackgroundHoverColor: data.color ? data.color : 'white'
            }
        },
        config: {
            chatTop: {
                name: data.tittle
            }
        }
    }
    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_HEADER_NAME, data: {
                type: "success",
                operation: UPDATE_HEADER_NAME,
                value: data
            }
        }
    })



}

export const updateHeaderImage = (data, chatId) => {
    /* TODO: Control de Imagen
    1. FIXME: Verificar tamaño porque devuelve 413 Entitty Too Large. 
    2. Falta un atributo en el servicio para indicar el Shape (forma)        
    */
    let content = {
        config: {
            chatTop: {
                avatar: {
                    public_id: "logo",
                    secure_url: data.avatarUrl
                }
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_HEADER_IMAGE, data: {
                type: "success",
                operation: UPDATE_HEADER_IMAGE,
                value: data
            }
        }
    })


}



export const updateFloatingIcon = color => {
    /* TOOD: Falta COMPLETO Floating Icon Image */
    return new Promise(function (resolve, reject) {
        resolve({
            type: UPDATE_HEADER_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_HEADER_BACKGROUND,
                value: color
            }
        })
    })
}

export const updateFloatingBackground = color => {
    /* TOOD: Falta COMPLETO Floating Icon Background */
    return new Promise(function (resolve, reject) {
        resolve({
            type: UPDATE_FLOATING_ICON_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_FLOATING_ICON_BACKGROUND,
                value: color
            }
        })
    })
}

export const updateFloatingTooltip = (chatId, data) => {
    let content = {
        styles: {
            chatIcon: {
                fontFamily: data.font ? data.font : 'Arial',
                fontSize: data.size ? data.size : '12px',
                color: data.color ? data.color : 'white'
            }
        },
        config: {
            chatIcon: {
                text: data.text
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_FLOATING_ICON_TOOLTIP, data: {
                type: "success",
                operation: UPDATE_FLOATING_ICON_TOOLTIP,
                value: data
            }
        }
    })
}


export const updateDialogBoxBackground = (color, chatId) => {
    let data = {
        styles: {
            messagesSection: {
                backgroundColor: color
            }
        }
    }
    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        data,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_DIALOGBOX_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_DIALOGBOX_BACKGROUND,
                value: color
            }
        }
    })
}

export const updateDialogBoxCallOuts = (data, chatId) => {

    let radius = {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",
    }


    let peak = "remove-peak"
    let font = data.font ? data.font : "Arial"
    let fontSize = data.fontSize ? data.fontSize : "10px"
    let shadow = "0px 0px 0px 0px rgba(0,0,0,0)"




    let radiusSpec = {
        "10px": ["CallOutsFormRadius", "CallOutsFormRadiusLeft", "CallOutsFormRadiusThree", "CallOutsFormRadiusTop", "CallOutsFormRadiusTopCenter", "CallOutsFormRadiusTwo"],
        "30px": ["CallOutsFormCircle", "CallOutsFormCircleTop", "CallOutsFormCircleTopCenter"],
    }

    let peakSpec = {
        "top-left": ["CallOutsFormCircleTop", "CallOutsFormRadiusTop", "CallOutsFormSquareTop"],
        "top-center": ["CallOutsFormCircleTopCenter", "CallOutsFormRadiusTopCenter", "CallOutsFormSquareTopCenter"],
        "left-top": ["CallOutsFormSquareLeftTop"],
        "left-center": ["CallOutsFormRadiusLeft", "CallOutsFormSquareLeftCenter"]
    }

    let peakMirror = {
        "top-left": "top-right",
        "top-center": "top-center",
        "left-top": "right-top",
        "left-center": "right-center",
        "remove-peak": "remove-peak"
    }

    Object.keys(radiusSpec).forEach(rs => {
        if (radiusSpec[rs].includes(data.form)) {
            //radius = rs
            radius = {
                borderTopLeftRadius: rs,
                borderTopRightRadius: rs,
                borderBottomRightRadius: rs,
                borderBottomLeftRadius: rs,
            }
        }
    })

    Object.keys(peakSpec).forEach(ps => {
        if (peakSpec[ps].includes(data.form)) {
            peak = ps
        }
    })



    let radiusUser = radius

    if (data.form === 'CallOutsFormRadiusThree') {
        radius = {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            borderBottomLeftRadius: "30px",
        }

        radiusUser = {
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "30px",
            borderBottomLeftRadius: "30px",
        }


    }

    if (data.form === 'CallOutsFormRadiusTwo') {
        radius = {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "30px",
            borderBottomRightRadius: "30px",
            borderBottomLeftRadius: "0px",
        }

        radiusUser = {
            borderTopLeftRadius: "30px",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "30px",
        }


    }


    switch (data.shadow) {
        case "CallOutsShadowBottomBlack":
            shadow = "0px 7px 10px rgba(0,0,0,0.7)"
            break
        case "CallOutsShadowBottomGray":
            shadow = "0px 7px 7px rgba(0,0,0,0.3)"
            break
        case "CallOutsShadowRightBlack":
            shadow = "7px 7px 10px rgba(0,0,0,0.6)"
            break
        case "CallOutsShadowRightGray":
            shadow = "7px 7px 7px rgba(0,0,0,0.3)"
            break
        default:
            break
    }


    let content = {
        styles: {
            messagesSection: {
                fontFamily: font,
                userGlobe: {
                    backgroundColor: data.visit.color ? data.visit.color : "#c3c3c3",
                    color: data.textUser.color ? data.textUser.color : "black",
                    fontSize: fontSize,
                    ...radiusUser,
                    peakPosition: peakMirror[peak], //peak !== "remove-peak" ? peakMirror['peak'] : "remove-peak",
                    boxShadow: shadow,
                    hideTime: !data.showTime,
                    hideName: !data.showName
                },
                botGlobe: {
                    backgroundColor: data.system.color ? data.system.color : "#c3c3c3",
                    color: data.textBot.color ? data.textBot.color : "black",
                    fontSize: fontSize,
                    ...radius,
                    peakPosition: peak,
                    boxShadow: shadow,
                    hideTime: !data.showTime,
                    hideName: !data.showName
                },

            }
        },
        config: {
            writeSection: {
                userGlobe: {
                    CallOutId: data.form,
                    ShadowId: data.shadow ? data.shadow : 'CallOutsShadowNone'
                },
                botGlobe: {
                    CallOutId: data.form,
                    ShadowId: data.shadow ? data.shadow : 'CallOutsShadowNone'
                }
            }
        }
    }

    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_DIALOGBOX_CALLOUTS, data: {
                type: "success",
                operation: UPDATE_DIALOGBOX_CALLOUTS,
                value: data
            }
        }
    })
}


export const updateSenderSectionBackground = (data, chatId) => {
    let content = {
        styles: {
            writeSection: {
                backgroundColor: data.background
            }
        }
    }

    if (data.activeShadow) {
        content.styles.writeSection.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.15)'
    } else {
        content.styles.writeSection.boxShadow = 'none'
    }


    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_SENDERSECTION_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_SENDERSECTION_BACKGROUND,
                value: data
            }
        }
    })
}

export const updateSenderSectionMessage = (data, chatId) => {


    let content = {
        styles: {
            writeSection: {
                color: data.color ? data.color : 'black',
                fontFamily: data.font ? data.font : 'Arial',
                fontSize: data.fontSize ? data.fontSize : "12px"
            }
        },
        config: {
            writeSection: {
                placeholderText: data.text
            }
        }

    }


    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_SENDERSECTION_MESSAGE, data: {
                type: "success",
                operation: UPDATE_SENDERSECTION_MESSAGE,
                value: data
            }
        }
    })


}

export const updateSenderSectionButton = (data, chatId) => {

    /* TODO: Sender Section Button 
        1. Falta incorporar el borderColor  
    */
    let content = {
        styles: {
            writeSection: {
                sendButton: {
                    "backgroundColor": data.background && data.background.color ? data.background.color : "white",
                    "borderColor": data.background && data.background.color ? data.background.color : "white",
                    "hoverBorderColor": data.backgroundHover && data.backgroundHover.color ? data.backgroundHover.color : "white",
                    "hoverBackgroundColor": data.backgroundHover && data.backgroundHover.color ? data.backgroundHover.color : "white",
                    "iconColor": data.mainColor && data.mainColor.color ? data.mainColor.color : "white",
                    "hoverIconColor": data.colorHover && data.colorHover.color ? data.colorHover.color : "white"
                }
            }
        },
        config: {
            writeSection: {
                sendButton: {
                    secure_url: data.image
                }
            }
        }
    }
    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: UPDATE_SENDERSECTION_BUTTON, data: {
                type: "success",
                operation: UPDATE_SENDERSECTION_BUTTON,
                value: data
            }
        }
    })
}

export const updateELBoxBackground = (data, chatId) => {
    let content = {
        styles: {
            chatTop: {
                backgroundColor: data.boxColor,
                opacityBackground: data.boxOpacity,
                blur: data.boxBlur
            },
            messagesSection: {
                backgroundColor: data.boxColor,
                opacityBackground: data.boxOpacity,
                blur: data.boxBlur
            },
            writeSection: {
                backgroundColor: data.boxColor,
                opacityBackground: data.boxOpacity,
                blur: data.boxBlur
            }
        }
    }
    return axios.put(
        BACKEND_API_URL + "/chat/update/" + chatId,
        content,
        { headers: headersData }
    ).then(result => {
        return {
            type: EL_UPDATE_BOX_BACKGROUND, data: {
                type: "success",
                operation: EL_UPDATE_BOX_BACKGROUND,
                value: data
            }
        }
    })
}

export const upLoadAvatar = (imageBase64, chatId) => {
    let data = {
        image: {
            imgBase64: imageBase64,
            fileType: "png"
        }
    }

    return axios.post(
        BACKEND_API_URL + "/chat/avatarUpload/" + chatId,
        data,
        { headers: headersData }
    )
}

export const updateELHeaderImage = (data, chatId) => {
    console.log('data :', data);

    return new Promise(function (resolve, reject) {
        resolve({
            type: EL_UPDATE_HEADER_IMAGE, data: {
                type: "success",
                operation: EL_UPDATE_HEADER_IMAGE,
                value: data
            }
        })
    })
}



//**************************************************************************** */
//**************************************************************************** */
//**************************************************************************** */

export const wrapperDataBaseToRedux = (chatConfig) => {

    return [
        () => {
            return {

                type: UPDATE_SENDERSECTION_BUTTON, data: {
                    type: "success",
                    operation: UPDATE_SENDERSECTION_BUTTON,
                    value: {
                        background: {
                            color: chatConfig.styles.writeSection.sendButton.backgroundColor
                        },
                        backgroundHover: {
                            color: chatConfig.styles.writeSection.sendButton.hoverBackgroundColor
                        },
                        mainColor: {
                            color: chatConfig.styles.writeSection.sendButton.iconColor
                        },
                        colorHover: {
                            color: chatConfig.styles.writeSection.sendButton.hoverIconColor
                        },
                        image: chatConfig.config.writeSection.sendButton.secure_url
                    }
                }
            }
        },
        () => {
            return {
                type: UPDATE_SENDERSECTION_MESSAGE, data: {
                    type: "success",
                    operation: UPDATE_SENDERSECTION_MESSAGE,
                    value: {
                        text: chatConfig.config.writeSection.placeholderText,
                        color: chatConfig.styles.writeSection.color,
                        font: chatConfig.styles.writeSection.fontFamily,
                        fontSize: chatConfig.styles.writeSection.fontSize
                    }
                }
            }
        },
        () => {
            return {
                type: UPDATE_SENDERSECTION_BACKGROUND, data: {
                    type: "success",
                    operation: UPDATE_SENDERSECTION_BACKGROUND,
                    value: {
                        background: chatConfig.styles.writeSection.backgroundColor,
                        showColorPicker: false,
                        activeShadow: chatConfig.styles.writeSection.boxShadow === 'none' ? false : true
                    }
                }
            }
        },
        //UPDATE_FLOATING_ICON_FORM
        () => {
            var form
            if (chatConfig.styles.chatIcon.borderTopRightRadius === "0px" && chatConfig.styles.chatIcon.borderTopLeftRadius === "0px") {
                form = "RECTANGLE"
            }
            if (chatConfig.styles.chatIcon.borderTopRightRadius === "10px" && chatConfig.styles.chatIcon.borderTopLeftRadius === "10px") {
                form = "RADIUS"
            }
            if (chatConfig.styles.chatIcon.borderTopRightRadius === "50px" && chatConfig.styles.chatIcon.borderTopLeftRadius === "50px") {
                form = "CIRCLE"
            }
            if (chatConfig.styles.chatIcon.borderTopRightRadius === "0px" && chatConfig.styles.chatIcon.borderTopLeftRadius === "10px") {
                form = "RECTANGLELEFT"
            }
            if (chatConfig.styles.chatIcon.borderTopRightRadius === "0px" && chatConfig.styles.chatIcon.borderTopLeftRadius === "50px") {
                form = "CIRCLELEFT"
            }

            var shadow
            switch (chatConfig.styles.chatIcon.boxShadow) {
                case '0px 7px 10px rgba(0,0,0,0.6)':
                    shadow = 'ShadowBottomBlack'
                    break
                case '0px 7px 7px rgba(0,0,0,0.3)':
                    shadow = 'ShadowBottomGray'
                    break
                case '0px 0px 0px rgba(0,0,0,0)':
                    shadow = 'None'
                    break
                case '7px 7px 10px rgba(0,0,0,0.6)':
                    shadow = 'ShadowRightBlack'
                    break
                case '7px 7px 7px rgba(0,0,0,0.3)':
                    shadow = 'ShadowRightGray'
                    break
                default:
                    break
            }

            return {
                type: UPDATE_FLOATING_ICON_FORM, data: {
                    type: "success",
                    operation: UPDATE_FLOATING_ICON_FORM,
                    value: {
                        form: form,
                        visible: chatConfig.styles.chatIcon.alwaysVisible === 'alwaysVisible' ? 'YES' : 'NO',
                        margin: chatConfig.styles.chatIcon.marginLeft === '20px' || chatConfig.styles.chatIcon.marginLeft === '20px' ? 'YES' : 'NO',
                        marginLeft: chatConfig.styles.chatIcon.marginLeft,
                        marginRight: chatConfig.styles.chatIcon.marginRight,
                        shadow: shadow,
                        formParameters: {}
                    }
                }
            }
        },

        //UPDATE_FLOATING_ICON_ICON
        () => {
            return {
                type: UPDATE_FLOATING_ICON_ICON, data: {
                    type: "success",
                    operation: UPDATE_FLOATING_ICON_ICON,
                    value: {
                        background: {
                            color: chatConfig.styles.chatIcon.backgroundColor
                        },
                        callOutsDoubleBoxes: {
                            selected: chatConfig.config.chatIcon.iconImage.secure_url === 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-1.svg' ? true : false,
                            url: chatConfig.config.chatIcon.iconImage.secure_url,
                            color1: {
                                color: chatConfig.styles.chatIcon.iconColor1
                            },
                            color2: {
                                color: chatConfig.styles.chatIcon.iconColor2
                            },
                            color3: {
                                color: chatConfig.styles.chatIcon.iconColor3
                            }
                        },
                        circleCallOuts: {
                            selected: chatConfig.config.chatIcon.iconImage.secure_url === 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-4.svg' ? true : false,
                            url: chatConfig.config.chatIcon.iconImage.secure_url,
                            color1: {
                                color: chatConfig.styles.chatIcon.iconColor1
                            },
                            color2: {
                                color: chatConfig.styles.chatIcon.iconColor2
                            },
                            color3: {
                                color: chatConfig.styles.chatIcon.iconColor3
                            }
                        },
                        doubleCircleCallOuts: {
                            selected: chatConfig.config.chatIcon.iconImage.secure_url === 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-5.svg' ? true : false,
                            url: chatConfig.config.chatIcon.iconImage.secure_url,
                            color1: {
                                color: chatConfig.styles.chatIcon.iconColor1
                            },
                            color2: {
                                color: chatConfig.styles.chatIcon.iconColor2
                            },
                            color3: {
                                color: chatConfig.styles.chatIcon.iconColor3
                            }
                        },
                        humanCallOuts: {
                            selected: chatConfig.config.chatIcon.iconImage.secure_url === 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-3.svg' ? true : false,
                            url: chatConfig.config.chatIcon.iconImage.secure_url,
                            color1: {
                                color: chatConfig.styles.chatIcon.iconColor1
                            },
                            color2: {
                                color: chatConfig.styles.chatIcon.iconColor2
                            },
                            color3: {
                                color: chatConfig.styles.chatIcon.iconColor3
                            }
                        },
                        menCallOuts: {
                            selected: chatConfig.config.chatIcon.iconImage.secure_url === 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-2.svg' ? true : false,
                            url: chatConfig.config.chatIcon.iconImage.secure_url,
                            color1: {
                                color: chatConfig.styles.chatIcon.iconColor1
                            },
                            color2: {
                                color: chatConfig.styles.chatIcon.iconColor2
                            },
                            color3: {
                                color: chatConfig.styles.chatIcon.iconColor3
                            }
                        },
                        radiusCallOuts: {
                            selected: chatConfig.config.chatIcon.iconImage.secure_url === 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-6.svg' ? true : false,
                            url: chatConfig.config.chatIcon.iconImage.secure_url,
                            color1: {
                                color: chatConfig.styles.chatIcon.iconColor1
                            },
                            color2: {
                                color: chatConfig.styles.chatIcon.iconColor2
                            },
                            color3: {
                                color: chatConfig.styles.chatIcon.iconColor3
                            }
                        }
                    }
                }
            }
        },

        () => {
            return {
                type: UPDATE_FLOATING_ICON_POSITION, data: {
                    type: "success",
                    operation: UPDATE_FLOATING_ICON_POSITION,
                    value: {
                        vertical: chatConfig.styles.chatIcon.verticalAlign,
                        horizontal: chatConfig.styles.chatIcon.alignIcon
                    }
                }
            }
        },
        //UPDATE_FLOATING_ICON_TOOLTIP
        () => {
            return {
                type: UPDATE_FLOATING_ICON_TOOLTIP, data: {
                    type: "success",
                    operation: UPDATE_FLOATING_ICON_TOOLTIP,
                    value: {
                        text: chatConfig.config.chatIcon.text,
                        color: chatConfig.styles.chatIcon.color,
                        font: chatConfig.styles.chatIcon.fontFamily,
                        size: chatConfig.styles.chatIcon.fontSize
                    }
                }
            }
        },
        () => ({
            type: UPDATE_DIALOGBOX_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_DIALOGBOX_BACKGROUND,
                value: chatConfig.styles.messagesSection.backgroundColor
            }
        }),
        () => {
            let border = ''
            if (chatConfig.styles.chatTop.borderTopLeftRadius === '0px')
                border = 'RECTANGLE'
            if (chatConfig.styles.chatTop.borderTopLeftRadius === '10px')
                border = 'RECTANGLERADIUS'
            if (chatConfig.styles.chatTop.borderTopLeftRadius === '25px')
                border = 'CIRCLE'
            return {
                type: UPDATE_HEADER_BORDERS, data: {
                    type: "success",
                    operation: UPDATE_HEADER_BORDERS,
                    value: {
                        border,
                        boxShadow: chatConfig.styles.chatTop.boxShadow === 'none' ? false : true
                    }
                }
            }

        }, () => ({
            type: UPDATE_HEADER_IMAGE, data: {
                type: "success",
                operation: UPDATE_DIALOGBOX_BACKGROUND,
                value: {
                    finalImage: chatConfig.config.chatTop.avatar.secure_url,
                    customAvatar: chatConfig.config.chatTop.avatar.secure_url
                }
            }
        }),

        () => ({
            type: UPDATE_HEADER_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_HEADER_BACKGROUND,
                value: chatConfig.styles.chatTop.backgroundColor
            }
        }),
        () => ({
            type: UPDATE_BOX_SIZE, data: {
                type: "success",
                operation: UPDATE_BOX_SIZE,
                value: {
                    boxHeight: parseInt((chatConfig.styles.chatFrame.height).split('p')[0]),
                    boxWidth: parseInt((chatConfig.styles.chatFrame.width).split('p')[0])
                }
            }
        }),

        () => ({
            type: UPDATE_HEADER_NAME, data: {
                type: "success",
                operation: UPDATE_HEADER_NAME,
                value: {
                    tittle: chatConfig.config.chatTop.name,
                    color: chatConfig.styles.chatTop.color,
                    font: chatConfig.styles.chatTop.fontFamily,
                    fontSize: chatConfig.styles.chatTop.fontSize,
                    textAlignment: chatConfig.styles.chatTop.textAlign
                }
            }
        }),
        () => ({
            type: UPDATE_DIALOGBOX_BACKGROUND, data: {
                type: "success",
                operation: UPDATE_DIALOGBOX_BACKGROUND,
                value: chatConfig.styles.messagesSection.backgroundColor
            }
        }),
        () => {
            /* TODO: Wrappear Shape seleccionada*/
            /*             let shadow = ''
                        switch (chatConfig.styles.messagesSection.botGlobe.shadow) {
                            case "0px 7px 10px rgba(0,0,0,0.7)":
                                shadow = "CallOutsShadowBottomBlack"
                                break
                            case "0px 7px 7px rgba(0,0,0,0.3)":
                                shadow = "CallOutsShadowBottomGray"
                                break
                            case "7px 7px 10px rgba(0,0,0,0.6)":
                                shadow = "CallOutsShadowRightBlack"
                                break
                            case "7px 7px 7px rgba(0,0,0,0.3)":
                                shadow = "CallOutsShadowRightGray"
                                break
                            default:
                                break
                        } */
            return {
                type: UPDATE_DIALOGBOX_CALLOUTS, data: {
                    type: "success",
                    operation: UPDATE_DIALOGBOX_CALLOUTS,
                    value: {
                        form: chatConfig.config.writeSection.botGlobe.CallOutId,
                        shadow: chatConfig.config.writeSection.botGlobe.ShadowId,
                        font: chatConfig.styles.messagesSection.fontFamily,
                        fontSize: chatConfig.styles.messagesSection.botGlobe.fontSize,
                        system: {
                            color: chatConfig.styles.messagesSection.botGlobe.backgroundColor
                        },
                        visit: {
                            color: chatConfig.styles.messagesSection.userGlobe.backgroundColor,
                        },
                        textBot: {
                            color: chatConfig.styles.messagesSection.botGlobe.color,
                        },
                        textUser: {
                            color: chatConfig.styles.messagesSection.userGlobe.color,
                        },
                        showTime: !chatConfig.styles.messagesSection.userGlobe.hideTime,
                        showName: !chatConfig.styles.messagesSection.userGlobe.hideName,
                        //shadow
                    }
                }
            }
        },
        () => ({
            type: EL_UPDATE_BOX_BACKGROUND, data: {
                type: "success",
                operation: EL_UPDATE_BOX_BACKGROUND,
                value: {
                    boxColor: chatConfig.styles.chatTop.backgroundColor,
                    boxOpacity: chatConfig.styles.chatTop.opacityBackground,
                    boxBlur: chatConfig.styles.chatTop.blur
                }
            }
        }),
        () => ({
            type: EL_UPDATE_HEADER_IMAGE, data: {
                type: "success",
                operation: EL_UPDATE_HEADER_IMAGE,
                value: {
                    customAvatarURL: chatConfig.config.chatTop.avatar.secure_url,
                    typeOfHeader:chatConfig.config.chatTop.avatarType
                }
            }
        })
    ]
}
