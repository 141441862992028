import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Chart, Dataset } from 'react-rainbow-components';
import ReactGA from 'react-ga';
import Container from '@material-ui/core/Container';
import { Grid } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { msgsByMonths, usage } from '../hooksActionCreators'
import TiaraHeader from '../../../../components/TiaraHeader'
import Loader from '../../../../components/LinearLoader/linearLoader'
import "./chatLanding.scss"
import UpgradeCard from "./UpgradeCard";
import i18n from '../../../../i18n/i18n'
import StatCards from "./StatsCards"
import Feedback from '../../../../components/feedback'
import PlanAlert from '../../../../components/planAlert'
import NotificationCards from '../../../../components/NotificationCards'

const tRoot = i18n()

const containerStyles = {
    //maxWidth: 970,
    width: '100%',
    background: 'var(--primary)!important'
};

const chatContent = {
    background: 'var(--primary)!important'
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        'padding-bottom': 10
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));



const chatLanding = () => {
    const classes = useStyles();
    var appState = JSON.parse(localStorage.getItem('appState'))
    var chatID = appState.baseData.currentChatConfig._id
    var chatName = appState.baseData.currentChatConfig.name
    var plan = JSON.parse(localStorage.getItem('plan'))

    const arrayYearLabels = (year) => {
        var yearsLabels = []
        for (let i = 1; i <= 12; i++) {
            yearsLabels.push(year + '-' + i)
        }
        return yearsLabels
    }

    var today = new Date()
    var actualYear = today.getFullYear()
    var InitialLabelsValues = arrayYearLabels(actualYear)
    var [chartLabels, setChartLabels] = useState(InitialLabelsValues)
    var [chartValues, setChartValues] = useState([])
    var [yearSelect, setYearSelect] = useState(actualYear)
    var [messagesUse, setMessagesUse] = useState()
    var [limit, setLimit] = useState()
    var [loader, setLoader] = useState(false)
    var [typeAlert, setTypeAlert] = useState("none")

    useEffect(() => {
        ReactGA.pageview("/chat/chatlanding", ['backTrackerV2', 'backTrackerV3'])

        var dateFilters = {
            yearFrom: 2023,
            monthFrom: 1,
            yearTo: 2023,
            monthTo: 12
        }
        fetchChartData(dateFilters)
        usageData()
    }, [])

    const fetchChartData = async (dateFilters) => {
        openLoader()
        let result = await msgsByMonths(chatID, dateFilters)

        var messages = []
        for (let i = 0; i < result.length; i++) messages.push(result[i].messages)

        setChartLabels(arrayYearLabels(dateFilters.yearFrom))
        setChartValues(messages)
        
        setLimit(defineLimit(await usageDataMax(messages), plan.messagesReserved))

        closeLoader()
    }

    const usageData = async () => {
        let useData = await usage(chatID)
        setMessagesUse(useData[0].messages)
        sessionStorage.setItem("msgsUse", useData[0].messages)
        defineTypeAlert(useData[0].messages, plan.messagesReserved)
    }

    const usageDataMax = async (useData) => {
        let max = 0
        useData.forEach(u => max = max > u ? max : u)
        return max
    }


    const handleYearChange = (event) => {
        setYearSelect(event.target.value)
        var dateFilters = {
            yearFrom: event.target.value,
            monthFrom: 1,
            yearTo: event.target.value,
            monthTo: 12
        }
        fetchChartData(dateFilters)
    }

    const openLoader = () => setLoader(true)
    const closeLoader = () => setLoader(false)


    const defineLimit = (msgUse, reserved) => {
        var r
        if (msgUse === 0 || ((msgUse / reserved) * 100) > 50) {
            r = new Array(12).fill(reserved)
        }
        return r
    }

    const defineTypeAlert = (msgUse, reserved) => {
        var rate = ((msgUse / reserved) * 100)

        if (rate > 75) {
            setTypeAlert("alert")
        } else {
            if (rate > 50) {
                setTypeAlert("warning")
            }
        }
    }


    const scale = {
        yAxes: [{
            ticks: {
                suggestedMin: 50,
                suggestedMax: 100,
                precision: 0,
                min: 0
            }
        }]
    }


    return (
        <div className="bodyCentral" style={{ 'width': '100%' }}>
            <TiaraHeader />
            <section className="dashboard-container">
                <div className="flex">
                    <div className="project-name">
                        {chatName}
                    </div>
                    <div className="notificationBox">
                        <NotificationCards />
                    </div>
                </div>


                <h2 style={{ 'textAlign': 'center' }}>{tRoot('chatLandingTittle')}</h2>

                <Container maxWidth={false} style={chatContent}>
                    <PlanAlert type={typeAlert} messagesUse={messagesUse} messagesReserved={plan.messagesReserved} />
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{tRoot('año')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={yearSelect}
                            onChange={handleYearChange}
                        >
                            <MenuItem value={2019}>2019</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                        </Select>
                    </FormControl>
                    {loader ? <div style={{ 'height': '300px' }}><Loader /></div> :
                        <div style={containerStyles}>
                            <div>
                                <Chart
                                    labels={chartLabels.length >= 1 ? chartLabels : []}
                                    type="line"
                                    min='0'
                                    disableXAxisGridLines
                                    maintainAspectRatio={false}
                                    style={{ 'height': '300px' }}
                                    scales={scale}

                                >
                                    <Dataset
                                        title={tRoot('Messages')}
                                        //values={[0]}
                                        values={chartValues.length >= 1 ? chartValues : []}
                                        backgroundColor="#01b6f5"
                                        borderColor="#01b6f5"

                                    />

                                    <Dataset
                                        title={tRoot('messagesReserved')}
                                        values={limit}
                                        backgroundColor='red'
                                        borderColor='red'

                                    />

                                </Chart>
                            </div>
                        </div>
                    }
                </Container>


                <div className="analytics m-sm-30">
                    <Grid container spacing={3}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <StatCards messagesUse={messagesUse} />
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <UpgradeCard />
                        </Grid>
                    </Grid>
                </div>


                <Feedback seccion="ChatLanding" />
            </section>
        </div>
    )
}


export default chatLanding
