import React from 'react'
import styled from 'styled-components'
import { styleSelected , unSelected} from '../styles'


const ButtonShadow = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
border-radius:10px
`


export const CallOutsShadowNone = ({ func, itemSelected }) => {
  return (
    <ButtonShadow onClick={e => {
      window.chatbot.updateBotGlobeStyles({
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0);"
      })

      window.chatbot.updateUserGlobeStyles({
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0);"
      })

      func('CallOutsShadowNone')
    }
    }
    style={itemSelected === 'CallOutsShadowNone' ? styleSelected : unSelected}>
    </ButtonShadow>
  )
}