import React from 'react';
import styled from 'styled-components'
import i18n from  '../../../i18n/i18n'



const tRoot = i18n()

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
    padding-left: 40px;
    width: 1000px;
`

const HeaderContent = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap:20px;
`
const Project = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: #b9bec1;
    text-align:left;
    float: left;
    height:30px;
    width:100%
    padding-left:40px

`

const Table = styled.div`
display: block;
width: 100%;
text-align: left;
font-size: 12px;
border-radius: 4px;
line-height: 18px;
border: 1px solid rgba(0,0,0,0.1);
margin-bottom: 15px;
max-width: 800px;
float:left;

`
const TableHead = styled.div`
background-color: #f4f4f4;
padding: 15px;
font-size: 14px;

`
const Success = styled.div`
color: #81d883;
    padding: 15px;
    font-size: 14px;

`
const TableContent = styled.div`
display:grid;
grid-template-columns:1fr 1fr 1fr;
padding:20px;

`
const Label = styled.div`
font-size: 12px;
    color: #999;
`
const LabelInput = styled.div`
font-size: 15px;
line-height: 24px;
`

const Note = styled.div`
padding: 15px;
font-size: 12px;
`
export const view = ({result, chatName},user) => {
  return (
    <div>
          <HeaderContent>
            <div>
              <Project>{chatName}</Project>
              <h2 style={{'padding-left': '40px', 'text-align':'left'}}>{tRoot('suscription')}</h2>
            </div>
          </HeaderContent>
          <Content>
            <Table>
                <TableHead>
                {tRoot('orderDetails')}
                </TableHead>
                <Success>
                {tRoot('thankMessage')}
                </Success>
                <TableContent>
                    <div>
                        <Label>{tRoot('orderNro')}</Label>
                        <LabelInput>{result.RefNo}</LabelInput>
                    </div>
                    <div>
                        <Label>{tRoot('paymentMethod')}</Label>
                        <LabelInput>{result.paymentMethod}</LabelInput>
                    </div>
                    <div>
                        <Label>{tRoot('date')}</Label>
                        <LabelInput>{result.StartDate}</LabelInput>
                    </div>
                    <div>
                        <Label>{tRoot('cardNumber')}</Label>
                        <LabelInput>{result.CCNumber}</LabelInput>
                    </div>
                    <div>
                        <Label>{tRoot('totalPrice')}</Label>
                        <LabelInput>${result.FinalPrice}</LabelInput>
                    </div>

                </TableContent>
            </Table>
            <Table>
                <Note> {tRoot('checkEmail')} ({user.email})</Note>
                
                
            </Table>
          </Content>
    </div>
  )
}


