import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import i18n from './i18n/i18n'
import getLang from './i18n/lang'
import ReactGA from 'react-ga'
import "./commons/index.scss";
import TagManager from 'react-gtm-module'

import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

var userID = localStorage.getItem("userID")

//GTM
const tagManagerArgs = {
  gtmId: process.env.GTM,
  dataLayer: {
    userId: userID,
    userProject: 'backend'
  }
}

TagManager.initialize(tagManagerArgs)
//



ReactGA.initialize([{
  trackingId: process.env.ANALYTICSTRACKID_BACK,
  gaOptions: {
    name: 'backTrackerV2',
    userId: userID
  }
}, {
  trackingId: process.env.ANALYTICSTRACKID_BACKv3,
  gaOptions: {
    name: 'backTrackerV3',
    userId: userID
  }
},
{
  trackingId: process.env.ANALYTICSTRACKID_BOX,
  gaOptions: {
    name: 'chatboxTracker'
  }
}], { debug: process.env.NODE_ENV === 'development' ? true : false, alwaysSendToDefaultTracker: false }

)


const APP_STATE_NAME = "appState";



let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = localStorage.getItem(APP_STATE_NAME)
  ? JSON.parse(localStorage.getItem(APP_STATE_NAME))
  : {};

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  localStorage.setItem(APP_STATE_NAME, JSON.stringify(store.getState()));
});

const queryClient = new QueryClient()

const Root = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

const lang = getLang()

import(
  `./i18n/${lang}`
).then(translations => {
  i18n.load(translations.default, lang)
  ReactDOM.render(Root, document.getElementById("root"));
})
