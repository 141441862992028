import React from 'react';
import styled from 'styled-components'
import "./suscription.scss";
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import i18n from '../../../i18n/i18n';
import lang from '../../../i18n/lang';
import Divider from '@material-ui/core/Divider';
import Feedback from '../../../components/feedback'
import TiaraHeader from '../../../components/TiaraHeader'


const tRoot = i18n()
const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
`

const HeaderContent = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap:20px;
    h2{
      text-align:left;
    }
`
const Project = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: #b9bec1;
    text-align:left;
    float: left;
    height:30px;
    width:100%

`

const SaveButton = styled.button`
border: 1px solid rgba(0,0,0,0.3);
background-color: #F99346;
border:1px solid #F99346;
padding: 10px 25px;
border-radius: 20px;
width: 120px;
display: inline-block;
margin: 20px 0;
transition: all 0.2s ease 0s;
text-align:center;
color:#fff;

&:hover{
  color:#F99346;
  border: 1px solid rgba(0,0,0,0.3);
  background:#fff
}

`
const Body = styled.div`
    width: 100%;
    background: #fff;
    padding-left: 0px;
    height: 100vh;
    overflow-y: scroll;
 
`

export const styles = theme => ({
  margin: {
    marginBottom: '20px',
    width: '80%',

  },

  cssLabel: {
    fontSize: '18px',
    marginBottom: '10px',

    '&$cssFocused': {
      color: '#333333',
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#ef7e29',

    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#ef7e29',
    },
  },
});


export const view = (classes, { name, description, analyticsTrackID, wakeupWord }, handler) => {
  return (
    
    
    <Body>
    <TiaraHeader /> 
    <div style={{'padding-left': '60px'}}>
      <HeaderContent>
        <div>
          <Project>{name}</Project>
          <h2>General</h2>

        </div>
      </HeaderContent>
      <Content>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {tRoot('name')}
          </InputLabel>
          <Input
            id="name"
            value={name}
            onChange={handler.name}
            classes={{ underline: classes.cssUnderline, }} />
        </FormControl>
        <FormControl className={classes.margin}>
          <TextField
            id="outlined-multiline-static"
            label={tRoot('description')}
            multiline
            value={description}
            onChange={handler.description}
            rows="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
        <SaveButton onClick={handler.save}>{tRoot('save')}</SaveButton>
      </Content>

      <Content>
        <Divider variant="middle" className={classes.margin} />
      </Content>

      <Content>
        <p className="header">
          {tRoot('trackIDText1')}
          <br /><br />
          {tRoot('trackIDText2')} <a href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/agregar-id-de-seguimiento-de-google-analytics' : 'https://supporten.blahbox.net/portal/kb/articles/add-google-analytics-tracking-id'} target="_blank" rel="noopener noreferrer" style={{ color: '#7E9BA4' }}>{tRoot('here')}</a>
        </p>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {tRoot('analyticsTrackID')}
          </InputLabel>
          <Input
            id="analyticsTrackID"
            value={analyticsTrackID}
            onChange={handler.analyticsTrackID}
            classes={{ underline: classes.cssUnderline, }} />
        </FormControl>
        <SaveButton onClick={handler.saveTackID}>{tRoot('save')}</SaveButton>
      </Content>

      <Content>
        <Divider variant="middle" className={classes.margin} />
      </Content>

      <Content>
        <p className="header">
          {tRoot('wakeupWordText1')}
          <br /><br />
          {tRoot('wakeupWordText2')} <a href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/palabra-de-inicio' : 'https://supporten.blahbox.net/portal/kb/articles/wake-up-word'} target="_blank" rel="noopener noreferrer" style={{ color: '#7E9BA4' }}>{tRoot('here')}</a>
        </p>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {tRoot('wakeupWordLabel')}
          </InputLabel>
          <Input
            id="wakeupWord"
            value={wakeupWord}
            onChange={handler.wakeupWord}
            classes={{ underline: classes.cssUnderline, }} />
        </FormControl>
        <SaveButton onClick={handler.saveWakeupWord}>{tRoot('save')}</SaveButton>
        <Feedback seccion="General-General"/>
      </Content>
      </div>
    </Body>

  )
}


