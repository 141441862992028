import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import styled from 'styled-components'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { CallOutsShadowNone } from './callOutsShadow/callOutsShadowNone';
import { CallOutsShadowBottomGray } from './callOutsShadow/callOutsShadowBottomGray';
import { CallOutsShadowRightGray } from './callOutsShadow/callOutsShadowRightGray';
import { CallOutsShadowBottomBlack } from './callOutsShadow/callOutsShadowBottomBlack';
import { CallOutsShadowRightBlack } from './callOutsShadow/callOutsShadowRightBlack';
import { CallOutsFormSquare } from './callOutsForm/callOutsFormSquare';
import { CallOutsFormSquareLeftCenter } from './callOutsForm/callOutsFormSquareLeftCenter';
import { CallOutsFormSquareLeftTop } from './callOutsForm/callOutsFormSquareLeftTop';
import { CallOutsFormSquareTop } from './callOutsForm/callOutsFormSquareTop';
import { CallOutsFormSquareTopCenter } from './callOutsForm/callOutsFormSquareTopCenter';
import { CallOutsFormRadius } from './callOutsForm/callOutsFormRadius';
import { CallOutsFormRadiusLeft } from './callOutsForm/callOutsFormRadiusLeft';
import { CallOutsFormRadiusTop } from './callOutsForm/callOutsFormRadiusTop';
import { CallOutsFormRadiusTopCenter } from './callOutsForm/callOutsFormRadiusTopCenter';
import { CallOutsFormCircle } from './callOutsForm/callOutsFormCircle';
import { CallOutsFormCircleTop } from './callOutsForm/callOutsFormCircleTop';
import { CallOutsFormCircleTopCenter } from './callOutsForm/callOutsFormCircleTopCenter';
import { CallOutsFormRadiusThree } from './callOutsForm/callOutsFormRadiusThree';
import { CallOutsFormRadiusTwo } from './callOutsForm/callOutsFormRadiusTwo';
import { SketchPicker } from 'react-color';
import i18n from '../../../../../../i18n/i18n'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Feedback from '../../../../../../components/feedback'


const tRoot = i18n()
const t = i18n('chatSettings')

const Label = styled.label`
    display:grid;
    width:100%;
    color:#545454;
    font-size:18px;
    margin-bottom:10px;
`
const Content = styled.div`
    display:grid;
    text-align:left;
`
const ContentCallOuts = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-row-gap:15px;
    text-align:left;
    margin-bottom:25px;
`
const ContentSwitch = styled.div`
display:grid;
grid-template-columns: 1fr 1fr  ;
grid-row-gap:15px;
text-align:left;
margin-bottom:25px;
`
const ContentForm = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    text-align:left;
    grid-gap:30px;
    margin-bottom:25px;
`

const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`

export const styles = theme => ({
  cssLabel: {
    fontSize: '18px',
    marginBottom: '10px',

    '&$cssFocused': {
      color: '#333333',
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#ef7e29',

    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#ef7e29',

    },
  },
  notchedOutline: {}

});

export const view = ({
  classes
}, {
  form,
  shadow,
  font,
  fontSize,
  system,
  visit,
  textBot,
  textUser,
  showTime,
  showName
},
  handleSystem,
  handleVisit,
  handleTextBot,
  handleTextUser,
  handleFont,
  handleFontSize,
  handleForm,
  handleShadow,
  handleShowTime,
  handleShowName,
  handleClosers
) => (
    <Content>
      <HeaderConfig title={t('callOutTittle')} />
      <Label>{tRoot('shape')}</Label>

      <ContentCallOuts>
        <CallOutsFormSquare func={handleForm} itemSelected={ form }/>
        <CallOutsFormSquareLeftCenter func={handleForm} itemSelected={ form }/>
        <CallOutsFormSquareLeftTop func={handleForm} itemSelected={ form }/>
        <CallOutsFormSquareTop func={handleForm} itemSelected={ form }/>
        <CallOutsFormSquareTopCenter func={handleForm} itemSelected={ form }/>
        <CallOutsFormRadius func={handleForm} itemSelected={ form }/>
        <CallOutsFormRadiusLeft func={handleForm} itemSelected={ form }/>
        <CallOutsFormRadiusTop  func={handleForm} itemSelected={ form }/>
        <CallOutsFormRadiusTopCenter func={handleForm} itemSelected={ form }/>
        <CallOutsFormCircle func={handleForm} itemSelected={ form }/>
        <CallOutsFormCircleTop func={handleForm} itemSelected={ form }/>
        <CallOutsFormCircleTopCenter func={handleForm} itemSelected={ form }/>
        <CallOutsFormRadiusThree func={handleForm} itemSelected={ form }/>
        <CallOutsFormRadiusTwo func={handleForm} itemSelected={ form }/>
      
      </ContentCallOuts>
      <ContentSwitch>
      <FormGroup row>
       <FormControlLabel
          control={
            <Switch
            checked={showTime}
              color="primary"
              onChange={handleShowTime}
              value="showTime"
            />
          }
          label={tRoot('showTime')}
        />
      </FormGroup>
      <FormGroup row>
       <FormControlLabel
          control={
            <Switch
             checked={showName}
              color="primary"
              onChange={handleShowName}
            />
          }
          label={tRoot('showName')}
        />
      </FormGroup>

      </ContentSwitch>
      

      <Label>{tRoot('shadow')}</Label>

      <ContentCallOuts>
        <CallOutsShadowNone func={handleShadow} itemSelected={ shadow} />
        <CallOutsShadowBottomGray func={handleShadow} itemSelected={ shadow}/>
        <CallOutsShadowRightGray func={handleShadow} itemSelected={ shadow}/>
        <CallOutsShadowBottomBlack func={handleShadow} itemSelected={ shadow}/>
        <CallOutsShadowRightBlack func={handleShadow} itemSelected={ shadow}/>
      </ContentCallOuts>

      <ContentForm>

      <ClickAwayListener onClickAway={handleClosers.closeColorSystem}>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {t('colorSystem')}
        </InputLabel>
          <Input
            id="colorSystem"
            value={system.color ? system.color : ''}
            
            classes={{ underline: classes.cssUnderline, }} />
          <Color className="colorSystem" style={{ background: system.color }} onClick={handleSystem.handleShow}/>
          {system.showChooseColor ? <SketchPicker
            onChangeComplete={handleSystem.pickColor}
            color={system.color} /> : ""}
        </FormControl>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={handleClosers.closeColorVisit}>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {t('colorVisit')}
        </InputLabel>
          <Input
            id="colorVisit"
            value={visit.color ? visit.color : ''}
           
            classes={{ underline: classes.cssUnderline, }} />
          <Color className="colorVisit" style={{ background: visit.color }} onClick={handleVisit.handleShow}/>
          {visit.showChooseColor ? <SketchPicker
            onChangeComplete={handleVisit.pickColor}
            color={visit.color} /> : ""}
        </FormControl>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={handleClosers.closeColorTextBot}>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {t('colorTextBot')}
         </InputLabel>
          <Input
            id="colorTextBot"
            value={textBot.color ? textBot.color : ''}
            classes={{ underline: classes.cssUnderline, }} />
          <Color className="colorTextBot" style={{ background: textBot.color }} onClick={handleTextBot.handleShow}/>
          {textBot.showChooseColor ? <SketchPicker
            onChange={handleTextBot.pickColor}
            color={textBot.color} /> : ""}
        </FormControl>
        </ClickAwayListener>

        <ClickAwayListener onClickAway={handleClosers.closeColorTextUser}>
        <FormControl className={classes.margin}>
          <InputLabel
            classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
            {t('colorTextUser')}
          </InputLabel>
          <Input
            id="colorTextUser"
            value={textUser.color ? textUser.color : ''}
            
            classes={{ underline: classes.cssUnderline, }} />
          <Color className="colorTextUser" style={{ background: textUser.color }} onClick={handleTextUser.handleShow}/>
          {textUser.showChooseColor ? <SketchPicker
            onChange={handleTextUser.pickColor}
            color={textUser.color} /> : ""}
        </FormControl>
        </ClickAwayListener>
        
        <FormControl className={classes.formControl}>
          <InputLabel > {tRoot('font')}</InputLabel>
          <Select
            value={font}
            onChange={handleFont}
            className={classes.cssUnderline}>
            <MenuItem value="Arial">Arial</MenuItem>
              <MenuItem value="Arial Black">Arial Black</MenuItem>
              <MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
              <MenuItem value="Courier New">Courier New</MenuItem>
              <MenuItem value="Impact">Impact</MenuItem>
              <MenuItem value="Lucida Console">Lucida Console</MenuItem>
              <MenuItem value="Lucida Sans Unicode">Lucida Sans Unicode</MenuItem>
              <MenuItem value="Tahoma">Tahoma</MenuItem>
              <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
              <MenuItem value="Verdana">Verdana</MenuItem>
          </Select>

        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel > {tRoot('size')}</InputLabel>
          <Select
            value={fontSize}
            onChange={handleFontSize}
            className={classes.cssUnderline}>
            <MenuItem value={"12px"}>12</MenuItem>
            <MenuItem value={"13px"} >13</MenuItem>
            <MenuItem value={"14px"} >14</MenuItem>
            <MenuItem value={"15px"} >15</MenuItem>
            <MenuItem value={"17px"} >17</MenuItem>
            <MenuItem value={"20px"} >20</MenuItem>
            <MenuItem value={"24px"} >24</MenuItem>
            <MenuItem value={"32px"} >32</MenuItem>
          </Select>

        </FormControl>
      </ContentForm>
      <Feedback seccion="CallOuts-Globes" previewMode/>
    </Content>
  )
