import React from "react";
import { connect } from "react-redux";
import { validateChatCredentials, slackNotify } from "../../../../../rootActions";
import { chatService } from "../../../../../services/chat.service";
import engineIconWatson from "../../../../../assets/img/engine-icon-watson.png";
import engineIconDialogflow from "../../../../../assets/img/engine-icon-dialogflow.png";
import engineIconDialogflowCX from "../../../../../assets/img/engine-icon-dialogflowcx.png";
import engineIconAmazonLex from "../../../../../assets/img/engine-icon-amazonLex.png";
import engineIconCortana from "../../../../../assets/img/engine-icon-cortana.png";
import i18n from '../../../../../i18n/i18n'
import lang from '../../../../../i18n/lang'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ReactGA from 'react-ga'


const tRoot = i18n()

class AmazonLex extends React.Component {

  state = {
    errorMessage: '',
    saveOKMsg: '',
    access: '',
    secret: '',
    botName: '',
    botAlias: '',
    region: '',
    apiVersion: ''
  }

  handler = {
    access: ev => this.setState({ access: ev.target.value }),
    secret: ev => this.setState({ secret: ev.target.value }),
    botName: ev => this.setState({ botName: ev.target.value }),
    botAlias: ev => this.setState({ botAlias: ev.target.value }),
    region: ev => this.setState({ region: ev.target.value }),
    apiVersion: ev => this.setState({ apiVersion: ev.target.value })
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/chat/nlp/lex", ['backTrackerV2', 'backTrackerV3'])
    this.props.closeLoader();

    if (this.props.nlp && this.props.nlp.provider === 'aws') {
      this.setState({
        access: this.props.nlp.access_key_id,
        secret: this.props.nlp.secret_access_key,
        botName: this.props.nlp.bot_name,
        botAlias: this.props.nlp.bot_alias,
        region: this.props.nlp.region,
        apiVersion: this.props.nlp.apiVersion
      })
    }

  }


  handleCloseMsg = () => {
    this.setState({ errorMessage: '', saveOKMsg: '' })
  }

  errorMsg = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.errorMessage !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.errorMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  saveOK = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.saveOKMsg !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.saveOKMsg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }



  handleSubmit = e => {
    e.preventDefault();
    this.props.openLoader();

    const jsonStructure = { NLP: {} };

    jsonStructure.NLP.provider = "aws";
    jsonStructure.NLP.access_key_id = this.state.access
    jsonStructure.NLP.secret_access_key = this.state.secret
    jsonStructure.NLP.bot_name = this.state.botName
    jsonStructure.NLP.bot_alias = this.state.botAlias
    jsonStructure.NLP.region = this.state.region
    jsonStructure.NLP.apiVersion = this.state.apiVersion

    this.props.openLoader();
    this.verifyCredentials(jsonStructure)
      .then(result => {
        ReactGA.event({
          category: 'Chat',
          action: 'Update NLP Lex'
        }, ['backTrackerV2', 'backTrackerV3'])
        this.updateChat(this.props.chatID, jsonStructure);
        this.setState({ saveOKMsg: tRoot('credentialSaveOKMsg') })
      }).catch(err => {

        this.props.closeLoader();
        ReactGA.event({
          category: 'Chat',
          action: 'UPDATE Lex Bad Credentials'
        }, ['backTrackerV2', 'backTrackerV3'])

        //slack notify
        jsonStructure.chatID = this.props.chatID
        slackNotify(JSON.stringify(jsonStructure))

        this.setState({ errorMessage: tRoot('badCredentialErrorMsg') })
      })
  };

  verifyCredentials = (credentials) => {
    return validateChatCredentials(credentials)
  }

  updateChat = (chatID, jsonStructure) => {
    chatService
      .updateChatConfig(chatID, jsonStructure)
      .then(data => {
        this.props.closeLoader();
      })
      .catch(err => {
        this.props.closeLoader();
      });
  };

  render() {
    return (
      <div className="engine-select">
        <div className="grid-container">
          <div className="inputs">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <TextField
                  label={tRoot('AccessLex')}
                  value={this.state.access}
                  onChange={this.handler.access}
                  margin="normal"
                  className="textfield"
                />

                <TextField
                  label={tRoot('secretAccessKeyLex')}
                  value={this.state.secret}
                  onChange={this.handler.secret}
                  margin="normal"
                  className="textfield"
                />

                <TextField
                  label={tRoot('botNameLex')}
                  value={this.state.botName}
                  onChange={this.handler.botName}
                  margin="normal"
                  className="textfield"
                />
                <TextField
                  label={tRoot('botAliasLex')}
                  value={this.state.botAlias}
                  onChange={this.handler.botAlias}
                  margin="normal"
                  className="textfield"
                />
                <div className="form-group more-info">
                  <Select
                    value={this.state.region}
                    onChange={this.handler.region}
                    inputProps={{
                      name: 'DFRegion',
                      id: 'DFRegion',
                    }}
                  >
                    <MenuItem value={'us-east-2'}>EE.UU. Este (Ohio)</MenuItem>
                    <MenuItem value={'us-east-1'}> EE.UU. Este (Norte de Virginia)</MenuItem>
                    <MenuItem value={'us-west-1'}>EE.UU. Oeste (Norte de California)</MenuItem>
                    <MenuItem value={'us-west-2'}>EE.UU. Oeste (Oregón)</MenuItem>
                    <MenuItem value={'ap-east-1'}>Asia Pacífico (Hong Kong)</MenuItem>
                    <MenuItem value={'ap-south-1'}> Asia Pacífico (Mumbai)</MenuItem>
                    <MenuItem value={'ap-northeast-2'}>Asia Pacífico (Seúl)</MenuItem>
                    <MenuItem value={'ap-southeast-1'}>Asia Pacífico (Singapur)</MenuItem>
                    <MenuItem value={'ap-southeast-2'}> Asia Pacífico (Sídney)</MenuItem>
                    <MenuItem value={'ap-northeast-1'}>Asia Pacífico (Tokio)</MenuItem>
                    <MenuItem value={'ca-central-1'}> Canadá (Central)</MenuItem>
                    <MenuItem value={'cn-north-1'}>China (Beijing)</MenuItem>
                    <MenuItem value={'cn-northwest-1'}>China (Ningxia)</MenuItem>
                    <MenuItem value={'eu-central-1'}> UE (Fráncfort)</MenuItem>
                    <MenuItem value={'eu-west-1'}>UE (Irlanda)</MenuItem>
                    <MenuItem value={'eu-west-2'}>UE (Londres)</MenuItem>
                    <MenuItem value={'eu-west-3'}>UE (París)</MenuItem>
                    <MenuItem value={'eu-north-1'}>UE (Estocolmo)</MenuItem>
                    <MenuItem value={'sa-east-1'}>América del Sur (São Paulo)</MenuItem>
                    <MenuItem value={'us-gov-east-1'}>AWS GovCloud (US-East)</MenuItem>
                    <MenuItem value={'us-gov-west-1'}>AWS GovCloud (EE.UU.)</MenuItem>
                  </Select>
                </div>
                <div className="form-group more-info">
                  <Select
                    value={this.state.apiVersion}
                    onChange={this.handler.apiVersion}
                    inputProps={{
                      name: 'DFVersion',
                      id: 'DFVersion',
                    }}
                  >
                    <MenuItem value={'latest'}>latest</MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                  </Select>
                </div>


                <div className="form-group more-info">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/integrar-amazon-lex' : 'https://supporten.blahbox.net/portal/kb/articles/integrate-amazon-lex'}
                  >
                    {tRoot('needMoreInformation')}
                  </a>
                </div>
                <div className="form-group">
                  <button className="button submit" type="submit">
                    {tRoot('go')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="selected-engine">
            <div className="shadow-border-box">
              <img src={engineIconAmazonLex} alt="amazon lex" />
              <p>AMAZON LEX</p>
            </div>
          </div>
          <div className="change">
            <h3 className="title">{tRoot('selectNLPEngine')}</h3>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("google")}
            >
              <img src={engineIconDialogflow} alt="dialogflow" />
              <p>DIALOGFLOW</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("dialogFlowCx")}
            >
              <img src={engineIconDialogflowCX} alt="dialogflow cx" />
              <p>DIALOGFLOW CX</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("watson")}
            >
              <img src={engineIconWatson} alt="watson assistant" />
              <p>WATSON ASSISTANT</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("microsoft")}
            >
              <img src={engineIconCortana} alt="Cortana" />
              <p>QnA Maker</p>
            </div>
          </div>
        </div>
        {this.errorMsg()}
        {this.saveOK()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state.baseData.currentChatConfig.NLP)
  return {
    chatID: state.baseData.currentChatConfig._id,
    nlp: state.baseData.currentChatConfig.NLP
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader: () => dispatch({ type: 'OPEN_LOADER' }),
    closeLoader: () => dispatch({ type: 'CLOSE_LOADER' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazonLex);




