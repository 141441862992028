
import {AUTHORIZED,UNAUTHORIZED} from './reducers'
import axios from 'axios' 

export const authorize = data => {
    return {
        type: AUTHORIZED,
        data 
    }
}

export const unAuthorize = errorMessage => {
    return {
        type: UNAUTHORIZED,
        data: {
            errorMessage
        }
    }
}

const BACKEND_API_URL = process.env.API_URL
const BACKEND_API_KEY = process.env.API_KEY

export const register = accessToken => {
    var url = BACKEND_API_URL + '/user/auth0Register/'
    return axios.post(url + accessToken)
}

export const login = accessToken => {
    var url = BACKEND_API_URL + '/user/auth0Login/'
    return axios.post(url + accessToken, {headers: {'x-core-client-id': BACKEND_API_KEY}})
}