import { userService } from '../services';

export const userActions = {
    register,
    login
};

function register(user) {

  const dateObj = new Date();
  const timezone = dateObj.getTimezoneOffset();

  const formData = {
    "location": {
      "coordinates": {
        "latitud": user.latitude,
        "longitude": user.longitude
      },
      "timezone": {
        "offset": timezone
      }
    },
    "email": user.email,
    "login": {
      "password": user.password
    }
  }

  userService.register(formData);
}

function login(user) {
  userService.login(user);
}
