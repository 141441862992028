import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import {updateHeaderBackground} from "../../../actionCreators.js"
import compose from 'recompose/compose'

class HeaderBackground extends React.Component {
	state = {
		selectedCallOut: '',
		background: '',
		showColorPicker : false
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => this.setState({background:this.props.background})
	handleChooseColor = (color,event) =>{
		this.setState({background:color.hex}, () =>{
			window.chatbot.updateChatTopStyles({
				backgroundColor: color.hex,
				buttonHoverColor: color.hex
			   })
			   this.props.save(color.hex,this.props.chatId)
		}) 
		
	}
	
	handleCloseColorPicker = () => this.setState({showColorPicker: false})
	handleShowColorPicker = () => this.setState({showColorPicker:!this.state.showColorPicker})
	
	render = () => view(
		this.props.classes,
		this.state.background ||this.props.background,
		this.handleChooseColor,
		this.state.showColorPicker,
		this.handleShowColorPicker,
		this.handleCloseColorPicker
		)
}


const mapStateToProps = state => {
	return {
		background: state.chatSettings.HeaderBackgroundResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (value,chatId) => updateHeaderBackground(value,chatId).then(result => dispatch(result)) 
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderBackground))
export default compose(
	withStyles(styles, { name: 'HeaderBackground' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(HeaderBackground)
  