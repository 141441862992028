import React from 'react'
import styled from 'styled-components'
import {selectedCallOut,unSelectedCallOut} from './styles'

const Button = styled.button`
    border:0px;
    background: transparent !important;
    cursor:pointer;
    width:75px;
`


export  const  CallOutsDoubleBoxes = ({active, name, func, palette}) => {
    
    return(
        <Button style={active ? selectedCallOut : unSelectedCallOut }
            onClick={e => func(name)}>  
            <svg id="iconFloat1" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58" 
                     space="preserve" >
				<path className="svg1" fill={palette.color1.color} d="M44,18.394v21.141c0,2.722-2.207,4.929-4.929,4.929L22,44.535l-10,11v-11H4.929
					C2.207,44.535,0,42.328,0,39.606l0-21.141c0-2.722,2.207-4.929,4.929-4.929l34.141-0.071C41.793,13.465,44,15.672,44,18.394z"></path>
				<path className="svg2" fill={palette.color2.color} d="M53.071,2.535l-34.141-0.07C16.207,2.465,14,4.672,14,7.394v6.122l25.071-0.052
					c2.722,0,4.929,2.207,4.929,4.93v18.441l7,7.7v-11h2.071c2.722,0,4.929-2.207,4.929-4.929V7.465
					C58,4.742,55.793,2.535,53.071,2.535z"></path>
				<circle className="svg3" fill={palette.color3.color} cx="11" cy="29.45" r="3"></circle>
				<circle className="svg3" fill={palette.color3.color}  cx="22" cy="29.45" r="3"></circle>
				<circle className="svg3" fill={palette.color3.color}  cx="33" cy="29.45" r="3"></circle>
			</svg>
         </Button>   
    )
}