import { combineReducers } from "redux";
import {
  RESET_STORE,
  OPEN_LOADER,
  CLOSE_LOADER,
  SELECT_SETTING,
  SET_CURRENT_CHAT_ID,
  SET_CURRENT_CHAT,
} from "./rootConstants";

import {chatConfigReducer} from "./containers/Dashboard/ChatSettings/reducers"
import {loginReducer} from './auth/reducers'

const initialState = {
  breakPoint: "",
  openLoader: "",
  settingSelected: "",
  currentChatId: "",
};

const baseReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case OPEN_LOADER:
      return { ...state, openLoader: true };
    case CLOSE_LOADER:
      return { ...state, openLoader: false };
    case SELECT_SETTING:
      return { ...state, settingSelected: action.data };
    case SET_CURRENT_CHAT_ID:
      return { ...state, currentChatId: action.chatId };
    case SET_CURRENT_CHAT:
      return { ...state, currentChatConfig: action.chat };
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
};



const appReducer = combineReducers({
  baseData: baseReducer,
  chatSettings: chatConfigReducer,
  auth: loginReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
