import React from 'react'
import ReactGA from 'react-ga'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import i18n from '../../i18n/i18n'

const tRoot = i18n()

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
    },
    buttonBlue: {
        margin: 0,
        'margin-left': 20,
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    },
    MuiAlert_message: {
        'padding-top': '2px',
        'padding-bottom': '2px'
    }

}));


export default function PlanAlert(props) {
    const classes = useStyles();

    const history = useHistory()
    const handleUpgrade = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging Alert Upgrade'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push('/dashboard/chat-settings/subscription')   
    }

    return (
        <div className={classes.root}>
            {props.type === "alert" ?
                <Alert variant="filled" onClick={handleUpgrade} severity="error" classes={{message: classes.MuiAlert_message }}>
                    {tRoot('alert75Text')}
                    <Button variant="contained" color="primary" className={classes.buttonBlue}>
                        {tRoot('upgrade')}
                    </Button>
                </Alert>
                : ''}
            {props.type === "warning" ?
                <Alert variant="filled" onClick={handleUpgrade} severity="warning" classes={{message: classes.MuiAlert_message }} >
                    {tRoot('alert50Text')}
                    <Button variant="contained" color="primary" className={classes.buttonBlue}>
                        {tRoot('upgrade')}
                    </Button>
                </Alert>
                : ''}
        </div>
    );
}