import React from 'react'
import styled from 'styled-components'
import { styleSelected , unSelected} from '../styles'

const ButtonForm = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
&:after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    bottom: auto;
    left: -15px;
    right: auto;
    top: -4px;
    border: 7px solid;
    border-color: #8a95ad #8a95ad transparent transparent;
}
`


export const CallOutsFormSquareLeftTop = ({ func, itemSelected }) => {
    return (
        <ButtonForm onClick={e => {
            window.chatbot.updateUserGlobeStyles({
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                peakPosition: "right-top"
            })

            window.chatbot.updateBotGlobeStyles({
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                peakPosition: "left-top"

            })
            func('CallOutsFormSquareLeftTop')
        }} 
        style={itemSelected === 'CallOutsFormSquareLeftTop' ? styleSelected : unSelected}>
        </ButtonForm>
    )
}