import React from "react";
import "./Dashboard.scss";
import Navigation from "../../components/Navigation/Navigation.js";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import Projects from "./Projects/Projects";
import NewChat from "./NewChat/NewChat";
import ChatSettings from "./ChatSettings/ChatSettings";
import Help from "./Help/Help";
import Profile from "./Profile/Profile";
import {connect} from 'react-redux';
import Welcome from './Welcome/Welcome'


class Dashboard extends React.Component {

  
  render = () => {
    const baseUrl = this.props.match.url;
    this.props.closeLoader()
    return (
      <div className="dashboard">
        <Navigation />
        <Switch>
          <Route path={baseUrl + "/projects/:chatIDDirect?"} component={Projects} />
          <Route path={baseUrl + "/new-chat"} component={NewChat} />
          <Route path={baseUrl + "/chat-settings"} component={ChatSettings} />
          <Route path={baseUrl + "/help"} component={Help} />
          <Route path={baseUrl + "/profile"} component={Profile} />
          <Route path={baseUrl + "/welcome"} exact component={Welcome}/>
        </Switch>
      </div>
    );
  };
}

const mapSteateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeLoader: () => dispatch({type:'CLOSE_LOADER'})
  }
}

export default connect(mapSteateToProps,mapDispatchToProps)(withRouter(Dashboard));
