import React from 'react'
import styled from 'styled-components'
import {selectedCallOut,unSelectedCallOut} from '../styles'

const Button = styled.button`
    border:0px;
    background: transparent !important;
    cursor:pointer;
    width:75px;
`
export  const DoubleCircleCallOuts = ({active, name, func,palette}) => {
    return(
        <Button style={active ? selectedCallOut : unSelectedCallOut }
			onClick={e => func(name)}>  
                        <svg id="iconFloat5" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58" space="preserve" >
							<g>
								<path className="svg1" fill={palette.color1.color} d="M23.905,11C11.15,11,0.81,21.34,0.81,34.095c0,4.304,1.181,8.331,3.231,11.78L0,58l13.16-3.463
									c0.004,0.002,0.007,0.003,0.011,0.005c0.004-0.001,0.008-0.004,0.012-0.005l6.289-1.399c0.541-0.115,1.074,0.221,1.193,0.76
									c0.12,0.539-0.22,1.073-0.759,1.193L16.3,55.893c2.383,0.833,4.938,1.296,7.605,1.296C36.66,57.19,47,46.85,47,34.095
									C47,21.34,36.66,11,23.905,11z"></path>
								<path className="svg2" fill={palette.color2.color} d="M23.905,11C36.66,11,47,21.34,47,34.095c0,3.378-0.731,6.583-2.034,9.475L58,47l-4.042-12.125
									c2.05-3.45,3.231-7.476,3.231-11.78C57.19,10.34,46.85,0,34.095,0c-9.426,0-17.528,5.65-21.118,13.746
									C16.231,11.995,19.951,11,23.905,11z"></path>
								<path className="svg3" fill={palette.color3.color} d="M30,28H19c-0.552,0-1-0.447-1-1s0.448-1,1-1h11c0.552,0,1,0.447,1,1S30.552,28,30,28z"></path>
								<path className="svg3" fill={palette.color3.color} d="M35,35H14c-0.552,0-1-0.447-1-1s0.448-1,1-1h21c0.552,0,1,0.447,1,1S35.552,35,35,35z"></path>
								<path className="svg3" fill={palette.color3.color} d="M35,42H14c-0.552,0-1-0.447-1-1s0.448-1,1-1h21c0.552,0,1,0.447,1,1S35.552,42,35,42z"></path>
							</g>
							</svg>
                    </Button>
    )
}