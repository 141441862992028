import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateDialogBoxCallOuts } from '../../../actionCreators.js';
import compose from 'recompose/compose';


class DialogBoxCallOuts extends React.Component {
	state = {
		form: '',
		shadow: '',
		font: '',
		fontSize: '',
		system: {
			color: '',
			showChooseColor: false
		},
		visit: {
			color: '',
			showChooseColor: false
		},
		textBot: {
			color: '',
			showChooseColor: false
		},
		textUser: {
			color: '',
			showChooseColor: false
		},
		showTime: false,
		showName: false
	}

	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => this.setState({ ...this.props.data })

	handleShowTime = (e) => {
		this.setState({ showTime: e.target.checked }, () => {
			window.chatbot.updateBotGlobeStyles({ "hideTime": !this.state.showTime })
			window.chatbot.updateUserGlobeStyles({ "hideTime": !this.state.showTime })
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleShowName = (e) => {
		this.setState({ showName: e.target.checked }, () => {
			window.chatbot.updateBotGlobeStyles({ "hideName": !this.state.showName })
			window.chatbot.updateUserGlobeStyles({ "hideName": !this.state.showName })
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleFont = (event) => this.setState({ font: event.target.value }, () => {
		window.chatbot.updateMessagesSectionStyles({ "fontFamily": this.state.font })
		//window.chatbot.updateUserGlobeStyles({ "fontFamily": this.state.font })
		this.props.save(this.state, this.props.chatId)
	})
	handleFontSize = (event) => this.setState({ fontSize: event.target.value }, () => {
		window.chatbot.updateBotGlobeStyles({ "fontSize": this.state.fontSize })
		window.chatbot.updateUserGlobeStyles({ "fontSize": this.state.fontSize })
		this.props.save(this.state, this.props.chatId)
	})

/*  	hideAllColorPicker = (e) => {
		 console.log("CCC: ", e)
		const new_state = Object.assign({}, this.state)
		new_state.system.showChooseColor = e === 'colorSystem' ? this.state.system.showChooseColor : false
		new_state.visit.showChooseColor = e === 'colorSystem' ? this.state.visit.showChooseColor : false
		new_state.textBot.showChooseColor = e === 'colorTextBot' ? this.state.textBot.showChooseColor : false
		new_state.textUser.showChooseColor = e === 'colorTextUser' ? this.state.textUser.showChooseColor : false
		this.setState({ ...new_state })
	} */

	handleClosers = {
		closeColorSystem: () => {this.setState({ system: { ...this.state.system, showChooseColor: false } })},
		closeColorVisit: () => {this.setState({ visit: { ...this.state.visit, showChooseColor: false } })},
		closeColorTextBot: () => {this.setState({ textBot: { ...this.state.textBot, showChooseColor: false } })},
		closeColorTextUser: () => {this.setState({ textUser: { ...this.state.textUser, showChooseColor: false } })}
	}


	handleSystem = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ system: { ...this.state.system, showChooseColor: !this.state.system.showChooseColor } })
		},
		pickColor: (color) => this.setState({
			system: {
				color: color.hex, showChooseColor: true
			}
		}, () => {
			window.chatbot.updateBotGlobeStyles({ "backgroundColor": color.hex })
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleVisit = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ visit: { ...this.state.visit, showChooseColor: !this.state.visit.showChooseColor } })
		},
		pickColor: (color) => this.setState({ visit: { color: color.hex, showChooseColor: true } }, () => {
			window.chatbot.updateUserGlobeStyles({ "backgroundColor": color.hex })
			this.props.save(this.state, this.props.chatId)
		})
	}


	handleTextBot = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ textBot: { ...this.state.textBot, showChooseColor: !this.state.textBot.showChooseColor } })
		},
		pickColor: (color) => this.setState({ textBot: { color: color.hex, showChooseColor: true } }, () => {
			window.chatbot.updateBotGlobeStyles({ "color": color.hex })
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleTextUser = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ textUser: { ...this.state.textUser, showChooseColor: !this.state.textUser.showChooseColor } })
		},
		pickColor: (color) => this.setState({ textUser: { color: color.hex, showChooseColor: true } }, () => {
			window.chatbot.updateUserGlobeStyles({ "color": color.hex })
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleForm = form => {
		this.setState({ form }, () => {
			this.props.save(this.state, this.props.chatId)
		})
	}


	handleShadow = shadow => this.setState({ shadow }, () => {
		this.props.save(this.state, this.props.chatId)
	})

	render = () => view(
		this.props,
		this.state,
		this.handleSystem,
		this.handleVisit,
		this.handleTextBot,
		this.handleTextUser,
		this.handleFont,
		this.handleFontSize,
		this.handleForm,
		this.handleShadow,
		this.handleShowTime,
		this.handleShowName,
		this.handleClosers)
}


const mapStateToProps = state => {
	return {
		data: state.chatSettings.DialogBoxCallOutsResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (data, chatId) => updateDialogBoxCallOuts(data, chatId).then(result => dispatch(result)).catch(err => console.log(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DialogBoxCallOuts))
export default compose(
	withStyles(styles, { name: 'DialogBoxCallOuts' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(DialogBoxCallOuts)
