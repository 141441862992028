import React from "react";
import "./Integration.scss";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import i18n from '../../../../i18n/i18n'
import lang from '../../../../i18n/lang'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import IntegrationCode from "../../../../assets/img/code.svg";
import IntegrationWordpress from "../../../../assets/img/wordpress-logo.svg";
import ReactGA from 'react-ga'
import Feedback from '../../../../components/feedback'
import TiaraHeader from '../../../../components/TiaraHeader'

const tRoot = i18n();

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);


class Integration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      content: '',
      contentWP: '',
      open: false
    };
  }

  handleClick = () => {
    this.setState({ open: true });
  };


  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/dashboard/chat-settings/integration", ['backTrackerV2','backTrackerV3'])

    const chatID = this.props.location.state.chatID;
    var chatboxURL = process.env.CHATBOX_URL;

    const script =
    `&lt;script&gt;
            (function(b,c,d,ex){var e=document.createElement('link');e.rel='stylesheet',e.type='text/css',e.href='` + chatboxURL + `/static/css/main.css', document.getElementsByTagName('head')[0].appendChild(e);
            var f=document.createElement('script');f.onload=function(){var g;if(c)g='previewInit';else{var h=document.createElement('div');g='cbinit',h.id='cbinit',document.body.append(h)} 
            console.log(document.querySelector('#'+g)),chatbox.initChat(document.querySelector('#'+g),b,c,d,ex)},f.src='` + chatboxURL + `/static/js/chat-lib.js',document.getElementsByTagName('head')[0].appendChild(f)})
            ('` +
      chatID +
      `', 0, {}, {hideHeader:false,startOpened:false});
          &lt;/script&gt;`;
   
    const WPContent = 'Chat ID: ' + chatID 
    
    this.setState({ content: script, contentWP: WPContent })
  }

  copy = () => {
    var aux = document.createElement("input")
    var c =  this.state.content
    c = c.replace("&lt;script&gt;", "<script>")
    c = c.replace("&lt;/script&gt;", "</script>")
    aux.setAttribute("value", c)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand("copy")
    document.body.removeChild(aux)

    this.handleClick()

  }

  copyChatID = () => {
    var aux = document.createElement("input")
    aux.setAttribute("value", this.props.location.state.chatID)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand("copy")
    document.body.removeChild(aux)

    this.handleClick()

  }

  

  render() {
    return (
      <div className="bodyCentral" style={{'width': '100%'}}>
      <TiaraHeader />
      <section className="integration-page">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={tRoot('codeCopied')}
          />
        </Snackbar>

        <div className="integration-container">
          <header>
            <h2>{tRoot('install')}</h2>
            <p>
              {tRoot('installInstructions')}
            </p>
          </header>
          <div className="accordion-container" >
           
            <ExpansionPanel className="accordion">
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <img src={IntegrationCode} alt="Web Integration" />
                <Typography className="title" >{tRoot('installWebsite')}</Typography>

              </ExpansionPanelSummary>
              <ExpansionPanelDetails >
                <div className="panel-int">
                  <p className="header">
                    {tRoot('installWebsiteInstruccions')}
                  </p>
                  <div className="text-canvas" style={{ with: "800px" }}>
                    <div className="widgetContent" dangerouslySetInnerHTML={{ __html: this.state.content }} style={{ width: "100%", overflowX: "initial" }}>

                    </div>
                    <button onClick={this.copy}>{tRoot('copyCode')}</button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className="accordion">
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <img src={IntegrationWordpress} alt="Wordpress Plugin" />
                <Typography className="title" >{tRoot('installWordpressPlugin')}</Typography>

              </ExpansionPanelSummary>
              <ExpansionPanelDetails >
                <div className="panel-int">
                  <p className="header">
                    {tRoot('installWordpressPluginInstruccions2')}
                    <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/wordpress-plugin-es' : 'https://supporten.blahbox.net/portal/kb/articles/wordpress-plugin-en'}
                  >
                    {tRoot('here')}
                  </a>
                  </p>
                  <br></br>
                  <p className="header">
                  {tRoot('installWordpressPluginInstruccions')} 
                  </p>
                  <div className="text-canvas" style={{ with: "800px" }}>
                    <div className="widgetContent" dangerouslySetInnerHTML={{ __html: this.state.contentWP }} style={{ width: "100%", overflowX: "initial" }}>

                    </div>
                    <button onClick={this.copyChatID}>{tRoot('copyChatID')}</button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>



          </div>
        </div>
        <Feedback seccion="Instalation"/>
      </section>
      </div>
    );
  }
}

export default Integration;
