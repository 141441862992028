import React from 'react'
import styled from 'styled-components'
import { styleSelected , unSelected} from '../styles'

const ButtonForm = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
border-radius:0px 30px 30px 0px;

`


export  const  CallOutsFormRadiusTwo = ({func, itemSelected}) => {
    return(
      <ButtonForm  onClick={e => { 
        window.chatbot.updateUserGlobeStyles({
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "0px",    
          peakPosition: "remove-peak"        
        })
  
        window.chatbot.updateBotGlobeStyles({
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "30px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "30px",
          peakPosition: "remove-peak"    
        })
        func('CallOutsFormRadiusTwo')} 
        }
        style={itemSelected === 'CallOutsFormRadiusTwo' ? styleSelected : unSelected}>  
      </ButtonForm> 
    )
}