import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateFloatingIconForm } from '../../../actionCreators.js';
import compose from 'recompose/compose';

class FloatingIconForm extends React.Component {
	state = {
		form: '',
		visible: '',
		margin: '',
		marginLeft: '',
		marginRight: '',
		shadow: '',
		enable: 'disabled',
		formParameters: {}
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	handlers = {
		handleForm: form => {
			let formParameters = {
				"borderTopRightRadius": "0px",
				"borderTopLeftRadius": "0px",
				"borderBottomRightRadius": "0px",
				"borderBottomLeftRadius": "0px"
			}

			switch (form) {
				case "RECTANGLE":
					window.chatbot.updateChatIconStyles({
						"borderTopRightRadius": "0px",
						"borderTopLeftRadius": "0px",
						"borderBottomRightRadius": "0px",
						"borderBottomLeftRadius": "0px"
					})
					formParameters = {
						"borderTopRightRadius": "0px",
						"borderTopLeftRadius": "0px",
						"borderBottomRightRadius": "0px",
						"borderBottomLeftRadius": "0px"
					}
					break
				case "RADIUS":
					window.chatbot.updateChatIconStyles({
						"borderTopRightRadius": "10px",
						"borderTopLeftRadius": "10px",
						"borderBottomRightRadius": "10px",
						"borderBottomLeftRadius": "10px"
					})
					formParameters = {
						"borderTopRightRadius": "10px",
						"borderTopLeftRadius": "10px",
						"borderBottomRightRadius": "10px",
						"borderBottomLeftRadius": "10px"
					}
					break
				case "CIRCLE":
					window.chatbot.updateChatIconStyles({
						"borderTopRightRadius": "50px",
						"borderTopLeftRadius": "50px",
						"borderBottomRightRadius": "50px",
						"borderBottomLeftRadius": "50px"
					})
					formParameters = {
						"borderTopRightRadius": "50px",
						"borderTopLeftRadius": "50px",
						"borderBottomRightRadius": "50px",
						"borderBottomLeftRadius": "50px"
					}
					break
				case "RECTANGLELEFT":
					window.chatbot.updateChatIconStyles({
						"borderTopRightRadius": "0px",
						"borderTopLeftRadius": "10px",
						"borderBottomRightRadius": "0px",
						"borderBottomLeftRadius": "10px"
					})
					break
				case "CIRCLELEFT":
					window.chatbot.updateChatIconStyles({
						"borderTopRightRadius": "0px",
						"borderTopLeftRadius": "50px",
						"borderBottomRightRadius": "0px",
						"borderBottomLeftRadius": "50px"
					})
					formParameters = {
						"borderTopRightRadius": "0px",
						"borderTopLeftRadius": "50px",
						"borderBottomRightRadius": "0px",
						"borderBottomLeftRadius": "50px"
					}
					break
				default:
					break
			}

			this.setState({ form, formParameters }, () => this.props.save(this.props.chatId, this.state))

		},
		handleVisible: event => {
			if (event.target.value === 'YES')
				window.chatbot.updateChatIconStyles({ "alwaysVisible": "alwaysVisible" })
			else
				window.chatbot.updateChatIconStyles({ "alwaysVisible": "notVisible" })

			this.setState({ visible: event.target.value }, () => this.props.save(this.props.chatId, this.state))
		},
		handleMargin: event => {
			if (event.target.value === 'YES') {
				window.chatbot.updateChatIconStyles({ "marginRight": "20px", "marginLeft": "20px", "alwaysVisible": "alwaysVisible" })
				this.setState({ margin: event.target.value, visible: 'YES', marginRight: '20px', marginLeft: '20px' }, () => this.props.save(this.props.chatId, this.state))
			}
			else {
				window.chatbot.updateChatIconStyles({ "marginRight": "0px", "marginLeft": "0px" })
				this.setState({ margin: event.target.value, marginRight: '0px', marginLeft: '0px' }, () => this.props.save(this.props.chatId, this.state))
			}
		},
		handleShadow: shadow => this.setState({ shadow }, () => this.props.save(this.props.chatId, this.state))
	}

	UNSAFE_componentWillMount = () => this.setState({ ...this.props.data })
	render = () => view(
		this.props,
		this.state,
		this.handlers
	)
}


const mapStateToProps = state => {

	return {
		chatId: state.baseData.currentChatConfig._id,
		data: state.chatSettings.FloatingIconFormResult.value
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (chatId, data) => updateFloatingIconForm(chatId, data).then(result => dispatch(result)).catch(err => console.log(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FloatingIconForm))
export default compose(
	withStyles(styles, { name: 'FloatingIconForm' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(FloatingIconForm)
