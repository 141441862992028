import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';
import { authorize, unAuthorize, register, login } from '../actionCreators'
import ReactGA from 'react-ga'

class ValidateLogin extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props)
    }

    componentDidMount = () => {
        let { access_token, error, error_description } = queryString.parse(this.props.location.hash)

        if (error) {
            this.props.reject(error_description)
            this.props.history.push("/unauthorized")
            return
        }

       
        login(access_token)
            .then(result => {
               
                this.props.accept({
                    ...result.data.user,
                    token: result.data.user.token
                })
                localStorage.setItem("token", result.data.user.token)
                localStorage.setItem("userID", result.data.user._id)
                
                ReactGA.event({
                    category: 'User',
                    action: 'New Login'
                  }, ['backTrackerV2', 'backTrackerV3'])
                this.props.history.push("/dashboard/projects")
            })
            .catch(err => {  
                register(access_token).then(result => {
                    this.props.accept({
                        ...result.data.user,
                        token: result.data.user.token
                    })
                    localStorage.setItem("token", result.data.user.token)
                    localStorage.setItem("userID", result.data.user._id)
                    
                    ReactGA.set({ userId: result.data.user._id }, ['backTrackerV2', 'backTrackerV3']);

                    ReactGA.event({
                        category: 'User',
                        action: 'New Register'
                      }, ['backTrackerV2', 'backTrackerV3'])
                    this.props.history.push("/dashboard/welcome")
                }).catch(err = console.log(err))
            })


    }

    render() {
        return (
            <div>Login</div>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        reject: errorMessage => {
            dispatch(unAuthorize(errorMessage))
        },
        accept: data => {
            dispatch(authorize(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidateLogin)

