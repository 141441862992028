import React from "react";
import Joyride from 'react-joyride'

import i18n from '../../i18n/i18n'

const tRoot = i18n()

export default function PlanAlert(props) {

    return (
        <Joyride
            steps={props.steps}
            debug={process.env.NODE_ENV === 'development'}
            run={true}
            continuous={true}
            showProgress={props.steps.length > 1}
            showSkipButton={true}
            locale={{ back: tRoot('back'), close: tRoot('close'), last: tRoot('last'), next: tRoot('next'), skip: tRoot('skip') }}
        />
    )

}