import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import i18n from '../../i18n/i18n'
import { DropContent, Drop, CancelButton } from './styled'

const tRoot = i18n()

const Dropzone = ({ setImg, handlerStep, handlerCancel }) => {

    const onDrop = useCallback(acceptedFiles => {
        let file = acceptedFiles[0]
        setImg(file)
        handlerStep(3)
    }, [setImg, handlerStep])
    
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      } = useDropzone({ onDrop })
    
    return (
        <DropContent>
            <Drop {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                <CloudUploadIcon style={{ fontSize: 80 }}/>
                {
                    isDragActive ?
                        <p>{tRoot('dropFilesHere')} ...</p> :
                        <p>{tRoot('dragNdropFilesHere1')}<u>{tRoot('click')}</u>{tRoot('dragNdropFilesHere2')}</p>
                }
            </Drop>
            <CancelButton
                onClick={handlerCancel}
                variant="contained"
                color="primary"
            >
                {tRoot('cancel')}
            </CancelButton>
        </DropContent>
    )
}

export default Dropzone