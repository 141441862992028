import React from 'react'
import {
    SquareIcon,
    Button
} from './styled'

export const SquareButton = ({ active, handleSwitchChange }) => {
    let opacityLevel = active ? 0.9 : 0.3
    return (
        <Button 
            onClick={e => handleSwitchChange('square')}
        >
            <SquareIcon style={{opacity: opacityLevel}}/>
        </Button>
    )
}
