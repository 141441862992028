import React, { useState, useEffect } from 'react'
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import {
    Content,
    FormControl,
    InputLabel,
    Input,
    Color,
    Column,
    Select,
    MenuItem
} from './styled'
import { getChat, chatUpdate } from '../../../hooksActionCreators'
import i18n from '../../../../../../i18n/i18n'
import Loader from '../../../../../../components/LinearLoader/linearLoader'

const tRoot = i18n()

const HEADER_FONT_WEIGHT = "400"

export const HeaderName = (chat) => {
    let chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id
    let [text, setText] = useState('')
    let [color, setColor] = useState('')
    let [showChooseColor, setShowChooseColor] = useState(false)
    let [font, setFont] = useState('')
    let [fontSize, setFontSize] = useState('')
    let [loader, setLoader] = useState(false)

    const openLoader = () => setLoader(true)
    const closeLoader = () => setLoader(false)

    const change = {
        styles: {
            chatTop: {
                avatarText: {
                    color: color,
                    fontFamily: font,
                    fontSize: fontSize,
                    fontWeight: HEADER_FONT_WEIGHT
                }
            }
        },
        config: {
            chatTop: {
                avatarType: 'text',
                avatarText: text
            }
        }
    }

    useEffect(() => {
        fetchChatData()
    }, [])

    const fetchChatData = async () => {
        openLoader()
        let chat = await getChat(chatID)
        setText(chat.config.chatTop.avatarText)
        setColor(chat.styles.chatTop.avatarText.color)
        setFont(chat.styles.chatTop.avatarText.fontFamily)
        setFontSize(chat.styles.chatTop.avatarText.fontSize)
        closeLoader()
    }

    const dataUpdate = (change) => {
        try {
            chatUpdate(chatID, change)
        } catch (error) {
            console.log('error :', error);
        }
    }

    const handleTextLostFocus = () => {
        window.chatbot.updatePlacerholderText(text)
        dataUpdate({ ...change, config: { chatTop: { avatarText: text } } })
    }

    const handleText = (event) => {
        setText(event.target.value)
    }

    const handleChooseColor = () => {
        setShowChooseColor(!showChooseColor)
    }

    const handleColor = (newColor) => {
        dataUpdate({ ...change, styles: { chatTop: { avatarText: { color: newColor.hex } } } })
        setColor(newColor.hex)
        window.chatbot.updateWriteSectionStyles({ "color": newColor.hex })
    }

    const handleCloseChooseColor = () => {
        setShowChooseColor(false)
    }

    const handleFont = (event) => {
        setFont(event.target.value)
        window.chatbot.updateWriteSectionStyles({ "fontFamily": event.target.value })
        dataUpdate({ ...change, styles: { chatTop: { avatarText: { fontFamily: event.target.value } } } })

    }

    const handleFontSize = (event) => {
        setFontSize(event.target.value)
        window.chatbot.updateWriteSectionStyles({ "fontSize": fontSize })
        dataUpdate({ ...change, styles: { chatTop: { avatarText: { fontSize: event.target.value } } } })
    }


    return (
        <div>

            {loader ? <div style={{ 'height': '300px' }}><Loader /></div> :
                <Content>
                    <>
                        <FormControl>
                            <InputLabel>
                                {tRoot('textHeader')}
                            </InputLabel>
                            <Input
                                id="backgrtextound"
                                onBlur={handleTextLostFocus}
                                value={text}
                                onChange={handleText}
                            />
                        </FormControl>
                        <ClickAwayListener onClickAway={handleCloseChooseColor}>
                            <FormControl>
                                <InputLabel>
                                    {tRoot('color')}
                                </InputLabel>
                                <Input
                                    id="background"
                                    value={color}
                                />

                                <Color style={{ background: color }} onClick={handleChooseColor} />
                                {showChooseColor ? <SketchPicker
                                    onChangeComplete={handleColor}
                                    color={color} /> : ""}
                            </FormControl>
                        </ClickAwayListener>
                        <Column>
                            <FormControl>
                                <InputLabel htmlFor="fontInput"> {tRoot('font')}</InputLabel>
                                <Select
                                    value={font}
                                    onChange={handleFont}
                                    inputProps={{
                                        name: 'fontInput',
                                        id: 'fontInput',
                                    }}
                                >
                                    <MenuItem value="Arial">Arial</MenuItem>
                                    <MenuItem value="Arial Black">Arial Black</MenuItem>
                                    <MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
                                    <MenuItem value="Courier New">Courier New</MenuItem>
                                    <MenuItem value="Impact">Impact</MenuItem>
                                    <MenuItem value="Lucida Console">Lucida Console</MenuItem>
                                    <MenuItem value="Lucida Sans Unicode">Lucida Sans Unicode</MenuItem>
                                    <MenuItem value="Tahoma">Tahoma</MenuItem>
                                    <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
                                    <MenuItem value="Verdana">Verdana</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel htmlFor='fontSizeInput'> {tRoot('size')}</InputLabel>
                                <Select
                                    value={fontSize}
                                    onChange={handleFontSize}
                                    inputProps={{
                                        name: 'fontSizeInput',
                                        id: 'fontSizeInput',
                                    }}
                                >
                                    <MenuItem value="12px">12</MenuItem>
                                    <MenuItem value="13px">13</MenuItem>
                                    <MenuItem value="14px">14</MenuItem>
                                    <MenuItem value="15px">15</MenuItem>
                                    <MenuItem value="17px">17</MenuItem>
                                    <MenuItem value="20px">20</MenuItem>
                                    <MenuItem value="24px">24</MenuItem>
                                    <MenuItem value="32px">32</MenuItem>
                                </Select>
                            </FormControl>
                        </Column>

                    </>
                </Content>
            }
        </div>
    )
}