import React from 'react'
import { connect } from 'react-redux'


class demo extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props)
    }

    componentDidMount = () => {
    }

    render() {
        return (
            <div>DEMOOOOOO</div>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        reject: errorMessage => {
            dispatch()
        },
        accept: data => {
            dispatch()
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(demo)

