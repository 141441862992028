import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width: 75px;
    height: 75px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
   
    border-radius: 50%;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
  }
  & svg{
    border-bottom-left-radius: 43%;
    border-bottom-right-radius: 50%;
    margin-top: 3px;
  }
`


export  const  ButtonAvatarUserIconBlue = ({func,avatarId})=> {
    return(
      <Button onClick= {e => func(avatarId)}>   
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="125px" height="125px" viewBox="  0  0 448 454" enableBackground="new  0  0 448 454" space="preserve">
        <g>
            <g>
                <path fill="#324A5E" d="M142.355,122.633c18.59-11.315,30.715-31.524,30.715-54.966C173.072,32.101,143.971,3,108.404,3
                    C72.839,3,43.738,32.101,43.738,67.667c0,23.441,12.125,43.649,30.716,54.966C42.121,134.758,17.871,163.05,11.405,197h16.167
                    c8.083-37.184,41.225-64.667,80.833-64.667s72.75,27.483,80.834,64.667h16.166C198.939,162.242,174.688,133.951,142.355,122.633z
                    M59.904,67.667c0-26.675,21.826-48.5,48.5-48.5c26.675,0,48.5,21.825,48.5,48.5c0,26.674-21.825,48.5-48.5,48.5
                    C81.73,116.167,59.904,94.342,59.904,67.667z"/>
            </g>
        </g>
        </svg>
      </Button>   
    )
}