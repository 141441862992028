export const userErrorHandler = {
  register,
  login
};

function register(error) {
  const genericEC = document.querySelector('.generic-error');
  const emailMandatoryEC = document.querySelector('.email-mandatory');
  const existingUserEC = document.querySelector('.existing-user');
  const cannotCreateUserEC = document.querySelector('.cannot-create');

  // enable button
  const button = document.querySelector('button.submit');
  button.disabled = false;
  button.classList.remove('disabled');
  
  // hide spinner
  const spinner = document.querySelector('.spinner-container');
  spinner.style.display = 'none';
  
  if(emailMandatoryEC && error === 400) {
    emailMandatoryEC.style.display = 'block';
  } else if (cannotCreateUserEC && error === 404) {
    cannotCreateUserEC.style.display = 'block';
  } else if (existingUserEC && error === 406) {
    existingUserEC.style.display = 'block';
  } else if (genericEC && error === 500) {
    genericEC.style.display = 'block';
  }
}

function login(error) {
  const genericEC = document.querySelector('.generic-error');
  const wrongCredentialsEC = document.querySelector('.wrong-credentials');

  // enable button
  const button = document.querySelector('button.submit');
  button.disabled = false;
  button.classList.remove('disabled');
  
  // hide spinner
  const spinner = document.querySelector('.spinner-container');
  spinner.style.display = 'none';

  if(genericEC && error === 500) {
    genericEC.style.display = 'block';
  } else if (wrongCredentialsEC && error === 404) {
    wrongCredentialsEC.style.display = 'block';
  }
}