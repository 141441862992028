
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import ReactGA from 'react-ga'
import compose from 'recompose/compose';

class Welcome extends React.Component {
    state = {

    }

    UNSAFE_componentWillMount() {
        ReactGA.pageview("/dashboard/welcome", ['backTrackerV2', 'backTrackerV3'])

        //window.gtag('event', 'conversion', { 'send_to': 'AW-734206153/ZjrkCNKoxaQBEMmxjN4C' });

        window.fbq('track', 'StartTrial');
    }

    handlers = {

        handleNewChat: () => {
            //window.gtag('event', 'conversion', {'send_to': 'AW-734206153/OgdYCMDcq6MBEMmxjN4C'});
            this.props.history.push("/dashboard/new-chat")
        },
        handlegotoDashboard: () => {
            this.props.history.push("/dashboard/projects")
        }

    }

    // eslint-disable-next-line
    constructor(props) { super(props); }
    render = () => view(
        this.props,
        this.handlers

    )
}


const mapStateToProps = state => {
    return {
        userData: state.auth.userLogged
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Welcome))
export default compose(
    withStyles(styles, { name: 'Welcome' }),
    connect(mapStateToProps, mapDispatchToProps)
)(Welcome)



