import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateHeaderImage, upLoadAvatar } from '../../../actionCreators.js';
import { view as viewUpLoadAvatar } from './UpLoadAvatar/view'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import i18n from '../../../../../../i18n/i18n';
import compose from 'recompose/compose';

const tRoot = i18n()

class HeaderImage extends React.Component {
  state = {
    selectedImage: '',
    selectedForm: '',
    showUpload: false,
    rawFile: null,
    finalImage: '',
    avatarUrl: '',
    customAvatar: '',
    crop: {
      aspect: 1,
      width: 50,
      x: 0,
      y: 0,
    },
    errorMessage: ''

  }

  

  // eslint-disable-next-line
  constructor(props) { super(props); }

  handleCloseMsg = () => {
    this.setState({ errorMessage: '', saveOKMsg: '' })
  }

  errorMsg = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.errorMessage !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.errorMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }


  onDropFile = (acceptedFiles, rejectedFiles) => {
    let file = acceptedFiles[0]

    const reader = new FileReader();
    reader.onload = () => {
      // eslint-disable-next-line
      const fileAsBinaryString = reader.result;
      // do whatever you want with the file content
    };
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');

    reader.readAsDataURL(file);

    reader.onload = () => this.setState({ rawFile: reader.result })

  }

  confirmImageAvatar = () => {
    upLoadAvatar(this.state.finalImage.split(',')[1], this.props.chatId)
      .then(result => {
        this.setState({ rawFile: null, showUpload: false, avatarUrl: result.data.chat.secure_url, customAvatar: result.data.chat.secure_url }, () => window.chatbot.updateChatContactPicture(result.data.chat.secure_url))

      }).catch(err => {
        this.setState({errorMessage: tRoot('imageToBigMsg')})
      })

    this.setState({ rawFile: null, showUpload: false })
  }

  cancelImageAvatar = () => this.setState({ rawFile: null, finalImage: null, showUpload: false })

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop, pixelCrop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg',
      );
      this.setState({ croppedImageUrl, finalImage: this.imageRef.src });
    }
  }

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.setState({ finalImage: reader.result })
        }

      }, 'image/jpeg');
    });
  }

  UNSAFE_componentWillMount = () => {
    this.setState({ ...this.props.data })
  }
  handleShowAvatar = () => this.setState({ showUpload: !this.state.showUpload })
  handleAvatar = selectedImage => this.setState({ selectedImage, avatarUrl: selectedImage }, () => {
    window.chatbot.updateChatContactPicture(selectedImage)
    this.props.save(this.state, this.props.chatId)
  })
  handleForm = selectedForm => this.setState({ selectedForm }, () => this.props.save(this.state.state, this.props.chatId))
  render = () => !this.state.showUpload ? view(
          this.props, 
          this.state, 
          this.handleAvatar, 
          this.handleForm, 
          this.handleShowAvatar, 
          this.cleanMessages,this.errorMsg) : viewUpLoadAvatar(this.props, this.state, this.onDropFile, this.onImageLoaded, this.onCropComplete, this.onCropChange, this.confirmImageAvatar, this.cancelImageAvatar, this.errorMsg)
}


const mapStateToProps = state => {

  return {
    data: state.chatSettings.HeaderImageResult.value,
    chatId: state.baseData.currentChatConfig._id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    save: (data, chatId) => updateHeaderImage(data, chatId).then(result => dispatch(result)).catch(err => console.log(err))
  }
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderImage))
export default compose(
  withStyles(styles, { name: 'HeaderImage' }),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderImage)




