import React from 'react'
import {view} from './view'

class Error500 extends React.Component {
    // eslint-disable-next-line
    constructor(props){
        super(props)
    }

    render = () => view()
}

export default Error500