import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import { Button } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

import Feedback from '../../../components/feedback'
import TiaraHeader from '../../../components/TiaraHeader'
import lang from '../../../i18n/lang'
import i18n from '../../../i18n/i18n'
import { stripeCustomerPortalSession } from '../../Dashboard/ChatSettings/hooksActionCreators'


const tRoot = i18n()

const useStyles = makeStyles((theme) => ({
    buttonUpgrade: {
        margin: theme.spacing(0),
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    }
}));

const Content = styled.div`

    padding:10px 0 20px;
    text-align:left;
`
const Body = styled.div`
    width: 100%;
    background: #fff;
    padding-left: 0px;
    height: 100vh;
    overflow-y: scroll;
    
`

const HeaderContent = styled.div`
    display: grid;
    grid-template-columns:  1fr;
    grid-column-gap:20px;
    h2{
      text-align:left;
      padding-top:0px;
    }
`
const Project = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: #b9bec1;
    text-align:left;
    float: left;
    height:30px;
    width:100%
`

const PlanSubscribed = styled.div`
  diplay: grid;
  background: #F3F3F3;
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const Box = styled.div`
  margin:auto;
`

const StripeSubscription = () => {
    const classes = useStyles();
    var appState = JSON.parse(localStorage.getItem('appState'))
    var chatID = appState.baseData.currentChatConfig._id
    var chatName = appState.baseData.currentChatConfig.name
    var plan = JSON.parse(localStorage.getItem('plan'))
    var user = appState.auth.userLogged


    const [SupportURL, setSupportURL] = useState('https://supporten.blahbox.net')

    useEffect(() => {
        ReactGA.pageview("/dashboard/chat-settings/StripeSubscription", ['backTrackerV2', 'backTrackerV3'])

        setSupportURL(lang() === 'es' ? 'https://supportes.blahbox.net' : 'https://supporten.blahbox.net')
    }, [])

    const handleCustomerPortal = async () => {
        try {
            var sessionCustomerPortal = await  stripeCustomerPortalSession(chatID, plan.stripeCustomerID)
        } catch (error) {
            console.log('error :', error);
            return
        }

        window.location = sessionCustomerPortal.url
    }

    return (

        <Body>
            <TiaraHeader />
            <div style={{ 'padding-left': '60px' }}>
                <HeaderContent>

                    <Project>{chatName}</Project>
                    <h2>{tRoot('subscription')}</h2>
                </HeaderContent>
                <Content>
                    <div>

                        <PlanSubscribed>
                            <Box>
                                <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('currectSubscription')}</span>
                                <p style={{ paddingTop: '10px' }} >{plan.ProductName}</p>
                            </Box>
                            <Box>
                                <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('planPrice')}</span>
                                <p style={{ paddingTop: '10px' }} >${plan.FinalPrice}</p>
                            </Box>
                            <Box>
                                <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('nextBilling')}</span>
                                <p style={{ paddingTop: '10px' }} >{plan.ExpirationDate}</p>
                            </Box>
                        </PlanSubscribed>

                        <suscription-table
                            user-id={user.sub}
                            nick-name={user.nickname ? user.nickname : user.name}
                            email={user.email}
                            lang={lang() === 'es' ? 'es' : 'en'}
                            chatid={chatID}
                            token={localStorage.getItem('token')}
                            envt={process.env.BACK_ENV}
                            currentplan={plan.ProductName}
                        ></suscription-table>

                        {plan.ProductName !== 'Free' ?
                        <div style={{ paddingTop: '30px' }}>
                            <Button
                                className={classes.buttonUpgrade}
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={handleCustomerPortal}
                            >
                                {tRoot('goCursomerPortal')}
                            </Button>
                        </div>
                        : ''}

                        <div style={{ paddingTop: '30px' }}>
                            <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('contactUS')}</span>
                            <p style={{ paddingTop: '10px', 'fontStyle': 'italic' }}>
                                {tRoot('contactUSText')} <a href={SupportURL + '/portal/newticket'} target="_blank" rel="noopener noreferrer" style={{ color: '#7E9BA4' }}>{tRoot('here')}!</a>
                            </p>
                        </div>
                        <div style={{ paddingTop: '30px' }}>
                            <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('refundPolicy')}</span>
                            <p style={{ paddingTop: '10px', 'fontStyle': 'italic' }}>{tRoot('refundPolicyText')}</p>
                        </div>
                        <div style={{ paddingTop: '30px' }}>
                            <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('privacyPolicy')}</span>
                            <p style={{ paddingTop: '10px', 'fontStyle': 'italic' }}>{tRoot('privacyPolicyText1')}
                                <br />{tRoot('privacyPolicyText2')}
                                <br />{tRoot('privacyPolicyText3')} <a href='https://www.blahbox.net/privacypolicy/' target="_blank" rel="noopener noreferrer" style={{ color: '#7E9BA4' }}>{tRoot('here')}</a>
                            </p>
                        </div>
                        <div style={{ paddingTop: '30px' }}>
                            <span style={{ textTransform: 'uppercase', color: '#C1B0A9' }}>{tRoot('termsOfService')}</span>
                            <p style={{ paddingTop: '10px', 'fontStyle': 'italic' }}>
                                {tRoot('moreInformation')} <a href='https://www.blahbox.net/termsofservice/' target="_blank" rel="noopener noreferrer" style={{ color: '#7E9BA4' }}>{tRoot('here')}</a>
                            </p>
                        </div>

                    </div>
                    <Feedback seccion="General-Suscription" />
                </Content>
            </div>
        </Body>
    )

}

export default StripeSubscription