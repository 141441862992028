import React from 'react'
import styled from 'styled-components'
import {styleSelected, unSelected} from '../styles'

const ButtonRadius = styled.button`
    width: 75px;
    height: 75px;
    border-radius: 3px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    & svg{
        margin-top:5px;
    }
`


export  const  ButtonSendIconArrow = ({handleSelect, currentImage}) => {
    return(
      <ButtonRadius onClick ={e => handleSelect('https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send3.svg')} style={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send3.svg'? styleSelected : unSelected}>  
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="75%" height="75%" viewBox="0 0 414 514" enableBackground="new 0 0 414 514"  space="preserve">
       <path fill={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send3.svg' ? '#fff' : '#3F566A'} d="M144.556,501.22c-5.052,0-9.472-1.895-13.26-5.685c-7.577-7.576-7.577-19.573,0-26.521l212.797-212.168
            L131.297,44.682c-7.577-7.577-7.577-19.575,0-26.521c7.577-7.578,19.575-7.578,26.521,0l226.06,225.427
            c3.79,3.789,5.684,8.209,5.684,13.261c0,5.053-1.895,10.104-5.684,13.26l-226.06,225.428
            C154.029,499.327,149.608,501.22,144.556,501.22z"/>
        </svg>

    </ButtonRadius>   
    )
}