import React from 'react'
import styled from 'styled-components'
import { styleSelected , unSelected} from '../styles'


const ButtonForm = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
border-radius:30px;
`


export const CallOutsFormCircle = ({ func, itemSelected }) => {
  return (
    <ButtonForm onClick={e => {
      window.chatbot.updateUserGlobeStyles({
        borderTopLeftRadius: "30px",
        borderTopRightRadius: "30px",
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        peakPosition:"remove-peak"
        
      })

      window.chatbot.updateBotGlobeStyles({
        borderTopLeftRadius: "30px",
        borderTopRightRadius: "30px",
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        peakPosition:"remove-peak"
      })
      func('CallOutsFormCircle')
    }
    }
    style={itemSelected === 'CallOutsFormCircle' ? styleSelected : unSelected}>
    </ButtonForm>
  )
}