//example: https://codesandbox.io/s/react-table-with-react-beautiful-dnd-0bj5d?file=/src/App.js:9478-9723
import React, { useEffect, useRef, useState } from 'react'
import Alert from '@material-ui/lab/Alert';
import styled from "styled-components";
import { useTable } from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'
import Loader from '../../../../../../components/LinearLoader/linearLoader'
import { getChat, listUpdate } from '../../../hooksActionCreators';


const tRoot = i18n()

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin-bottom: 25px;
    justify-content: center;
    margin-top: 20px;
`

const Styles = styled.div`
  table {
    margin: auto;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    transition: background-color 0.5s ease;
    thead {
      tr {
        display: grid;
        grid-template-columns: minmax(auto, 150px) 3fr 25px;
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        justify-items: left;
        margin-left: 3px;
        padding: 10px 10px 10px 0px;
        background-color: white;
        color: black;
        font-weight: 450;
        text-align: left;
        color: #545454;
        font-size: 1rem;
        margin-bottom: 10px;
      }
    }
    th {
      text-align: left;
    }
    th:nth-child(2n) {
        margin-left: 8px;
    }
    tbody {
        Tr {
            display: grid;
            grid-template-columns: minmax(auto, 150px) 3fr 25px;
            grid-column-gap: 10px;
            padding: 5px 5px;
            td:nth-child(3n) {
                margin: auto;
            }
            input {
                width: 100%;
                text-align: left;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border: 0;
                border-radius: 5px;
                :hover {
                    color: white;
                    background-color: #0b3548;
                }
                :focus {
                    color: white;
                    background-color: #0b3548;
                }
            }
        }
    }
  }
`;

const EditableTextCell = props => {
    const { column, row, cell, updateMyData, updateTableData, setIsValidURL } = props;
    const value = cell.value;
    const rowIndex = row.index;
    const columnId = column.id;
    const onChange = e => {
        updateMyData(rowIndex, columnId, e.target.value);
    };
    const onBlur = () => {
        let dataOK
        if (columnId === 'url') {
            if (isValidHttpUrl(value)) {
                dataOK = true
                setIsValidURL(true)
                updateTableData()
            } else {
                dataOK = false
                setIsValidURL(false)
            }
        }

        if (columnId === 'label' && dataOK) {
            updateTableData()
        }
    }
    return <input value={value} onChange={onChange} onBlur={onBlur} placeholder={tRoot("typeHere")} />;
};

const isValidHttpUrl = (string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const Tr = styled.tr`
  background-color: white;
  display: ${({ isDragging }) => (isDragging ? "table" : "")};
`;

const Table = ({
    columns,
    data,
    updateMyData,
    removeRow,
    addRow,
    resetData,
    reorderData,
    updateTableData,
    setIsValidURL
}) => {
    const table = useTable({
        columns,
        data,
        // non-API instance pass-throughs
        updateMyData,
        removeRow,
        addRow,
        reorderData,
        updateTableData,
        setIsValidURL
    });
    // console.log({ table });
    const { getTableProps, headerGroups, prepareRow, rows } = table;

    const handleDragEnd = result => {
        const { source, destination } = result;
        if (!destination) return;
        reorderData(source.index, destination.index);
    };

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-body">
                        {(provided, snapshot) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <Draggable
                                            draggableId={row.original.id}
                                            key={row.original.id}
                                            index={row.index}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <Tr
                                                        {...row.getRowProps()}
                                                        {...provided.draggableProps}
                                                        // {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        isDragging={snapshot.isDragging}
                                                    >
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>
                                                                {cell.render("Cell", {
                                                                    dragHandleProps: provided.dragHandleProps,
                                                                    isSomethingDragging: snapshot.isDraggingOver
                                                                })}
                                                            </td>
                                                        ))}
                                                    </Tr>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                                <tr style={{ padding: "20px 10px 10px 0px" }}>
                                    <td
                                        style={{ backgroundColor: "white" }}
                                        colSpan={columns.length}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                textAlign: "center"
                                            }}
                                        >
                                            <StyledAddItem addRow={addRow} />
                                            {/* <StyledResetData resetData={resetData} /> */}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </table>

            {/* <pre>{JSON.stringify(rows.map(row => row.values), null, 2)}</pre> */}
        </>
    );
}

const TrashCan = ({ removeRow, row, className }) => (
    <span
        className={className}
        onClick={() => removeRow(row.index)}
        role="img"
        aria-label="delete"
    >
        🗑️
    </span>
);
const StyledTrashCan = styled(TrashCan)`
  cursor: pointer;
  display: none;
  tr:hover & {
    display: ${({ isSomethingDragging }) =>
        isSomethingDragging ? "none" : "inline"};
  }
`;

const UpDownArrow = props => (
    <span
        {...props.dragHandleProps}
        className={props.className}
        aria-label="move"
        role="img"
    >
        ↕️
    </span>
);
const StyledUpDownArrow = styled(UpDownArrow)`
    position: absolute;
    top: -10px;
    left: -20px;
    padding: 15px;
    display: inline;
    tr:hover & {
        display: ${({ isSomethingDragging }) =>
        isSomethingDragging ? "none" : "inline"};
    }
`;

const AddItem = props => (
    <span
        className={props.className}
        onClick={() => props.addRow()}
        role="img"
        aria-label="add"
    >
        + {tRoot("addItem")}
    </span>
);
const StyledAddItem = styled(AddItem)`
    cursor: pointer;
    color: black;
   
`;

const Description = styled.span`
    display: flex;
    align-items: center;
    position: relative;
`;

const Sum = styled.span`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

export const HeaderMenu = () => {
    const chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id
    const isInitialMount = useRef(true)

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [nonURLData, setNonURLData] = useState([])
    const [idCount, setIdCount] = useState(data.length + 1)
    const [isValidURL, setIsValidURL] = useState(true)

    const openLoader = () => setLoader(true)
    const closeLoader = () => setLoader(false)

    const columns = React.useMemo(() => {
        const DescriptionCell = props => {
            return (
                <Description>
                    <StyledUpDownArrow {...props} />
                    <EditableTextCell {...props} />
                </Description>
            );
        };

        const SumCell = props => {
            return (
                <Sum>
                    <StyledTrashCan {...props} />
                </Sum>
            );
        };
        return [
            {
                Header: tRoot("label"),
                accessor: "label",
                Cell: DescriptionCell
            },
            {
                Header: tRoot("url"),
                accessor: "url",
                Cell: EditableTextCell
            },
            {
                Header: "",
                accessor: "",
                id: "sum",
                Cell: SumCell
            }
        ];
    }, []);

    const getUuid = () => (new Date()).getTime().toString(36)

    useEffect(() => {
        const fetchData = async () => {
            isInitialMount.current = true
            openLoader()
            let chatConfig = await getChat(chatID)
            setData((chatConfig.config.chatTop.menu.items).filter((item) => item.action === 'url'))
            setNonURLData((chatConfig.config.chatTop.menu.items).filter((item) => item.action !== 'url'))
            setIdCount((chatConfig.config.chatTop.menu.items).length + 1)
            closeLoader()
            isInitialMount.current = false
        }

        fetchData()
    }, [])

    useEffect(() => {
        !isInitialMount.current && menuUpdate()
    }, [tableData])

    const menuUpdate = () => {
        let change = {
            listName: "menu",
            list: [...data, ...nonURLData]
        }

        try {
            listUpdate(chatID, change)
        } catch (error) {
            console.log('error :', error);
        }
    }

    const resetData = () => setData('')

    const removeRow = rowIndex => {
        setData(old => old.filter((row, index) => index !== rowIndex))
        setTableData(data)
    }

    const addRow = () => {
        setData(old => [
            ...old,
            {
                id: getUuid(),
                label: '',
                action: 'url',
                url: ''
            }
        ])

        setIdCount(idCount + 1)
    }

    const updateMyData = (rowIndex, columnID, newValue) => {
        setData(oldData =>
            oldData.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...oldData[rowIndex],
                        [columnID]: newValue
                    };
                }
                return row;
            })
        )
    }

    const updateTableData = () => {
        setTableData(data)
    }

    const reorderData = (startIndex, endIndex) => {
        const newData = [...data]
        const [movedRow] = newData.splice(startIndex, 1)
        newData.splice(endIndex, 0, movedRow)
        setData(newData)
        setTableData(newData)
    }

    return (
        <div>
            <HeaderConfig title={tRoot('menu')} />
            <Content>
                {loader ? <div style={{ 'height': '300px' }}><Loader /></div> :
                    <Styles>
                        <Table
                            columns={columns}
                            data={data}
                            updateMyData={updateMyData}
                            removeRow={removeRow}
                            addRow={addRow}
                            resetData={resetData}
                            reorderData={reorderData}
                            updateTableData={updateTableData}
                            setIsValidURL={setIsValidURL}
                        />
                        {!isValidURL &&
                            <Alert variant="outlined" severity="error">
                               {tRoot("mustBeAValidURL")}
                            </Alert>
                        }
                    </Styles>
                }
                <Feedback seccion="Header-Avatar" previewMode />
            </Content>
        </div>
    )
}
