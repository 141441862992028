import React from "react";
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'
import Button from '@material-ui/core/Button'

import "./tiaraHeader.scss"
import i18n from '../../i18n/i18n'

const tRoot = i18n()

const TiaraHeader = () => {
    const plan = JSON.parse(localStorage.getItem("plan"))
    const history = useHistory()

    const handleUpgrade = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Upgrade Btn Tiara Header'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push('/dashboard/chat-settings/subscription');
    }

    return (
        <div>
        {plan.ProductName === 'Free' ? 
        <header className="tiara-headers" data-tiara-generated="true">
            <div className="alert alert-trial">
                <p>
                {tRoot('tiaraText', {reserved: plan.messagesReserved})}
                <Button onClick={handleUpgrade} color="secondary" style={{ 'padding': '0px 8px' }}>{tRoot('upgrade')}</Button>
                </p>
            </div>
        </header>
        : ''}
        </div>
    )

}

export default TiaraHeader


