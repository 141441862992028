import React from 'react'
import styled from 'styled-components'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
const Body = styled.div`
padding: 50px;
`
const Content = styled.div`
margin: 50px auto;
display: table;
background-color: #ffffff;
border-radius: 3px;
box-shadow: 0 0 30px rgba(0,0,0,0.1);
padding: 50px;
`
const Head = styled.div`
display: block;
width: 100%;
position: relative;
`

const Logo = styled.div`
position: absolute;
top: -150px;
margin-left:42%;
width: 120px;
svg {
  max-width:120px;
}
`
const Title = styled.div`
font-size: 3.2em;
  padding-top: 25px;
  text-align:center;
`
const SubTitle = styled.div`
font-size: 1.2em;
margin: 5px 0;
text-align:center;
`
const ContentForm = styled.div`
display: grid;
grid-template-columns:400px 400px;
width: 100%;
margin-top: 40px
grid-gap: 20px;
padding:10px 0 20px;
text-align:left
`
const ContentLabel = styled.div`

padding-top:25px;
vertical-align: middle;
display: table-cell;
border-right:1px solid #ccc;
`
const ContentRemember = styled.div`
display:grid;
grid-template-columns:153px 1fr;
`
const ContentButton = styled.div`
padding-top:45px;
text-align:left;
vertical-align: middle;
margin-left:40px;
`

const Terms = styled.div`
font-size: 14px;
display: block;
margin: 30px auto;
max-width: 500px;
width: 100%;
text-align:center;
`


const RedesSociales = styled.div`
cursor: pointer;
margin: 20px 0;
transition: all 0.2s ease 0s;
display:grid;
grid-template-columns:50px 1fr;
&:hover{
  box-shadow:0 0 15px rgba(0,0,0,0.2)
}

`
const ResetPass = styled.a`
line-height:50px;
text-decoration: none;
color: #324a5e;
transition: all 0.2s ease 0s;
  &:hover{
    color: #ef7e29;
  }

`

const BtnLogin = styled.button`
border: 1px solid #ef7e29;
padding: 10px 30px;
border-radius: 30px;
display: inline-block;
cursor: pointer;
transition: all 0.2s ease 0s;
font-size: 18px;
color: #ef7e29;
margin: 10px 0;
width: 85%;
text-align: center;
  &:hover{
    background:#ef7e29;
    color:#fff;
  }
`
const Facebook = styled.button`
display:grid;
grid-template-columns:50px 1fr;
background-color: #3b5998;
line-height: 50px;
text-align: center;
color: #fff;
font-size: 18px;
width:100%;
 
  
`
const Google = styled.button`
display:grid;
grid-template-columns:50px 1fr;
background-color: #4285f4;
line-height: 50px;
text-align: center;
color: #fff;
font-size: 18px;
width:100%;

  
`
const IconFacebook = styled.div`
background:#fff;
width:50px;
height:50px;
height:54px;
margin-top:1px;
border-bottom: 1px solid #3b5998
border-left: 1px solid #3b5998;
border-top: 1px solid #3b5998;
svg{
  width: 50px;
  height: 25px;
  margin-top: 13px;
}
 
  
`
const IconGoogle = styled.div`
background:#fff;
width:50px;
height:54px;
margin-top:1px;
border-bottom: 1px solid #4285f4;
border-left: 1px solid #4285f4;
border-top: 1px solid #4285f4;
svg{
  width: 50px;
  height: 25px;
  margin-top: 13px;
}
  
`


export const styles = theme => ({
  errorLabel:{
    color:"#ff0000",
    fontSize:"12px",
    marginTop:"5px",

  },
 
  formLabel:{
    width:"85%",
    marginBottom:"20px",
  },
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',

        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },

});


export const view = ({classes},{userName, userPassword},handleUserName,handleUserPassword,login,loginWithFacebook,loginWithGoogle ) => {
  return (
  <Body>
     <Content>
      <Head>
        <Logo>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	        width="204px" height="198.43px" viewBox="53.405 0 204 198.43" enable-background="new 53.405 0 204 198.43" space="preserve">
        <circle fill="#324A5E" cx="155.905" cy="99.215" r="99.215"/>
        <path fill="#EF7E29" d="M200.306,46.876h-88.803c-5.54,0-10.02,4.479-10.02,10.021v62.594c0,5.54,4.479,10.02,10.02,10.02h10.57
          l-6.326,22.044l34.814-22.044h49.745c5.54,0,10.02-4.479,10.02-10.02V56.897C210.326,51.356,205.846,46.876,200.306,46.876z"/>
        <g>
          <circle fill="#FFFFFF" cx="131.975" cy="88.724" r="8.212"/>
          <circle fill="#FFFFFF" cx="155.905" cy="88.724" r="8.212"/>
          <circle fill="#FFFFFF" cx="179.834" cy="88.724" r="8.212"/>
        </g>
        </svg>
        </Logo>
        <Title>Star in Chat Box</Title>
        <SubTitle>¿New in Chat Box? 
        <b> Registrate</b> </SubTitle>
      </Head>
      <ContentForm>
        <ContentLabel>
        <FormControl className={classes.formLabel} >
          <InputLabel
            classes={{root: classes.cssLabel,focused: classes.cssFocused, }} >
            Email
          </InputLabel>
          <Input
            id="email"
            value={userName.value}
            onChange={handleUserName}
            classes={{underline: classes.cssUnderline,}}
            
            />
            {
              userName.errorMessage ?  <FormHelperText id="component-error-text" className={classes.errorLabel} >{userName.errorMessage}</FormHelperText> : ''
            }
        </FormControl>
        <FormControl className={classes.formLabel}  >
          <InputLabel
            classes={{root: classes.cssLabel,focused: classes.cssFocused, }} >
            Contraseña
          </InputLabel>
          <Input
            id="password"
            value={userPassword.value}
            onChange={handleUserPassword}
            classes={{underline: classes.cssUnderline,}}
            
             />
 {
              userPassword.errorMessage ?  <FormHelperText id="component-error-text" className={classes.errorLabel} >{userPassword.errorMessage}</FormHelperText> : ''
            }
         </FormControl>
        <ContentRemember  >
            <FormControlLabel
              control={
                <Checkbox
                  value="checkedA"
                />
              }
              label="Recuerdame"
            />
            <ResetPass>¿Olvidaste tu contraseña?</ResetPass>
        </ContentRemember>
        <BtnLogin onClick = {login} >Iniciar Sesion</BtnLogin>
        </ContentLabel>
        <ContentButton>
          <RedesSociales>
            <IconFacebook>
            <svg version="1.1" id="Layer_1"
             xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	            width="162px" height="302px" viewBox="0 0 162 302"  space="preserve">

            <path class="st0" fill="#3A5BA2" d="M161.6,58h-46.2c-5.5,0-11.6,7.4-11.6,17.3v29.4h57.7v57.4h-57.7v139H46.1v-139H0v-57.3h46.2V75.9
              c0-41.4,31.1-75,69.3-75h46.2V58H161.6z"/>
            </svg>
            </IconFacebook>
            <Facebook onClick={loginWithFacebook}> Continuar con Facebook</Facebook>
          </RedesSociales>
          <RedesSociales>
            <IconGoogle>
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
                <defs>
                    <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/>
                </defs>
                <clipPath id="b">
                    <use href="#a" overflow="visible"/>
                </clipPath>
                <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/>
                <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/>
                <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/>
                <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
            </svg>
            </IconGoogle>
            <Google onClick={loginWithGoogle}> Continuar con Google </Google>
          </RedesSociales>
          <RedesSociales>
          
            
          </RedesSociales>
        </ContentButton>
        
      </ContentForm>
       
    </Content>
    <Terms>* Al iniciar tu sesiórn, aceptas nuestros Térrminos de Uso y recibirárs correos electrórnicos y actualizaciones de ChatBox; al reconocer que leírste nuestra Polírtica de Privacidad</Terms>

  </Body>
   
        
    )
}


