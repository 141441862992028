import i18n from './i18n'

const langKey = 'blahbox:backoffice:lang'
const defaultLang = getBrowserLangOr('en')

export default function lang(lang){
	return lang ? setLang(lang) : getLang()
}

export function getBrowserLangOr(defaultLang) {
	const userLang = navigator.language || navigator.userLanguage || defaultLang
	const prefixLang = userLang.substring(0, 2)
	return isValidLang(prefixLang) ? prefixLang : defaultLang
}

export const enabledLangs = {
	es: 'Español',
	en: 'English'
}

function isValidLang(lang){
	if (lang === 'es' || lang === 'en'){
		return true
	}

	return false
	
	//return keys(enabledLangs).includes(lang)
}

function getLang(){
	return localStorage.getItem(langKey) || defaultLang
}

function setLang(lang) {
	import(
		/* webpackChunkName: "lang-[request]" */
		`./${lang}`
	).then(translations => {
		i18n.load(translations.default, lang)
		localStorage.setItem(langKey, lang)
		//flicktrip.lang = lang
		window.location.reload()
	})
}