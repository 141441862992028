import {
  RESET_STORE,
  OPEN_LOADER,
  CLOSE_LOADER,
  SELECT_SETTING,
  SET_CURRENT_CHAT_ID,
  SET_CURRENT_CHAT
} from "./rootConstants";

import axios from 'axios'
var middleURL = process.env.MIDDLE_URL
var middleKEY = process.env.MIDDLE_KEY
const BACKEND_API_URL = process.env.API_URL
const BACKEND_API_KEY = process.env.API_KEY
const getToken = () => (localStorage.getItem("token"))
var SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';
var slack = require('slack-notify')(SLACK_WEBHOOK_URL);


export const validateChatCredentials = credentials => {
  var url = middleURL + "/chat/validate"
  const h = {
    headers: { 'x-ibm-client-id': middleKEY }
  }
  let service = axios.create({
    headers: { 'x-ibm-client-id': middleKEY }
  });
  return service.post(url, credentials, h)
}

export const updateSuscription = (refno, chatId) => {
  var url = BACKEND_API_URL + "/subs/subscriptionUpgrade"
  const h = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-core-client-id': BACKEND_API_KEY,
      'Authorization': getToken()
    }
  }
  let service = axios.create({
  });

  const body = {
    refNo: refno,
    chatID: chatId
  }
  return service.post(url, body, h)
}

export const resetStore = () => {
  return dispatch => dispatch({ type: RESET_STORE });
};

export const openLoader = () => {

  return dispatch => dispatch({ type: OPEN_LOADER });
};

export const closeLoader = () => {
  return dispatch => dispatch({ type: CLOSE_LOADER });
};

export const selectSetting = data => {

  if (window.chatbot) {
    switch (data) {
      case 'floatingIconIcon':
      case 'floatingIconForm':
      case 'floatingIconPosition':
      case 'floatingIconTooltip':
        window.chatbot.closeChat()
        break;
      default:
        window.chatbot.openChat()
    }
  }
  return dispatch => dispatch({ type: SELECT_SETTING, data });
};

export const setCurrentChatId = chatId => {
  return dispatch => dispatch({ type: SET_CURRENT_CHAT_ID, chatId });
};

export const setCurrentChat = chat => {
  return dispatch => dispatch({ type: SET_CURRENT_CHAT, chat });
};

export const getCurrentSubscription = id => {
  var url = BACKEND_API_URL + "/subs/getsubs/" + id
  const h = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-core-client-id': BACKEND_API_KEY,
      'Authorization': getToken()
    }
  }
  let service = axios.create({
  });

  const body = {
  }
  return service.post(url, body, h)
}

export const saveChatNameAndDescription = (chatId, name, description) => {
  var url = BACKEND_API_URL + "/chat/update/" + chatId
  const h = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-core-client-id': BACKEND_API_KEY,
      'Authorization': getToken()
    }
  }
  let service = axios.create({
  });

  const body = {
    name,
    description
  }

  return service.put(url, body, h)
}

export const saveTrackID = (chatId, analyticsTrackID) => {
  var url = BACKEND_API_URL + "/chat/update/" + chatId
  const h = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-core-client-id': BACKEND_API_KEY,
      'Authorization': getToken()
    }
  }
  let service = axios.create({
  });

  const body = {
    analyticsTrackID
  }

  return service.put(url, body, h)
}


export const saveWakeupWord = (chatId, wakeupWord) => {
  var url = BACKEND_API_URL + "/chat/update/" + chatId
  const h = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-core-client-id': BACKEND_API_KEY,
      'Authorization': getToken()
    }
  }
  let service = axios.create({
  });

  const body = {
    wakeupWord
  }

  return service.put(url, body, h)
}

export const slackNotify = (message) => {
  slack.alert(message)
}