import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Router } from "react-router-dom";
import Register from "./containers/Register/Register";
import Login from "./containers/Login/Login";
import Dashboard from "./containers/Dashboard/Dashboard";
import LoadingSpinner from "./components/Spinner/Spinner.js";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import createBrowserHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
import NewLogin from './auth/login/index.js'
import NewRegister from './auth/register/index.js'
import ValidateLogin from './auth/validate/index'
import Auth from './auth/auth'
import Demo from './demo'
import Error503 from './auth/503/index'
import { connect } from 'react-redux'
import Lock from './auth/lock'
import ErrorBoundary from './components/ErrorHandler/index'
import Error500 from './components/Error500/index'



const customHistory = createBrowserHistory();
const auth = new Auth()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ef7e29'
    },
    secondary: {
      main: '#ef7e29',
    },
  },
});

class AppRouter extends React.Component {
  state = {
    token: ''
  }

  UNSAFE_componentWillMount = () => {
    this.setState({ token: this.props.userData.token })
  }

  render() {

    return (
      <div>

        
        <Router history={customHistory}>
          <MuiThemeProvider theme={theme}>
            <Switch>
              <ErrorBoundary>
                <PrivateRoute exact path="/" component={Dashboard} />
                <Route path={"/register"} exact component={Register} />
                <Route path={"/login"} exact component={Login} />
                <Route path={"/newlogin"} exact component={NewLogin} />
                <Route path={"/newregister"} exact component={NewRegister} />
                <Route path={"/validate"} exact component={ValidateLogin} />
                <Route path={"/unauthorized"} exact component={Error503} />
                <Route path={'/authLock'} exact render={() => <Lock location={this.props.location} />} />
                <Route path={'/auth'} exact render={() => auth.login()} />
                <Route path={'/error'} exact component={Error500} />
                <Route path={'/demo'} exact component={Demo} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
              </ErrorBoundary>
            </Switch>
          </MuiThemeProvider>
        </Router>
        <LoadingSpinner />

      </div>
    );
  }
}


function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

const mapStateToProps = state => {

  return {
    userData: state.auth.userLogged
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
