import React, { useState, useEffect } from 'react'
import DropCropUpload from '../../../../../../components/DropCropSaveImage/DropCropSaveImage'

import {
	Content,
	ContentForm,
	Label,
	RadiosContent,
	RadioGroup,
	FormControlLabel,
	Radio,
	IconsContent,
	ShapeContent
} from './styled'

import { SquareButton } from './ShapesButtons/square'
import { CircleButton } from './ShapesButtons/circle'
import { DiamondButton } from './ShapesButtons/diamond'

import { CallOutsDoubleBoxes } from './ImageAvatars/callOutsDoubleBoxes';
import { RadiusCallOuts } from './ImageAvatars/radiusCallOuts';
import { DoubleCircleCallOuts } from './ImageAvatars/doubleCircleCallOuts';
import { CircleCallOuts } from './ImageAvatars/circleCallOuts';
import { ColorPicker } from '../../../../../../components/ColorPicker';
import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'
import Loader from '../../../../../../components/LinearLoader/linearLoader'
import useChat from '../../../../../../hooks/useChat'
import useChatMutation from '../../../../../../hooks/useChatMutation'


const tRoot = i18n()
const t = i18n('chatSettings')

export const FloatingIconIcon = () => {
	const chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id
	const icons = {
		callOutsDoubleBoxes: {
			public_id: 'chat-icon-1',
			url: 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-1.svg',
			name: 'callOutsDoubleBoxes',
			selected: false,
			color1: {
				color: 'white'
			},
			color2: {
				color: 'white'
			},
			color3: {
				color: 'white'
			}
		},
		circleCallOuts: {
			public_id: 'chat-icon-4',
			url: 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-4.svg',
			name: 'circleCallOuts',
			selected: false,
			color1: {
				color: 'white'
			},
			color2: {
				color: 'white'
			},
			color3: {
				color: 'white'
			}
		},
		doubleCircleCallOuts: {
			public_id: 'chat-icon-5',
			url: 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-5.svg',
			name: 'doubleCircleCallOuts',
			selected: false,
			color1: {
				color: 'white'
			},
			color2: {
				color: 'white'
			},
			color3: {
				color: 'white'
			}
		},
		radiusCallOuts: {
			public_id: 'chat-icon-6',
			url: 'https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-6.svg',
			name: 'radiusCallOuts',
			selected: false,
			color1: {
				color: 'white'
			},
			color2: {
				color: 'white'
			},
			color3: {
				color: 'white'
			}
		}
	}
	const [iconType, setIconType] = useState('predefined')
	const [background, setBackground] = useState('')
	const [callOutsDoubleBoxes, setCallOutsDoubleBoxes] = useState({ ...icons.callOutsDoubleBoxes })
	const [circleCallOuts, setCircleCallOuts] = useState({ ...icons.circleCallOuts })
	const [doubleCircleCallOuts, setDoubleCircleCallOuts] = useState({ ...icons.doubleCircleCallOuts })
	const [radiusCallOuts, setRadiusCallOuts] = useState({ ...icons.radiusCallOuts })
	const [color1, setColor1] = useState('')
	const [color2, setColor2] = useState('')
	const [color3, setColor3] = useState('')
	const [shape, setShape] = useState('')
	const [customAvatarURL, setCustomAvatarURL] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const { isLoading, isError, data: chat = {}, isSuccess } = useChat(chatID)

	const mutation = useChatMutation()

	if (isError) {
		console.log("Error Fetching Data")
	}

	const chatUpdate = (change) => {
		mutation.mutate({ chatID, change })
	}

	const selectACallOut = callOut => {
		setCallOutsDoubleBoxes({ ...callOutsDoubleBoxes, selected: callOut === 'callOutsDoubleBoxes' ? true : false })
		setCircleCallOuts({ ...circleCallOuts, selected: callOut === 'circleCallOuts' ? true : false })
		setDoubleCircleCallOuts({ ...doubleCircleCallOuts, selected: callOut === 'doubleCircleCallOuts' ? true : false })
		setRadiusCallOuts({ ...radiusCallOuts, selected: callOut === 'radiusCallOuts' ? true : false })

	}

	const getSelectedIcon = () => {
		if (callOutsDoubleBoxes.selected) return 'callOutsDoubleBoxes'
		if (circleCallOuts.selected) return 'circleCallOuts'
		if (doubleCircleCallOuts.selected) return 'doubleCircleCallOuts'
		if (radiusCallOuts.selected) return 'radiusCallOuts'
		return 'radiusCallOuts'
	}

	const iconSelect = icon => {
		selectACallOut(icon)
		chatUpdate({
			...changeTemplate, config: {
				chatIcon: {
					iconImage: {
						public_id: icons[icon].public_id,
						secure_url: icons[icon].url,
						shape: icons[icon.shape] = shape
					}
				}
			}
		})
	}

	const setColors = {
		color1: (color) => {
			setColor1(color)
			chatUpdate({ ...changeTemplate, styles: { chatIcon: { iconColor1: color, iconColor2: color2, iconColor3: color3 } } })
		},
		color2: (color) => {
			setColor2(color)
			chatUpdate({ ...changeTemplate, styles: { chatIcon: { iconColor1: color1, iconColor2: color, iconColor3: color3 } } })
		},
		color3: (color) => {
			setColor3(color)
			chatUpdate({ ...changeTemplate, styles: { chatIcon: { iconColor1: color1, iconColor2: color2, iconColor3: color } } })
		},
		backgound: (color) => {
			setBackground(color)
			chatUpdate({ ...changeTemplate, styles: { chatIcon: { backgroundColor: color } } })
		}
	}


	useEffect(() => {
		setCallOutsDoubleBoxes({ ...callOutsDoubleBoxes, color1: { color: color1 }, color2: { color: color3 }, color3: { color: color2 } })
		setCircleCallOuts({ ...circleCallOuts, color1: { color: color1 }, color2: { color: color2 }, color3: { color: color3 } })
		setDoubleCircleCallOuts({ ...doubleCircleCallOuts, color1: { color: color1 }, color2: { color: color3 }, color3: { color: color2 } })
		setRadiusCallOuts({ ...radiusCallOuts, color1: { color: color1 }, color2: { color: color2 }, color3: { color: color3 } })
	}, [color1, color2, color3])

	useEffect(() => {
		if (isSuccess) {
			chat.config.chatIcon.iconImage.public_id === icons.callOutsDoubleBoxes.public_id && selectACallOut('callOutsDoubleBoxes')
			chat.config.chatIcon.iconImage.public_id === icons.circleCallOuts.public_id && selectACallOut('circleCallOuts')
			chat.config.chatIcon.iconImage.public_id === icons.doubleCircleCallOuts.public_id && selectACallOut('doubleCircleCallOuts')
			chat.config.chatIcon.iconImage.public_id === icons.radiusCallOuts.public_id && selectACallOut('radiusCallOuts')
			setIconType(chat.config.chatIcon.iconType === 'iconImage' ? 'predefined' : 'custom')
			setColor1(chat.styles.chatIcon.iconColor1)
			setColor2(chat.styles.chatIcon.iconColor2)
			setColor3(chat.styles.chatIcon.iconColor3)
			setBackground(chat.styles.chatIcon.backgroundColor)
			setCustomAvatarURL(chat.config.chatIcon.avatar.secure_url)
			setShape(chat.config.chatIcon.avatar.shape)
		}
	}, [chat])

	const changeTemplate = {
		styles: {
			chatIcon: {
				backgroundColor: background,
				iconColor1: color1,
				iconColor2: color2,
				iconColor3: color3,
			}
		},
		config: {
			chatIcon: {
				iconImage: {
					public_id: icons[getSelectedIcon()].public_id,
					secure_url: icons[getSelectedIcon()].url,
					shape: shape
				}
			}
		}
	}

	const handleSwitchChange = event => {
		setIconType(event.target.value)
		let type = event.target.value === 'predefined' ? 'iconImage' : 'avatar'
		chatUpdate({ ...changeTemplate, config: { chatIcon: { iconType: type } } })
	}

	const handleShape = shape => {
		setShape(shape)
		chatUpdate({
			...changeTemplate, config: {
				chatIcon: {
					iconImage: {
						shape: shape
					},
					avatar: {
						shape: shape
					}
				}
			}
		})
	}

	return (
		<div>
			<HeaderConfig title={t('floatingIcon')} />
			{isLoading ? <div style={{ 'height': '300px' }}><Loader /></div> :
				<>
					<div>
						<Content>
							<RadiosContent>
								<RadioGroup aria-label="position" name="apiVersionControl" value={iconType} onChange={handleSwitchChange} row>
									<FormControlLabel
										value="predefined"
										control={<Radio color="primary" />}
										label={tRoot('predefined')}
										labelPlacement="end"
									/>
									<FormControlLabel
										value="custom"
										control={<Radio color="primary" />}
										label={tRoot('customImage')}
										labelPlacement="end"
									/>
								</RadioGroup>
							</RadiosContent>
						</Content>
						{iconType === 'predefined' ?
							<Content>
								<Label>{t('icon')}</Label>
								<IconsContent>
									<CallOutsDoubleBoxes
										palette={callOutsDoubleBoxes}
										name="callOutsDoubleBoxes"
										active={callOutsDoubleBoxes.selected}
										func={iconSelect}
									/>
									<CircleCallOuts
										name="circleCallOuts"
										palette={circleCallOuts}
										active={circleCallOuts.selected}
										func={iconSelect}
									/>
									<DoubleCircleCallOuts
										name="doubleCircleCallOuts"
										palette={doubleCircleCallOuts}
										active={doubleCircleCallOuts.selected}
										func={iconSelect}
									/>
									<RadiusCallOuts
										name="radiusCallOuts"
										palette={radiusCallOuts}
										active={radiusCallOuts.selected}
										func={iconSelect}
									/>
								</IconsContent>

								<ContentForm>
									<Label style={{ gridColumn: "1 / span 2" }}>{t('editColor')}</Label>
									<ColorPicker
										color={color1}
										label='Color 1'
										colorPicked={setColors.color1}
									/>
									<ColorPicker
										color={color2}
										label='Color 2'
										colorPicked={setColors.color2}
									/>

									{circleCallOuts.selected || radiusCallOuts.selected ? '' :
										<ColorPicker
											color={color3}
											label='Color 3'
											colorPicked={setColors.color3}
										/>
									}
								</ContentForm>
							</Content>
							:
							<DropCropUpload
								customAvatarURL={customAvatarURL}
								setCustomAvatarURL={setCustomAvatarURL}
								setErrorMessage={setErrorMessage}
								chatID={chatID}
								imgWidth="40"
								imgHeight="40"
							/>
						}
						<Label>{tRoot('background')}</Label>
						<ContentForm>
							<ColorPicker
								color={background}
								label='Color'
								colorPicked={setColors.backgound}
							/>
						</ContentForm>
						<Label>{tRoot('shape')}</Label>
						<ContentForm>
							<ShapeContent>
								<SquareButton
									active={shape === 'square'}
									handleSwitchChange={handleShape}
								/>
								<CircleButton
									active={shape === 'circle'}
									handleSwitchChange={handleShape}
								/>
								<DiamondButton
									active={shape === 'diamond'}
									handleSwitchChange={handleShape}
								/>
							</ShapeContent>
						</ContentForm>
					</div>
					<Feedback seccion="Floating-Icon" previewMode />
				</>
			}
		</div>
	)
}