

import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SketchPicker } from 'react-color';
import i18n from '../../../../../../i18n/i18n'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Feedback from '../../../../../../components/feedback'


const tRoot = i18n()

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
    
`
const Column = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    
`
const Column2 = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    
`
const Button = styled.button`
    border:1px solid rgba(0,0,0,0.2);
    background: transparent !important;
    cursor:pointer;
    width:75px;
    padding:15px;
    &:hover {
        border: 1px solid #ef7e29 ;
      }

`


const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`


export const styles = theme => ({
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',
        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        width: '90%',
        marginBottom: '25px',
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },
    notchedOutline: {}

});

export const view = (
    { classes },
    { showChooseColor, color, tittle, font, fontSize, textAlignment },
    handleChooseColor,
    handleColorClick,
    handleTittleChange,
    handleFont,
    handleFontSize,
    handleTextAlignment,
    handleTittleLostFocus,
    handleCloseColorShow) => (
        <div>
            <HeaderConfig title={tRoot('name')} />
            <Content>
                <FormControl className={classes.margin}>
                    <InputLabel

                        classes={{
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        }}>
                        {tRoot('name')}
                </InputLabel>
                    <Input
                        id="name"
                        onChange={handleTittleChange}
                        value={tittle}
                        onBlur={handleTittleLostFocus}
                        classes={{
                            underline: classes.cssUnderline,
                        }} />

                </FormControl>
                <ClickAwayListener onClickAway={handleCloseColorShow}>
                <FormControl className={classes.margin}>
                    <InputLabel
                        classes={{
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        }}>{tRoot('color')}
                </InputLabel>
                    <Input
                        id="background"
                        
                        value={color}
                        classes={{
                            underline: classes.cssUnderline,
                        }} />
                    <Color style={{ background: color }} onClick={handleChooseColor} />
                    {showChooseColor ? <SketchPicker
                        onChangeComplete={handleColorClick}
                        color /> : ""}
                </FormControl>
                </ClickAwayListener>
                <Column>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="fontInput"> {tRoot('font')}</InputLabel>
                        <Select
                            value={font}
                            onChange={handleFont}
                            inputProps={{
                                name: 'fontInput',
                                id: 'fontInput',
                            }}
                            className={classes.cssUnderline}>
                            <MenuItem value="Arial">Arial</MenuItem>
                            <MenuItem value="Arial Black">Arial Black</MenuItem>
                            <MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
                            <MenuItem value="Courier New">Courier New</MenuItem>
                            <MenuItem value="Impact">Impact</MenuItem>
                            <MenuItem value="Lucida Console">Lucida Console</MenuItem>
                            <MenuItem value="Lucida Sans Unicode">Lucida Sans Unicode</MenuItem>
                            <MenuItem value="Tahoma">Tahoma</MenuItem>
                            <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
                            <MenuItem value="Verdana">Verdana</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="fontSizeInput" > {tRoot('size')}</InputLabel>
                        <Select
                            value={fontSize}
                            onChange={handleFontSize}
                            inputProps={{
                                name: 'fontSizeInput',
                                id: 'fontSizeInput',
                            }}
                            className={classes.cssUnderline}>
                            <MenuItem value={"12px"}>12</MenuItem>
                            <MenuItem value={"13px"}>13</MenuItem>
                            <MenuItem value={"14px"}>14</MenuItem>
                            <MenuItem value={"15px"}>15</MenuItem>
                            <MenuItem value={"17px"}>17</MenuItem>
                            <MenuItem value={"20px"}>20</MenuItem>
                            <MenuItem value={"24px"}>24</MenuItem>
                            <MenuItem value={"32px"}>32</MenuItem>
                        </Select>
                    </FormControl>
                </Column>
                <Column2>

                    <Button onClick={e => handleTextAlignment('LEFT')} style={textAlignment==='LEFT' ? { border: "1px solid #ef7e29" } : {}}>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="30px" height="30px" viewBox="0 0 311.809 198.43" enableBackground="new 0 0 311.809 198.43"
                            space="preserve">
                            <g opacity="0.5">
                                <rect x="57.119" y="170.083" width="197.571" height="28.347" />
                                <rect x="57.119" y="113.389" width="155.48" height="28.347" />
                                <rect x="57.119" y="56.695" width="197.571" height="28.347" />
                                <rect x="57.119" y="0" width="112.53" height="28.347" />
                            </g>
                        </svg>
                    </Button>
                    <Button onClick={e => handleTextAlignment('CENTER')} style={textAlignment==='CENTER' ? { border: "1px solid #ef7e29" } : {}}>
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width="30px" height="30px" viewBox="0 0 311.809 198.43" enableBackground="new 0 0 311.809 198.43"
                            space="preserve">
                            <g opacity="0.5">
                                <rect x="70.863" y="170.083" width="170.083" height="28.347" />
                                <rect x="57.119" y="113.389" width="197.571" height="28.347" />
                                <rect x="99.21" y="57.553" width="113.389" height="28.348" />
                                <rect x="57.119" y="0" width="197.571" height="28.347" />
                            </g>
                        </svg>
                    </Button>
                    <Button onClick={e => handleTextAlignment('RIGHT')} style={textAlignment==='RIGHT' ? { border: "1px solid #ef7e29" } : {}}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 311.809 198.43" enableBackground="new 0 0 311.809 198.43" space="preserve">
                            <g opacity="0.5">
                                <rect x="56" y="170.083" width="199.43" height="28.347" />
                                <rect x="98.486" y="113.389" width="156.944" height="28.347" />
                                <rect x="56" y="56.695" width="199.43" height="28.347" />
                                <rect x="141.841" y="0" width="113.589" height="28.347" />
                            </g>
                        </svg>
                    </Button>
                </Column2>
                <Feedback seccion="Header-Name" previewMode/>
            </Content>
        </div>
    )
