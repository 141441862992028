import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width: 75px;
    height: 75px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
   
    border-radius: 50%;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
  }
  & svg{
    border-bottom-left-radius: 43%;
    border-bottom-right-radius: 50%;
    margin-top: 3px;
  }
`


export  const  ButtonAvatarUserBlue = ({func,avatarId}) => {
    return(
      <Button  onClick= {e => func(avatarId)}>   
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="125px" height="125px" viewBox=" 0  0 270 285" enableBackground="new  0  0 270 285" space="preserve">
        <g id="User">
            <g>
                <path fill="#324A5E" d="M32.691,91.109c9.576-4.785,14.886-7.637,17.332-9.949l5.399-5.105l-4.688-5.758
                    c-7.384-9.07-11.623-19.09-11.623-27.484c0-6.473,0-13.805,3.063-19.875C45.842,15.68,53.179,12,64.002,12
                    c10.814,0,18.159,3.68,21.824,10.934c3.063,6.074,3.063,13.406,3.063,19.879c0,8.391-4.234,18.41-11.627,27.484l-4.689,5.762
                    l5.4,5.104c2.445,2.311,7.752,5.158,17.332,9.947c8.971,4.484,15.072,7.646,17.987,10.563c5.138,5.138,6.634,14.75,6.704,19.621
                    l8.003-0.127c-0.043-2.729-0.681-16.78-9.049-25.155c-3.778-3.777-10.101-7.074-20.066-12.059
                    c-5.024-2.512-13.386-6.691-15.413-8.605c8.489-10.434,13.416-22.219,13.416-32.535c0-7,0-15.711-3.918-23.48
                    C89.438,12.336,81.544,4,64.002,4c-17.546,0-25.439,8.336-28.967,15.332c-3.923,7.77-3.923,16.48-3.923,23.48
                    c0,10.32,4.923,22.102,13.417,32.536c-2.032,1.918-10.393,6.098-15.417,8.604c-9.963,4.983-16.285,8.28-20.066,12.06
                    c-8.369,8.375-9.002,22.426-9.045,25.159c0,0,7.996,0.28,7.997,0.122c0.023-1.34,0.391-13.305,6.705-19.621
                    C17.618,98.758,23.72,95.598,32.691,91.109z"/>
            </g>
        </g>
        </svg>
      </Button>   
    )
}