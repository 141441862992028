import React from 'react'
import styled from 'styled-components'
import {selectedCallOut,unSelectedCallOut} from '../styles'

const Button = styled.button`
    border:0px;
    background: transparent !important;
    cursor:pointer;
    width:75px;
`
export  const CircleCallOuts = ({active, name, func,palette}) => {
    return(
        <Button style={active ? selectedCallOut : unSelectedCallOut }
            onClick={e => func(name)}>  
                        <svg id="iconFloat4" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58" space="preserve" >
							<g>
								<path className="svg2" fill={palette.color1.color} d="M34.064,54.07c-0.539-0.12-0.879-0.654-0.76-1.193c0.121-0.539,0.663-0.874,1.193-0.759l7.252,1.614
									c0.003-0.002,0.007-0.003,0.01-0.005l0.171,0.045l0.326,0.073c0.027,0.006,0.047,0.024,0.073,0.032L58,58l-4.988-14.963
									C55.543,38.78,57,33.812,57,28.5C57,12.76,44.24,0,28.5,0S0,12.76,0,28.5S12.76,57,28.5,57c3.603,0,7.048-0.673,10.221-1.894
									L34.064,54.07z"></path>
								<circle className="svg3" fill={palette.color2.color}  cx="14.773" cy="29" r="3"></circle>
								<circle className="svg3" fill={palette.color2.color}  cx="27.773" cy="29" r="3"></circle>
								<circle className="svg3" fill={palette.color2.color} cx="40.773" cy="29" r="3"></circle>
							</g>
							</svg>
                    </Button>
    )
}