import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import styled from 'styled-components'
import { ButtonUpload } from './ImageButtons/buttonUpload';
import { ButtonAvatarHumanBlue } from './ImageButtons/buttonAvatarHumanBlue';
import { ButtonAvatarMenBlue } from './ImageButtons/buttonAvatarMenBlue';
import { ButtonAvatarHumanWhite } from './ImageButtons/buttonAvatarHumanWhite';
import { ButtonAvatarMenWhite } from './ImageButtons/buttonAvatarMenWhite';
import { ButtonAvatarMen } from './ImageButtons/buttonAvatarMen';
import { ButtonAvatarHuman } from './ImageButtons/buttonAvatarHuman';
import { ButtonAvatarUserIconBlue } from './ImageButtons/buttonAvatarUserIconBlue'
import { ButtonAvatarUserBlue } from './ImageButtons/buttonAvatarUserBlue';
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'

const tRoot = i18n()

const Content = styled.div`
    display: grid;
    text-align:left;
    margin-bottom:25px;
`
const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align:left;
    margin-bottom:25px;
`
const ContentGridImages = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align:left;
    margin-bottom:25px;
`
const ContentAvatar = styled.div`
    display: grid;
    grid-template-columns: 100px 100px;
    text-align:left;
`

const Label = styled.label`
    text-align:left;
    font-size:18px;
`



export const styles = theme => ({})

//const URL_cloudinary="https://res.cloudinary.com/blahbox/image/upload/"

const URL_cloudinary = "https://res.cloudinary.com/blahbox/image/upload/predefinedAvatars/"

//quedan fuera por ahora porque no tengo los svg en cloudinary (estas lineas estaban en la 122 y 123)
//<ButtonAvatarUserIconWhite func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarUserIconWhite.svg'} />
//<ButtonAvatarUserWhite func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarUserIconWhite.svg'} />

export const view = ({ classes }, { finalImage, avatarUrl, customAvatar, processResult }, handleAvatar, handleForm, handleShowAvatar, cleanMessages,errorMsg) => (
    <div>
        <HeaderConfig title={tRoot('image')} />
        <Content>
            <ContentGrid>
                <Label >{tRoot('predefined')}</Label>

                <Label >{tRoot('upload')}</Label>
             </ContentGrid>
             <ContentGridImages>
                <ContentAvatar>
                    {finalImage ?
                    <ButtonAvatarMenBlue func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarMenBlue.svg'} /> : ''}
                    <ButtonAvatarHumanBlue func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarHumanBlue.svg'} />
                    <ButtonAvatarHumanWhite func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarHumanWhite.svg'} />
                    <ButtonAvatarMenWhite func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarMenWhite.svg'} />
                    <ButtonAvatarMen func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarMen.svg'} />
                    <ButtonAvatarHuman func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarHuman.svg'} />
                    <ButtonAvatarUserIconBlue func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarUserIconBlue.svg'} />
                    <ButtonAvatarUserBlue func={handleAvatar} avatarId={URL_cloudinary + 'ButtonAvatarUserBlue.svg'} />
                   
                </ContentAvatar>
                <ButtonUpload func={handleShowAvatar}></ButtonUpload>
                </ContentGridImages>
                <Feedback seccion="Header-Avatar" previewMode/>
        </Content>
        {errorMsg()}
    </div>
)

