
// see: https://github.com/StephanHoyer/translate.js
import translate from 'translate.js'

let t = null
let months = null
let lang = null


/**
 * Example of use:
 * 
 * import i18n from './i18n'
 * 
 * i18n.load({
 * 	sayHello: 'Hello {user}!',
 * 	form: {
 * 		title: 'My form',
 * 		inputPlaceholder: 'type your user'
 * 	}
 * })
 * 
 * const t = i18n()
 * t('sayHello', { user: 'John' })
 * // => 'Hello John'
 * 
 * 
 * 
 * const t = i18n({
 * 	sayHello: 'Hello {user}!',
 * 	form: {
 * 		title: 'My form',
 * 		inputPlaceholder: 'type your user'
 * 	}
 * })
 * 
 * 
 * t('sayHello', { user: 'John' }) 
 * // => 'Hello John'
 * 
 * const t2 = i18n('form') // hold the key form
 * t2('title')  // use 'title' as subkey of 'form'
 * // => 'My form'
 * 
 * t2.root('sayHello', { user: 'John' }) // unlock the key, use the root as context
 * // => 'Hello John'
 * 
 * 
 */


/**
 * build a translator for the translations loaded, fixed to root of messages
 * @param {string | null} key 
 */
const i18n = function(key){

	const translations = (...args) => {
		return key ? t(key, ...args) : t(...args)
	}

	translations.lang = lang

	/**
	 * Return the key: values map of the translations
	 */
	translations.keys = () => {
		return key ? t.keys[key] : t.keys
	}

	translations.dateFormat = () => t.keys['dateFormat']

	translations.monthName = number => months[number]
	translations.monthNames = () => months

	/**
	 * Translation function without use the key
	 */
	translations.root = t

	/**
	 * new translator, same translation different key fixed
	 * @param {string} key
	 */
	translations.of = i18n

	return translations
}


i18n.load = function(messages, langToLoad){
	t = translate(messages) // global
	months = (t.keys['monthNames'] || '').split(',') // global
	lang = langToLoad
}

i18n.isLoaded = () => {
	return t !== null
}


export default i18n