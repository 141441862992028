export const styleSelected = {
    backgroundColor: '#3f566a',
    boxShadow: '0 0 20px rgba(0,0,0,0.2)',

}
export const unSelected ={
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.2)',
}

