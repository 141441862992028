import React, { useState, useEffect, useRef } from 'react'

import {
    Content,
    RadiosContent,
    RadioGroup,
    FormControlLabel,
    Radio
} from './styled'
import { getChat, chatUpdate } from '../../../hooksActionCreators'
import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'
import Loader from '../../../../../../components/LinearLoader/linearLoader'
import DropCropUpload from '../../../../../../components/DropCropSaveImage/DropCropSaveImage'
import { HeaderName } from '../Name'

const tRoot = i18n()

export default function HeaderImage() {
    const chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id
    const isInitialMount = useRef(true)
    const [loader, setLoader] = useState(false)
    const [avatarType, setAvatarType] = useState('')
    const [customAvatarURL, setCustomAvatarURL] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const openLoader = () => setLoader(true)
    const closeLoader = () => setLoader(false)

    const change = {
        config: {
            chatTop: {
                avatarType: avatarType
            }
        }
    }


    useEffect(() => {
        const fetchChatData = async () => {
            isInitialMount.current = true
            openLoader()
            let chat = await getChat(chatID)
            setAvatarType(chat.config.chatTop.avatarType)
            setCustomAvatarURL(chat.config.chatTop.avatar.secure_url)
            closeLoader()
            isInitialMount.current = false
        }
        fetchChatData()
    }, [])



    const dataUpdate = (change) => {
        try {
            chatUpdate(chatID, change)
        } catch (error) {
            console.log('error :', error);
        }
    }

    const handleSwitchChange = event => {
        setAvatarType(event.target.value)
    }

    useEffect(() => {
        !isInitialMount.current && dataUpdate(change)
    }, [avatarType])

    return (
        <div>
            <HeaderConfig title={tRoot('image')} />
            {loader ? <div style={{ 'height': '300px' }}><Loader /></div> :
                <Content>
                    <RadiosContent>
                        <RadioGroup aria-label="position" name="apiVersionControl" value={avatarType} onChange={handleSwitchChange} row>
                            <FormControlLabel
                                value="image"
                                control={<Radio color="primary" />}
                                label="Image"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="text"
                                control={<Radio color="primary" />}
                                label="Text"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </RadiosContent>
                    <div>
                        {avatarType === 'image' ?
                            <DropCropUpload
                                customAvatarURL={customAvatarURL}
                                setCustomAvatarURL={setCustomAvatarURL}
                                setErrorMessage={setErrorMessage}
                                chatID={chatID}
                                imgWidth="200"
                                imgHeight="80"
                            />
                            :
                            <HeaderName />
                        }
                    </div>
                    <Feedback seccion="Header-Image-Text" previewMode />
                </Content>
            }
        </div>
    )
}

