import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactGA from 'react-ga'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router-dom';

import i18n from '../../../src/i18n/i18n'
import { sendFeedback } from "../../containers/Dashboard/ChatSettings/hooksActionCreators"

const tRoot = i18n()

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        padding: 'initial'

    },

    divCenter: {
        'text-align': 'center',
        'padding-top': '10px'
    },
    dialogText: {
        'padding-top': '20px'
    },
    buttonBlue: {
        margin: theme.spacing(1),
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    },
    margin: {
        margin: 0,
        top: 'auto',
        right: 450,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        },
        'z-index': 99
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    btnSelected: {
        color: '#fff',
        backgroundColor: '#2196F3'
    },

}));


const Feedback = (props) => {
    const [open, setOpen] = useState(false)
    const feedbackModalInitial = {
        type: '',
        comments: '',
        sended: false
    }
    const [feedbackModal, setFeedbackModal] = useState(feedbackModalInitial)

    const chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id

    const toggle = () => setOpen(!open)

    const handleFeedbackType = feedbackType => setFeedbackModal({ ...feedbackModal, type: feedbackType })

    const handleComments = e => setFeedbackModal({ ...feedbackModal, comments: e.target.value })

    const handleCancel = () => {
        toggle()
        setFeedbackModal(feedbackModalInitial)
    }

    const location = useLocation();

    const handleSend = async () => {

        var feedback = {}
        feedback["type"] = feedbackModal.type
        feedback["comments"] = feedbackModal.comments
        feedback["url"] = location.pathname
        feedback["seccion"] = props.seccion
        var fb = {}
        fb["feedback"] = feedback

        sendFeedback(chatID, fb).then(result => {

            setFeedbackModal({ ...feedbackModal, sended: true })

            ReactGA.event({
                category: 'Feedback',
                action: 'New Feedback'
            }, ['backTrackerV2', 'backTrackerV3'])

        }).catch(err => console.log(err))

    }

    const classes = useStyles();
    return (
        <div>
            <Dialog open={open} onClose={toggle} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{tRoot('feedback')}</DialogTitle>
                {!feedbackModal.sended ?
                    <Container fixed>
                        <DialogContentText style={{ width: '500px' }}>
                            {tRoot('feedbackText')}
                        </DialogContentText>
                        <ButtonGroup color="primary" fullWidth aria-label="outlined primary button group">
                            <Button onClick={() => handleFeedbackType('Bug')} className={feedbackModal.type === 'Bug' ? classes.btnSelected : ''}>{tRoot('Bug')}</Button>
                            <Button onClick={() => handleFeedbackType('Improvement')} className={feedbackModal.type === 'Improvement' ? classes.btnSelected : ''}>{tRoot('Improvement')}</Button>
                            <Button onClick={() => handleFeedbackType('Feature')} className={feedbackModal.type === 'Feature' ? classes.btnSelected : ''}>{tRoot('Feature')}</Button>
                        </ButtonGroup>
                        <DialogContent classes={{ root: classes.root }}>
                            <FormControl component="fieldset">
                                <TextField
                                    error={true}
                                    autoFocus
                                    margin="normal"
                                    id="comments"
                                    label={tRoot('yourComments')}
                                    type="text"
                                    onChange={handleComments}
                                    multiline
                                    rows="6"
                                    variant="outlined"
                                    style={{ width: '500px' }}
                                    value={feedbackModal.comments}
                                />
                                <br />
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">
                                {tRoot('cancel')}
                            </Button>
                            <Button onClick={handleSend} color="primary">
                                {tRoot('send')}
                            </Button>
                        </DialogActions>
                    </Container>
                    :
                    <Container fixed>
                        <DialogContentText>
                            {tRoot('thanksForYourFeedback')}
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">
                                {tRoot('close')}
                            </Button>
                        </DialogActions>
                    </Container>

                }
            </Dialog>

            <Fab
                variant="extended"
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
                style={!props.previewMode ? {right: '20px'} : null}
                onClick={toggle}
            >
                <NavigationIcon className={classes.extendedIcon} />
                {tRoot('feedback')}
            </Fab>
        </div>
    )
}

export default Feedback