import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from 'react-bootstrap/Alert';
import { makeStyles } from '@material-ui/core/styles';
import AlertMaterial from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom'

import { invite } from '../hooksActionCreators'
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from '../../../../i18n/i18n'
import ReactGA from 'react-ga'

const tRoot = i18n()

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    divCenter: {
        'text-align': 'center',
        'padding-top': '10px'
    },
    dialogText: {
        'padding-top': '20px'
    },
    buttonBlue: {
        margin: theme.spacing(1),
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    },
}));

const modalNewUser = ({ open, hide, chatID, fetchData }) => {
    const classes = useStyles()
    const history = useHistory()

    var newUserData = {
        email: '',
        role: 'editor',
        emailValid: true,
        existing: false
    }

    const [newUser, setnewUser] = useState(newUserData);

    const handleSend = async () => {

        if (!validateEmail(newUser.email)) {
            setnewUser({ ...newUser, emailValid: validateEmail(newUser.email) })
            return
        }

        var r = await invite(newUser, chatID)

        ReactGA.event({
            category: 'Chat',
            action: 'User Invited'
        }, ['backTrackerV2', 'backTrackerV3'])


        if (r.status === 409) {
            setnewUser({ ...newUser, existing: true })
        } else {
            fetchData()
            hide()
        }
    }

    const handleUpgrade = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Upgrade Invite Button'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push('/dashboard/chat-settings/subscription');
    }

    const handleRoleChange = (event) => setnewUser({ ...newUser, role: event.target.value })

    const handlerEmail = (event) => setnewUser({ ...newUser, email: event.target.value, emailValid: validateEmail(newUser.email) })


    const alertMsg = (msg) => {
        return (
            <Alert key='idx' variant='danger'>
                {msg}
            </Alert>
        )
    }

    const handleClose = () => {
        setnewUser(newUserData)
        hide()
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }

    const plan = JSON.parse(localStorage.getItem("plan"))

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{tRoot('inviteMember')}</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    {plan.ProductCode === 'freeM' ?
                    <div className={classes.root}>
                        <AlertMaterial severity="warning">
                            <div> {tRoot('freePlanMsg')}</div>
                            <div className={classes.divCenter}>
                                <Button onClick={handleUpgrade} variant="contained" color="primary" className={classes.buttonBlue}>
                                {tRoot('upgrade')}
                                </Button>
                            </div>
                        </AlertMaterial>
                    </div>
                    : '' }
                    <DialogContentText className={classes.dialogText}>
                        {tRoot('inviteText')}
                    </DialogContentText>
                    <TextField
                        error={!newUser.emailValid}
                        autoFocus
                        margin="dense"
                        id="name"
                        onChange={handlerEmail}
                        label={tRoot('emailAddress')}
                        type="email"
                        helperText={newUser.emailValid ? '' : tRoot('badEmail')}
                        style={{ width: '350px' }}
                    />
                    <br />

                    <RadioGroup aria-label="role" name="role1" value={newUser.role} onChange={handleRoleChange}>
                        <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                        <FormControlLabel value="publisher" control={<Radio />} label={tRoot('publisher')} />
                        <FormControlLabel value="editor" control={<Radio />} label={tRoot('editor')} />
                    </RadioGroup>
                </FormControl>
                {newUser.existing ? alertMsg(tRoot('userExistError')) : ''}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {tRoot('cancel')}
                </Button>
                <Button onClick={handleSend} color="primary" disabled = {plan.ProductCode === 'freeM' ? true : false}>
                    {tRoot('send')}
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default modalNewUser