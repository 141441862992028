import React from 'react'
import {
    DiamondIcon,
    Button
} from './styled'

export const DiamondButton = ({ active, handleSwitchChange }) => {
    let opacityLevel = active ? 0.9 : 0.3
    return (
        <Button
            onClick={e => handleSwitchChange('diamond')}
        >
            <DiamondIcon style={{opacity: opacityLevel}}/>
        </Button>
    )
}