import styled from 'styled-components'
import ButtonS from '@material-ui/core/Button'

export const DropCropSave = styled.div`
    width: 100%;
`

export const CurrentImage = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
`

export const ImageLoaded = styled.div`
    margin-bottom: 15px;
`

export const Drop = styled.div`

`

export const Crop = styled.div`
    width: 100%;
`

export const UpLoadButton = styled(ButtonS)`
    && {
        margin: theme.spacing(1);
        margin-top: 15px;
        color: #fff;
        background-color: #2196F3;
        &:hover {
            background-color: #9044AD;
        }
    }
`
