import React from "react";
import { connect } from "react-redux";
import { validateChatCredentials, slackNotify } from "../../../../../rootActions";
import { chatService } from "../../../../../services/chat.service";
import TextField from '@material-ui/core/TextField';
import engineIconWatson from "../../../../../assets/img/engine-icon-watson.png";
import engineIconDialogflow from "../../../../../assets/img/engine-icon-dialogflow.png";
import engineIconDialogflowCX from "../../../../../assets/img/engine-icon-dialogflowcx.png";
import engineIconAmazonLex from "../../../../../assets/img/engine-icon-amazonLex.png";
import engineIconCortana from "../../../../../assets/img/engine-icon-cortana.png";
import i18n from '../../../../../i18n/i18n'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import lang from '../../../../../i18n/lang';
import ReactGA from 'react-ga';


const tRoot = i18n()

class WatsonAssistant extends React.Component {

  state = {
    workspaceID: '',
    key: '',
    password: '',
    errorMessage: '',
    saveOKMsg: '',
    apiVersion: 'watson',
    fieldsV1: {},
    fieldsV2: {},
    isV1: false,
    url: '',
    apikey: '',
    assistant_id: ''
  }

  handler = {
    workspace: ev => {
      this.setState({ workspaceID: ev.target.value })
    },
    key: ev => {
      this.setState({ key: ev.target.value })
    },
    password: ev => {
      this.setState({ password: ev.target.value })
    },
    isV1: value => {
      this.setState({ isV1: value })
    },
    url: ev => {
      this.setState({ url: ev.target.value })
    },
    apikey: ev => {
      this.setState({ apikey: ev.target.value })
    },
    assistant_id: ev => {
      this.setState({ assistant_id: ev.target.value })
    }
  }

  handleCloseMsg = () => {
    this.setState({ errorMessage: '', saveOKMsg: '' })
  }

  errorMsg = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.errorMessage !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.errorMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  saveOK = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.saveOKMsg !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.saveOKMsg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/chat/nlp/watson", ['backTrackerV2', 'backTrackerV3'])

    this.props.closeLoader();
    if (!this.props.nlp) {
      this.setState({ isV1: false })
    } else {
      if (this.props.nlp.provider === 'watson') {
        this.setState({ isV1: true, workspaceID: this.props.nlp.workspaceid, key: this.props.nlp.key, password: this.props.nlp.password })
      } else {
        this.setState({ isV1: false, url: this.props.nlp.url, apikey: this.props.nlp.iam_apikey, assistant_id: this.props.nlp.assistant_id })
      }

    }

  }

 
  handleSubmit = e => {
    e.preventDefault();
    
    const jsonStructure = {};

    if (this.state.isV1) {
      jsonStructure.provider = "watson";
      jsonStructure.workspaceid = this.state.workspaceID
      jsonStructure.key = this.state.key
      jsonStructure.password = this.state.password
    } else {
      jsonStructure.provider = "watsonV2";
      jsonStructure.version = '2018-02-16'
      jsonStructure.url = this.state.url
      jsonStructure.iam_apikey = this.state.apikey
      jsonStructure.assistant_id = this.state.assistant_id

    }

    var newNLP = {
      wakeupWord: "",
      NLP: jsonStructure
    }

    this.props.openLoader();
    this.verifyCredentials(newNLP)
      .then(result => {
        ReactGA.event({
          category: 'Chat',
          action: 'Update NLP Watson'
        }, ['backTrackerV2', 'backTrackerV3'])
        this.updateChat(this.props.chatID, newNLP);
        this.setState({ saveOKMsg: tRoot('credentialSaveOKMsg') })
      }).catch(err => {

        this.props.closeLoader();
        ReactGA.event({
          category: 'Chat',
          action: 'UPDATE Watson Bad Credentials'
        }, ['backTrackerV2', 'backTrackerV3'])

        //slack notify
        newNLP.chatID = this.props.chatID
        slackNotify(JSON.stringify(newNLP))

        this.setState({ errorMessage: tRoot('badCredentialErrorMsg') })
      })
  };

  verifyCredentials = (credentials) => {
    return validateChatCredentials(credentials)
  }

  updateChat = (chatID, jsonStructure) => {
    chatService
      .updateChatConfig(chatID, jsonStructure)
      .then(data => {
        this.props.closeLoader();
      })
      .catch(err => {
        this.props.closeLoader();
      });
  };

  fieldsV2 = () => {
    return (
      <div>

        <TextField
          label="URL"
          value={this.state.url}
          onChange={this.handler.url}
          margin="normal"
          className="textfield"
        />

        <TextField
          label="APIKey"
          value={this.state.apikey}
          onChange={this.handler.apikey}
          margin="normal"
          className="textfield"
        />

        <TextField
          label="Assistant ID"
          value={this.state.assistant_id}
          onChange={this.handler.assistant_id}
          margin="normal"
          className="textfield"
        />

      </div>
    )
  }

  fieldsV1 = () => {
    return (
      <div>
        <TextField
          label="WorkspaceId"
          value={this.state.workspaceID}
          onChange={this.handler.workspace}
          margin="normal"
          className="textfield"
        />

        <TextField
          label="Username"
          value={this.state.key}
          onChange={this.handler.key}
          margin="normal"
          className="textfield"
        />


        <TextField
          label="Password"
          value={this.state.password}
          onChange={this.handler.password}
          margin="normal"
          className="textfield"
        />
      </div>
    )
  }

  linkToHelpV1 = () => {
    return(
      <a
      target="_blank"
      rel="noopener noreferrer"
      href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/agregar-credenciales-de-watson-assistant-v1': 'https://supporten.blahbox.net/portal/kb/articles/integrate-watson-assitant-v1'}
    >
     {tRoot('needMoreInformation')} 
    </a>
    )

  }

  linkToHelpV2 = () => {
    return(
      <a
      target="_blank"
      rel="noopener noreferrer"
      href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/integrar-watson-assistant-v2': 'https://supporten.blahbox.net/portal/kb/articles/integrate-watson-assitant-v2'}
    >
     {tRoot('needMoreInformation')} 
    </a>

    )
  }

  handleChange = event => {
    this.setState({ isV1: event.target.value === 'watson' ? true : false })
  }

  render() {
    return (
      <div className="engine-select">
        <div className="grid-container">
          <div className="inputs">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <RadioGroup aria-label="position" name="apiVersionControl" value={this.state.isV1 ? 'watson' : 'watsonV2'} onChange={this.handleChange} row>
                  <FormControlLabel
                    value="watson"
                    control={<Radio color="primary" />}
                    label="V1"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="watsonV2"
                    control={<Radio color="primary" />}
                    label="V2"
                    labelPlacement="end"
                  />
                </RadioGroup>

                {this.state.isV1 ? this.fieldsV1() : this.fieldsV2()}

                <div className="form-group more-info">
                  {this.state.isV1 ? this.linkToHelpV1() : this.linkToHelpV2()}
                </div>
                <div className="form-group">
                  <button className="button submit" type="submit">
                  {tRoot('go')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="selected-engine">
            <div className="shadow-border-box">
              <img src={engineIconWatson} alt="dialog flow" />
              <p>WATSON ASSISTANT</p>
            </div>
          </div>
          <div className="change">
            <h3 className="title">{tRoot('selectNLPEngine')}</h3>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("google")}
            >
              <img src={engineIconDialogflow} alt="dialogflow" />
              <p>DIALOGFLOW</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("dialogFlowCx")}
            >
              <img src={engineIconDialogflowCX} alt="dialogflowCX" />
              <p>DIALOGFLOW CX</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("aws")}
            >
              <img src={engineIconAmazonLex} alt="amazon lex" />
              <p>AMAZON LEX</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("microsoft")}
            >
              <img src={engineIconCortana} alt="Cortana" />
              <p>QnA Maker</p>
            </div>
          </div>
        </div>
        {this.errorMsg()}
        {this.saveOK()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state.baseData.currentChatConfig.NLP.provider.private_key)
  return {
    chatID: state.baseData.currentChatConfig._id,
    nlp: state.baseData.currentChatConfig.NLP
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader: () => dispatch({ type: 'OPEN_LOADER' }),
    closeLoader: () => dispatch({ type: 'CLOSE_LOADER' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatsonAssistant);

