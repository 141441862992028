import React from 'react'
import styled from 'styled-components'
import {styleSelected,styleUnSelected} from './style'

const ButtonShadow = styled.div`
cursor: pointer;
background-color: #8a95ad;
padding: 10px;
position: relative;
margin: 0 18px 0 0;
border: 4px solid #8a95ad;
width: 70px;
height: 40px;
margin-bottom: 15px;
border-radius:10px;
box-shadow: 0px 7px 10px rgba(0,0,0,0.6)
`




export  const  ShadowBottomBlack = ({handleShadow,selected}) => {
    return(
      <ButtonShadow  onClick = {e => { 
        window.chatbot.updateChatIconStyles({"boxShadow":"0px 7px 10px rgba(0,0,0,0.6)"})
        handleShadow('ShadowBottomBlack')
      }

      } style = {selected==='ShadowBottomBlack' ? styleSelected : styleUnSelected}/>
    )
}