import styled from 'styled-components'
import FormControlMaterial from '@material-ui/core/FormControl'
import InputLabelMaterial from '@material-ui/core/InputLabel'
import InputMaterial from '@material-ui/core/Input'
import MenuItemMaterial from '@material-ui/core/MenuItem'
import SelectMaterial from '@material-ui/core/Select'

import FormGroupMaterial from '@material-ui/core/FormGroup'
import FormControlLabelMaterial from '@material-ui/core/FormControlLabel'
import SwitchMaterial from '@material-ui/core/Switch'


export const FormGroup = styled(FormGroupMaterial)`

`

export const FormControlLabel = styled(FormControlLabelMaterial)`

`

export const Switch = styled(SwitchMaterial)`

`

export const Label = styled.label`
    display:grid;
    width:100%;
    color:#545454;
    font-size:18px;
    margin-bottom:10px;
`
export const Content = styled.div`
    display:grid;
    text-align:left;
`
export const ContentCallOuts = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-row-gap:15px;
    text-align:left;
    margin-bottom:25px;
`
export const ContentSwitch = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr  ;
    grid-row-gap:15px;
    text-align:left;
    margin-bottom:25px;
`
export const ContentForm = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    text-align:left;
    grid-gap:30px;
    margin-bottom:25px;
`

export const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`

export const FormControl = styled(FormControlMaterial)`

`

export const InputLabel = styled(InputLabelMaterial)`
    && {
        font-size: 18px;
        margin-bottom: 10px;
    }
`

export const Input = styled(InputMaterial)`
    && {
        margin-top: 15px;
        width: 90%;
        margin-bottom: 25px;
    }

`

export const Select = styled(SelectMaterial)`
    && {
        width: 90%;
        margin-bottom: 25px;
    }

`

export const MenuItem = styled(MenuItemMaterial)`
    && {
        margin-top: 10px;
    }

`