import axios from "axios";

export const chatService = {
  getChats,
  newChat,
  getChat,
  updateChatConfig,
  updateChatHeaderStyles,
  updateChatHeaderName,
  publishChat
};

const getToken = () => (localStorage.getItem("token"))
const BACKEND_API_URL = process.env.API_URL
const BACKEND_API_KEY = process.env.API_KEY



// axios response interceptor
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// axios request interceptor
axios.interceptors.request.use(
  config => {
    config.headers = {
      'Authorization': localStorage.getItem("token"),
      'x-core-client-id': BACKEND_API_KEY
    }
    
    //config.headers.Authorization = localStorage.getItem("token");
    
    return config;
  },
  error => Promise.reject(error)
);

function getChats() {
  return axios({
    method: "post",
    url: BACKEND_API_URL + "/chat/getUserChats"
  });
}

function getChat(chatID) {
  console.log(chatID);

  return axios
    .get(BACKEND_API_URL + "/chat/getChat/" + chatID)
    .then(response => {
      return response;
    })
    .catch(error => {
      if (error.response) {
        const errorStatus = error.response.status;
        console.log(errorStatus);
      }
    });
}

function newChat(chat) {
  // setting watson as the default provider
  //chat.provider = chat.provider ||  "watson";
  
  let header  = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-core-client-id': BACKEND_API_KEY,
        'Authorization': getToken()
    }
}
  return axios
    .post(BACKEND_API_URL + "/chat/newchat", chat,header)
    .then(response => {
      return response.data.chatStored.id;
    })
    .catch(error => {
      if (error.response) {
        const errorStatus = error.response.status;
        console.log(errorStatus);
      }
    });
}


function updateChatConfig(chatID, newConfig) {
  return axios
    .put(
      BACKEND_API_URL + "/chat/update/" + chatID,
      newConfig
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      if (error.response) {
        const errorStatus = error.response.status;
        console.log(errorStatus);
      }
    });
}

function updateChatHeaderStyles(chatID, newStyles) {
  // show spinner
  const spinner = document.querySelector(".spinner-container");
  if (spinner) {
    spinner.style.display = "flex";
  }
  const url = BACKEND_API_URL + "/chat/update/" + chatID;
  const postData = {
    styles: {
      chatTop: newStyles
    }
  };

  return axios
    .put(url, postData)
    .then(response => {
      // hide spinner
      const spinner = document.querySelector(".spinner-container");
      spinner.style.display = "none";

      console.log(response);
      return response;
    })
    .catch(error => {
      if (error.response) {
        const errorStatus = error.response.status;
        console.log(errorStatus);
      }
    });
}

function updateChatHeaderName(chatID, newName) {
  // show spinner
  const spinner = document.querySelector(".spinner-container");
  if (spinner) {
    spinner.style.display = "flex";
  }

  const url = BACKEND_API_URL + "/chat/update/" + chatID;
  const postData = {
    config: {
      chatTop: {
        name: newName
      }
    }
  };

  return axios
    .put(url, postData)
    .then(response => {
      // hide spinner
      const spinner = document.querySelector(".spinner-container");
      spinner.style.display = "none";
      console.log(response);
      return response;
    })
    .catch(error => {
      if (error.response) {
        const errorStatus = error.response.status;
        console.log(errorStatus);
      }
    });
}

function publishChat(chatID) {
  return axios
    .post(BACKEND_API_URL + "/chat/publish/" + chatID)
    .then(response => {
      console.log(response);
      return response;
    })
    .catch(error => {
      if (error.response) {
        const errorStatus = error.response.status;
        console.log(errorStatus);
      }
    });
}
