import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components'
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom'
import { truncate } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import i18n from '../../../i18n/i18n'
import Loader from '../../../components/LinearLoader/linearLoader'
import "./checkout.scss"


import { upgradeSubscription } from '../../Dashboard/ChatSettings/hooksActionCreators'

const tRoot = i18n()

const useStyles = makeStyles(() => ({
    paper: { minWidth: "680px" },
}));

const Content = styled.div`

    padding:10px 0 20px;
    text-align:left;
`

const Body = styled.div`
    width: 100%;
    background: #fff;
    padding-left: 0px;
    height: 100vh;
    overflow-y: scroll;
    
`



const Table = styled.div`
    display: block;
    width: 100%;
    text-align: left;
    font-size: 12px;
    border-radius: 4px;
    line-height: 18px;
    border: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 15px;
    max-width: 800px;
    float:left;

`
const TableHead = styled.div`
    background-color: #f4f4f4;
    padding: 15px;
    font-size: 14px;

`
const Success = styled.div`
    color: #81d883;
    padding: 15px;
    font-size: 14px;

`
const TableContent = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    padding:20px;
    padding-top: 5px;

`
const Label = styled.div`
    font-size: 13px;
    color: #999;
`
const LabelInput = styled.div`
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 20px;
`

const Note = styled.div`
    padding: 15px;
    font-size: 12px;
`
const getQueryParam = param => {
    var result = window.location.search.match(
        new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
    );

    return result ? result[3] : false;
}



const CheckoutSuccess = () => {
    const classes = useStyles();
    const history = useHistory()
    var appState = JSON.parse(localStorage.getItem('appState'))
    var chatID = appState.baseData.currentChatConfig._id
    const session_id = getQueryParam('session_id')

    const [loader, setLoader] = useState(truncate)
    const [subscription, setSubscription] = useState({})
    const [dialogTittle, setDialogTittle] = useState(tRoot('subscriptionProcessing'))

    const handleOK = () => history.push('/dashboard/projects')

    useEffect(() => {
        ReactGA.pageview("/chat/checkoutSuccess", ['backTrackerV2', 'backTrackerV3'])
        openLoader()

        checkoutSuccess()
    }, [])

    const checkoutSuccess = async () => {
        try {
            let result = await upgradeSubscription(chatID, session_id)
/*              let result = {
                subscription: {
                    CCNumber: "**** **** **** 4242",
                    created: "2020-08-13",
                    currency: "usd",
                    discount: "0.00",
                    expirationDate: "2021-08-13",
                    finalPrice: "288.00",
                    mode: "subscription",
                    paymentMethod: "Visa",
                    producName: "Standard Annual",
                    productCode: "standardA",
                    recurringInterval: "year",
                    recurringIntervalCount: 1,
                    startDate: "2020-08-13",
                    trialPeriodDays: null,
                    userPayEmail: "soystripe6@nueva.com",
                    userPayName: "Matias G"
                }
            }  */

            result.subscription.paymentMethod = capitalize(result.subscription.paymentMethod)
            setDialogTittle(tRoot('subscriptionType'))
            setSubscription({ ...result.subscription })
            
        } catch (error) {
            console.log('error :', error);
        }

        localStorage.removeItem("plan")

        closeLoader()
    }

    const openLoader = () => setLoader(true)
    const closeLoader = () => setLoader(false)

    const capitalize = (word) => {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    return (
        <div style={{ 'width': '100%' }}>
            <Dialog open={true} classes={{ paper: classes.paper }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogTittle}</DialogTitle>
                <DialogContent>
                    {loader ? <Loader /> :
                        <div>
                            <Table>
                                <TableHead>
                                    {tRoot('checkoutSummary')}
                                </TableHead>
                                <Success>
                                {subscription.userPayName ? subscription.userPayName + ', ' : '' } {tRoot('thankMessage')}
                                </Success>
                                <TableContent>
                                    <div>
                                        <Label>{tRoot('subscriptionType')}</Label>
                                        <LabelInput>{subscription.producName}</LabelInput>
                                    </div>
                                    <div>
                                        <Label>{tRoot('paymentMethod')}</Label>
                                        <LabelInput>{subscription.paymentMethod}</LabelInput>
                                    </div>
                                    <div>
                                        <Label>{tRoot('startDate')}</Label>
                                        <LabelInput>{subscription.startDate}</LabelInput>
                                    </div>
                                    <div>
                                        <Label>{tRoot('cardNumber')}</Label>
                                        <LabelInput>{subscription.CCNumber}</LabelInput>
                                    </div>
                                    <div>
                                        <Label>{tRoot('totalPrice')}</Label>
                                        <LabelInput>${subscription.finalPrice}</LabelInput>
                                    </div>
                                    <div>
                                        <Label>{tRoot('endtDate')}</Label>
                                        <LabelInput>{subscription.expirationDate}</LabelInput>
                                    </div>
                                </TableContent>
                                <Note> {tRoot('subscriptionWillRenewAutomatically')}</Note>
                            </Table>
                            <Table>
                                <Note> {tRoot('checkEmail')} ({subscription.userPayEmail})</Note>
                            </Table>
                        </div>
                    }

                </DialogContent>
                <DialogActions>

                    <Button color="primary" disabled={false} onClick={handleOK}>
                        {tRoot('ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Body>

                <Content>


                </Content>
            </Body>
        </div>


    );
};

export default CheckoutSuccess