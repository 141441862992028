
import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import styled from 'styled-components'
import i18n from '../../../../../../i18n/i18n'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Feedback from '../../../../../../components/feedback'

const tRoot = i18n()

const ContentButtons = styled.div`
    display: grid;
    grid-template-columns: 50px 50px 50px;
    grid-gap: 20px;
    padding:10px 0 20px;
`
const Content = styled.div`
    display: grid;
    text-align:left;
`
const Rectangle = styled.div`
    display: inline-block;
    width: 50px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;

    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`
const RectangleRadius = styled.div`
    display: inline-block;
    width: 50px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius:15px;
    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`
const Circle = styled.div`
    display: inline-block;
    width: 50px;
    border: 6px solid #8a95ad;
    height: 50px;
    background-color: #fff;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    border-radius:50px;
    &:hover {
        box-shadow:5px 5px 10px rgba(0,0,0,0.2);
      }
`

const selectedItem = {
  boxShadow: "5px 5px 10px rgba(0,0,0,0.2)",
}
export const styles = theme => ({})


export const view = (handleClick, handleActiveShadow, borderSelected, boxShadow) => {
  return (
    <div>
      <HeaderConfig title={tRoot('border')} />
      <Content>
        <label className="noInput">{tRoot('type')}</label>
        <ContentButtons >
          <Rectangle onClick={e => handleClick('RECTANGLE')} style={borderSelected === 'RECTANGLE' ? selectedItem : {}} />
          <RectangleRadius onClick={e => handleClick('RECTANGLERADIUS')} style={borderSelected === 'RECTANGLERADIUS' ? selectedItem : {}} />
          <Circle onClick={e => handleClick('CIRCLE')} style={borderSelected === 'CIRCLE' ? selectedItem : {}} />
        </ContentButtons>

        <FormGroup row>

          <FormControlLabel
            control={
              <Switch
                onChange={handleActiveShadow}
                color="primary"
                checked={boxShadow}
              />
            }
            label={tRoot('shadow')}
          />
        </FormGroup>
        <Feedback seccion="Header-Border" previewMode />
      </Content>
    </div>
  )
}
