import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width: 75px;
    height: 75px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
   
    border-radius: 50%;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
  }
  & svg{
    border-bottom-left-radius: 43%;
    border-bottom-right-radius: 50%;
    margin-top: 3px;
  }
`


export  const  ButtonAvatarHuman = ({func,avatarId}) => {
    return(
      <Button onClick = {e => func(avatarId)}>  
         <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="65px" height="65px" viewBox="0 0 198 198.43" enableBackground="new 0 0 198 198.43" space="preserve">
         <g>
            <path fill="#330C00" d="M101.472,42.862v152.557c0,0-86.603,8.974-86.603-33.243c0,0,23.262,2.933,18.5-16.471
                c-6.038-24.594-2.661-64.427,0-86.199C36.03,37.734,101.472,42.862,101.472,42.862z"/>
            <path fill="#330C00" d="M101.191,42.862v152.557c0,0,86.604,8.974,86.604-33.243c0,0-23.263,2.933-18.498-16.471
                c6.036-24.594,2.659-64.427,0-86.199C166.635,37.734,101.191,42.862,101.191,42.862z"/>
            <path fill="#00B497" d="M102.983,172.733v-22.228c0,0,35.281-1.069,35.281,20.284L102.983,172.733z"/>
            <path fill="#00B497" d="M99.961,172.733v-22.228c0,0-35.279-1.069-35.279,20.284L99.961,172.733z"/>
            <g>
                <g>
                    <rect x="87.459" y="150.503" fill="#FDCC9B" width="28.029" height="31.392"/>
                    <path fill="#FCBC85" d="M87.459,154.201c0,0,11.789,7.508,28.029,5.77v-9.468H87.459V154.201L87.459,154.201z"/>
                    <ellipse fill="#FCBC85" cx="47.043" cy="101.831" rx="13.675" ry="15.602"/>
                    <ellipse fill="#FCBC85" cx="155.897" cy="101.831" rx="13.674" ry="15.602"/>
                    <path fill="#FDCC9B" d="M155.842,72.028c0-22.982-16.815-39.8-54.37-39.8c-37.557,0-54.373,16.819-54.373,39.8
                        c0,22.98-3.922,83.519,54.373,83.519C159.767,155.548,155.842,95.01,155.842,72.028z"/>
                    <g>
                        <g>
                            <ellipse fill="#3B2519" cx="74.987" cy="98.094" rx="5.887" ry="6.446"/>
                            <circle fill="#FFFFFF" cx="72.424" cy="95.246" r="1.76"/>
                        </g>
                        <path fill="#684B37" d="M65.575,84.196c2.782,1.065,7.25-3.623,17.482,0.456c1.864,0.743,2.978-6.358-7.987-6.358
                            C65.575,78.293,63.892,83.55,65.575,84.196z"/>
                        <g>
                            <ellipse fill="#3B2519" cx="128.685" cy="98.094" rx="5.882" ry="6.446"/>
                            <circle fill="#FFFFFF" cx="126.117" cy="95.248" r="1.761"/>
                        </g>
                        <path fill="#684B37" d="M137.369,84.196c-2.782,1.065-7.25-3.623-17.479,0.456c-1.866,0.743-2.978-6.358,7.986-6.358
                            C137.369,78.293,139.053,83.55,137.369,84.196z"/>
                    </g>
                    <path fill="#FCBC85" d="M101.472,120.767c-5.816,0-9.319-4.345-9.319-2.241c0,2.103,1.68,6.096,9.319,6.096
                        c7.638,0,9.319-3.992,9.319-6.096S107.287,120.767,101.472,120.767z"/>
                    <path fill="#FCBC85" d="M101.472,140.096c-2.013,0-3.224-1.504-3.224-0.775c0,0.728,0.581,2.108,3.224,2.108
                        s3.224-1.381,3.224-2.108C104.695,138.592,103.482,140.096,101.472,140.096z"/>
                    <path fill="#F7945E" d="M101.472,134.738c-9.219,0-14.772-3.753-14.772-2.803c0,0.951,2.667,4.696,14.772,4.696
                        c12.106,0,14.771-3.745,14.771-4.696C116.243,130.987,110.691,134.738,101.472,134.738z"/>
                </g>
                <circle fill="#F9AA8D" cx="67.421" cy="118.695" r="7.987"/>
                <circle fill="#F9AA8D" cx="136.508" cy="118.695" r="7.987"/>
            </g>
            <path fill="#8FD6E7" d="M101.472,163.117v35.313H40.095C40.095,184.138,69.242,163.117,101.472,163.117z"/>
            <path fill="#8FD6E7" d="M101.472,163.117v35.313h61.378C162.85,184.138,133.704,163.117,101.472,163.117z"/>
            <path fill="#F6B1CF" d="M101.472,196.832v-33.715c-8.979,0-17.688,1.668-25.623,4.33L101.472,196.832z"/>
            <path fill="#F6B1CF" d="M101.472,196.832v-33.715c8.978,0,17.688,1.668,25.623,4.33L101.472,196.832z"/>
            <path fill="#F283B4" d="M101.472,163.117c-6.529,0-12.915,0.894-18.973,2.387c0.863,5.246,4.404,16.009,18.973,16.009
                c14.569,0,18.11-10.763,18.973-16.009C114.386,164.009,108.002,163.117,101.472,163.117z"/>
            <path fill="#FDCC9B" d="M87.459,164.338c0,0,0,12.623,14.013,12.623c14.015,0,14.015-12.623,14.015-12.623
                S100.945,159.043,87.459,164.338z"/>
            <path fill="#3ABDAA" d="M80.026,165.75c0.418-2.728-16.322-0.718-16.188,6.981c0.141,7.695,18.498,13.618,23.543,16.421
                c5.045,2.805,14.013,9.276,14.013,9.276s1.683-6.502-6.166-11.521C87.383,181.895,79.465,169.395,80.026,165.75z"/>
            <path fill="#3ABDAA" d="M122.714,165.75c-0.421-2.728,16.321-0.718,16.185,6.981c-0.14,7.695-18.498,13.618-23.544,16.421
                c-5.044,2.805-14.012,9.276-14.012,9.276s-1.682-6.502,6.166-11.521C115.354,181.895,123.274,169.395,122.714,165.75z"/>
            <path fill="#401900" d="M101.472,1.373c-63.06,0-71.468,55.06-71.468,95.635c0,40.578,21.006,64.14,37.417,70.72
                c0,0-26.207-46.442-13.314-89.435c10.549-35.177,47.368-24.791,47.368-24.791s36.813-10.387,47.363,24.791
                c12.894,42.992-13.313,89.435-13.313,89.435c16.41-6.58,37.415-30.143,37.415-70.72C172.938,56.432,164.533,1.373,101.472,1.373z"
                />
            <path fill="#401900" d="M122.794,63.217c0,0-23.668,11.869-44.568,9.485c-20.899-2.383-28.93,19.287-27.653,33.626L43.597,74.41
                l12.612-25.253L92.153,30.66l33.964,1.568l16.105,13.987l13.675,17l9.616,11.194l-4.625,34.443l-9.79,11.777
                C151.098,120.631,159.957,76.437,122.794,63.217z"/>
        </g>
        </svg>
      </Button>   
    )
}