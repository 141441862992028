import { userErrorHandler } from '../helpers';
import { userSuccessHandler } from '../helpers';
import axios from 'axios';
const BACKEND_API_URL = process.env.API_URL
export const userService = {
  register,
  login,
  logout
};

// axios response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

// login user service function
function login(user) {
  axios.post(
    BACKEND_API_URL + '/user/login',
    user,
    {
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => {
      userSuccessHandler.login(response);
    })
    .catch(error => {
      if(error.response) {
        const errorStatus = error.response.status;
        userErrorHandler.login(errorStatus)
      }
    });
}

// logout user service function
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

// register user service function
function register(user) {
  axios.post(
    BACKEND_API_URL + '/user/register',
    user)
  .then(response => {
    userSuccessHandler.register(response);
  })
  .catch(error => {
    if(error.response) {
      const errorStatus = error.response.status;
      userErrorHandler.register(errorStatus)
    }
  });
}
