import React from 'react'
import styled from 'styled-components'
import HeadLine from '../../HeadLine/index'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'

const t = i18n("chatSettings")
const tRoot = i18n()


const Conteiner = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr; 
    grid-gap: 20px;
`

const ConteinerForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-gap: 20px;
`
export const styles = theme => ({
	selectEmpty: {
		marginTop: theme.spacing(2),
		textAlign: "left"
    },
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',
        textAlign: "left",
    
        '&$cssFocused': {
          color: '#333333',
          fontSize: '20px',
          marginBottom: '10px',
        },
      },
      cssFocused: {},
      cssUnderline: {
        textAlign: "left",
        '&:after': {
          borderBottomColor: '#ef7e29',
    
        },
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: '#ef7e29',
    
        },
      },
      notchedOutline: {}
}); 

export const view  = ({classes},{vertical,horizontal},{handleVertical,handleHorizontal}) =>  (
<Conteiner>
    <HeadLine title={t('positionTittle')} />
    <ConteinerForm>
   
    <FormControl>
        <InputLabel htmlFor="position">Horizontal</InputLabel>
        <Select 
            value={horizontal}
            onChange={handleHorizontal}
            inputProps={{
                name: 'positionHorizontal',
                id: 'positionHorizontal',
            }}
            className={classes.cssUnderline}
                        >
            <MenuItem value={'left'}>{tRoot('left')}</MenuItem>
            <MenuItem value={'right'}>{tRoot('right')}</MenuItem>
        </Select>
    </FormControl>  

    </ConteinerForm>
    <Feedback seccion="Floating-Position" previewMode/>
</Conteiner>)