export const BREAK_MOBILE = 320;
export const BREAK_TABLET = 768;
export const BREAK_DESKTOP = 1024;
export const BREAK_DESKTOP_LG = 1448;
export const RESET_STORE = "RESET_STORE";
export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const SELECT_SETTING = "SELECT_SETTING";
export const SET_CURRENT_CHAT_ID = "SET_CURRENT_CHAT_ID";
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT";
