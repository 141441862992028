import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import {updateFloatingIconPosition} from '../../../actionCreators.js'
import {view,styles} from './view'
import compose from 'recompose/compose'


class BoxPosition extends React.Component {
	state = {
		horizontal: ''
	}

	handleChange = event => {
		this.setState({value:event.target.value},this.props.save(event.target.value,this.props.chatId))
		
	};

	handlers = {
		handleHorizontal: event => {
			this.setState({horizontal:event.target.value}, () => {
				//window.chatbot.updateChatIconStyles({"alignIcon":event.target.value.toLowerCase()})
				//window.chatbot.updateChatIconStyles({"alignFrame":event.target.value.toLowerCase()})
				this.props.save(this.state,this.props.chatId)
			})
		}
	}

	UNSAFE_componentWillMount = () => this.setState({...this.props.data})
	// eslint-disable-next-line
	constructor(props) {super(props);}

	render = () => view(
		this.props,
		this.state,
		this.handlers)
}

const mapStateToProps = state => {
	
	return {
		data: state.chatSettings.FloatingIconPositionResult.value,
		chatId: state.baseData.currentChatConfig._id

	}
}

const mapDispatchToProps = dispatch => {
	return {
		save : (data,chatId) => updateFloatingIconPosition(data,chatId).then(result => dispatch(result)).catch(err => console.log(err))
	}
}

export default compose(
	withStyles(styles, { name: 'BoxPosition' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(BoxPosition)