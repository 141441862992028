import React from 'react'
import styled from 'styled-components'
import Auth from "../../../auth/auth"
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import i18n from '../../../i18n/i18n'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Feedback from '../../../components/feedback'

const tRoot = i18n()
const auth = new Auth()

const Body = styled.div`
margin:0px 60px;
width:100%;
 
`

const HeaderContent = styled.div`
  display: grid;
  text-align:left;
    h2{
      margin-bottom:0px;
      
    }
 
`
const Copete = styled.div`
  text-align:left;
`
const Content = styled.div`
  margin-bottom: 30px;
  border: 1px solid #ccc;
  width: 100%;
  padding: 30px;
  border-radius: 4px;
  display: inline-block;
  background:#fff;
  margin:30px 0px;

  text-align:center;
`
const ContentForm = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  text-align:left;
`
const ContentInputs = styled.div`
  display:grid;
  grid-template-columns: 1fr ;
  text-align:left;
`
const ContentAvatar = styled.div`
  border: 1px solid #fff;
  width:50%;
  max-width: 300px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
  transition: 1.2s ease all;
  text-align: center;
  margin:60px auto;
  max-height: 250px;
  min-width:233px;
`
const ContentButtons = styled.div`
  display:grid;
  grid-template-columns: 1fr  1fr 1fr;
  grid-gap:20px;
`
const SaveChange = styled.button`
  border: 1px solid rgba(0,0,0,0.3);
  padding: 10px 25px;
  border-radius: 20px;
  width: auto;
  display: inline-block;
  margin: 20px 0;
  transition: all 0.2s ease 0s;
    &:hover{
      color:#fff;
      background-color: #ef7e29;
      border:1px solid #ef7e29;
    }
`

const ChangePassword = styled.button`
  border: 1px solid rgba(0,0,0,0.3);
  padding: 10px 25px;
  border-radius: 20px;
  width: auto;
  display: inline-block;
  margin: 20px 0;
  transition: all 0.2s ease 0s;
    &:hover{
      color:#fff;
      background-color: #ef7e29;
      border:1px solid #ef7e29;
    }
`

const Logout = styled.button`
  border: 1px solid #324a5e;
  padding: 10px 25px;
  border-radius: 20px;
  width: auto;
  display: inline-block;
  margin: 20px 0;
  transition: all 0.2s ease 0s;color:#fff;
  background-color: #324a5e;
  border:1px solid #324a5e;
    &:hover{
      color:#324a5e;
      background-color: #fff;
      border:1px solid #324a5e;
    }
`

const Avatar = styled.div`
 
  border-radius: 100px;
  padding: 0px;
  height: 133px;
  width:133px;
  min-height: 133px;
  min-width:133px;
  margin:auto;
  img{
    width: 130px;
    height: 130px;
    border-radius: 100px;
    }
  }
`

export const styles = theme => ({
  margin: {
    marginBottom: '20px',
    width: '100%',

  },

  cssLabel: {
    fontSize: '18px',
    marginBottom: '10px',

    '&$cssFocused': {
      color: '#333333',
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#ef7e29',

    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#ef7e29',
    },
  },
  notchedOutline: {}
});

export const view = ({ classes, userData }, { openChangePasswordConfirmation, email }, { openDialogChangePassword, closeDialogChangePassword, sendRequest, handleEmail }) => {

  return (
    <Body>
      <HeaderContent>
        <h2>{tRoot('profile')} </h2>
        <Copete></Copete>
      </HeaderContent>
      <Content>
        <Dialog
          open={openChangePasswordConfirmation}
          onClose={closeDialogChangePassword}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Cambio de clave"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {tRoot('changePwdWarn')}
            </DialogContentText>
            <TextField
              error
              autoFocus
              value={email}
              onChange={handleEmail}
              margin="dense"
              id="name"
              helperText="Incorrect entry."
              label="Email donde desea recibir el request"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialogChangePassword} color="primary">
              Cancelar
            </Button>
            <Button onClick={sendRequest} color="primary" autoFocus>
              Enviarme solicitud de cambio de clave
            </Button>
          </DialogActions>
        </Dialog>
        <ContentForm>
          <ContentInputs>
            <FormControl className={classes.margin}>
              <InputLabel
                classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                {tRoot('name')}
              </InputLabel>
              <Input
                id="name"
                value={userData.name || userData.nickname}
                classes={{ underline: classes.cssUnderline, }} />
            </FormControl>
            <FormControl className={classes.margin}>
              <InputLabel
                classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                {tRoot('email')}
              </InputLabel>
              <Input
                id="email"
                value={userData.email}
                classes={{ underline: classes.cssUnderline, }} />
            </FormControl>
            <FormControl className={classes.margin}>
              <InputLabel
                classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                {tRoot('designation')}
              </InputLabel>
              <Input
                id="designacion"
                classes={{ underline: classes.cssUnderline, }} />
            </FormControl>
            <FormControl className={classes.margin}>
              <InputLabel
                classes={{ root: classes.cssLabel, focused: classes.cssFocused, }} >
                {tRoot('phone')}
              </InputLabel>
              <Input
                id="phone"
                classes={{ underline: classes.cssUnderline, }} />
            </FormControl>

            <ContentButtons>
              <SaveChange>
                {tRoot('saveChange')}
              </SaveChange>
              {
                userData.sub && userData.sub.includes('auth0') ? <ChangePassword onClick={openDialogChangePassword}>Cambiar clave</ChangePassword> : ''
              }

              <Logout onClick={e => {
                auth.logout()
              }}>
                {tRoot('logout')}
              </Logout>
            </ContentButtons>

          </ContentInputs>
          <ContentAvatar>
            <Avatar>
              <img src={userData.picture} alt="avatar"/>
            </Avatar>
          </ContentAvatar>
        </ContentForm>
        <Feedback seccion="Profile"/>
      </Content>
    </Body>



  )
}


