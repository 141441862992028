import React from "react";
import { connect } from "react-redux";
import { validateChatCredentials, slackNotify } from "../../../../../rootActions";
import { chatService } from "../../../../../services/chat.service";
import engineIconWatson from "../../../../../assets/img/engine-icon-watson.png";
import engineIconDialogflow from "../../../../../assets/img/engine-icon-dialogflow.png";
import engineIconDialogflowCX from "../../../../../assets/img/engine-icon-dialogflowcx.png";
import engineIconAmazonLex from "../../../../../assets/img/engine-icon-amazonLex.png";
import engineIconCortana from "../../../../../assets/img/engine-icon-cortana.png";
import i18n from '../../../../../i18n/i18n'
import lang from '../../../../../i18n/lang'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactGA from 'react-ga'
import ReactFileReader from 'react-file-reader'
import Base64 from 'base-64'
import MuiAlert from '@material-ui/lab/Alert';

const tRoot = i18n()

export const styles = theme => ({

  cssLabel: {
    fontSize: '18px',
    marginBottom: '10px',

    '&$cssFocused': {
      color: '#333333',
      fontSize: '20px',
      marginBottom: '10px',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '120px'
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#ef7e29',

    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#ef7e29',

    },
  },

});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class DialogFlowCX extends React.Component {

  state = {
    jsonConfig: '',
    agent_id: '',
    lang: '',
    environment: '',
    errorMessage: '',
    saveOKMsg: '',
    labelWidth: '',
    fileName: ''
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/chat/nlp/dialogflowcx", ['backTrackerV2', 'backTrackerV3'])
    this.props.closeLoader();

    this.setState({
      jsonConfig: this.props.jsonConfig ? JSON.stringify(this.props.jsonConfig) : '',
      agent_id: this.props.nlp ? this.props.nlp.agent_id : '',
      lang: this.props.nlp ? this.props.nlp.lenguaje : 'en',
      environment: this.props.nlp ? this.props.nlp.environment : 'Draft',
      fileName: this.props.nlp ? this.props.nlp.fileName : ''
    })
  }

  handleJsonConfig = event => {
    this.setState({ jsonConfig: event.target.value })
  }

  handleCloseMsg = () => {
    this.setState({ errorMessage: '', saveOKMsg: '' })
  }

  handleAgentID = ev => {
    this.setState({ agent_id: ev.target.value })
  }

  handleLang = ev => this.setState({ lang: ev.target.value })



  errorMsg = () => {
    return (
      <Snackbar
        severity="error"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.errorMessage !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        <Alert severity="error">{this.state.errorMessage}</Alert>
      </Snackbar>
    )
  }

  saveOK = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.saveOKMsg !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}

        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        <Alert severity="success">{this.state.saveOKMsg}</Alert>
      </Snackbar>
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.openLoader();
    const fields = e.target.elements;

    //varify valid JSON
    try {
      JSON.parse(this.state.jsonConfig);
    } catch (e) {
      console.log('e :', e);
      ReactGA.event({
        category: 'Chat',
        action: 'UPDATE DialogflowCX Bad Credentials'
      }, ['backTrackerV2', 'backTrackerV3'])
      this.setState({ errorMessage: tRoot('invalidJSONFormat') })
      this.props.closeLoader();
      return false;
    }


    const jsonStructure = {};
    jsonStructure.NLP = JSON.parse(this.state.jsonConfig)
    jsonStructure.NLP.provider = "dialogFlowCx"
    jsonStructure.NLP.lenguaje = fields.DFLang.value
    jsonStructure.NLP.agent_id = this.state.agent_id
    jsonStructure.NLP.environment = this.state.environment
    jsonStructure.NLP.fileName = this.state.fileName

    var newNLP = {
    
      NLP: jsonStructure.NLP
    }

    this.props.openLoader();
    this.verifyCredentials(newNLP)
      .then(result => {
        ReactGA.event({
          category: 'Chat',
          action: 'Update NLP DialogflowCX'
        }, ['backTrackerV2', 'backTrackerV3'])
        this.updateChat(this.props.chatID, { NLP: { ...result.data.data.NLP, originalInput: JSON.parse(fields.DFText.value) } });

      }).catch(err => {
        console.log('err :', err);

        this.props.closeLoader();
        ReactGA.event({
          category: 'Chat',
          action: 'UPDATE DialogflowCX Bad Credentials'
        }, ['backTrackerV2', 'backTrackerV3'])

        //slack notify
        newNLP.chatID = this.props.chatID
        slackNotify(JSON.stringify(newNLP))

        this.setState({ errorMessage: tRoot('badCredentialErrorMsg') })
      })

  }

  verifyCredentials = (credentials) => {
    return validateChatCredentials(credentials)
  }


  updateChat = (chatID, jsonStructure) => {
    chatService
      .updateChatConfig(chatID, jsonStructure)
      .then(data => {
        this.props.closeLoader();
        this.setState({ saveOKMsg: tRoot('credentialSaveOKMsg') })
      })
      .catch(err => {
        this.props.closeLoader();
        this.setState({ errorMessage: tRoot('badCredentialErrorMsg') })
      });
  };

  handlerEnvironment = e => this.setState({ environment: e.target.value })

  handleFiles = (files) => {
    let jsonBase64 = files.base64
    let index = jsonBase64.indexOf(',')
    let encodedString = jsonBase64.substr(index + 1)
    var bytes = Base64.decode(encodedString)
    this.setState({ jsonConfig: bytes, fileName: files.fileList[0].name })
  }

  render() {
    return (
      <div className="engine-select">
        <div className="grid-container">
          <div className="inputs">
            <div className="form-container">

              <form onSubmit={this.handleSubmit}>
                <div className="form-group more-info">
                  <ReactFileReader fileTypes={[".json"]} base64={true} multipleFiles={false} handleFiles={this.handleFiles}>
                    <button className='button submit' type="button">{tRoot('uploadGoogleCredentialFile')}</button>
                  </ReactFileReader>
                  <div className="form-group more-info" style={{ 'text-align': 'center' }}>
                    {this.state.fileName}
                  </div>
                </div>
                <div className="form-group more-info">
                  <TextField
                    id="DFText"
                    label={tRoot('pasteJSONContent')}
                    multiline
                    value={this.state.jsonConfig}
                    onChange={this.handleJsonConfig}
                    rows="8"
                    margin="normal"
                    variant="outlined"
                    style={{ width: '400px' }}
                    hidden={true}
                  />

                </div>
                <div className="form-group more-info">
                  <FormControl className={styles.formControl} fullWidth>
                    <TextField
                      label="Agent ID"
                      value={this.state.agent_id}
                      onChange={this.handleAgentID}
                      margin="normal"
                      className="textfield"
                    />
                    <FormHelperText>{tRoot('agentIDHelper')}</FormHelperText>

                    <Select
                      value={this.state.lang || ''}
                      onChange={this.handleLang}
                      className={styles.cssUnderline}
                      inputProps={{
                        name: 'DFLang',
                        id: 'DFLang'
                      }}
                    >
                      <MenuItem value="en">Inglés</MenuItem>
                      <MenuItem value="es">Español</MenuItem>
                      <MenuItem value="zh-HK">Chinese (Cantonese)</MenuItem>
                      <MenuItem value="zh-CN">Chinese (Simplified)</MenuItem>
                      <MenuItem value="zh-TW">Chinese (Traditional) </MenuItem>
                      <MenuItem value="da">	Danish </MenuItem>
                      <MenuItem value="nl">	Dutch </MenuItem>
                      <MenuItem value="en">	English </MenuItem>
                      <MenuItem value="en-AU">English (Australian)</MenuItem>
                      <MenuItem value="en-CA">English (Canadian) </MenuItem>
                      <MenuItem value="en-GB">English (Great Britain)</MenuItem>
                      <MenuItem value="en-IN">English (Indian)</MenuItem>
                      <MenuItem value="en-US">English (US)</MenuItem>
                      <MenuItem value="fr">	French</MenuItem>
                      <MenuItem value="fr-CA">French (Canadian)</MenuItem>
                      <MenuItem value="fr-FR">French (France) </MenuItem>
                      <MenuItem value="de">German</MenuItem>
                      <MenuItem value="hi">Hindi - hi</MenuItem>
                      <MenuItem value="id">Indonesian</MenuItem>
                      <MenuItem value="it">Italian </MenuItem>
                      <MenuItem value="ja">Japanese</MenuItem>
                      <MenuItem value="ko">Korean</MenuItem>
                      <MenuItem value="no">Norwegian </MenuItem>
                      <MenuItem value="pl">Polish</MenuItem>
                      <MenuItem value="pt">Portuguese</MenuItem>
                      <MenuItem value="pt-BR">Portuguese (Brazilian)</MenuItem>
                      <MenuItem value="ru">Russian</MenuItem>
                      <MenuItem value="es">Spanish</MenuItem>
                      <MenuItem value="es-419">Spanish (Latin America)</MenuItem>
                      <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
                      <MenuItem value="sv">Swedish</MenuItem>
                      <MenuItem value="th">	Thai</MenuItem>
                      <MenuItem value="tr">Turkish</MenuItem>
                      <MenuItem value="uk">	Ukranian</MenuItem>
                    </Select>
                    <FormHelperText>{tRoot('langHelper')}</FormHelperText>
                    <TextField
                      label={tRoot('environment')}
                      value={this.state.environment}
                      onChange={this.handlerEnvironment}
                      margin="normal"
                      className="textfield"
                    />
                    <FormHelperText>{tRoot('environmentHelper')}</FormHelperText>
                  </FormControl>
                </div>
                <div className="form-group more-info">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/es/kb/articles/integrar-google-dialogflow-cx-12-2-2021' : 'https://supporten.blahbox.net/portal/en/kb/articles/integrate-google-dialogflow-cx'}
                  >
                    {tRoot('needMoreInformation')}
                  </a>
                </div>
                <div className="form-group">
                  <button className="button submit" type="submit">
                    {tRoot('go')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="selected-engine">
            <div className="shadow-border-box">
              <img src={engineIconDialogflowCX} alt="dialogflow cx" />
              <p>DIALOGFLOW CX</p>
            </div>
          </div>
          <div className="change">
            <h3 className="title">{tRoot('selectNLPEngine')}</h3>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("google")}
            >
              <img src={engineIconDialogflow} alt="dialogflow" />
              <p>DIALOGFLOW</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("watson")}
            >
              <img src={engineIconWatson} alt="watson assistant" />
              <p>WATSON ASSISTANT</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("aws")}
            >
              <img src={engineIconAmazonLex} alt="amazon lex" />
              <p>AMAZON LEX</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("microsoft")}
            >
              <img src={engineIconCortana} alt="Cortana" />
              <p>QnA Maker</p>
            </div>
          </div>
        </div>
        {this.errorMsg()}
        {this.saveOK()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.baseData.currentChatConfig.NLP.provider.private_key)
  return {
    chatID: state.baseData.currentChatConfig._id,
    nlp: state.baseData.currentChatConfig.NLP,
    jsonConfig: state.baseData.currentChatConfig.NLP && state.baseData.currentChatConfig.NLP.provider === 'dialogFlowCx' ? state.baseData.currentChatConfig.NLP.originalInput : ''
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader: () => dispatch({ type: 'OPEN_LOADER' }),
    closeLoader: () => dispatch({ type: 'CLOSE_LOADER' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogFlowCX);
