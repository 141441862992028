import React, { useState, useEffect, useRef } from 'react'
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { CallOutsFormSquare } from './callOutsForm/callOutsFormSquare'
import { CallOutsFormSquareLeftCenter } from './callOutsForm/callOutsFormSquareLeftCenter'
import { CallOutsFormSquareLeftTop } from './callOutsForm/callOutsFormSquareLeftTop'
import { CallOutsFormSquareTop } from './callOutsForm/callOutsFormSquareTop'
import { CallOutsFormSquareTopCenter } from './callOutsForm/callOutsFormSquareTopCenter'
import { CallOutsFormRadius } from './callOutsForm/callOutsFormRadius'
import { CallOutsFormRadiusLeft } from './callOutsForm/callOutsFormRadiusLeft'
import { CallOutsFormRadiusTop } from './callOutsForm/callOutsFormRadiusTop'
import { CallOutsFormRadiusTopCenter } from './callOutsForm/callOutsFormRadiusTopCenter'
import { CallOutsFormCircle } from './callOutsForm/callOutsFormCircle'
import { CallOutsFormCircleTop } from './callOutsForm/callOutsFormCircleTop'
import { CallOutsFormCircleTopCenter } from './callOutsForm/callOutsFormCircleTopCenter'
import { CallOutsFormRadiusThree } from './callOutsForm/callOutsFormRadiusThree'
import { CallOutsFormRadiusTwo } from './callOutsForm/callOutsFormRadiusTwo'
import {
	Label,
	Content,
	ContentCallOuts,
	ContentSwitch,
	ContentForm,
	Color,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Input,
	FormGroup,
	FormControlLabel,
	Switch
} from './styled'
import { getChat, chatUpdate } from '../../../hooksActionCreators'
import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'
import Loader from '../../../../../../components/LinearLoader/linearLoader'
import GlobesForm from './callOutsConfig.json'

const tRoot = i18n()
const t = i18n('chatSettings')

export const DialogBoxCallOuts = () => {
	const isInitialMount = useRef(true)
	const chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id

	const [loader, setLoader] = useState(false)
	const [form, setForm] = useState('')
	const [showTime, setShowTime] = useState(false)
	const [showName, setShowName] = useState(false)
	const [font, setFont] = useState('')
	const [fontSize, setFontSize] = useState('')
	const [system, setSystem] = useState({ color: '' })
	const [visit, setVisit] = useState({ color: '' })
	const [textBot, setTextBot] = useState({ color: '' })
	const [textUser, setTextUser] = useState({ color: '' })
	const [showChooseColor, setShowChooseColor] = useState({
		system: false,
		visit: false,
		textBot: false,
		textUser: false
	})

	const openLoader = () => setLoader(true)
	const closeLoader = () => setLoader(false)

	useEffect(() => {
		const fetchChatData = async () => {
			isInitialMount.current = true
			openLoader()
			let chatConfig = await getChat(chatID)
			setForm(chatConfig.config.writeSection.botGlobe.CallOutId)
			setFont(chatConfig.styles.messagesSection.fontFamily)
			setFontSize(chatConfig.styles.messagesSection.botGlobe.fontSize)
			setShowName(!chatConfig.styles.messagesSection.userGlobe.hideName)
			setShowTime(!chatConfig.styles.messagesSection.userGlobe.hideTime)
			setSystem({
				color: chatConfig.styles.messagesSection.botGlobe.backgroundColor,
				showChooseColor: false
			})
			setVisit({
				color: chatConfig.styles.messagesSection.userGlobe.backgroundColor,
				showChooseColor: false
			})
			setTextBot({
				color: chatConfig.styles.messagesSection.botGlobe.color,
				showChooseColor: false
			})
			setTextUser({
				color: chatConfig.styles.messagesSection.userGlobe.color,
				showChooseColor: false
			})
			closeLoader()
			isInitialMount.current = false
		}

		fetchChatData()
	}, [])

	useEffect(() => {
		!isInitialMount.current && dataUpdate()
	}, [form, showTime, showName, font, fontSize, system, visit, textBot, textUser])

	const dataUpdate = () => {
		let change = {
			styles: {
				messagesSection: {
					fontFamily: font,
					userGlobe: {
						backgroundColor: visit.color,
						color: textUser.color,
						fontSize: fontSize,
						borderTopLeftRadius: GlobesForm[form].userGlobe.borderTopLeftRadius,
						borderTopRightRadius: GlobesForm[form].userGlobe.borderTopRightRadius,
						borderBottomRightRadius: GlobesForm[form].userGlobe.borderBottomRightRadius,
						borderBottomLeftRadius: GlobesForm[form].userGlobe.borderBottomLeftRadius,
						peakPosition: GlobesForm[form].userGlobe.peakPosition,
						hideTime: !showTime,
						hideName: !showName
					},
					botGlobe: {
						backgroundColor: system.color,
						color: textBot.color,
						fontSize: fontSize,
						borderTopLeftRadius: GlobesForm[form].botGlobe.borderTopLeftRadius,
						borderTopRightRadius: GlobesForm[form].botGlobe.borderTopRightRadius,
						borderBottomRightRadius: GlobesForm[form].botGlobe.borderBottomRightRadius,
						borderBottomLeftRadius: GlobesForm[form].botGlobe.borderBottomLeftRadius,
						peakPosition: GlobesForm[form].botGlobe.peakPosition,
						hideTime: !showTime,
						hideName: !showName
					}
				}
			},
			config: {
				writeSection: {
					userGlobe: {
						CallOutId: form,
					},
					botGlobe: {
						CallOutId: form,
					}
				}
			}
		}

		try {
			chatUpdate(chatID, change)
		} catch (error) {
			console.log('error :', error);
		}
	}

	const handleForm = (form) => {
		setForm(form)
	}

	const handleShowTime = (e) => {
		setShowTime(e.target.checked)
		window.chatbot.updateBotGlobeStyles({ "hideTime": !e.target.checked })
		window.chatbot.updateUserGlobeStyles({ "hideTime": !e.target.checked })
	}

	const handleShowName = (e) => {
		setShowName(e.target.checked)
		window.chatbot.updateBotGlobeStyles({ "hideName": !e.target.checked })
		window.chatbot.updateUserGlobeStyles({ "hideName": !e.target.checked })
	}


	const handleClosers = {
		closeColorSystem: () => setShowChooseColor({ ...showChooseColor, system: false }),
		closeColorVisit: () => setShowChooseColor({ ...showChooseColor, visit: false }),
		closeColorTextBot: () => setShowChooseColor({ ...showChooseColor, textBot: false }),
		closeColorTextUser: () => setShowChooseColor({ ...showChooseColor, textUser: false })
	}

	const handleSystem = {
		handleShow: () => {
			setShowChooseColor({ ...showChooseColor, system: !showChooseColor.system })
		},
		pickColor: (color) => {
			setSystem({ color: color.hex })
			window.chatbot.updateBotGlobeStyles({ "backgroundColor": color.hex })
		}
	}

	const handleVisit = {
		handleShow: () => {
			setShowChooseColor({ ...showChooseColor, visit: !showChooseColor.visit })
		},
		pickColor: (color) => {
			setVisit({ color: color.hex })
			window.chatbot.updateUserGlobeStyles({ "backgroundColor": color.hex })
		}
	}

	const handleTextBot = {
		handleShow: () => {
			setShowChooseColor({ ...showChooseColor, textBot: !showChooseColor.textBot })
		},
		pickColor: (color) => {
			setTextBot({ color: color.hex })
			window.chatbot.updateBotGlobeStyles({ "color": color.hex })
		}
	}

	const handleTextUser = {
		handleShow: () => {
			setShowChooseColor({ ...showChooseColor, textUser: !showChooseColor.textUser })
		},
		pickColor: (color) => {
			setTextUser({ color: color.hex, showChooseColor: true })
			window.chatbot.updateUserGlobeStyles({ "color": color.hex })
		}
	}

	const handleFont = (e) => {
		setFont(e.target.value)
		window.chatbot.updateMessagesSectionStyles({ "fontFamily": e.target.value })
	}

	const handleFontSize = (e) => {
		setFontSize(e.target.value)
		window.chatbot.updateBotGlobeStyles({ "fontSize": e.target.value })
		window.chatbot.updateUserGlobeStyles({ "fontSize": e.target.value })
	}

	return (
		<Content>
			<HeaderConfig title={t('callOutTittle')} />
			{loader ? <div style={{ 'height': '300px' }}><Loader /></div> :
				<>
					<Label>{tRoot('shape')}</Label>
					<ContentCallOuts>
						<CallOutsFormSquare func={handleForm} itemSelected={form} />
						<CallOutsFormSquareLeftCenter func={handleForm} itemSelected={form} />
						<CallOutsFormSquareLeftTop func={handleForm} itemSelected={form} />
						<CallOutsFormSquareTop func={handleForm} itemSelected={form} />
						<CallOutsFormSquareTopCenter func={handleForm} itemSelected={form} />
						<CallOutsFormRadius func={handleForm} itemSelected={form} />
						<CallOutsFormRadiusLeft func={handleForm} itemSelected={form} />
						<CallOutsFormRadiusTop func={handleForm} itemSelected={form} />
						<CallOutsFormRadiusTopCenter func={handleForm} itemSelected={form} />
						<CallOutsFormCircle func={handleForm} itemSelected={form} />
						<CallOutsFormCircleTop func={handleForm} itemSelected={form} />
						<CallOutsFormCircleTopCenter func={handleForm} itemSelected={form} />
						<CallOutsFormRadiusThree func={handleForm} itemSelected={form} />
						<CallOutsFormRadiusTwo func={handleForm} itemSelected={form} />
					</ContentCallOuts>
					<ContentSwitch>
						<FormGroup row>
							<FormControlLabel
								control={
									<Switch
										checked={showTime}
										color="primary"
										onChange={handleShowTime}
										value="showTime"
									/>
								}
								label={tRoot('showTime')}
							/>
						</FormGroup>
						<FormGroup row>
							<FormControlLabel
								control={
									<Switch
										checked={showName}
										color="primary"
										onChange={handleShowName}
									/>
								}
								label={tRoot('showName')}
							/>
						</FormGroup>
					</ContentSwitch>
					<ContentForm>
						<ClickAwayListener onClickAway={handleClosers.closeColorSystem}>
							<FormControl>
								<InputLabel>
									{t('colorSystem')}
								</InputLabel>
								<Input
									id="colorSystem"
									value={system.color ? system.color : ''}
								/>
								<Color className="colorSystem" style={{ background: system.color }} onClick={handleSystem.handleShow} />
								{showChooseColor.system ? <SketchPicker
									onChangeComplete={handleSystem.pickColor}
									color={system.color} /> : ""}
							</FormControl>
						</ClickAwayListener>
						<ClickAwayListener onClickAway={handleClosers.closeColorVisit}>
							<FormControl>
								<InputLabel>
									{t('colorVisit')}
								</InputLabel>
								<Input
									id="colorVisit"
									value={visit.color ? visit.color : ''}
								/>
								<Color className="colorVisit" style={{ background: visit.color }} onClick={handleVisit.handleShow} />
								{showChooseColor.visit ? <SketchPicker
									onChangeComplete={handleVisit.pickColor}
									color={visit.color} /> : ""}
							</FormControl>
						</ClickAwayListener>
						<ClickAwayListener onClickAway={handleClosers.closeColorTextBot}>
							<FormControl>
								<InputLabel>
									{t('colorTextBot')}
								</InputLabel>
								<Input
									id="colorTextBot"
									value={textBot.color ? textBot.color : ''}
								/>
								<Color className="colorTextBot" style={{ background: textBot.color }} onClick={handleTextBot.handleShow} />
								{showChooseColor.textBot ? <SketchPicker
									onChange={handleTextBot.pickColor}
									color={textBot.color} /> : ""}
							</FormControl>
						</ClickAwayListener>
						<ClickAwayListener onClickAway={handleClosers.closeColorTextUser}>
							<FormControl >
								<InputLabel>
									{t('colorTextUser')}
								</InputLabel>
								<Input
									id="colorTextUser"
									value={textUser.color ? textUser.color : ''}
								/>
								<Color className="colorTextUser" style={{ background: textUser.color }} onClick={handleTextUser.handleShow} />
								{showChooseColor.textUser ? <SketchPicker
									onChange={handleTextUser.pickColor}
									color={textUser.color} /> : ""}
							</FormControl>
						</ClickAwayListener>
						<FormControl>
							<InputLabel > {tRoot('font')}</InputLabel>
							<Select
								value={font}
								onChange={handleFont}
							>
								<MenuItem value="Arial">Arial</MenuItem>
								<MenuItem value="Arial Black">Arial Black</MenuItem>
								<MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
								<MenuItem value="Courier New">Courier New</MenuItem>
								<MenuItem value="Impact">Impact</MenuItem>
								<MenuItem value="Lucida Console">Lucida Console</MenuItem>
								<MenuItem value="Lucida Sans Unicode">Lucida Sans Unicode</MenuItem>
								<MenuItem value="Tahoma">Tahoma</MenuItem>
								<MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
								<MenuItem value="Verdana">Verdana</MenuItem>
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel > {tRoot('size')}</InputLabel>
							<Select
								value={fontSize}
								onChange={handleFontSize}
							>
								<MenuItem value={"12px"}>12</MenuItem>
								<MenuItem value={"13px"} >13</MenuItem>
								<MenuItem value={"14px"} >14</MenuItem>
								<MenuItem value={"15px"} >15</MenuItem>
								<MenuItem value={"17px"} >17</MenuItem>
								<MenuItem value={"20px"} >20</MenuItem>
								<MenuItem value={"24px"} >24</MenuItem>
								<MenuItem value={"32px"} >32</MenuItem>
							</Select>
						</FormControl>
					</ContentForm>
				</>
			}
			<Feedback seccion="CallOuts-Globes" previewMode />
		</Content>
	)
}