import React from "react";
import { connect } from "react-redux";
import { chatService } from "../../../services";
import addChat from "../../../assets/img/add-chat.svg";
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteChat, changeName, getSubs } from '../ChatSettings/actionCreators'
import TextField from '@material-ui/core/TextField';
import i18n from '../../../i18n/i18n'
import ReactGA from 'react-ga'
import compose from 'recompose/compose';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import styled from 'styled-components'

import {
  openLoader,
  closeLoader,
  setCurrentChatId,
  setCurrentChat,
  getCurrentSubscription
} from "../../../rootActions";

import { getChat, getAccessChat } from "../ChatSettings/actionCreators"

const tRoot = i18n()

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
};

ReactGA.initialize([{
  trackingId: process.env.ANALYTICSTRACKID_BACK,
  gaOptions: {
    name: 'backTrackerV2',
    userId: localStorage.getItem("userID")
  }
}, {
  trackingId: process.env.ANALYTICSTRACKID_BOX,
  gaOptions: {
    name: 'chatboxTracker'
  }
}], { debug: process.env.NODE_ENV === 'development' ? true : false, alwaysSendToDefaultTracker: false }

)

const CardActionArea = styled.div``


class Projects extends React.Component {
  state = {
    userChats: [],
    currentname: '',
    openDeleteConfirmation: false,
    chatBotSelected: {},
    openChangeNameConfirmation: false,
    deleteNoAccess: false,
    planActive: false
  };



  closeDeleteConfirmation = () => this.setState({ openDeleteConfirmation: false, deleteNoAccess: false })
  openChangeNameConfirmation = chatBotSelected => this.setState({ openChangeNameConfirmation: true, chatBotSelected, currentname: chatBotSelected.name })
  closeChangeNameConfirmation = () => this.setState({ openChangeNameConfirmation: false })

  openDeleteConfirmation = (chatBotSelected) => {
    this.props.openLoader()

    var CancelAtPeriodEnd = false
    getSubs(chatBotSelected.subs).then(subscription => {
      CancelAtPeriodEnd = subscription.CancelAtPeriodEnd || CancelAtPeriodEnd
      if (subscription.ProductCode !== 'freeM' && !CancelAtPeriodEnd) {
        this.setState({ planActive: true, deleteNoAccess: true })
      }
      this.props.closeLoader()
      this.setState({ openDeleteConfirmation: true, chatBotSelected })
    })
  }


  confirmDelete = () => {
    this.props.openLoader()
    deleteChat(this.state.chatBotSelected).then(result => {
      this.props.closeLoader()
      this.closeDeleteConfirmation()
      this.UNSAFE_componentWillMount()
    }).catch(err => {
      if (err.response.status) {
        this.props.closeLoader()
        this.setState({ deleteNoAccess: true })
        return
      }
      console.log(err)
      return
    })
  }


  changeName = () => {
    this.props.openLoader()
    changeName(this.state.chatBotSelected, this.state.currentname).then(result => {
      this.props.closeLoader()
      this.closeChangeNameConfirmation()
      this.UNSAFE_componentWillMount()
    }).catch(err => console.log(err))

  }

  UNSAFE_componentWillMount() {
    //Google Analytics
    ReactGA.pageview("/dashboard/projects", ['backTrackerV2', 'backTrackerV3'])

    localStorage.removeItem("chatAccess")

    this.props.openLoader()


    //si viene el chat id como parametro en chatIDDirect entra direcamente a ese chat
    if (this.props.match.params.chatIDDirect) {
      let chatObj = {
        _id: this.props.match.params.chatIDDirect
      }

      this.chatConfigRedirect(chatObj)

    } else {
      chatService.getChats().then(data => {
        this.setState({
          userChats: data.data.userChats

        });
        this.props.closeLoader();
      }).catch(err => {
        this.props.closeLoader();
      })
    }

  }

  handleName = (event) => this.setState({ currentname: event.target.value })

  chatConfigRedirect = (chatObj) => {

    /*     getChat(chatObj._id).then(result => {
          this.props.setCurrentChat(result.data.data);
          this.props.history.push({
            pathname: "/dashboard/chat-settings/configurations",
            state: {
              chatID: chatObj._id
            }
          });
        })  */

    getAccessChat(chatObj._id).then(r => {

      localStorage.setItem("chatAccess", JSON.stringify(r.data.chatAccess))

      getChat(chatObj._id).then(result => {

        this.props.setCurrentChat(result.data.data);

        getCurrentSubscription(result.data.data.subscription).then(async result => {
          localStorage.setItem("plan", JSON.stringify(result.data.subscription))
          this.props.history.push({
            pathname: "/dashboard/chat-settings/chatlanding",
            state: {
              chatID: chatObj._id
            }
          })
        })
      })
    })
  };

  userID = localStorage.getItem("userID")

  render() {
    return (
      <section className="dashboard-container">
        <h2>{tRoot('projects')}</h2>

        <div className="jumbotron">
          <h3>{tRoot('jumbotronWellcome')}</h3>
          <p className="lead">{tRoot('jumbotronTextLine1')}<br />
            {tRoot('jumbotronTextLine2')}</p>
        </div>


        <Dialog
          open={this.state.openDeleteConfirmation}
          onClose={this.closeDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"><h2>{tRoot('sureDelete')}</h2></DialogTitle>
          <DialogContent>
            {!this.state.deleteNoAccess ?
              <DialogContentText id="alert-dialog-description">

                {tRoot('deleteMsgLine1')} <b> {this.state.chatBotSelected.name}. </b> {tRoot('deleteMsgLine2')}

              </DialogContentText>
              :
              <DialogContentText id="alert-dialog-description">
                <WarningRoundedIcon style={{ color: 'red', fontSize: 40 }} />
                {this.state.planActive ? tRoot('planActiveWarning') : tRoot('noAccessForThisAccion')}
              </DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDeleteConfirmation} color="primary">
              {tRoot('cancel')}
            </Button>
            <Button onClick={this.confirmDelete} color="primary" autoFocus disabled={this.state.deleteNoAccess}>
              {tRoot('delete')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openChangeNameConfirmation}
          onClose={this.closeChangeNameConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{tRoot('newName')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

            </DialogContentText>
            <TextField
              id="standard-name"
              label={tRoot('newName')}
              value={this.state.currentname}
              onChange={this.handleName}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeChangeNameConfirmation} color="primary">
              {tRoot('cancel')}
            </Button>
            <Button onClick={this.changeName} color="primary" autoFocus>
              {tRoot('changeName')}
            </Button>
          </DialogActions>
        </Dialog>


        <div className="chats-container">

          <Card
            className="box moveUp new-chat-box"
            onClick={() => {
              ReactGA.event({
                category: 'Chat',
                action: 'New Chat Wizard'
              }, ['backTrackerV2', 'backTrackerV3'])

              this.props.history.push("/dashboard/new-chat")
            }}
          >

            <CardActionArea>
              <CardContent>
                <div>
                  <img src={addChat} alt="add new chat" />
                </div>
                <div className="hover-contentAdd">
                  <p>{tRoot('newChat')}</p>
                </div>
              </CardContent>
            </CardActionArea>

          </Card>

          {this.state.userChats.map((chat, userID) => (


            <Card key={chat._id + '_' + userID} className="box ">
              <div className="moveUp">
                <CardContent className="info-container-top">
                  <h3>{chat.name}</h3>
                  {chat.description}
                  <div className="hover-content">
                    <button onClick={() => { this.chatConfigRedirect(chat) }} >{tRoot('go')}</button>
                  </div>
                </CardContent>
              </div>

              <CardActions className="icons info-container-bottom" >
                <p className="plan">Blahbox</p>
                <p className="date">{chat.createdDate}</p>
                <div style={{ width: "30%", textAlign: "right", float: "right", paddingTop: "20px" }}>
                  <Button onClick={e => this.openChangeNameConfirmation(chat)} size="small" color="primary" style={{ minWidth: "20px", paddingTop: "0px" }}>
                    <EditIcon />
                  </Button>

                  <Button onClick={e => this.openDeleteConfirmation(chat)} size="small" color="primary" style={{ minWidth: "20px", paddingTop: "0px" }}>
                    <DeleteIcon />
                  </Button>

                </div>
              </CardActions>

            </Card>


          ))}



        </div>
      </section >
    );
  }
}

/* const mapStateToProps = state => {
  return {}

}

const mapDispatchToProps = dispatch => {
  return {
    openLoader,
    closeLoader,
    setCurrentChatId,
    setCurrentChat,
    getCurrentSubscription
  }
} */

//export default connect(null, { openLoader, closeLoader, setCurrentChatId, setCurrentChat })(withStyles(styles)(withRouter(Projects)));
export default compose(
  withStyles(styles, { name: 'Projects' }),
  connect(null, { openLoader, closeLoader, setCurrentChatId, setCurrentChat, getCurrentSubscription })
)(Projects)
