import React from 'react'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { connect } from 'react-redux'
import { saveChatNameAndDescription, saveTrackID, saveWakeupWord } from '../../../rootActions';
import compose from 'recompose/compose';
import ReactGA from 'react-ga'


class SuscriptionGeneral extends React.Component {
  state = {
    name: '',
    description: '',
    analyticsTrackID: '',
    chatId: '',
    wakeupWord: ''
  }
	// eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/dashboard/chat-settings/generalSubscription", ['backTrackerV2', 'backTrackerV3'])

    this.setState({
      name: this.props.config.name,
      chatId: this.props.config._id,
      description: this.props.config.description,
      analyticsTrackID: this.props.config.analyticsTrackID,
      wakeupWord: this.props.config.wakeupWord
    })
  }

  handler = {
    name: (ev) => this.setState({ name: ev.target.value }),
    description: ev => this.setState({ description: ev.target.value }),
    analyticsTrackID: ev => this.setState({ analyticsTrackID: ev.target.value }),
    wakeupWord: ev => this.setState({ wakeupWord: ev.target.value }),
    save: () => {
      this.props.openLoader()
      saveChatNameAndDescription(this.state.chatId, this.state.name, this.state.description)
        .then(() => this.props.closeLoader())
        .catch(err => {
          this.props.closeLoader()
          console.log(err)
        })
    },
    saveTackID: () => {


      this.props.openLoader()
      saveTrackID(this.state.chatId, this.state.analyticsTrackID)
        .then(() => {
          ReactGA.event({
            category: 'Chat',
            action: 'Analytics Set'
          }, ['backTrackerV2', 'backTrackerV3'])

          this.props.closeLoader()
        })
        .catch(err => {
          this.props.closeLoader()
          console.log(err)
        })
    },
    saveWakeupWord: () => {
      this.props.openLoader()
      saveWakeupWord(this.state.chatId, this.state.wakeupWord)
        .then(() => {
          ReactGA.event({
            category: 'Chat',
            action: 'WakeupWord Set'
          }, ['backTrackerV2', 'backTrackerV3'])

          this.props.closeLoader()
        })
        .catch(err => {
          this.props.closeLoader()
          console.log(err)
        })
    }

  }

  render = () => view(this.props.classes, this.state, this.handler)

}

const mapStateToProps = state => {
  return {
    config: state.baseData.currentChatConfig
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader: () => dispatch({ type: 'OPEN_LOADER' }),
    closeLoader: () => dispatch({ type: 'CLOSE_LOADER' })
  }
}


//export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SuscriptionGeneral))
export default compose(
  withStyles(styles, { name: 'SuscriptionGeneral' }),
  connect(mapStateToProps, mapDispatchToProps)
)(SuscriptionGeneral)
