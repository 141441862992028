import React from "react";
import { connect } from "react-redux";
import { validateChatCredentials, slackNotify } from "../../../../../rootActions";
import { chatService } from "../../../../../services/chat.service";
import engineIconWatson from "../../../../../assets/img/engine-icon-watson.png";
import engineIconDialogflow from "../../../../../assets/img/engine-icon-dialogflow.png";
import engineIconDialogflowCX from "../../../../../assets/img/engine-icon-dialogflowcx.png";
import engineIconAmazonLex from "../../../../../assets/img/engine-icon-amazonLex.png";
import engineIconCortana from "../../../../../assets/img/engine-icon-cortana.png";
import i18n from '../../../../../i18n/i18n'
import lang from '../../../../../i18n/lang'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import ReactGA from 'react-ga'

const tRoot = i18n()

class Cortana extends React.Component {

  state = {
    jsonConfig: '',
    errorMessage: '',
    saveOKMsg: ''
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/chat/nlp/qna", ['backTrackerV2', 'backTrackerV3'])
    this.props.closeLoader()
    this.setState({ jsonConfig: this.props.jsonConfig ? this.props.jsonConfig : '' })
  }

  handleJsonConfig = event => {
    this.setState({jsonConfig:event.target.value })
  }

  handleCloseMsg = () => {
    this.setState({ errorMessage: '', saveOKMsg: '' })
  }

  errorMsg = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.errorMessage !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.errorMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  saveOK = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.saveOKMsg !== ''}
        autoHideDuration={6000}
        onClose={this.handleCloseMsg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.saveOKMsg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            // className={classes.close}
            onClick={this.handleCloseMsg}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.openLoader();

    const fields = e.target.elements;

    var post = fields.CText.value.replace("{", "");
    post = post.replace("}", "");
    post = post.replace(/"/g, "");

    var jsonStructure = {};
    jsonStructure.NLP = {};
    jsonStructure.NLP.connectionPath = post //fields.CText.value.replace(/['"]+{}/g, "");
    jsonStructure.NLP.provider = "microsoft";


    var newNLP = {
      NLP: jsonStructure.NLP
    }

    this.props.openLoader();
    this.verifyCredentials(newNLP)
      .then(result => {
        ReactGA.event({
          category: 'Chat',
          action: 'Update NLP QnA'
        })
        this.updateChat(this.props.chatID, { NLP: { ...result.data.data.NLP, originalInput: jsonStructure.NLP.connectionPath } });

      }).catch(err => {

        this.props.closeLoader();
        ReactGA.event({
          category: 'Chat',
          action: 'UPDATE QnA Bad Credentials'
        }, ['backTrackerV2', 'backTrackerV3'])
        
        //slack notify
        newNLP.chatID = this.props.chatID
        slackNotify(JSON.stringify(newNLP))


        this.setState({ errorMessage: tRoot('badCredentialErrorMsg') })
      })



  };


  verifyCredentials = (credentials) => {
    return validateChatCredentials(credentials)
  }

  updateChat = (chatID, jsonStructure) => {
    chatService
      .updateChatConfig(chatID, jsonStructure)
      .then(data => {
        this.props.closeLoader();
        this.setState({ saveOKMsg: tRoot('credentialSaveOKMsg') })
      })
      .catch(err => {
        this.props.closeLoader();
        this.setState({ errorMessage: tRoot('badCredentialErrorMsg') })
      });
  };

 
  render() {
    return (
      <div className="engine-select">
        <div className="grid-container">
          <div className="inputs">
            <div className="form-container">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group more-info">
                  
                  <TextField
                      id="CText"
                      label={tRoot('qnaCredentialTittle')}
                      multiline
                      value={this.state.jsonConfig}
                      onChange={this.handleJsonConfig}
                      rows="8"
                      margin="normal"
                      variant="outlined"
                      style={{width:'400px'}}
                    />

                </div>
                <div className="form-group more-info">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={lang() === 'es' ? 'https://supportes.blahbox.net/portal/kb/articles/integrar-microsoft-qna-maker' : 'https://supporten.blahbox.net/portal/kb/articles/ingegrate-microsoft-qna-maker'}
                  >
                    {tRoot('needMoreInformation')}
                  </a>
                </div>
                <div className="form-group">
                  <button className="button submit" type="submit">
                    {tRoot('go')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="selected-engine">
            <div className="shadow-border-box">
              <img src={engineIconCortana} alt="Cortana" />
              <p>QnA Maker</p>
            </div>
          </div>
          <div className="change">
            <h3 className="title">{tRoot('selectNLPEngine')}</h3>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("google")}
            >
              <img src={engineIconDialogflow} alt="dialogflow" />
              <p>DIALOGFLOW</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("dialogFlowCx")}
            >
              <img src={engineIconDialogflowCX} alt="dialogflow cx" />
              <p>DIALOGFLOW CX</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("aws")}
            >
              <img src={engineIconAmazonLex} alt="amazon lex" />
              <p>AMAZON LEX</p>
            </div>
            <div
              className="white-box"
              onClick={() => this.props.changeMotor("watson")}
            >
              <img src={engineIconWatson} alt="watson assistant" />
              <p>WATSON ASSISTANT</p>
            </div>
          </div>
        </div>
        {this.errorMsg()}
        {this.saveOK()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.baseData.currentChatConfig.NLP.provider.private_key)
  return {
    chatID: state.baseData.currentChatConfig._id,
    nlp: state.baseData.currentChatConfig.NLP,
    jsonConfig: state.baseData.currentChatConfig.NLP && state.baseData.currentChatConfig.NLP.provider === 'microsoft' ? state.baseData.currentChatConfig.NLP.originalInput : ''
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader: () => dispatch({ type: 'OPEN_LOADER' }),
    closeLoader: () => dispatch({ type: 'CLOSE_LOADER' })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cortana);

