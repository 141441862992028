const checkAccess = (accessRequest) => {
    var ua = JSON.parse(localStorage.getItem("chatAccess"))
    var access = false

    if (!ua) return false

    if (ua.admin) return true

    ua.permissions.forEach(doc => {
        access = doc === accessRequest ? true : access
    })

    return access
}

module.exports = {
    checkAccess
}