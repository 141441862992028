import React from "react";
import "./Register.scss";
import { userActions } from "../../actions";
import Logo from "../../assets/img/logo.svg";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: "",
      user: {
        email: "",
        password: "",
        verifyPassword: "",
        latitude: "",
        longitude: ""
      },
      fields: {
        email: "",
        password: "",
        verifyPassword: ""
      },
      errors: {},
      submitted: false
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  componentDidMount(props) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;

        let user = Object.assign({}, this.state.user);
        user.latitude = latitude;
        user.longitude = longitude;
        this.setState({ user });
      },
      () => {
        console.log("Cannot get user location");
      }
    );
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    const formContainer = document.querySelector(".register-container form");
    var emailFormGroup = formContainer.querySelector(".form-group.email");
    var passwordFormGroup = formContainer.querySelector(".form-group.password");
    var verifyPasswordFormGroup = formContainer.querySelector(
      ".form-group.verify-password"
    );

    // email regex validation
    if (typeof fields["email"] !== "undefined") {
      if (
        !fields["email"].match(
          /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        formIsValid = false;
        errors["email"] = "Por favor ingresa un email válido";
        emailFormGroup.classList.add("has-error");
      } else {
        emailFormGroup.classList.remove("has-error");
      }
    }

    // password required
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Este campo es obligatorio";
      passwordFormGroup.classList.add("has-error");
    } else {
      passwordFormGroup.classList.remove("has-error");
    }

    // verify password required
    if (!fields["verifyPassword"]) {
      formIsValid = false;
      errors["verifyPassword"] = "Este campo es obligatorio";
      verifyPasswordFormGroup.classList.add("has-error");
    } else {
      verifyPasswordFormGroup.classList.remove("has-error");
    }

    // verify password match
    if (typeof fields["verifyPassword"] !== "undefined") {
      if (fields["verifyPassword"] !== fields["password"]) {
        formIsValid = false;
        errors["verifyPassword"] = "Las contraseñas no coinciden";
        verifyPasswordFormGroup.classList.add("has-error");
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });

    let user = this.state.user;
    user[field] = e.target.value;
    this.setState({ user });
  }

  clearErrors() {
    const _ = require("lodash");
    const errors = document.querySelectorAll(".user-messages-container .alert");

    if (errors) {
      _.forEach(errors, function(element) {
        element.style.display = "none";
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.clearErrors();

    const { user } = this.state;

    if (this.handleValidation()) {
      event.preventDefault();

      // show spinner
      const spinner = document.querySelector(".spinner-container");
      if (spinner) {
        spinner.style.display = "flex";
      }

      // disable button
      const button = this.refs.submitBtn;
      button.disabled = true;
      button.classList.add("disabled");

      this.setState({ submitted: true });

      userActions.register(user);
    }
  }

  onFocus(event) {
    const focusParent = event.target.parentNode;
    focusParent.classList.add("focus");
  }

  onBlur(event) {
    const focusParent = event.target.parentNode;

    if (event.target.value === "") {
      focusParent.classList.remove("focus");
    }
  }

  render() {
    return (
      <div className="register padding-top-bottom">
        <section>
          <div className="page-contents">
            <header>
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <div className="info-container">
                <h1>Regístrate</h1>
                <p>
                  ¿Ya tienes una cuenta con Chat Box?{" "}
                  <a href="/login">Inicia Sesión</a>
                </p>
              </div>
            </header>
            <div className="register-container flex-container">
              <div className="form-container border-right">
                <form name="form" onSubmit={this.handleSubmit}>
                  <div className={"form-group email"}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={this.handleChange.bind(this, "email")}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      value={this.state.fields["email"]}
                    />
                    <div className="error-message">
                      <p>{this.state.errors["email"]}</p>
                    </div>
                  </div>
                  <div className={"form-group password"}>
                    <label htmlFor="password">Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.handleChange.bind(this, "password")}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      value={this.state.fields["password"]}
                    />
                    <div className="error-message">
                      <p>{this.state.errors["password"]}</p>
                    </div>
                  </div>
                  <div className={"form-group verify-password"}>
                    <label htmlFor="verifyPassword">
                      Repetir la contraseña
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="verifyPassword"
                      onChange={this.handleChange.bind(this, "verifyPassword")}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      value={this.state.fields["verifyPassword"]}
                    />
                    <div className="error-message">
                      <p>{this.state.errors["verifyPassword"]}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="button submit" ref="submitBtn">
                      Registrarse
                    </button>
                  </div>
                  <div className="form-group user-messages-container">
                    <div className="success-msg alert success">
                      Successful operation
                    </div>
                    <div className="generic-error alert danger">
                      Error creating user
                    </div>
                    <div className="email-mandatory alert warning">
                      Email is mandatory
                    </div>
                    <div className="existing-user alert warning">
                      Existing user
                    </div>
                    <div className="cannot-create alert danger">
                      User can not register. Try Again.
                    </div>
                  </div>
                </form>
              </div>
              <div className="social-login">
                <div className="hidden-xs message">o conectarse con</div>
                <button href="#" className="social-item">
                  <div className="icon facebook" />
                  <div className="social-link facebook">
                    Continuar con Facebook
                  </div>
                </button>
                <button href="#" className="social-item">
                  <div className="icon google" />
                  <div className="social-link google">Continuar con Google</div>
                </button>
              </div>
            </div>
          </div>

          <div className="tyc">
            <p>
              * Al iniciar tu sesión, aceptas nuestros Térrminos de Uso y
              recibirás correos electrónicos y actualizaciones de ChatBox; al
              reconocer que leíste nuestra Polírtica de Privacidad.
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default Register;
