import styled from 'styled-components';
import SquareIconMaterial from '@material-ui/icons/CropSquare';
import CircleIconMaterial from '@material-ui/icons/RadioButtonUnchecked';

export const SquareIcon = styled(SquareIconMaterial)`
    &&{
        font-size: 70px;

    }
`

export const DiamondIcon = styled(SquareIconMaterial)`
    &&{
        font-size: 60px;
        transform: rotate(45deg);

    }
`

export const CircleIcon = styled(CircleIconMaterial)`
    &&{
        font-size: 60px;

    }
`

export const Button = styled.button`
    border:0px;
    background: transparent !important;
    cursor:pointer;
    width:75px;
`