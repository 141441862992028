import React from 'react'
import Dropzone from 'react-dropzone'
import HeaderConfig from '../../../HeadLine/index'
import styled from 'styled-components'
import classNames from 'classnames'
import { view as ImageCropper } from '../ImageCropper/view'
import i18n from '../../../../../../../i18n/i18n'
import Feedback from '../../../../../../../components/feedback'

const t = i18n('chatSettings')
const tRoot = i18n()

const Conteiner = styled.div`
    display: grid; 

`
const ConteinerDropZxone = styled.div`
    display: grid;
    grid-template-rows: 4fr 1fr;
    background: #fff;
    height: 200px;
    width: 400px;
    margin: auto; 
    border-radius: 6px;
    border: 2px dashed #546e84;
    padding:20px;
    padding-top:50px;
`
const Cancel= styled.button`
    display: grid;
    background: #324a5e;
    border-radius: 4px;
    width:100px;
    margin:auto;
    padding:10px;
    color:#fff;
    margin-bottom: 30px;
    
`


const AvatarDropZone = ({ onDropFile, cancelImageAvatar }) => {
    
    return (
        <ConteinerDropZxone>
            <Dropzone onDrop={onDropFile}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <div
                            {...getRootProps()}
                            className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                        >
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop files here...</p> :
                                    <p>{t('msgToUpload')}</p>
                            }
                        </div>

                    )
                }}
            </Dropzone>
            <Cancel onClick={e => cancelImageAvatar()}>{tRoot('cancel')}</Cancel>
        </ConteinerDropZxone>)
}

// eslint-disable-next-line 
export const view = ({}, { rawFile, crop }, onDropFile, onImageLoaded, onCropComplete, onCropChange, confirmImageAvatar, cancelImageAvatar) => {

    return (

        <Conteiner>
            <HeaderConfig title={tRoot('image')} />
            {rawFile ? <ImageCropper
                src={rawFile}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onCropComplete={onCropComplete}
                confirmImageAvatar={confirmImageAvatar}
                cancelImageAvatar={cancelImageAvatar}
                onCropChange={onCropChange} /> : <AvatarDropZone onDropFile={onDropFile} cancelImageAvatar={cancelImageAvatar} />}
        <Feedback seccion="Header-AvatarUpload" previewMode/>
        </Conteiner>
    )
}