import React from 'react'
import styled from 'styled-components'
import {styleSelected, unSelected} from '../styles'

const ButtonRadius = styled.button`
    width: 75px;
    height: 75px;
    border-radius: 3px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    & svg{
        margin-top:10px;
    }
`


export  const  ButtonSendPlain = ({handleSelect, currentImage}) => {
    return(
      <ButtonRadius  onClick ={e => handleSelect('https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send1.svg')} style={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send1.svg' ? styleSelected : unSelected}>  
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="75%" height="75%" viewBox="0 0 565 565" enableBackground="new 0 0 565 565"  space="preserve">
        <g>
            <g>
                <path fill={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send1.svg' ? '#fff': '#3F566A'} d="M485.121,14.371l-468.18,245.5c-19.251,10.09-18.46,24.885,1.735,32.888l59.78,23.633
                    c20.156,7.967,50.752,3.646,67.882-9.763l259.6-204.703c17.071-13.443,18.903-11.457,4.07,4.418l-205.243,219.71
                    c-14.873,15.837-10.435,35.224,9.782,43.111l7.002,2.739c20.213,7.833,53.296,20.988,73.416,29.206l66.3,26.967
                    c20.137,8.197,36.611,15.008,36.611,15.121c0,0.116,0.116,0.485,0.21,0.503c0.098,0.039,4.861-17.054,10.554-38.041
                    l101.098-371.48C515.426,13.176,504.373,4.282,485.121,14.371z"/>
                <path fill={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send1.svg' ? '#fff': '#3F566A'} d="M206.926,394.07L161.055,375.3c-20.1-8.235-29.745,1.428-21.413,21.509
                    c0.02,0,42.592,102.603,41.416,106.21c-1.234,3.608,40.836-60.877,40.836-60.877C233.796,423.928,227.025,402.308,206.926,394.07z
                    "/>
            </g>
        </g>
        </svg>
    </ButtonRadius>   
    )
}