import React from 'react'
import { view } from './view'
import { updateSuscription } from '../../../rootActions'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'

const getQueryParam = param => {
  var result = window.location.search.match(
    new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)")
  );

  return result ? result[3] : false;
}

class Thanks extends React.Component {
  state = {
    result: {},
    chatName: ''
  }
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/dashboard/chat-settings/thankyou", ['backTrackerV2', 'backTrackerV3'])


    const refno = getQueryParam('refno')
    this.setState({ chatName: this.props.chatName })
    updateSuscription(refno, this.props.chatId).then(result => {
      this.setState({ result: result.data.order })
      this.props.closeLoader()
    }).catch(err => {
      this.props.closeLoader()
      console.log(err)
    })
  }

  componentDidMount() {
    this.props.openLoader()
  }


  render = () => view(this.state, this.props.user, this.props.openLoader)

}

const mapStateToProps = state => {

  return {
    chatId: state.baseData.currentChatConfig._id,
    chatName: state.baseData.currentChatConfig.name,
    user: state.auth.userLogged
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoader: () => dispatch({ type: 'OPEN_LOADER' }),
    closeLoader: () => dispatch({ type: 'CLOSE_LOADER' })
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Thanks)
