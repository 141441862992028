import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateHeaderName } from '../../../actionCreators.js';
import compose from 'recompose/compose';


class HeaderName extends React.Component {
	state = {
		tittle: '',
		color: '',
		font: '',
		fontSize: '',
		textAlignment: "",
		showChooseColor: false,
		tittleInFocus: false
	}

	UNSAFE_componentWillMount = () => this.setState({ ...this.props.data })
	// eslint-disable-next-line
	constructor(props) { super(props); }
/* 
	componentDidMount () {
		const script = document.createElement("script");
		
		script.src = "https://desk.zoho.com/portal/api/feedbackwidget/391591000000173233?orgId=688843946&displayType=popout";
		script.async = true;
	
		document.body.appendChild(script);
	} */

	handleCloseColorShow = () => this.setState({ showChooseColor: false })
	handleChooseColorShow = () => this.setState({ showChooseColor: !this.state.showChooseColor })
	handleColorClick = (color, event) => this.setState({ color: color.hex }, () => {
		window.chatbot.updateChatTopStyles({
			color: color.hex, 
			buttonColor: color.hex, 
			buttonBackgroundHoverColor: color.hex
		})
		
			this.props.save(this.state, this.props.chatId)
		
		//!this.state.showChooseColor && !this.state.tittleInFocus ? this.props.save(this.state, this.props.chatId)
	})
	handleTittleChange = (event) => this.setState({ tittle: event.target.value, tittleInFocus: true })
	handleFont = event => this.setState({ font: event.target.value }, () => {
		window.chatbot.updateChatTopStyles({
			fontFamily: event.target.value
		})
		this.props.save(this.state, this.props.chatId)
	})

	handleFontSize = (event) => this.setState({ fontSize: event.target.value }, () => {
		window.chatbot.updateChatTopStyles({
			fontSize: event.target.value
		})
		this.props.save(this.state, this.props.chatId)
	})

	handleTextAlignment = selected => this.setState({ textAlignment: selected }, () => {
		window.chatbot.updateChatTopStyles({
			textAlign: selected,
		})

		this.props.save(this.state, this.props.chatId)
	})

	handleTittleLostFocus = () => this.setState({ tittleInFocus: false }, () => {
		window.chatbot.updateChatTopName(this.state.tittle);
		this.props.save(this.state, this.props.chatId)
	})

	render = () => view(
		this.props,
		this.state,
		this.handleChooseColorShow,
		this.handleColorClick,
		this.handleTittleChange,
		this.handleFont,
		this.handleFontSize,
		this.handleTextAlignment,
		this.handleTittleLostFocus,
		this.handleCloseColorShow
	)
}


const mapStateToProps = state => {
	return {
		data: state.chatSettings.HeaderNameResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (data, chatId) => updateHeaderName(data, chatId).then(result => dispatch(result)).catch(err => console.log(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderName))
export default compose(
	withStyles(styles, { name: 'HeaderName' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(HeaderName)
  

