import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateSenderSectionBackground } from "../../../actionCreators.js"
import compose from 'recompose/compose';

class SenderSectionBackground extends React.Component {
	state = {
		background: '',
		showColorPicker: false,
		activeShadow: false
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => this.setState({ ...this.props.data })
	handleChooseColor = (color, event) => this.setState({ background: color.hex }, () => {
		window.chatbot.updateWriteSectionStyles({ "backgroundColor": color.hex })
		this.props.save(this.state, this.props.chatId)
	})
	handleCloseColorPicker = () => this.setState({ showColorPicker: false })
	handleShowColorPicker = () => this.setState({ showColorPicker: !this.state.showColorPicker })

	handleActiveShadow = async () => {
		
		await this.setState({ activeShadow: !this.state.activeShadow })
		window.chatbot.updateWriteSectionStyles({
			boxShadow: this.state.activeShadow ? "0 10px 30px rgba(0, 0, 0, 0.15)" : "none"
		})

		this.props.save(this.state, this.props.chatId)

	}

	render = () => view(
		this.props.classes,
		this.state.background,
		this.handleChooseColor,
		this.state.showColorPicker,
		this.handleShowColorPicker,
		this.state.activeShadow,
		this.handleActiveShadow,
		this.handleCloseColorPicker)
}


const mapStateToProps = state => {
	return {
		data: state.chatSettings.SenderSectionBackgroundResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (value, chatId) => updateSenderSectionBackground(value, chatId).then(result => dispatch(result))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SenderSectionBackground))
export default compose(
	withStyles(styles, { name: 'SenderSectionBackground' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(SenderSectionBackground)