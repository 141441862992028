import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Tooltip from '@material-ui/core/Tooltip';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ReactGA from 'react-ga'

import { deleteUserAccess, userAccessUpdate } from "../hooksActionCreators"
import i18n from '../../../../i18n/i18n'

const tRoot = i18n()

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    clara: {
        display: 'inline',
        margin: '8px 0px 8px 6px',
        color: '#999'
    },
    secondaryItem: {
        align: 'right'
    },
    button: {
        margin: theme.spacing(1),
        color: '#fff',
        backgroundColor: '#2196F3',
        "&:hover": {
            backgroundColor: "#9044AD"
        }
    },
    input: {
        display: 'none',
    },
});


function AlignItemsList(props) {

    const [deleteAccess, setdeleteAccess] = useState({ openDeleteConfirmation: false, idUA: null, email: '' })
    const [accessChange, setAccessChange] = useState({ openAccessChange: false, idUA: null, email: '', oldRole: '', newRole: '' })


    const delAccessDialog = (user) => setdeleteAccess({ openDeleteConfirmation: true, idUA: user.idUA, email: user.email })
    const closeDeleteModal = () => setdeleteAccess({ openDeleteConfirmation: false, idUA: null, email: '' })

    const userAccessChange = (user) => setAccessChange({ openAccessChange: true, idUA: user.idUA, email: user.email, oldRole: user.role, newRole: user.role })
    const closeAccessChange = () => setAccessChange({ openAccessChange: false, idUA: null, email: '', oldRole: '', newRole: '' })

    const confirmDelete = () => {
        deleteUserAccess(props.chatID, deleteAccess.idUA).then(result => {
            closeDeleteModal()
            props.fetchData()

            ReactGA.event({
                category: 'Chat',
                action: 'Delete User Access'
              }, ['backTrackerV2', 'backTrackerV3'])

        }).catch(err => console.log(err))

    }

    const handleRoleChange = (event) => setAccessChange({ ...accessChange, newRole: event.target.value })

    const updateUserAccess = () => {
        userAccessUpdate(props.chatID, accessChange.idUA, accessChange.newRole).then(result => {
            closeAccessChange()
            props.fetchData()

            ReactGA.event({
                category: 'Chat',
                action: 'Update User Role'
              }, ['backTrackerV2', 'backTrackerV3'])

        }).catch(err => console.log(err))
    }

    const { classes } = props;
    return (
        <div>
            <Dialog
                open={deleteAccess.openDeleteConfirmation}
                onClose={closeDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{tRoot('deleteMember')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <WarningRoundedIcon style={{ color: 'red', fontSize: 40 }} />
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {tRoot('deleteWarning')} {deleteAccess.email} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal} color="primary">
                        {tRoot('cancel')}
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        {tRoot('delete')}
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={accessChange.openAccessChange}
                onClose={closeAccessChange}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{tRoot('editRole')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {tRoot('editWarning')} {accessChange.email} ?
                    </DialogContentText>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="role" name="role1" value={accessChange.newRole} onChange={handleRoleChange}>
                            <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                            <FormControlLabel value="publisher" control={<Radio />} label="Publisher" />
                            <FormControlLabel value="editor" control={<Radio />} label="Editor" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAccessChange} color="primary">
                    {tRoot('cancel')}
                    </Button>
                    <Button onClick={updateUserAccess} color="primary" autoFocus>
                    {tRoot('go')}
                    </Button>
                </DialogActions>
            </Dialog>

            <List className={classes.root}>
                {props.users.map(user => (
                    <ListItem alignItems="flex-start" key={user.idUA}>
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={user.pic ? user.pic : ''} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={user.email}
                            secondary={
                                <React.Fragment>
                                    <Typography component="span" className={classes.inline} color="textPrimary">
                                        {user.admin === true ? 'Admin' : tRoot(user.role)}
                                    </Typography>
                                    <Typography component="span" className={classes.clara} color="textPrimary">
                                        {user.status === 'Pending' ? ' - ' + tRoot(user.status) : ''}
                                    </Typography>

                                </React.Fragment>
                            }
                        />
                        <ListItemSecondaryAction>
                            {user.actualUser ?
                                <Button disabled variant="contained" color="primary" onClick={e => userAccessChange(user)} className={classes.button}>
                                    {tRoot('editRole')}
                                </Button>
                                :
                                <Button variant="contained" color="primary" onClick={e => userAccessChange(user)} className={classes.button}>
                                    {tRoot('editRole')}
                                </Button>
                            }

                            {user.actualUser ?

                                <IconButton disabled aria-label="Delete" onClick={e => delAccessDialog(user)}>
                                    <DeleteIcon />
                                </IconButton>
                                :
                                <Tooltip title={tRoot('deleteMember')} placement="bottom-end">
                                    <IconButton aria-label="Delete" onClick={e => delAccessDialog(user)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }

                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

AlignItemsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlignItemsList);
