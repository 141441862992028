import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components'
import { SketchPicker } from 'react-color';
import i18n from '../../../../../../i18n/i18n'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Feedback from '../../../../../../components/feedback'

const tRoot = i18n()

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
`

const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`
export const styles = theme => ({
    cssLabel: {
        fontSize: '18px',
        marginBottom: '10px',

        '&$cssFocused': {
            color: '#333333',
            fontSize: '20px',
            marginBottom: '10px',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: '#ef7e29',

        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: '#ef7e29',

        },
    },
    notchedOutline: {}
});


export const view = (classes, background, handleChooseColor, showColorPicker, handleShowColorPicker, handleCloseColorPicker) => {
    
    return (
        <div>
            <HeaderConfig title={tRoot('background')} />
            <Content>
            <ClickAwayListener onClickAway={handleCloseColorPicker}>
                <FormControl className={classes.margin}>
                    <InputLabel
                        classes={{
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        }} >
                        {tRoot('color')}
                    </InputLabel>
                    <Input
                        id="background"
                        value={background}
                        classes={{
                            underline: classes.cssUnderline,
                        }} />
                    <Color style={{background}} onClick={handleShowColorPicker}></Color>
                    {showColorPicker ? <SketchPicker
                        onChangeComplete={handleChooseColor}
                        color={background} /> : ""}
                </FormControl>
                </ClickAwayListener>
                <Feedback seccion="CallOuts-Backgound" previewMode/>
            </Content>
        </div>
    )
}


