import React from 'react'
import styled from 'styled-components'
import {styleSelected, unSelected} from '../styles'

const ButtonRadius = styled.button`
    width: 75px;
    height: 75px;
    border-radius: 3px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    & svg{
        margin-top:7px;
    }
`


export  const  ButtonSendArrowWhite = ({handleSelect, currentImage}) => {
    return(
      <ButtonRadius onClick ={e => handleSelect('https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send2.svg')} style={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send2.svg' ? styleSelected : unSelected }>  
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"   xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             width="75%" height="75%" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" space="preserve">
        <path fill={currentImage === 'https://res.cloudinary.com/blahbox/image/upload/sendButton/edit-send2.svg' ? '#fff' : '#3F566A'} d="M478.588,243.588L51.302,46.379c-6.804-3.09-14.921-1.25-19.655,4.602
            c-4.765,5.851-4.93,14.167-0.394,20.181l140.511,187.35L31.253,445.859c-4.536,6.016-4.371,14.362,0.362,20.182
            c3.188,3.979,7.953,6.112,12.784,6.112c2.333,0,4.667-0.493,6.869-1.512l427.286-197.208c5.851-2.696,9.564-8.515,9.564-14.924
            C488.119,252.101,484.406,246.283,478.588,243.588z"/>
        </svg>
    </ButtonRadius>   
    )
}