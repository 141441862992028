import React from 'react'
import {connect} from 'react-redux'
import { view } from './view'

class Error503 extends React.Component {
    // eslint-disable-next-line
    constructor(props){
        super(props)
    }

    render = () => view()
}


const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return { 

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Error503)
