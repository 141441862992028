import React, { useState, useEffect } from 'react'
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import {
    Content,
    FormControl,
    InputLabel,
    Input,
    Color,
    Column,
    Select,
    MenuItem
} from './styled'
import HeaderConfig from '../../HeadLine/index'
import i18n from '../../../../../../i18n/i18n'
import Feedback from '../../../../../../components/feedback'
import Loader from '../../../../../../components/LinearLoader/linearLoader'
import useChat from '../../../../../../hooks/useChat'
import useChatMutation from '../../../../../../hooks/useChatMutation'

const tRoot = i18n()

export const PlaceHolderMessage = () => {
    const chatID = JSON.parse(localStorage.getItem('appState')).baseData.currentChatConfig._id
    const [text, setText] = useState('')
    const [textLostFocus, setTextLostFocus] = useState('')
    const [color, setColor] = useState('')
    const [showChooseColor, setShowChooseColor] = useState(false)
    const [font, setFont] = useState('')
    const [fontSize, setFontSize] = useState('')
    const { isLoading, isError, data: chat = {}, isSuccess } = useChat(chatID)

    const mutation = useChatMutation()

    const changeTemplate = {
        styles: {
            writeSection: {
                color: color,
                fontFamily: font,
                fontSize: fontSize
            }
        },
        config: {
            writeSection: {
                placeholderText: textLostFocus
            }
        }
    }

    if (isError) {
        console.log("Error in Fetch Data")
    }

    const chatUpdate = (change) => {
        mutation.mutate({ chatID, change })
    }

    const handleTextLostFocus = () => {
        setTextLostFocus(text)
        window.chatbot.updatePlacerholderText(text)
        chatUpdate({ ...changeTemplate, config: { writeSection: { placeholderText: text } } })
    }

    const handleText = (event) => {
        setText(event.target.value)
    }

    const handleChooseColor = () => {
        setShowChooseColor(!showChooseColor)
    }

    const handleColor = (newColor) => {
        chatUpdate({ ...changeTemplate, styles: { writeSection: { color: newColor.hex } } })
        setColor(newColor.hex)
        window.chatbot.updateWriteSectionStyles({ "color": newColor.hex })
    }

    const handleCloseChooseColor = () => {
        setShowChooseColor(false)
    }

    const handleFont = (event) => {
        setFont(event.target.value)
        window.chatbot.updateWriteSectionStyles({ "fontFamily": event.target.value })
        chatUpdate({ ...changeTemplate, styles: { writeSection: { fontFamily: event.target.value } } })

    }

    const handleFontSize = (event) => {
        setFontSize(event.target.value)
        window.chatbot.updateWriteSectionStyles({ "fontSize": fontSize })
        chatUpdate({ ...changeTemplate, styles: { writeSection: { fontSize: event.target.value } } })
    }

    useEffect(() => {
        if (isSuccess) {
            setText(chat.config.writeSection.placeholderText)
            setTextLostFocus(chat.config.writeSection.placeholderText)
            setColor(chat.styles.writeSection.color)
            setFont(chat.styles.writeSection.fontFamily)
            setFontSize(chat.styles.writeSection.fontSize)
        }

    }, [chat])

    return (
        <div>
            <HeaderConfig title={tRoot('message')} />
            {isLoading ? <div style={{ 'height': '300px' }}><Loader /></div> :
                <Content>
                    <>
                        <FormControl>
                            <InputLabel>
                                {tRoot('text')}
                            </InputLabel>
                            <Input
                                id="backgrtextound"
                                onBlur={handleTextLostFocus}
                                value={text}
                                onChange={handleText}
                            />
                        </FormControl>
                        <ClickAwayListener onClickAway={handleCloseChooseColor}>
                            <FormControl>
                                <InputLabel>
                                    {tRoot('color')}
                                </InputLabel>
                                <Input
                                    id="background"
                                    value={color}
                                />

                                <Color style={{ background: color }} onClick={handleChooseColor} />
                                {showChooseColor ? <SketchPicker
                                    onChangeComplete={handleColor}
                                    color={color} /> : ""}
                            </FormControl>
                        </ClickAwayListener>
                        <Column>
                            <FormControl>
                                <InputLabel htmlFor="fontInput"> {tRoot('font')}</InputLabel>
                                <Select
                                    value={font}
                                    onChange={handleFont}
                                    inputProps={{
                                        name: 'fontInput',
                                        id: 'fontInput',
                                    }}
                                >
                                    <MenuItem value="Arial">Arial</MenuItem>
                                    <MenuItem value="Arial Black">Arial Black</MenuItem>
                                    <MenuItem value="Comic Sans MS">Comic Sans MS</MenuItem>
                                    <MenuItem value="Courier New">Courier New</MenuItem>
                                    <MenuItem value="Impact">Impact</MenuItem>
                                    <MenuItem value="Lucida Console">Lucida Console</MenuItem>
                                    <MenuItem value="Lucida Sans Unicode">Lucida Sans Unicode</MenuItem>
                                    <MenuItem value="Tahoma">Tahoma</MenuItem>
                                    <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
                                    <MenuItem value="Verdana">Verdana</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel htmlFor='fontSizeInput'> {tRoot('size')}</InputLabel>
                                <Select
                                    value={fontSize}
                                    onChange={handleFontSize}
                                    inputProps={{
                                        name: 'fontSizeInput',
                                        id: 'fontSizeInput',
                                    }}
                                >
                                    <MenuItem value="12px">12</MenuItem>
                                    <MenuItem value="13px">13</MenuItem>
                                    <MenuItem value="14px">14</MenuItem>
                                    <MenuItem value="15px">15</MenuItem>
                                    <MenuItem value="17px">17</MenuItem>
                                    <MenuItem value="20px">20</MenuItem>
                                    <MenuItem value="24px">24</MenuItem>
                                    <MenuItem value="32px">32</MenuItem>
                                </Select>
                            </FormControl>
                        </Column>
                        <Feedback seccion="Message-Message" previewMode />
                    </>
                </Content>
            }
        </div>
    )
}