import React from "react";
import { connect } from "react-redux";
import "./NewChat.scss";
import { chatService } from "../../../services/chat.service";
import chatTypeDesktop from "../../../assets/img/desktop.svg";
import chatTypeMobile from "../../../assets/img/smartphone.svg";
import leftArrow from "../../../assets/img/left-arrow.svg";
import cancelIcon from "../../../assets/img/close.svg";
import classicWidget from "../../../assets/img/classicWidget.jpg";
import elegantWidget from "../../../assets/img/elegantWidget.png";
import { openLoader, closeLoader } from "../../../rootActions";
import i18n from '../../../i18n/i18n'
import ReactGA from 'react-ga'

const tRoot = i18n()

class NewChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      chat: {
        destiny: "",
        name: "",
        description: "",
        widgetType: "",
        NLP: {
          provider: "",
          workspaceID: "",
          key: "",
          password: ""
        }
      },
      fields: {
        name: "",
        workspaceID: "",
        key: "",
        password: ""
      },
      provider: ""
    };

    
    this.onClickPrevious = this.onClickPrevious.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);

    this.optionWeb = this.optionWeb.bind(this);
    this.optionMobile = this.optionMobile.bind(this);

    this.handleNameSubmit = this.handleNameSubmit.bind(this);
    this.createChat = this.createChat.bind(this);
  }

  // common funcitons
  onClickPrevious() {
    const step = this.state.currentStep;
    this.setState({
      currentStep: step - 1
    });
  }

  onClickCancel() {
    window.location = "/dashboard/projects";
  }

  // first step functions
  optionWeb() {
    let chat = Object.assign({}, this.state.chat);
    chat.destiny = "desktop";
    this.setState({
      chat,
      currentStep: 2
    });
  }

  optionMobile() {
    let chat = Object.assign({}, this.state.chat);
    chat.destiny = "mobile";
    this.setState({
      chat,
      currentStep: 2
    });
  }

  // second step functions
  handleNameChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });

    let chat = this.state.chat;
    chat[field] = e.target.value;
    this.setState({ chat });

    const continueButton = this.refs.continueButton;

    if (chat.name !== "") {
      if (continueButton.disabled === true) {
        continueButton.disabled = false;
        continueButton.classList.remove("disabled");
      }
    } else {
      continueButton.disabled = true;
      continueButton.classList.add("disabled");
    }
  }

  handleNameSubmit(event) {
    event.preventDefault();
    this.createChat('classic')
    //this.setState({ currentStep: 3 });
    //this.skip()
  }

  // third step funcitons

  createChat = (widgetType) => {
    ReactGA.event({
      category: 'NewChat',
      action: widgetType
    }, ['backTrackerV2', 'backTrackerV3'])

    this.props.openLoader()

    let { chat } = this.state
  
    chat.widgetType = widgetType;
    this.setState({ chat });

    delete chat['NLP']

    chatService.newChat(chat).then(data => {
      let chatStateID = data;
      this.props.openLoader();
      this.props.history.push({
        pathname: "/dashboard/projects/" + chatStateID,
        state: {
          chatID: chatStateID
        }
      });
    }).catch(err => {

      console.log(err)
      this.props.closeLoader()
    });

  }

  render() {
    const currentStep = this.state.currentStep;

    return (
      <div className="new-chat">
        {currentStep === 1 && (
          <section className="step-one">
            <div className="top">
              <h2>{tRoot('typeOfChat')}</h2>
              <div className=" nav-button cancel" onClick={this.onClickCancel}>
                <img src={cancelIcon} alt="cancel" />
              </div>
            </div>
            <div>
              <div className="chat-type" onClick={this.optionWeb}>
                <img src={chatTypeDesktop} alt="typeDesktop" />
                <div className="cta">{tRoot('forAWebSite')}</div>
              </div>
              <div className="chat-typeMobile" >
                <img src={chatTypeMobile} alt="typeMobile" />
                <div className="cta">{tRoot('comingsoon')}</div>
              </div>

            </div>
          </section>
        )}
        {currentStep === 2 && (
          <section className="step-two">
            <div>
              <div className="top">
                <h2>{tRoot('chatNameMsg')}</h2>
                <div className="nav-button prev" onClick={this.onClickPrevious}>
                  <img src={leftArrow} alt="previous step" />
                </div>
                <div
                  className=" nav-button cancel"
                  onClick={this.onClickCancel}
                >
                  <img src={cancelIcon} alt="cancel" />
                </div>
              </div>
              <div className="form-container">
                <form onSubmit={this.handleNameSubmit}>
                  <div className={"form-group name"}>
                    <input
                      className="form-control"
                      autoFocus
                      name="name"
                      onChange={this.handleNameChange.bind(this, "name")}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="button disabled"
                      disabled
                      ref="continueButton"
                    >
                      {tRoot('go')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        )}
        {currentStep === 3 && (
          <section className="step-three">
            <div className="top">
              <h2>{tRoot('selectwidgetStyle')}</h2>
              <div className="nav-button prev" onClick={this.onClickPrevious}>
                  <img src={leftArrow} alt="previous step" />
              </div>
              <div className=" nav-button cancel" onClick={this.onClickCancel}>
                <img src={cancelIcon} alt="cancel" />
              </div>
            </div>
            <div>
              <div className="widget-type" onClick={() => this.createChat('elegant')}>
                <img src={elegantWidget} alt="Elegant" />
                <div className="cta">Elegant</div>
              </div>
              <div className="widget-type" onClick={() => this.createChat('classic')}>
                <img src={classicWidget} alt="Classic" />
                <div className="cta">Classic</div>
              </div>

            </div>
          </section>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  { openLoader, closeLoader }
)(NewChat);
