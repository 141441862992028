import React from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { selectSetting, setCurrentChat } from "../../rootActions";

import NavItemConfigurations from "../../assets/img/nav-item-configurations.svg";
import "./ChatSettingsNavigation.scss";

import NavItemChatbox from "../../assets/img/nav-item-chatbox.svg";
import NavItemTeam from "../../assets/img/nav-item-team.svg";
import NavItemIntegration from "../../assets/img/nav-item-integration.svg";
import NavItemSuscription from "../../assets/img/configuration_global.svg";
import NavItemIntelligent from "../../assets/img/intelligent.svg";
import DashboardIcon from "../../assets/img/nav-item-dashboard.svg";
import i18n from '../../i18n/i18n'
import { checkAccess } from '../../helpers/chatUserAccess'

import { getChat } from "../../containers/Dashboard/ChatSettings/actionCreators.js"
import ClassicSecondNav from "../../components/ClassicSecondNav"
import ElegantSecondNav from "../../components/ElegantSecondNav"

const tRoot = i18n()

class ChatConfigNavigation extends React.Component {
  state = {
    openSettings: false,
    openSuscription: false
  };

  toggleSettings = () => {
    this.setState({ openSettings: !this.state.openSettings, openSuscription: false });
  };

  toggleSuscription = () => {
    this.setState({ openSuscription: !this.state.openSuscription, openSettings: false });
  };

  loadChatSettings = () => {
    getChat(this.props.chatId).then(result => {
      this.props.setCurrentChat(result.data.data);
    })
  }


  render() {
    const chatID = this.props.chatId

    return (
      <div className="chat-settings-nav">
        <nav>
          <ul>
            <li>
              <img
                src={NavItemChatbox}
                alt="projects"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/dashboard/projects",
                    state: {
                      chatID: chatID
                    }
                  })
                }
              />
              <label title="Projects">{tRoot('projects')}</label>
            </li>
            <li>
              <img
                src={DashboardIcon}
                alt="Dashboard"
                onClick={() => {
                  this.loadChatSettings()
                  this.setState({ openSettings: false, openSuscription: false })
                  this.props.history.push({
                    pathname: "/dashboard/chat-settings/chatlanding",
                    state: {
                      chatID: chatID
                    }
                  })
                }
                }
              />
              <label title="Dashboard">Dashboard</label>
            </li>
            <li id="NLPMenuItem">
              <img
                src={NavItemIntelligent}
                alt="NLP"
                onClick={() => {
                  this.loadChatSettings()
                  this.setState({ openSettings: false, openSuscription: false })
                  this.props.history.push({
                    pathname: "/dashboard/chat-settings/npl",
                    state: {
                      chatID: chatID
                    }
                  })
                }
                }
              />
              <label title="NLP">NLP</label>
            </li>
            <li id="widgetConfig">
              <img
                src={NavItemConfigurations}
                alt="settings"
                onClick={() => {
                  this.loadChatSettings()
                  //abrir menu
                  this.toggleSettings();

                  //Enviar a redux con cada click
                  //this.props.selectSetting("headerImage")
                  this.props.selectSetting("boxSize")
                  this.props.history.push({

                    pathname: "/dashboard/chat-settings/configurations",
                    state: {
                      chatID: chatID
                    }
                  });
                }}
              />
              <label title="Settings">{tRoot('settings')}</label>
            </li>
            <li id="widgetInstall">
              <img
                src={NavItemIntegration}
                alt="integration"
                onClick={() => {
                  this.loadChatSettings()
                  this.setState({ openSettings: false, openSuscription: false })
                  this.props.history.push({
                    pathname: "/dashboard/chat-settings/integration",
                    state: {
                      chatID: chatID
                    }
                  })
                }
                }
              />
              <label title="integration">{tRoot('install')}</label>
            </li>
            {checkAccess("Admin") ?
              <li>
                <img
                  src={NavItemTeam}
                  alt="team"
                  onClick={() => {
                    this.loadChatSettings()
                    this.setState({ openSettings: false, openSuscription: false })
                    this.props.history.push({
                      pathname: "/dashboard/chat-settings/team",
                      state: {
                        chatID: chatID
                      }
                    })
                  }
                  }
                />
                <label title="Team">{tRoot('team')}</label>
              </li>
              : ''}


            <li>
              <img src={NavItemSuscription} alt='General'
                onClick={() => {
                  this.loadChatSettings()
                  //abrir menu
                  this.toggleSuscription();
                }}
              />
              <label title="integration">General</label>
            </li>
          </ul>
        </nav>
        
        {
          this.props.widgetType === 'classic' ?
            <ClassicSecondNav openNav={this.state.openSettings} toggleSettings={this.toggleSettings} />
            :
            <ElegantSecondNav openNav={this.state.openSettings} toggleSettings={this.toggleSettings} />
        }


        {/* <nav
          className={classNames("chat-styles-settings", {
            open: this.state.openSettings
          })}
        >
          <div className="second-level-nav">
            <div className="close" onClick={() => this.toggleSettings()} />
            <header>
              <img src={NavItemConfigurations} alt="settings icon" />
              <h3>{tRoot('settings')}</h3>
            </header>
            <div className="chat-section-container">
              <h4>{tRoot('header')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => this.props.selectSetting("headerImage")}>
                  {this.props.selectedItem === "headerImage" ? " >  " + tRoot('image') : tRoot('image')}

                </li>
                <li onClick={() => this.props.selectSetting("headerName")}>
                  {this.props.selectedItem === "headerName" ? " >  " + tRoot('name') : tRoot('name')}

                </li>
                <li onClick={() => this.props.selectSetting("headerBorders")}>
                  {this.props.selectedItem === "headerBorders" ? " >  " + tRoot('border') : tRoot('border')}

                </li>
                <li
                  onClick={() => this.props.selectSetting("headerBackground")}
                >

                  {this.props.selectedItem === "headerBackground" ? " >  " + tRoot('background') : tRoot('background')}

                </li>
              </ul>
            </div>




            <div className="chat-section-container">
              <h4>{tRoot('dialogbox')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => this.props.selectSetting("dialogBoxCallOuts")}>

                  {this.props.selectedItem === "dialogBoxCallOuts" ? " >  " + t('callOutTittle') : t('callOutTittle')}
                </li>
                <li onClick={() => this.props.selectSetting("dialogBoxBackground")}>
                  {this.props.selectedItem === "dialogBoxBackground" ? " >  " + tRoot('background') : tRoot('background')}
                </li>
              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{tRoot('senderSection')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => this.props.selectSetting("senderSectionMessage")}>

                  {this.props.selectedItem === "senderSectionMessage" ? " >  " + tRoot('message') : tRoot('message')}
                </li>
                <li onClick={() => this.props.selectSetting("senderSectionButton")}>
                  {this.props.selectedItem === "senderSectionButton" ? " >  " + tRoot('button') : tRoot('button')}
                </li>
                <li onClick={() => this.props.selectSetting("senderSectionBackground")}>
                  {this.props.selectedItem === "senderSectionBackground" ? " >  " + tRoot('background') : tRoot('background')}
                </li>
              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{t('floatingIcon')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => { this.props.selectSetting("floatingIconIcon") }}>
                  {this.props.selectedItem === "floatingIconIcon" ? " >  " + tRoot('image') : tRoot('image')}
                </li>
                <li onClick={() => this.props.selectSetting("floatingIconForm")}>
                  {this.props.selectedItem === "floatingIconForm" ? " >  " + t('formTittle') : t('formTittle')}
                </li>
                <li onClick={() => this.props.selectSetting("floatingIconPosition")}>
                  {this.props.selectedItem === "floatingIconPosition" ? " >  " + t('positionTittle') : t('positionTittle')}
                </li>
                <li onClick={() => this.props.selectSetting("floatingIconTooltip")}>
                  {this.props.selectedItem === "floatingIconTooltip" ? " >  " + tRoot('tooltip') : tRoot('tooltip')}
                </li>

              </ul>
            </div>
            <div className="chat-section-container">
              <h4>{tRoot('box')}</h4>
              <ul className="chat-config-links-container">
                <li onClick={() => { this.props.selectSetting("boxSize") }}>
                  {this.props.selectedItem === "boxSize" ? " >  " + tRoot('size') : tRoot('size')}
                </li>
              </ul>
            </div>
          </div>
        </nav> */}


        <nav className={classNames("chat-styles-settings", {
          open: this.state.openSuscription
        })}
        >
          <div className="second-level-nav" style={{ 'overflow': 'hidden' }}>
            <div className="close" onClick={() => this.toggleSuscription()} />
            <header>
              <img src={NavItemSuscription} alt='General' />

              <h3>General</h3>
            </header>
            <div className="chat-section-container">

              <ul className="chat-config-links-container">
                <li onClick={() => {
                  this.props.history.push({
                    pathname: "/dashboard/chat-settings/generalSubscription",
                    state: {
                      chatID: chatID
                    }
                  });
                }}>
                  General
               </li>
                {/*                {checkAccess("Admin") ?
                <li onClick={() => {

                  this.props.history.push({

                    pathname: "/dashboard/chat-settings/subscription",
                    state: {
                      chatID: chatID
                    }

                  });
                }}>
                  {tRoot('suscription')}
                </li>
                : ''} */}
                {checkAccess("Admin") ?
                  <li onClick={() => {

                    this.props.history.push({

                      pathname: "/dashboard/chat-settings/subscription",
                      state: {
                        chatID: chatID
                      }

                    });
                  }}>
                    {tRoot('subscription')}
                  </li>
                  : ''}
                {checkAccess("Admin") ?
                  <li onClick={() => {

                    this.props.history.push({

                      pathname: "/dashboard/chat-settings/usage",
                      state: {
                        chatID: chatID
                      }

                    });
                  }}>
                    {tRoot('usageHistoy')}
                  </li>
                  : ''}

              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedItem: state.baseData.settingSelected,
    chatId: state.baseData.currentChatConfig._id,
    widgetType: state.baseData.currentChatConfig.widgetType
  }
}


export default connect(
  mapStateToProps,
  { selectSetting, setCurrentChat }
)(withRouter(ChatConfigNavigation));
