export const userSuccessHandler = {
  register,
  login
};

function register(response) {
  window.location = "/dashboard/projects";
}

function login(response) {
  if (response.data) {
    let token = response.data.token;
    localStorage.setItem("token", token);
    localStorage.setItem("userID", response.data.user._id)

    window.location = "/dashboard/projects";
  }
}
