
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import Auth from '../../../auth/auth'
import ReactGA from 'react-ga'
import compose from 'recompose/compose';

class Profile extends React.Component {
    state = {
        openChangePasswordConfirmation: false,
        email: this.props.userData.email || this.props.userData.name
    }

    handlers =  {
        closeDialogChangePassword:  () => this.setState({openChangePasswordConfirmation: false}),
        openDialogChangePassword:  () => this.setState({openChangePasswordConfirmation: true}),
        sendRequest: () => {
            const auth = new Auth()
            auth.sendChangePasswordRequest(this.state.email)
            this.handlers.closeDialogChangePassword()
        },
        handleEmail: event => this.setState({email:event.target.value})
    }

    UNSAFE_componentWillMount() {
        ReactGA.pageview("/dashboard/profile", ['backTrackerV2', 'backTrackerV3'])
    }
	// eslint-disable-next-line
    constructor(props) { super(props); }
    render = () => view(
        this.props,
        this.state,
        this.handlers
        
    )
}


const mapStateToProps = state => {
    return {
        userData : state.auth.userLogged
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))
export default compose(
    withStyles(styles, { name: 'Profile' }),
    connect(mapStateToProps, mapDispatchToProps)
  )(Profile)




