import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateSenderSectionButton } from '../../../actionCreators.js';
import compose from 'recompose/compose';

class SenderSectionButton extends React.Component {
	state = {
		image: '',
		mainColor: { //IconColor
			color: '',
			showChooseColor: false
		},
		background: { //backgroundColor
			color: '',
			showChooseColor: false
		},
		colorHover: { //hoverIconColor
			color: '',
			showChooseColor: false
		},
		backgroundHover: { //hoverBackgroundColor
			color: '',
			showChooseColor: false
		}
	}

	UNSAFE_componentWillMount = () => this.setState({ ...this.props.data })

	/* 	hideAllColorPicker = event => {
			const new_state = Object.assign({},this.state)
			new_state.mainColor.showChooseColor = event.target.attributes.class.value.includes('color') ? this.state.mainColor.showChooseColor : false
			new_state.background.showChooseColor = event.target.attributes.class.value.includes('background') ? this.state.background.showChooseColor : false
			new_state.colorHover.showChooseColor = event.target.attributes.class.value.includes('cHover') ? this.state.colorHover.showChooseColor : false
			new_state.backgroundHover.showChooseColor = event.target.attributes.class.value.includes('bHover') ? this.state.backgroundHover.showChooseColor : false
			this.setState({...new_state})
		} */

	handleClosers = {
		closeColor: () => { this.setState({ mainColor: { color: this.state.mainColor.color, showChooseColor: false } }) },
		closeBackground: () => { this.setState({ background: { color: this.state.background.color, showChooseColor: false } }) },
		closeColorHover: () => { this.setState({ colorHover: { color: this.state.colorHover.color, showChooseColor: false } }) },
		closeBackgroundHover: () => { this.setState({ backgroundHover: { color: this.state.backgroundHover.color, showChooseColor: false } }) }
	}

	updateChatBot = (data) => {
		window.chatbot.updateSendButtonStyles({
			"backgroundColor": data.background.color,
			"borderColor": data.background.color,
			"borderWidth": "1px",
			"hoverBorderColor": "",
			"hoverBackgroundColor": data.backgroundHover.color,
			"iconColor": data.mainColor.color,
			"hoverIconColor": data.colorHover.color
		}
		)
	}
	handleMainColor = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ mainColor: { color: this.state.mainColor.color, showChooseColor: !this.state.mainColor.showChooseColor } })
		},
		pickColor: (color) => this.setState({ mainColor: { color: color.hex, showChooseColor: true } }, () => {

			this.updateChatBot(this.state)
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleBackgroundColor = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ background: { color: this.state.background.color, showChooseColor: !this.state.background.showChooseColor } })
		},
		pickColor: (color) => this.setState({ background: { color: color.hex, showChooseColor: true } }, () => {
			this.updateChatBot(this.state)
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleHoverColor = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ colorHover: { color: this.state.colorHover.color, showChooseColor: !this.state.colorHover.showChooseColor } })
		},
		pickColor: (color) => this.setState({ colorHover: { color: color.hex, showChooseColor: true } }, () => {
			this.updateChatBot(this.state)
			this.props.save(this.state, this.props.chatId)
		})
	}

	handleBackgroundHover = {
		handleShow: (e) => {
			//this.hideAllColorPicker(e)
			this.setState({ backgroundHover: { color: this.state.backgroundHover.color, showChooseColor: !this.state.backgroundHover.showChooseColor } })
		},
		pickColor: (color) => this.setState({ backgroundHover: { color: color.hex, showChooseColor: true } }, () => {
			this.updateChatBot(this.state)
			this.props.save(this.state, this.props.chatId)
		})
	}

	selectImage = selected => this.setState({ image: selected }, () => {
		window.chatbot.updateSendIconImage(selected)
		this.props.save(this.state, this.props.chatId)
	})


	// eslint-disable-next-line
	constructor(props) { super(props); }

	render = () => view(
		this.props,
		this.state,
		this.handleMainColor,
		this.handleBackgroundColor,
		this.handleHoverColor,
		this.handleBackgroundHover,
		this.selectImage,
		this.handleClosers)
}


const mapStateToProps = state => {
	return {
		data: state.chatSettings.SenderSectionButtonResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (data, chatId) => updateSenderSectionButton(data, chatId).then(result => dispatch(result)).catch(err => console.log(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SenderSectionButton))
export default compose(
	withStyles(styles, { name: 'SenderSectionButton' }),
	connect(mapStateToProps, mapDispatchToProps)
)(SenderSectionButton)
