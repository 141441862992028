import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateSenderSectionMessage } from '../../../actionCreators.js';
import compose from 'recompose/compose'

class SenderSectionMessage extends React.Component {
	state = {
		text: '',
		color: '',
		font: '',
		fontSize: '',
		showChooseColor: false,
		focusInText: false
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => this.setState({...this.props.data})
	handleCloseChooseColor = () => this.setState({ showChooseColor: false },)
	handleChooseColor = () => this.setState({ showChooseColor: !this.state.showChooseColor },)
	handleColor = (color, event) => this.setState({ color: color.hex },() => {
		window.chatbot.updateWriteSectionStyles({"color":color.hex})
		this.props.save(this.state,this.props.chatId)
	})
	handleFont = (event) => this.setState({ font: event.target.value },() => {
		window.chatbot.updateWriteSectionStyles({"fontFamily":this.state.font})
		this.props.save(this.state,this.props.chatId)
	})
	handleFontSize = event => {this.setState({ fontSize: event.target.value }, () => {
		window.chatbot.updateWriteSectionStyles({"fontSize":this.state.fontSize +"px"})
		this.props.save(this.state,this.props.chatId)
	})}
	handleText = event => this.setState({ text: event.target.value, focusInText: true })
	handleTextLostFocus = () => this.setState({ focusInText: false },() => {
		window.chatbot.updatePlacerholderText(this.state.text)
		this.props.save(this.state,this.props.chatId)
	})

	render = () => view(
		this.props,
		this.state,
		this.handleChooseColor,
		this.handleColor,
		this.handleFont,
		this.handleFontSize,
		this.handleText,
		this.handleTextLostFocus,
		this.handleCloseChooseColor)
}


const mapStateToProps = state => {
	
	return {
		data : state.chatSettings.SenderSectionMessageResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save : (data,chatId) => updateSenderSectionMessage(data,chatId).then(result => dispatch(result)).catch(err => console.log(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SenderSectionMessage))
export default compose(
	withStyles(styles, { name: 'SenderSectionMessage' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(SenderSectionMessage)