
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import compose from 'recompose/compose';
import auth0 from 'auth0-js'

const webAuth = new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENT,
    redirectUri: '/validate', 
    responseType: 'token id_token',
    scope: 'openid email profile'
})

class Login extends React.Component {
    state = {
        userName: {
            value: '',
            errorMessage: '',
        },
        userPassword: {
            value: '',
            errorMessage: '',
        },
    }
    // eslint-disable-next-line
    constructor(props) { super(props); }

    handleUserName = (event) => {
        this.setState({
            userName: {
                value: event.target.value,
                errorMessage: event.target.value ? '' : 'El campo es requerido'
            }
        })
    }
    handleUserPassword = (event) => {
        this.setState({
            userPassword: {
                value: event.target.value,
                errorMessage: event.target.value ? '' : 'El campo es requerido'
            }
        })
    }

    login = () => {
        webAuth.crossOriginVerification()
        webAuth.login({
            realm: 'Username-Password-Authentication',
            username: this.state.userName.value,
            password: this.state.userPassword.value,
          });
    }

    loginWithFacebook = () => {
        webAuth.authorize({
            connection: 'facebook'
        })
    }

    loginWithGoogle = () => {
        webAuth.authorize({
            connection: 'google-oauth2',
            responseType: "token",
            client_id: "Yrfawa4r0b6Slh1gjDGwwREkVJbeFKb0",
            
        })
    }




    render = () => view(
        this.props,
        this.state,
        this.handleUserName,
        this.handleUserPassword,
        this.login,
        this.loginWithFacebook,
        this.loginWithGoogle
    )
}


const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
export default compose(
    withStyles(styles, { name: 'Login' }),
    connect(mapStateToProps, mapDispatchToProps)
  )(Login)
