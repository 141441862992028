import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateHeaderBoders } from '../../../actionCreators.js';
import { handleUnexpectedError } from '../../../../../../components/ErrorHandler/index';
import compose from 'recompose/compose';


class HeaderBorders extends React.Component {
	state = {
		border: '',
		boxShadow: false
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => {
		this.setState({ border: this.props.borderSelected, boxShadow: this.props.activeShadow })
		// this.setState({ border: this.props.borderSelected})
	}

	handleClick = value => this.setState({ border: value }, () => {
		let radius = "0px"

		switch (value) {
			case "RECTANGLERADIUS":
				radius = "10px"
				break
			case "CIRCLE":
				radius = "25px"
				break
			default:
				break
		}

		window.chatbot.updateChatTopStyles({
			borderTopLeftRadius: radius,
			borderTopRightRadius: radius,
			borderBottomLeftRadius: "0px",
			borderBottomRightRadius: "0px"
		})

		this.props.save(this.state.border, this.state.boxShadow, this.props.chatId)
	})

	 handleActiveShadow = async () => {
		
		await this.setState({ boxShadow: !this.state.boxShadow })
		
		if (this.state.boxShadow) {
			window.chatbot.updateChatTopStyles({ "boxShadow": "0 10px 20px rgba(0, 0, 0, 0.2)" })
		} else {
			window.chatbot.updateChatTopStyles({ "boxShadow": "none" })
		}

		this.props.save(this.state.border, this.state.boxShadow, this.props.chatId)
	}
	render = () => view(this.handleClick, this.handleActiveShadow, this.state.border, this.state.boxShadow)
}


const mapStateToProps = state => {
	return {
		borderSelected: state.chatSettings.HeaderBordersResult.value.border,
		activeShadow: state.chatSettings.HeaderBordersResult.value.boxShadow,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (border, boxShadow, chatId) => updateHeaderBoders(border, boxShadow, chatId).then(result => dispatch(result)).catch(err => handleUnexpectedError(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderBorders))
export default compose(
	withStyles(styles, { name: 'HeaderBorders' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(HeaderBorders)