import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateELBoxBackground } from "../../../actionCreators.js"
import compose from 'recompose/compose'

class BoxBackground extends React.Component {
	state = {
		boxColor: '',
		boxOpacity: '',
		boxBlur: '',
		showColorPicker : false
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	UNSAFE_componentWillMount = () => {
		this.setState({ ...this.props.data })
	}

	handleChooseColor = (color,event) => this.setState({boxColor:color.hex}, () => {
		window.chatbot.updateMessagesSectionStyles({"backgroundColor":color.hex})
		this.props.save(this.state, this.props.chatId)
		
	}) 

	handleCloseColorPicker = () => this.setState({showColorPicker: false})
	handleShowColorPicker = () => this.setState({showColorPicker:!this.state.showColorPicker})

	handleOpacityChange = (e, newOpacity) => {
		let opacity = newOpacity
		//comando para carbiar en preview
		//window.chatbot.updateChatFrameWidth(opacity)

		this.setState({ boxOpacity: opacity.toString() })
	}

	handleBlurChange = (e, newBlur) => {
		let blur = newBlur
		//comando para carbiar en preview
		//window.chatbot.updateChatFrameWidth(color)

		this.setState({ boxBlur: blur.toString() })
	}

	handleChangeCommitted = () => {
		this.props.save(this.state, this.props.chatId)
	}

	render = () => view(
		this.props.classes,
		this.state.boxColor,
		parseFloat(this.state.boxOpacity),
		parseFloat(this.state.boxBlur),
		this.handleChooseColor,
		this.handleOpacityChange,
		this.handleBlurChange,
		this.handleChangeCommitted,
		this.state.showColorPicker,
		this.handleShowColorPicker,
		this.handleCloseColorPicker
	)
}


const mapStateToProps = state => {
	return {
		data: state.chatSettings.ElBoxSectionBackgroundResult.value,
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (data, chatId) => updateELBoxBackground(data, chatId).then(result => dispatch(result))
	}
}

export default compose(
	withStyles(styles, { name: 'BoxBackground' }),
	connect(mapStateToProps, mapDispatchToProps)
)(BoxBackground)
