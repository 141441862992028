import React, { useState, useEffect } from 'react'

import Dropzone from '../DropZoneB/DropZone'
import CropImage from '../CropImage/CropImage'
import { DropCropSave, Drop, Crop, CurrentImage, ImageLoaded, UpLoadButton } from './styled'
import i18n from '../../i18n/i18n'
import { imageUpload } from '../../containers/Dashboard/ChatSettings/hooksActionCreators'

const tRoot = i18n()


const DropCropSaveImage = ({ customAvatarURL, setCustomAvatarURL, setErrorMessage, chatID, imgWidth, imgHeight }) => {
    const [image, setImage] = useState(null)
    const [step, setStep] = useState(1)
    const [croppedImage, setCroppedImage] = useState(null)

    const handlerStep = step => setStep(step)

    const handlerUploadButton = () => {
        handlerStep(2)
    }

    const handlerCancel = () => {
        setImage(null)
        handlerStep(1)
    }

    useEffect(() => {
        const imageUpload = () => {
            //TODO upload logic 
            //console.log("base64 CroppedImage: ", croppedImage)
        }
        imageUpload()
    }, [croppedImage])

    const handleImageChange = (imageCropped) => {
        //hay que cambiar el metodo en el core para poder enviar cualquier imagen
        imageUpload(imageCropped.split(',')[1], 'isologo', chatID)
            .then(result => {
                setCustomAvatarURL(result.data.chat.secure_url)
            }).catch(err => {
                console.log('err :', err);
                setErrorMessage(tRoot('imageToBigMsg'))
            })
    }

    return (
        <DropCropSave>
            {step === 1 &&
                <CurrentImage>
                    <ImageLoaded>
                        <img src={customAvatarURL} alt="logo" width={imgWidth} height={imgHeight} />
                    </ImageLoaded>
                    <UpLoadButton
                        onClick={handlerUploadButton}
                        variant="contained"
                    >
                        {customAvatarURL ? tRoot('change') : tRoot('upload')}
                    </UpLoadButton>
                </CurrentImage>
            }

            {step === 2 &&
                <Drop>
                    <Dropzone setImg={setImage} handlerStep={handlerStep} handlerCancel={handlerCancel} />
                </Drop>
            }

            {(step === 3 && image) &&
                <Crop>
                    <CropImage 
                        handlerStep={handlerStep} 
                        handleImageChange={handleImageChange} 
                        image={image} 
                        handlerCancel={handlerCancel} 
                        setCroppedImage={setCroppedImage} 
                        imgWidth={imgWidth}
                        imgHeight={imgHeight} 
                    />
                </Crop>
            }
        </DropCropSave>
    )
}

export default DropCropSaveImage