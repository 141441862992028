import React from 'react'
import styled from 'styled-components'
const Body = styled.div`
padding: 50px;

`
const Content = styled.div`
margin: 50px auto;
display: table;
border-radius: 3px;
padding: 50px;
width:800px;
text-align:center;
h3{
    font-size:14px;
}
h2{
    font-weight: 900;
    font-size: 65px;
    line-height: 1;
    margin-bottom: 10px;
    opacity: 0.6;
}
`
const ContentText = styled.div`
display:grid;
grid-template-columns: 2fr 1fr;
`

export const styles = theme => ({
 

});


export const view = ( ) => {
  return (
  <Body>
    <Content> 
    <ContentText>
    <img src="https://res.cloudinary.com/dz2gxorkj/image/upload/v1552308786/chat_1.png" alt="error"></img>
    <div style={{textAlign:"center", margin:"auto"}}>
    <h2>Oops!!!</h2>
    <h3>Lo sentimos, pero no posee permisos para acceder o bien su usuario y cláve no son validos</h3>
    
    </div>
    </ContentText>
    </Content>
    </Body>
   
        
    )
}


