import React from 'react'
import styled from 'styled-components'
import {selectedCallOut,unSelectedCallOut} from './styles'

const Button = styled.button`
    border:0px;
    background: transparent !important;
    cursor:pointer;
    width:75px;
`
export  const  RadiusCallOuts = ({active, name, func,palette}) => {
    return(
        <Button style={active ? selectedCallOut : unSelectedCallOut }
            onClick={e => func(name)}>  
                        <svg id="iconFloat6" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58" space="preserve" >
							<g>
								<path name="pepe" className="svg2" fill={palette.color1.color}  d="M0,16.207c0-8.822,7.178-16,16-16h25c8.822,0,17,7.178,17,16v16c0,8.485-7.644,15.429-16,15.949
									v-3.949c0-0.553-0.448-1-1-1s-1,0.447-1,1v4v1v8.586l-1.359-1.359c-5.306-5.305-12.359-8.227-19.86-8.227H16
									c-8.822,0-16-7.178-16-16L0,16.207z"></path>
								<path className="svg3" fill={palette.color2.color} d="M30,18.207H16c-0.553,0-1-0.448-1-1s0.447-1,1-1h14c0.553,0,1,0.448,1,1S30.553,18.207,30,18.207z"></path>
								<path className="svg3" fill={palette.color2.color} d="M43,26.207H16c-0.553,0-1-0.448-1-1s0.447-1,1-1h27c0.553,0,1,0.448,1,1S43.553,26.207,43,26.207z"></path>
								<path className="svg3" fill={palette.color2.color} d="M43,34.207H16c-0.553,0-1-0.448-1-1s0.447-1,1-1h27c0.553,0,1,0.448,1,1S43.553,34.207,43,34.207z"></path>
							</g>
							</svg>
                    </Button>
    )
}