import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    width: 75px;
    height: 75px;
    padding: 6px;
    margin: 10px;
    border: 1px solid rgba(0,0,0,0.2);
    transition: all 0.2s ease 0s;
    cursor: pointer;
   
    border-radius: 50%;
  &:hover {
    box-shadow:0 0 20px rgba(0,0,0,0.2);
  }
  & svg{
    border-bottom-left-radius: 43%;
    border-bottom-right-radius: 50%;
    margin-top: 3px;
  }
`


export  const  ButtonAvatarMenBlue = ({func,avatarId}) => {
    return(
      <Button onClick= {e => func(avatarId)}>  
         <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"width="65px" height="65px" viewBox=" 0  0 490 390" enableBackground="new 0  0 490 390" space="preserve">
            <g>
                <path fill="#324A5E" d="M279.412,311.368c-11.056-1.759-11.308-32.157-11.308-32.157s32.484-32.157,39.564-75.4
                    c19.045,0,30.809-45.974,11.76-62.148C320.227,124.635,343.909,8,224,8c-119.911,0-96.226,116.636-95.433,133.662
                    c-19.047,16.175-7.284,62.148,11.762,62.148c7.079,43.243,39.563,75.4,39.563,75.4s-0.252,30.398-11.307,32.157
                    C132.976,317.034,0,375.686,0,440h224h224C448,375.686,315.023,317.034,279.412,311.368z"/>
            </g>
        </svg>
        
      </Button>   
    )
}