import React from "react";
import { connect } from "react-redux";
import { openLoader, closeLoader } from "../../rootActions";
import "./Login.scss";
import { userActions } from "../../actions";
import Logo from "../../assets/img/logo.svg";
import AnimatedInput from "../../components/AnimatedInput/AnimatedInput";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: "",
      user: {
        email: "",
        password: ""
      },
      fields: {
        email: "",
        password: ""
      },
      errors: {}
    };

    /*
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);*/
    this.clearErrors = this.clearErrors.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.closeLoader();
  }

  handleValidation = fields => {
    let errors = {};
    let formIsValid = true;

    /* email regex validation
    /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/*/

    if (
      !fields.email.value ||
      !/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/.test(
        fields.email.value
      )
    ) {
      formIsValid = false;
      errors.email = true;
    } else {
      errors.email = false;
    }

    if (!fields.password.value) {
      formIsValid = false;
      errors.password = true;
    } else {
      errors.password = false;
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });

    let user = this.state.user;
    user[field] = e.target.value;
    this.setState({ user });
  }

  clearErrors() {
    const _ = require("lodash");
    const errors = document.querySelectorAll(".user-messages-container .alert");

    if (errors) {
      _.forEach(errors, function(element) {
        element.style.display = "none";
      });
    }
  }

  handleSubmit = event => {
    event.preventDefault();

    this.clearErrors();

    if (this.handleValidation(event.target.elements)) {
      event.preventDefault();

      // show spinner
      this.props.openLoader();

      // disable button
      const button = this.refs.submitBtn;
      button.disabled = true;
      button.classList.add("disabled");

      const user = {
        email: event.target.elements.email.value,
        password: event.target.elements.password.value
      };

      console.log(user);

      userActions.login(user);
    }
  };

  onFocus(event) {
    const focusParent = event.target.parentNode;
    focusParent.classList.add("focus");
  }

  onBlur(event) {
    const focusParent = event.target.parentNode;

    if (event.target.value === "") {
      focusParent.classList.remove("focus");
    }
  }

  render() {
    return (
      <div className="login padding-top-bottom">
        <section>
          <div className="page-contents">
            <header>
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <div className="info-container">
                <h1>Start in ChatBox</h1>
                <p>
                  ¿New in Chat Box? <a href="/register">Regístrate</a>
                </p>
              </div>
            </header>
            <div className="login-container flex-container">
              <div className="form-container border-right">
                <form name="form" onSubmit={this.handleSubmit}>
                  <AnimatedInput
                    label="Email"
                    name="email"
                    errorMessage="Por favor ingrese un email válido"
                    error={this.state.errors.email}
                  />
                  <AnimatedInput
                    label="Contraseña"
                    name="password"
                    type="password"
                    errorMessage="Por favor ingrese su contraseña"
                    error={this.state.errors.password}
                  />
                  <div className={"form-group remember"}>
                    <div className="remember-checkbox">
                      <input type="checkbox" name="remember" />
                      <label htmlFor="remember">Recuérdame</label>
                    </div>
                    <div className="forgot-password">
                      <a href="/forgot-password">¿Olvidaste tu contraseña?</a>
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="button submit" ref="submitBtn">
                      Iniciar Sesión
                    </button>
                  </div>
                  <div className="form-group user-messages-container">
                    <div className="success-msg alert success">
                      Successful operation
                    </div>
                    <div className="generic-error alert danger">
                      Server error
                    </div>
                    <div className="wrong-credentials alert danger">
                      Bad user or password
                    </div>
                  </div>
                </form>
              </div>
              <div className="social-login">
                <div className="hidden-xs message">o conectarse con</div>
                <button href="#" className="social-item">
                  <div className="icon facebook" />
                  <div className="social-link facebook">
                    Continuar con Facebook
                  </div>
                </button>
                <button href="#" className="social-item">
                  <div className="icon google" />
                  <div className="social-link google">Continuar con Google</div>
                </button>
              </div>
            </div>
          </div>
          <div className="tyc">
            <p>
              * Al iniciar tu sesión, aceptas nuestros Térrminos de Uso y
              recibirás correos electrónicos y actualizaciones de ChatBox; al
              reconocer que leíste nuestra Polírtica de Privacidad.
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(
  null,
  { openLoader, closeLoader }
)(Login);
