import React from 'react'
import styled from 'styled-components'
import i18n from '../../../i18n/i18n'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import addChat from "../../../assets/img/add-chat.svg";
import Button from '@material-ui/core/Button';



const tRoot = i18n()


const HeaderContent = styled.div`
  display: grid;
  text-align:left;
    h2{
      margin-bottom:0px;
      text-align: center;
      font-size: 32px;
      padding: 40px 0 20px;
      font-weight: 300;

    };
    h3{
      margin-bottom:0px;
      text-align: center;
      font-size: 28px;
      padding: 40px 0 20px;
      font-weight: 300;

    }
 
`
const Copete = styled.div`
  text-align:left;
`
const Content = styled.div`
  margin-bottom: 30px;
  border: 0px solid #ccc;
  width: 100%;
  padding: 30px;
  border-radius: 4px;
  display: inline-block;
  background:#fff;
  margin:30px 0px;

  text-align:center;
`


export const styles = theme => ({
  margin: {
    marginBottom: '20px',
    width: '100%',

  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },

});

//onClick={() => {
//  props.history.push("/dashboard/new-chat");
//}}


export const view = (props, { handleNewChat, handlegotoDashboard }) => {
  
  return (
    <section className="dashboard-container">
      <HeaderContent>
        <h2>{tRoot('welcomeToBlahbox')} </h2>
        <h3>{tRoot('youCanStart')}</h3>
        <Copete></Copete>
      </HeaderContent>
      <Content>
        <div className="chats-container">

          <Card
            className="box moveUp new-chat-box"
            onClick={handleNewChat}
          >
            <CardActionArea>
              <CardContent>
                <div>
                  <img src={addChat} alt="add new chat" />
                </div>
                <div className="hover-contentAdd">
                  <p>{tRoot('newChat')}</p>
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <div>
          <Button onClick={handlegotoDashboard} color="primary">{tRoot('goToDashboard')}</Button>
        </div>
      </Content>
    </section>



  )
}




