import React from "react"
import { Grid, Card, Icon, IconButton, Tooltip } from "@material-ui/core"
import { withStyles } from "@material-ui/styles";
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom'

import "./StatsCards.scss"
import lang from '../../../../i18n/lang'
import i18n from '../../../../i18n/i18n'

const tRoot = i18n()

const styles = theme => ({
    icon: {
        fontSize: "44px",
        opacity: 0.6,
        color: theme.palette.primary.main
    },
    MuiCard: {
        borderRadius: "8px",
        display: 'flex',
        'flex-wrap': 'wrap',
        'flex-direction': 'row',
        'justify-content': 'space-between',
        'align-items': 'center',
        small: {
            'line-height': 1,
        },
        padding: '24px !important',
        background: 'var(--bg-paper)'
    }

});


const StatCards = ({ classes, ...props }) => {
    var plan = JSON.parse(localStorage.getItem('plan'))
    const history = useHistory()

    const handleUsage = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging Usage'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push('/dashboard/chat-settings/usage')
    }

    const handlePlan = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging Plan'
        }, ['backTrackerV2', 'backTrackerV3'])

        history.push('/dashboard/chat-settings/subscription')
    }

    const handleHelp = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging Analytics'
        }, ['backTrackerV2', 'backTrackerV3'])

        let url = lang() === 'es' ? 'https://supportes.blahbox.net/portal/es/kb/articles/agregar-id-de-seguimiento-de-google-analytics' : 'https://supporten.blahbox.net/portal/en/kb/articles/add-google-analytics-tracking-id'
        window.open(url, "_blank")
    }

    const handleRichMessages = async () => {
        ReactGA.event({
            category: 'Chat',
            action: 'Chatlanging RichMessages'
        }, ['backTrackerV2', 'backTrackerV3'])

        let url = lang() === 'es' ? 'https://supportes.blahbox.net/portal/es/kb/blahbox-spanish/mensajes-enriquecidos' : 'https://supporten.blahbox.net/portal/en/kb/blahbox/rich-messaging'

        window.open(url, "_blank")

    }

 
    return (
        <Grid container spacing={3} className="mb-3">
            <Grid item xs={12} md={6}>
                {/*  <Card className="play-card p-sm-24 bg-paper" elevation={6}> */}
                <Card className={classes.MuiCard} elevation={6}>
                    <div className="flex items-center">
                        <Icon className={classes.icon}>attach_money</Icon>
                        <div className="ml-3">
                            <small className="text-muted">{tRoot('actualPlan')}</small>
                            <h6 className="m-0 mt-1 text-primary font-medium">{plan.ProductName}</h6>
                        </div>
                    </div>
                    <Tooltip title={tRoot('actualPlanTooltip')} placement="top">
                        <IconButton onClick={handlePlan}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card className={classes.MuiCard} elevation={6}>
                    <div className="flex items-center">
                        <Icon className={classes.icon}>chat_icon</Icon>
                        <div className="ml-3">
                            <small className="text-muted">{tRoot('messagesReserved')}</small>
                            <h6 className="m-0 mt-1 text-primary font-medium"> {props.messagesUse} / {plan.messagesReserved}</h6>
                        </div>
                    </div>
                    <Tooltip title={tRoot('messagesReservedTootip')} placement="top">
                        <IconButton onClick={handleUsage}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card className={classes.MuiCard} elevation={6}>
                    <div className="flex items-center">
                        <Icon className={classes.icon}>timeline</Icon>
                        <div className="ml-3">
                            <small className="text-muted">{tRoot('seeAnalytics')}</small>

                        </div>
                    </div>
                    <Tooltip title={tRoot('seeAnalyticsTooltip')} placement="top">
                        <IconButton onClick={handleHelp}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card className={classes.MuiCard} elevation={6}>
                    <div className="flex items-center">
                        <Icon className={classes.icon}>view_carousel</Icon>
                        <div className="ml-3">
                            <small className="text-muted">{tRoot('richMessages')}</small>

                        </div>
                    </div>
                    <Tooltip title={tRoot('richMessagesTooltip')} placement="top">
                        <IconButton onClick={handleRichMessages}>
                            <Icon>arrow_right_alt</Icon>
                        </IconButton>
                    </Tooltip>
                </Card>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles, { withTheme: true })(StatCards);
