import React from "react";
import { connect } from "react-redux";
import { chatService } from "../../../../services";
import { wrapperDataBaseToRedux } from "../actionCreators.js";
import "./ChatConfig.scss";

import FloatingIconForm from "./FloatingIcon/Form";

import BoxPosition from './Box/Position';
import FloatingIconTooltip from "./FloatingIcon/Tooltip";
import SenderSectionBackground from "./SenderSection/Background";
import SenderSectionButton from "./SenderSection/Button";
import ReactGA from 'react-ga'
import TiaraHeader from "../../../../components/TiaraHeader"

//Elegant Imports //TODO clean imports up
import BoxSize from './Box/Size'
import BoxBackground from './Box/Background'
import HeaderLogo from './Header/Image'
import { HeaderMenu } from './Header/Menu'
import { PlaceHolderMessage } from './SenderSection/Message'
import { DialogBoxCallOuts } from './DialogBox/CallOuts'
import { FloatingIconIcon } from "./FloatingIcon/Icon";

class ChatConfigElegant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatID: '',
      chat: {},
      publishChatWindow: false
    };

    this.chatInit = this.chatInit.bind(this);
    //this.handleViews = this.handleViews.bind(this);
  }

  UNSAFE_componentWillMount() {
    ReactGA.pageview("/dashboard/chat-settings/configurations", ['backTrackerV2', 'backTrackerV3'])
    this.setState({ chatID: this.props.chatSelected._id })
  }

  componentDidMount() {
    // chat core data functionality

    chatService.getChat(this.props.chatSelected._id).then(response => {
      this.setState({
        chat: response.data.data
      });
    });

    // chat preview functionality
    //const chatDOMContainer = document.querySelector("#content");

    this.chatInit();
    this.props.loadChatSettingsFromDataBase(this.props.chatSelected)
  }

  chatInit() {
    let chatbox = window.chatbox;
    const chatID = this.props.chatSelected._id;
    const token = localStorage.getItem("token");

    (function (b, c, d) {
      var g;
      if (c) {
        g = "previewInit";
      } else {
        var h = document.createElement("div");
        g = "cbinit";
        h.id = "cbinit";
        document.body.append(h);
      }
      chatbox.initChat(document.querySelector("#" + g), b, c, d);
    })(chatID, 1, token);
  }


  render() {
    return (
      <div style={{ 'width': '100%' }}>

        <TiaraHeader />
        <div className="chat-config-page">

          <section className="section chat-configuration">




            {this.props.settingSelected === "floatingIconPosition" ? (
              <BoxPosition />
            ) : (
              ""
            )}
            {this.props.settingSelected === "floatingIconForm" ? (
              <FloatingIconForm />
            ) : (
              ""
            )}

            {this.props.settingSelected === "floatingIconIcon" ? (
              <FloatingIconIcon />
            ) : (
              ""
            )}


            {this.props.settingSelected === "floatingIconTooltip" ? (
              <FloatingIconTooltip />
            ) : (
              ""
            )}






            {this.props.settingSelected === "senderSectionButton" ? (
              <SenderSectionButton />
            ) : (
              ""
            )}

            {this.props.settingSelected === "senderSectionBackground" ? (
              <SenderSectionBackground />
            ) : (
              ""
            )}
            {/***************************************************** */}
            {this.props.settingSelected === "boxSize" ? (
              <BoxSize />
            ) : (
              ""
            )}
            {this.props.settingSelected === "boxBackground" ? (
              <BoxBackground />
            ) : (
              ""
            )}
            {this.props.settingSelected === "boxPosition" ? (
              <BoxPosition />
            ) : (
              ""
            )}
            {this.props.settingSelected === "headerLogo" ? (
              <HeaderLogo />
            ) : (
              ""
            )}
            {this.props.settingSelected === "headerMenu" ? (
              <HeaderMenu />
            ) : (
              ""
            )}
            {this.props.settingSelected === "placeHolderMessage" ? (
              <PlaceHolderMessage />
            ) : (
              ""
            )}
            {this.props.settingSelected === "dialogBoxCallOuts" ? (
              <DialogBoxCallOuts />
            ) : (
              ""
            )}


          </section>
          <section className="section chat-preview pl0 pr0 ">
            <h2 className="pl30">Preview</h2>
            <div id="previewInit" className="chat-preview-container " />
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    settingSelected: state.baseData.settingSelected,
    chatSelected: state.baseData.currentChatConfig
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadChatSettingsFromDataBase: (chatConfig) => {
      wrapperDataBaseToRedux(chatConfig).forEach(f => dispatch(f()))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatConfigElegant);
