import React, {useState} from 'react'
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
    Color,
    FormControl,
    InputLabel,
    Input
} from './styled'

export const ColorPicker = ({ color, label, colorPicked }) => {
    const [openPicker, setOpenPicker] = useState(false)

    const closePicker = () => setOpenPicker(false)

    const handleShow = () => setOpenPicker(!openPicker)

    const pickColor = color => {
        colorPicked(color.hex)
    }

    return (
        <ClickAwayListener onClickAway={closePicker}>
            <FormControl>
                <InputLabel>
                {label}
                </InputLabel>
                <Input
                    id={label}
                    value={color ? color : ''}
                />
                <Color className="color" style={{ background: color }} onClick={handleShow} />
                {openPicker ? <SketchPicker
                    onChangeComplete={pickColor}
                    color={color} /> : ""}
            </FormControl>
        </ClickAwayListener>
    )

}