import React from 'react'
import {
    CircleIcon,
    Button
} from './styled'

export const CircleButton = ({ active, handleSwitchChange }) => {
    let opacityLevel = active ? 0.9 : 0.3
    return (
        <Button
            onClick={e => handleSwitchChange('circle')}
        >
            <CircleIcon style={{opacity: opacityLevel}}/>
        </Button>
    )
}