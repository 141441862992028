import styled from 'styled-components'
import RadioMaterial from '@material-ui/core/Radio'
import RadioGroupMaterial from '@material-ui/core/RadioGroup'
import FormControlLabelMaterial from '@material-ui/core/FormControlLabel'

export const Content = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: column;
    margin-bottom: 25px;
    justify-content: center;
    margin-top: 20px;
`

export const Radio = styled(RadioMaterial)`
`

export const RadioGroup = styled(RadioGroupMaterial)`
`

export const FormControlLabel = styled(FormControlLabelMaterial)`
`

export const RadiosContent = styled.div`
    align-self: center;
`
