import React from 'react'
import { withStyles } from '@material-ui/styles';
import {view,styles} from './view'
import {connect} from 'react-redux'
import { updateFloatingTooltip } from '../../../actionCreators.js';
import compose from 'recompose/compose';

class FloatingIconTooltip extends React.Component {
    // eslint-disable-next-line
     constructor(props){
        super(props)
    } 

    state = {
        text:'',
        color:'',
        font: '',
        size: '',
        showColorPicker: false,
        textOnFocus : false 
    }


    
    UNSAFE_componentWillMount = () => this.setState({...this.props.data})

    handleCloseChooseColor = () => this.setState({showColorPicker: false})
    handleShowChooseColor = () => this.setState({showColorPicker: !this.state.showColorPicker})

    handleFont = (event) => {
        this.setState({font:event.target.value},() => !this.state.textOnFocus && !this.state.showColorPicker && this.props.save(this.props.chatId,this.state))
        window.chatbot.updateChatIconStyles({"fontFamily":event.target.value})
    }

    handleChooseColor =  (color,event) => {
        this.setState({color:color.hex, showColorPicker:false},() =>!this.state.textOnFocus && !this.state.showColorPicker && this.props.save(this.props.chatId,this.state))
        window.chatbot.updateChatIconStyles({"color":color.hex})
    }
        
    handleFontSize = (event) => {
        this.setState({size:event.target.value}, ()=> !this.state.textOnFocus && !this.state.showColorPicker && this.props.save(this.props.chatId,this.state))
        window.chatbot.updateChatIconStyles({"fontSize": event.target.value})
    }

    handleText = (event) => {
        this.setState({textOnFocus:true,text:event.target.value})
        window.chatbot.updateChatIconText(event.target.value);
    }
    
    textLostFocus = () => this.setState({textOnFocus:false}, ()=> !this.state.textOnFocus && !this.state.showColorPicker && this.props.save(this.props.chatId,this.state))

    render = () => view(
        this.props,
        this.state,
        this.handleShowChooseColor,
        this.handleChooseColor,
        this.handleText,
        this.handleFont,
        this.handleFontSize,
        this.textLostFocus,
        this.handleCloseChooseColor)
}

const mapStateToProps = state => {
    return {
        chatId: state.baseData.currentChatConfig._id,
        data : state.chatSettings.FloatingIconTooltipResult.value
    }
}

const mapDispatchToProps = dispatch => {
    return {
        save: (chatId,data) => updateFloatingTooltip(chatId,data).then(result => dispatch(result)).catch(err => console.log(err))
    }
}

//export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(FloatingIconTooltip))
export default compose(
    withStyles(styles, { name: 'FloatingIconTooltip' }),
    connect(mapStateToProps, mapDispatchToProps)
  )(FloatingIconTooltip)