import React from 'react'
import HeaderConfig from '../../HeadLine/index'
import styled from 'styled-components'
import { CallOutsDoubleBoxes } from './ImageAvatars/callOutsDoubleBoxes';
import { RadiusCallOuts } from './ImageAvatars/radiusCallOuts';
import { DoubleCircleCallOuts } from './ImageAvatars/doubleCircleCallOuts';
import { CircleCallOuts } from './ImageAvatars/circleCallOuts';
import { SketchPicker } from 'react-color';
import { filter } from 'ramda'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import i18n from  '../../../../../../i18n/i18n'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Feedback from '../../../../../../components/feedback'

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
    margin-bottom:25px;
`
const ContentForm = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
    margin-bottom:25px;
`
const Label = styled.label`
     text-align:left !important;
     display:grid;
     font-size:18px;
`

const Color = styled.div`
    border: 2px solid rgba(0,0,0,0.5);
    display: block;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 0;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
    cursor: pointer;
    z-index: 10;
    width:30px !important;
`
const t = i18n("chatSettings")
const tRoot = i18n()

export const styles = theme => ({})


export const view = ({ classes }, state, handlers) => {


    let selected = filter(e => state[e].selected, Object.keys(state))[0]
    let functions = handlers[selected]

    let color1 = state[selected] ? state[selected].color1.color : ''
    let showColorPicker1 = state[selected] && state[selected].color1.showPicker
    let handleChooseColor1 = functions && functions.pickColor1
    let handleShowChoosePicker1 = functions && functions.showPicker1

    let color2 = state[selected] ? state[selected].color2.color : ''
    let showColorPicker2 = state[selected] && state[selected].color2.showPicker
    let handleChooseColor2 = functions && functions.pickColor2
    let handleShowChoosePicker2 = functions && functions.showPicker2

    let color3 = state[selected] ? state[selected].color3.color : ''
    let showColorPicker3 = state[selected] && state[selected].color3.showPicker
    let handleChooseColor3 = functions && functions.pickColor3
    let handleShowChoosePicker3 = functions && functions.showPicker3

    return (
        <div>
            <HeaderConfig title={t('floatingIcon')} />
            <Label>{t('icon')}</Label>
            <Content>
                <CallOutsDoubleBoxes
                    palette={state.callOutsDoubleBoxes}
                    name="callOutsDoubleBoxes"
                    active={state.callOutsDoubleBoxes.selected}
                    func={handlers.callOutsDoubleBoxes.selectACallOut}
                />
{/*                 <MenCallOuts
                    name="menCallOuts"
                    palette={state.menCallOuts}
                    active={state.menCallOuts.selected}
                    func={handlers.menCallOuts.selectACallOut}
                />
                <HumanCallOuts
                    name="humanCallOuts"
                    palette={state.humanCallOuts}
                    active={state.humanCallOuts.selected}
                    func={handlers.humanCallOuts.selectACallOut}
                /> */}
                <CircleCallOuts
                    name="circleCallOuts"
                    palette={state.circleCallOuts}
                    active={state.circleCallOuts.selected}
                    func={handlers.circleCallOuts.selectACallOut}
                />
                <DoubleCircleCallOuts
                    name="doubleCircleCallOuts"
                    palette={state.doubleCircleCallOuts}
                    active={state.doubleCircleCallOuts.selected}
                    func={handlers.doubleCircleCallOuts.selectACallOut}
                />
                <RadiusCallOuts
                    name="radiusCallOuts"
                    palette={state.radiusCallOuts}
                    active={state.radiusCallOuts.selected}
                    func={handlers.radiusCallOuts.selectACallOut}
                />
            </Content>





            {!selected ? '' :
                <ContentForm>
                    <Label style={{ gridColumn: "1 / span 2" }}>{t('editColor')}</Label>
                    <ClickAwayListener onClickAway={handlers.closeColor1Picker}>
                    <FormControl className={classes.margin} >
                        <InputLabel
                            htmlFor="color1"
                            classes={{
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                            }} >

                            Color 1
                    </InputLabel>
                        <Input
                            id="color1"
                            value={color1}
                            classes={{
                                underline: classes.cssUnderline,
                            }} />
                        <Color className='color1' style={{ background: color1 }} onClick={handleShowChoosePicker1}/>

                        {showColorPicker1 ?
                            <SketchPicker
                                onChange={handleChooseColor1}
                                color={color1} /> : ""}
                    </FormControl>
                    </ClickAwayListener>
                    <ClickAwayListener onClickAway={handlers.closeColor2Picker}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            classes={{
                                root: classes.cssLabel,
                                focused: classes.cssFocused,
                            }} >
                            Color 2
                    </InputLabel>
                        <Input
                            id="background2"
                            value={color2}
                            classes={{
                                underline: classes.cssUnderline,
                            }} />
                        <Color className='color2' style={{ background: color2 }} onClick={handleShowChoosePicker2} />

                        {showColorPicker2 ?
                            <SketchPicker
                                onChange={handleChooseColor2}
                                color={color2} /> : ""}
                    </FormControl>
                    </ClickAwayListener>



                    {selected === 'circleCallOuts' || selected === 'radiusCallOuts' ? '' :
                       <ClickAwayListener onClickAway={handlers.closeColor3Picker}>
                       <FormControl className={classes.margin}>
                            <InputLabel
                                classes={{
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                }} >
                                Color 3
                    </InputLabel>
                            <Input
                                id="background3"
                                value={color3}
                                classes={{
                                    underline: classes.cssUnderline,
                                }} />
                            <Color className='color3' style={{ background: color3 }} onClick={handleShowChoosePicker3} />

                            {showColorPicker3 ?
                                <SketchPicker
                                    onChange={handleChooseColor3}
                                    color={color3} /> : ""}
                        </FormControl>
                        </ClickAwayListener>
                    }

                </ContentForm>

            }



            <Label>{tRoot('background')}</Label>
            <ContentForm>
            <ClickAwayListener onClickAway={handlers.closeBackgroundPicker}>
                <FormControl className={classes.margin}>
                    <InputLabel
                        classes={{
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                        }} >
                        Color
                    </InputLabel>
                    <Input
                        id="background"
                        value={state.background.color}
                        classes={{
                            underline: classes.cssUnderline,
                        }} />
                    <Color className='background' style={{ background: state.background.color }} onClick={handlers.background.showPicker} />

                    {state.background.showPicker ?
                        <SketchPicker
                            onChangeComplete={handlers.background.pickColor}
                            color={state.background.color} /> : ""}
                </FormControl>
                </ClickAwayListener>
            </ContentForm>
            <Feedback seccion="Floating-Icon" previewMode/>


        </div>
    )
}