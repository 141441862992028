import { useMutation, useQueryClient } from 'react-query'
import { chatUpdate } from '../../src/containers/Dashboard/ChatSettings/hooksActionCreators'

export default function useChatMutation() {
    const queryClient = useQueryClient()

    return useMutation(updateData => chatUpdate(updateData.chatID, updateData.change),
    {
        onSuccess: (result) => {
            queryClient.invalidateQueries(['chatData', result.data.chat.id])
        },
        retry: 3
    })
}