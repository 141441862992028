import React from 'react'
import Auth0Lock from 'auth0-lock';
import { Redirect } from 'react-router-dom';

class Lock extends React.Component {
    lock = new Auth0Lock(process.env.AUTH0_CLIENT, process.env.AUTH0_DOMAIN, {
        auth: {
            responseType: 'token id_token',
            sso: false,
        },
        container: "Conteiner",
        theme: {
            primaryColor: '#c3c3c3'//'#3a99d8'
        }
    });

    constructor(props) {
        super(props);
        this.state = { loggedIn: false };
        this.onAuthenticated = this.onAuthenticated.bind(this);

        this.onAuthenticated();
    }
    onAuthenticated() {
        this.lock.on('authenticated', (authResult) => {
            let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
            localStorage.setItem('access_token', authResult.accessToken);
            localStorage.setItem('id_token', authResult.idToken);
            localStorage.setItem('expires_at', expiresAt);
            this.setState({ loggedIn: true });
        });
    }

    componentDidMount() {
        // Avoid showing Lock when hash is parsed.
        /*if (!(/access_token|id_token|error/.test(this.props.location.hash))) {
            this.lock.show();
        }*/

      

        this.lock.show();
    }

    render() {
        const style = { marginTop: '32px' }

        return (
            !this.state.loggedIn ? (
                <div>
                    <div id="Conteiner" style={style}></div>
                </div>
            ) : (
                    <Redirect to={{
                        pathname: '/dashboard/projects',
                        state: { from: this.props.location }
                    }} />
                )
        );
    }
}

export default Lock;