import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import "./Spinner.scss";

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div
        className={classNames("spinner-container", {
          on: this.props.openLoader
        })}
      >
        <div className="loading-spinner">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { openLoader: state.baseData.openLoader };
};

export default connect(
  mapStateToProps,
  null
)(LoadingSpinner);
