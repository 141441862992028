import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import compose from 'recompose/compose';


const Waiting = () => (
    <div>Esperando</div>
)

class Register extends React.Component {
    state = {

        mode : 'auth0',

        userName: {
            value: '',
            errorMessage: ''
        },
        userPassword: {
            value: '',
            errorMessage: ''
        },
        userPassword2: {
            value: '',
            errorMessage: ''
        },


    }
    // eslint-disable-next-line
    constructor(props) { super(props); }

    handleUserName = (event) => {
        this.setState({
            userName: {
                value: event.target.value,
                errorMessage: event.target.value ? '' : 'El campo es requerido'
            }
        })
    }
    handleUserPassword = (event) => {
        this.setState({
            userPassword: {
                value: event.target.value,
                errorMessage: event.target.value ? '' : 'El campo es requerido'
            }
        })
    }
    handleUserPassword2 = (event) => {
        this.setState({
            userPassword2: {
                value: event.target.value,
                errorMessage: event.target.value ? '' : 'El campo es requerido'
            }
        })
    }




    render = () => ( this.state.mode==='auth0' ? <Waiting/> : view(
        this.props,
        this.state,
        this.handleUserName,
        this.handleUserPassword,
        this.handleUserPassword2
    ))
}


const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Register))
export default compose(
    withStyles(styles, { name: 'Register' }),
    connect(mapStateToProps, mapDispatchToProps)
  )(Register)