export const UNAUTHORIZED = "UNAUTHORIZED"
export const AUTHORIZED = 'AUTHORIZED'

const initState = {
        userLogged : {
            userid: '',
            loggedAt: '',
            token: '',
            errorMessage: ''
        }
}

export  const loginReducer = (state=initState,action) => {
    switch(action.type){
        case AUTHORIZED:
            return { ...state, userLogged: action.data }
        case UNAUTHORIZED:
            return { ...state, userLogged: action.data }
        default:
            return state 
    }
}