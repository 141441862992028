import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import {updateDialogBoxBackground} from "../../../actionCreators.js"
import { UPDATE_DIALOGBOX_BACKGROUND} from '../../../ChatSettings';
import compose from 'recompose/compose';

class DialogBoxBackground extends React.Component {
	state = {
		selectedCallOut: '',
		background: '',
		showColorPicker : false
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	handleChooseColor = (color,event) => this.setState({background:color.hex}, () => {
		window.chatbot.updateMessagesSectionStyles({"backgroundColor":color.hex})
		this.props.save(color.hex,this.props.chatId)
	}) 
			

	handleCloseColorPicker = () => this.setState({showColorPicker: false})
	handleShowColorPicker = () => this.setState({showColorPicker:!this.state.showColorPicker})
	
	render = () => view(
		this.props.classes,
		this.state.background ||this.props.background,
		this.handleChooseColor,
		this.state.showColorPicker,
		this.handleShowColorPicker,
		this.handleCloseColorPicker)
}


const mapStateToProps = state => {
	return {
		background: (state.chatSettings.DialogBoxBackgroundResult && state.chatSettings.DialogBoxBackgroundResult.operation===UPDATE_DIALOGBOX_BACKGROUND) ? state.chatSettings.DialogBoxBackgroundResult.value : '',
		chatId: state.baseData.currentChatConfig._id
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (value,chatId) => updateDialogBoxBackground(value,chatId).then(result => dispatch(result)) 
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DialogBoxBackground))
export default compose(
	withStyles(styles, { name: 'DialogBoxBackground' }),
	connect(mapStateToProps, mapDispatchToProps)
  )(DialogBoxBackground)