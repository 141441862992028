import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ReactGA from 'react-ga';

import Feedback from '../../../components/feedback'
import i18n from '../../../i18n/i18n'
import { usage } from '../../Dashboard/ChatSettings/hooksActionCreators'
import Loader from '../../../components/LinearLoader/linearLoader'
import TiaraHeader from '../../../components/TiaraHeader'


const tRoot = i18n()

const useStyles = makeStyles({
    table: {
        minWidth: 450,
        width: 650,
        'border-top': 'outset'
    },
    paper: {
        minWidth: 450,
        width: 650,
    },
    tableHeader: {
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 'bold',
        'line-height': 1.5,
        'letter-spacing': '0.00938em',
        color: 'rgba(0, 0, 0, 0.87)',

    },
    item: {
        'font-size': '1rem',
        'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
        'font-weight': 400,
        'line-height': 1.5,
        'letter-spacing': '0.00938em',
        color: 'rgba(0, 0, 0, 0.87)',

    }

});

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 20px;
    padding:10px 0 20px;
    text-align:left;
`
const Body = styled.div`
    width: 100%;
    background: #fff;
    padding-left: 60px;
    height: 100vh;
    overflow-y: scroll;
`

const HeaderContent = styled.div`
    display: grid;
    grid-template-columns:  1fr;
    grid-column-gap:20px;
    h2 {
      text-align:left;
      padding-top: 5px;
    }
`
const Project = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: #b9bec1;
    text-align:left;
    float: left;
    height:30px;
    width:100%
`

const Usage = () => {
    const classes = useStyles();
    var appState = JSON.parse(localStorage.getItem('appState'))
    var chatID = appState.baseData.currentChatConfig._id
    var chatName = appState.baseData.currentChatConfig.name

    const [loader, setLoader] = useState(false)
    const [messages, setMessages] = useState([])

    useEffect(() => {
        ReactGA.pageview("/general/usage", ['backTrackerV2', 'backTrackerV3'])
        openLoader()
        fetchData()
    }, [])

    const fetchData = async () => {
        let result = await usage(chatID)
        closeLoader()
        setMessages(result)
    }

    const openLoader = () => setLoader(true)
    const closeLoader = () => setLoader(false)

    return (
        <div style={{'width': '100%'}}>
        <TiaraHeader />
        <Body>
            
            <HeaderContent>
                <Project>{chatName}</Project>
                <h2 style={{ 'padding-top': '5px', 'padding-bottom': '25px' }}>
                    {tRoot('usageHistoy')}
                </h2>
            </HeaderContent>
            <Content>
                <TableContainer component={Paper} className={classes.paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>{tRoot('Period')}</TableCell>
                                <TableCell align="right" className={classes.tableHeader}>{tRoot('Plan')}</TableCell>
                                <TableCell align="right" className={classes.tableHeader}>{tRoot('Reserved')}</TableCell>
                                <TableCell align="right" className={classes.tableHeader}>{tRoot('Messages')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loader ?
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Loader />
                                    </TableCell>
                                </TableRow>
                                : ''}
                            {messages.map((rowm) => (
                                <TableRow key={rowm.startDate}>
                                    <TableCell component="th" scope="row" className={classes.item}>
                                        {rowm.startDate} {' > '} {rowm.endDate}
                                    </TableCell>
                                    <TableCell align="right" className={classes.item}>{rowm.plan}</TableCell>
                                    <TableCell align="right" className={classes.item}>{rowm.messagesReserved}</TableCell>
                                    <TableCell align="right" className={classes.item}>{rowm.messages}</TableCell>
                                </TableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Feedback seccion="General-Historial" />
            </Content>
        </Body>
        </div>
    )

}

export default Usage


