import React from 'react'
import styled from 'styled-components'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Feedback from '../../../../../../../components/feedback'

const Conteiner = styled.div`
    width:400px;
    height: 400px
`
const Buttons = styled.div`
    width:400px;
    margin-top:20px;
`

const Save = styled.button`
    background: #324a5e;
    border-radius: 4px;
    width:100px;
    margin:auto;
    padding:10px;
    color:#fff;
    margin-right:20px;
`

const Cancel = styled.button`
    background: #324a5e;
    border-radius: 4px;
    width:100px;
    margin:auto;
    padding:10px;
    color:#fff;
`



export const view = ({ onImageLoaded, onCropComplete, onCropChange, src, crop, confirmImageAvatar, cancelImageAvatar }) => {

    return (
        <Conteiner>
            <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
            />
            <Buttons>
                <Save onClick={e => confirmImageAvatar()}>Aplicar</Save>
                <Cancel onClick={e => cancelImageAvatar()}>Cancelar</Cancel>
            </Buttons>
            <Feedback seccion="Header-ImageCropper" previewMode/>
        </Conteiner>
    )
}