import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles';
import { view, styles } from './view'
import { updateFloatingIconIcon } from '../../../actionCreators.js';
import compose from 'recompose/compose';



class FloatingIconIcon extends React.Component {
	state = {
		background: {
			color: '',
			showPicker: false
		},
		callOutsDoubleBoxes: {
			selected: false,
			url: '',
			color1: {
				color: '#ebba16',
				showPicker: false
			},
			color2: {
				color: '#ed8a19',
				showPicker: false
			},
			color3: {
				color: 'white',
				showPicker: false
			}
		},
		circleCallOuts: {
			selected: false,
			url: '',
			color1: {
				color: '#ed8a19',
				showPicker: false
			},
			color2: {
				color: 'white',
				showPicker: false
			},
			color3: {
				color: '',
				showPicker: false
			}
		},
		doubleCircleCallOuts: {
			selected: false,
			url: '',
			color1: {
				color: '#ebba16',
				showPicker: false
			},
			color2: {
				color: '#ed8a19',
				showPicker: false
			},
			color3: {
				color: 'white',
				showPicker: false
			}
		},
		humanCallOuts: {
			selected: false,
			url: '',
			color1: {
				color: '#e8eae9',
				showPicker: false
			},
			color2: {
				color: '#ed8a19',
				showPicker: false
			},
			color3: {
				color: 'white',
				showPicker: false
			}
		},
		menCallOuts: {
			selected: false,
			url: '',
			color1: {
				color: '#8ad7f8',
				showPicker: false
			},
			color2: {
				color: '#ed8a19',
				showPicker: false
			},
			color3: {
				color: 'white',
				showPicker: false
			}
		},
		radiusCallOuts: {
			selected: false,
			url: '',
			color1: {
				color: '#ed8a19',
				showPicker: false
			},
			color2: {
				color: 'white',
				showPicker: false
			},
			color3: {
				color: '',
				showPicker: false
			}
		}
	}

	UNSAFE_componentWillMount = () => this.setState({ ...this.props.data })

	handlers = {
		clearSelected: () => {
			this.setState({
				callOutsDoubleBoxes: { ...this.state.callOutsDoubleBoxes, selected: false },
				circleCallOuts: { ...this.state.circleCallOuts, selected: false },
				doubleCircleCallOuts: { ...this.state.doubleCircleCallOuts, selected: false },
				humanCallOuts: { ...this.state.humanCallOuts, selected: false },
				menCallOuts: { ...this.state.menCallOuts, selected: false },
				radiusCallOuts: { ...this.state.radiusCallOuts, selected: false }
			})
		},

		closeColor1Picker: () => {
			const new_state = Object.assign({}, this.state)
			new_state.callOutsDoubleBoxes.color1.showPicker = false
			new_state.circleCallOuts.color1.showPicker = false
			new_state.humanCallOuts.color1.showPicker = false
			new_state.menCallOuts.color1.showPicker = false
			new_state.radiusCallOuts.color1.showPicker = false
			new_state.doubleCircleCallOuts.color1.showPicker = false
			this.setState({ ...new_state })
		},

		closeColor2Picker: () => {
			const new_state = Object.assign({}, this.state)
			new_state.callOutsDoubleBoxes.color2.showPicker = false
			new_state.circleCallOuts.color2.showPicker = false
			new_state.humanCallOuts.color2.showPicker = false
			new_state.menCallOuts.color2.showPicker = false
			new_state.radiusCallOuts.color2.showPicker = false
			new_state.doubleCircleCallOuts.color2.showPicker = false
			this.setState({ ...new_state })
		},

		closeColor3Picker: () => {
			const new_state = Object.assign({}, this.state)
			new_state.callOutsDoubleBoxes.color3.showPicker = false
			new_state.circleCallOuts.color3.showPicker = false
			new_state.humanCallOuts.color3.showPicker = false
			new_state.menCallOuts.color3.showPicker = false
			new_state.radiusCallOuts.color3.showPicker = false
			new_state.doubleCircleCallOuts.color3.showPicker = false
			this.setState({ ...new_state })
		},

		closeBackgroundPicker: () => {
			const new_state = Object.assign({}, this.state)
			new_state.background.showPicker = false
			this.setState({ ...new_state })
		},

		unSelectAll: (event) => {

			
			window.eve = event.target
			const new_state = Object.assign({}, this.state)

			new_state.callOutsDoubleBoxes.color1.showPicker = event.target.attributes.class.value.includes('color1') ? this.state.callOutsDoubleBoxes.color1.showPicker : false
			new_state.callOutsDoubleBoxes.color2.showPicker = event.target.attributes.class.value.includes('color2') ? this.state.callOutsDoubleBoxes.color2.showPicker : false
			new_state.callOutsDoubleBoxes.color3.showPicker = event.target.attributes.class.value.includes('color3') ? this.state.callOutsDoubleBoxes.color3.showPicker : false
			new_state.circleCallOuts.color1.showPicker = event.target.attributes.class.value.includes('color1') ? this.state.circleCallOuts.color1.showPicker : false
			new_state.circleCallOuts.color2.showPicker = event.target.attributes.class.value.includes('color2') ? this.state.circleCallOuts.color2.showPicker : false
			new_state.circleCallOuts.color3.showPicker = event.target.attributes.class.value.includes('color3') ? this.state.circleCallOuts.color3.showPicker : false
			new_state.humanCallOuts.color1.showPicker = event.target.attributes.class.value.includes('color1') ? this.state.humanCallOuts.color1.showPicker : false
			new_state.humanCallOuts.color2.showPicker = event.target.attributes.class.value.includes('color2') ? this.state.humanCallOuts.color2.showPicker : false
			new_state.humanCallOuts.color3.showPicker = event.target.attributes.class.value.includes('color3') ? this.state.humanCallOuts.color3.showPicker : false
			new_state.menCallOuts.color1.showPicker = event.target.attributes.class.value.includes('color1') ? this.state.menCallOuts.color1.showPicker : false
			new_state.menCallOuts.color2.showPicker = event.target.attributes.class.value.includes('color2') ? this.state.menCallOuts.color2.showPicker : false
			new_state.menCallOuts.color3.showPicker = event.target.attributes.class.value.includes('color3') ? this.state.menCallOuts.color3.showPicker : false
			new_state.radiusCallOuts.color1.showPicker = event.target.attributes.class.value.includes('color1') ? this.state.radiusCallOuts.color1.showPicker : false
			new_state.radiusCallOuts.color2.showPicker = event.target.attributes.class.value.includes('color2') ? this.state.radiusCallOuts.color2.showPicker : false
			new_state.radiusCallOuts.color3.showPicker = event.target.attributes.class.value.includes('color3') ? this.state.radiusCallOuts.color3.showPicker : false
			new_state.background.showPicker = event.target.attributes.class.value.includes('background') ? this.state.background.showPicker : false
			this.setState({ ...new_state })
		},

		callOutsDoubleBoxes: {
			selectACallOut: event => {
				window.chatbot.updateChatIconImage("https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-1.svg")
				this.handlers.clearSelected()
				this.setState({
					callOutsDoubleBoxes: {
						...this.state.callOutsDoubleBoxes,
						url: "https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-1.svg",
						selected: true
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor1: color => this.setState({
				callOutsDoubleBoxes: {
					...this.state.callOutsDoubleBoxes,
					color1: { color: color.hex, showPicker: true }
				}
			},
				() => {
					window.chatbot.updateChatIconStyles({ iconColor1: color.hex })
					this.props.save(this.props.chatId, this.state)
				}),
			showPicker1: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					callOutsDoubleBoxes: {
						...this.state.callOutsDoubleBoxes,
						color1: {
							...this.state.callOutsDoubleBoxes.color1,
							showPicker: !this.state.callOutsDoubleBoxes.color1.showPicker
						}
					}
				})
			},
			pickColor2: color => this.setState({ callOutsDoubleBoxes: { ...this.state.callOutsDoubleBoxes, color2: { color: color.hex, showPicker: true } } },
				() => {
					window.chatbot.updateChatIconStyles({ iconColor2: color.hex })
					this.props.save(this.props.chatId, this.state)
				}),
			showPicker2: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					callOutsDoubleBoxes: {
						...this.state.callOutsDoubleBoxes,
						color2: {
							...this.state.callOutsDoubleBoxes.color2,
							showPicker: !this.state.callOutsDoubleBoxes.color2.showPicker
						}
					}
				})
			},
			pickColor3: color => this.setState({ callOutsDoubleBoxes: { ...this.state.callOutsDoubleBoxes, color3: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor3: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker3: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					callOutsDoubleBoxes: {
						...this.state.callOutsDoubleBoxes,
						color3: {
							...this.state.callOutsDoubleBoxes.color3,
							showPicker: !this.state.callOutsDoubleBoxes.color3.showPicker
						}
					}
				})
			}
		},
		circleCallOuts: {
			selectACallOut: event => {
				window.chatbot.updateChatIconImage("https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-4.svg")
				this.handlers.clearSelected()
				this.setState({
					circleCallOuts: {
						...this.state.circleCallOuts,
						url: "https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-4.svg",
						selected: true
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor1: color => this.setState({ circleCallOuts: { ...this.state.circleCallOuts, color1: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor1: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker1: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					circleCallOuts: {
						...this.state.circleCallOuts,
						color1: {
							...this.state.circleCallOuts.color1,
							showPicker: !this.state.circleCallOuts.color1.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor2: color => this.setState({ circleCallOuts: { ...this.state.circleCallOuts, color2: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor2: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker2: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					circleCallOuts: {
						...this.state.circleCallOuts,
						color2: {
							...this.state.circleCallOuts.color2,
							showPicker: !this.state.circleCallOuts.color2.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor3: color => this.setState({ circleCallOuts: { ...this.state.circleCallOuts, color3: { color: color.hex, showPicker: true } } }, () => this.props.save(this.props.chatId, this.state)),
			showPicker3: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					circleCallOuts: {
						...this.state.circleCallOuts,
						color3: {
							...this.state.circleCallOuts.color3,
							showPicker: !this.state.circleCallOuts.color3.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			}
		},
		doubleCircleCallOuts: {
			selectACallOut: event => {
				window.chatbot.updateChatIconImage("https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-5.svg")
				this.handlers.clearSelected()
				this.setState({
					doubleCircleCallOuts: {
						...this.state.doubleCircleCallOuts,
						url: "https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-5.svg",
						selected: true
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor1: color => this.setState({ doubleCircleCallOuts: { ...this.state.doubleCircleCallOuts, color1: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor1: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker1: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					doubleCircleCallOuts: {
						...this.state.doubleCircleCallOuts,
						color1: {
							...this.state.doubleCircleCallOuts.color1,
							showPicker: !this.state.doubleCircleCallOuts.color1.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor2: color => this.setState({ doubleCircleCallOuts: { ...this.state.doubleCircleCallOuts, color2: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor2: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker2: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					doubleCircleCallOuts: {
						...this.state.doubleCircleCallOuts,
						color2: {
							...this.state.doubleCircleCallOuts.color2,
							showPicker: !this.state.doubleCircleCallOuts.color2.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor3: color => this.setState({ doubleCircleCallOuts: { ...this.state.doubleCircleCallOuts, color3: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor3: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker3: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					doubleCircleCallOuts: {
						...this.state.doubleCircleCallOuts,
						color3: {
							...this.state.doubleCircleCallOuts.color3,
							showPicker: !this.state.doubleCircleCallOuts.color3.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			}
		},
		humanCallOuts: {
			selectACallOut: event => {
				window.chatbot.updateChatIconImage("https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-3.svg")
				this.handlers.clearSelected()
				this.setState({
					humanCallOuts: {
						...this.state.humanCallOuts,
						url: "https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-3.svg",
						selected: true
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor1: color => this.setState({ humanCallOuts: { ...this.state.humanCallOuts, color1: { color: color.hex, showPicker: true } } }, () => this.props.save(this.props.chatId, this.state)),
			showPicker1: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					humanCallOuts: {
						...this.state.humanCallOuts,
						color1: {
							...this.state.humanCallOuts.color1,
							showPicker: !this.state.humanCallOuts.color1.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor2: color => this.setState({ humanCallOuts: { ...this.state.humanCallOuts, color2: { color: color.hex, showPicker: true } } }, () => this.props.save(this.props.chatId, this.state)),
			showPicker2: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					humanCallOuts: {
						...this.state.humanCallOuts,
						color2: {
							...this.state.humanCallOuts.color2,
							showPicker: !this.state.humanCallOuts.color2.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor3: color => this.setState({ humanCallOuts: { ...this.state.humanCallOuts, color3: { color: color.hex, showPicker: true } } }, () => this.props.save(this.props.chatId, this.state)),
			showPicker3: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					humanCallOuts: {
						...this.state.humanCallOuts,
						color3: {
							...this.state.humanCallOuts.color3,
							showPicker: !this.state.humanCallOuts.color3.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			}
		},
		menCallOuts: {
			selectACallOut: event => {
				window.chatbot.updateChatIconImage("https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-2.svg")
				this.handlers.clearSelected()
				this.setState({
					menCallOuts: {
						...this.state.menCallOuts,
						selected: true,
						url: "https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-2.svg"
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor1: color => this.setState({
				menCallOuts: {
					...this.state.menCallOuts,
					color1: { color: color.hex, showPicker: true }
				}
			},
				() => {
					window.chatbot.updateChatIconStyles({ iconColor1: color.hex })
					this.props.save(this.props.chatId, this.state)
				}),
			showPicker1: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					menCallOuts: {
						...this.state.menCallOuts,
						color1: {
							...this.state.menCallOuts.color1,
							showPicker: !this.state.menCallOuts.color1.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor2: color => this.setState({ menCallOuts: { ...this.state.menCallOuts, color2: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor2: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker2: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					menCallOuts: {
						...this.state.menCallOuts,
						color2: {
							...this.state.menCallOuts.color2,
							showPicker: !this.state.menCallOuts.color2.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor3: color => this.setState({ menCallOuts: { ...this.state.menCallOuts, color3: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor3: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker3: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					menCallOuts: {
						...this.state.menCallOuts,
						color3: {
							...this.state.menCallOuts.color3,
							showPicker: !this.state.menCallOuts.color3.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			}
		},
		radiusCallOuts: {
			selectACallOut: event => {
				window.chatbot.updateChatIconImage("https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-6.svg")
				this.handlers.clearSelected()
				this.setState({
					radiusCallOuts: {
						...this.state.radiusCallOuts,
						url: "https://res.cloudinary.com/blahbox/image/upload/chat-icons/chat-icon-6.svg",
						selected: true
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor1: color => this.setState({ radiusCallOuts: { ...this.state.radiusCallOuts, color1: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor1: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker1: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					radiusCallOuts: {
						...this.state.radiusCallOuts,
						color1: {
							...this.state.radiusCallOuts.color1,
							showPicker: !this.state.radiusCallOuts.color1.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor2: color => this.setState({ radiusCallOuts: { ...this.state.radiusCallOuts, color2: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor2: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker2: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					radiusCallOuts: {
						...this.state.radiusCallOuts,
						color2: {
							...this.state.radiusCallOuts.color2,
							showPicker: !this.state.radiusCallOuts.color2.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			},
			pickColor3: color => this.setState({ radiusCallOuts: { ...this.state.radiusCallOuts, color3: { color: color.hex, showPicker: true } } }, () => {
				window.chatbot.updateChatIconStyles({ iconColor3: color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker3: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({
					radiusCallOuts: {
						...this.state.radiusCallOuts,
						color3: {
							...this.state.radiusCallOuts.color3,
							showPicker: !this.state.radiusCallOuts.color3.showPicker
						}
					}
				}, () => this.props.save(this.props.chatId, this.state))
			}
		},
		background: {
			pickColor: color => this.setState({ background: { color: color.hex, showPicker: true } }, () => {
				window.chatbot.updateChatIconStyles({ "backgroundColor": color.hex })
				this.props.save(this.props.chatId, this.state)
			}),
			showPicker: (e) => {
				this.handlers.unSelectAll(e)
				this.setState({ background: { ...this.state.background, showPicker: !this.state.background.showPicker } })
			}
		}
	}
	// eslint-disable-next-line
	constructor(props) { super(props); }

	render = () => view(this.props, this.state, this.handlers)
}


const mapStateToProps = state => {
	return {
		chatId: state.baseData.currentChatConfig._id,
		data: state.chatSettings.FloatingIconIconResult.value
	}
}

const mapDispatchToProps = dispatch => {
	return {
		save: (chatId, data) => updateFloatingIconIcon(chatId, data).then(result => dispatch(result)).catch(err => console.log(err))
	}
}


//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FloatingIconIcon))
export default compose(
	withStyles(styles, { name: 'FloatingIconIcon' }),
	connect(mapStateToProps, mapDispatchToProps)
)(FloatingIconIcon)



